.wf-widget {
	display: block;
	background: url("https://portal.worldfavor.com/assets/img/loaders/onWhite_loop1_large.gif") center center no-repeat;
	min-height: 300px;
	width: 100%;

	&.loaded {
		background: transparent;
	}
}

.powered-by {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	text-align: right;

	a {
		display: inline-block;
		padding: 5px 0;
		color: #aaa;
		color: rgba(0, 0, 0, 0.4);
		font-size: 11px;
		
		&, &:hover {
			text-decoration: none;
		}

		.wf-logo {
			display: inline-block;
			background: url("https://portal.worldfavor.com/assets/img/logos/wf_logo_dark.png") center center no-repeat;
			background-size: contain;
			width: 57px;
			height: 10px;
		}
	}
}