@imageWidth: 60px;
@imageHeight: 60px;
@imageRightMargin: 16px;

@chartHeight: 200;
@xLabelsHeight: 43;
@yLabelsHeight: 33;
@cellSpacing: 1;

.add-placeholder {
	.wf-placeholder-item {
		display: block;
		background: #fff;

		//See wf-list-interface.less (under wf-list-item { div.wf-placeholder-item selector }) for placeholder colours
	
		.animated-background {
			animation-duration: 1s;
			animation-fill-mode: forwards;
			animation-iteration-count: infinite;
			animation-name: placeHolderShimmer;
			animation-timing-function: linear;
			background: #f6f7f8;
			height: @imageHeight + 46px;
			position: relative;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: @imageWidth;
				height: @imageHeight;
				border-radius: 3px;
				box-shadow: 0 0 0 4px #fff;
			}
		}//end of: .animated-background
		
		.mask {
			position: absolute;
			
			//-----Mask positioning-----
			&.header-top,
			&.header-bottom,
			&.subheader-bottom {
				top: 0;
				left: @imageWidth;
				right: 0;
				height: 6px;
			}
			
			&.header-left,
			&.subheader-left,
			&.header-right,
			&.subheader-right {
				top: 6px;
				left: @imageWidth;
				height: 12px;
				width: @imageRightMargin;
			}
			
			&.header-bottom {
				top: 18px;
				height: 6px;
			}
			
			&.subheader-left,
			&.subheader-right {
				top: 24px;
				height: 6px;
			}
			
			
			&.header-right,
			&.subheader-right {
				width: auto;
				left: 300px;
				right: 0;
			}
			
			&.subheader-right {
				left: 230px;
			}
			
			&.subheader-bottom {
				top: 30px;
				height: @imageHeight - 30px;
			}
			
			&.content-top,
			&.content-second-line,
			&.content-third-line,
			&.content-second-end,
			&.content-third-end,
			&.content-first-end {
				top: @imageHeight;
				left: 0;
				right: 0;
				height: 6px;
			}
			
			&.content-top {
				height:20px;
			}
			
			&.content-first-end,
			&.content-second-end,
			&.content-third-end{
				width: auto;
				left: 380px;
				right: 0;
				top: 70px;
				height: 8px;
			}
			
			&.content-second-line  {
				top: 78px;
			}
			
			&.content-second-end {
				left: 420px;
				top: 84px;
			}
			
			&.content-third-line {
				top: 92px;
			}
			
			&.content-third-end {
				left: 300px;
				top: 98px;
			}
		}//end of: .mask
	}//end of: .wf-placeholder-item

	.wf-placeholder-chart {
		.animated-background {
			background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
			background-size: 1000px 104px;
		
			animation-duration: 1s;
			animation-fill-mode: forwards;
			animation-iteration-count: infinite;
			animation-name: placeHolderShimmer;
			animation-timing-function: linear;
		
			height: @chartHeight * 1px;
			position: relative;
		}//end of: .animated-background
		
		.x-labels {
			width: 100%;
			height: 40px;
			position: relative;
			
			&:before {
				content: "";
				position: absolute;
				left: 0;
				width: @yLabelsHeight * 1px;
				height: @xLabelsHeight * 1px;
				background-color: white;
			}
		
			.label-mask {
				&.top {
					background-color: white;
					position: absolute;
					width: 100%;
					height: 17px;
				}
			
				&.bottom {
					background-color: white;
					position: absolute;
					width: 100%;
					height: 10px;
					top: 30px;
				}
			}
		}//end of: .x-labels
		
		.y-labels {
			.label-mask {
				position: static;
				width: 33px;
				height: @chartHeight * 1px;
			}
			
			.mask {
				display: inline-block;
				height: 100%;
	
				&.left {
					width: 10px;
				}
			
				&.right {
					background-color: white;
					width: 20px;
				}
			}
		}//end of: .y-labels
		
		.grid-table {
			margin: 0 !important;
			table {
				width: 100%;
				border-spacing: @cellSpacing * 1px;
				border-collapse: separate;
				tr {
					td {
						height: (@chartHeight - @xLabelsHeight) / 5 * 1px;
						background-color: white;
					}
				}
			}
		}//end of: .grid-table
	}//end of: .wf-placeholder-chart

	.actual-item, .actual-chart {
		display: none;
	}
}

@keyframes placeHolderShimmer{
	0%{
		background-position: -468px 0
	}
	100%{
		background-position: 468px 0
	}
}