wf-dropdown,
wf-item-tools,
.wf-dropdown {
	display: inline-block;

	li.divider {
		display: none;

	}

	li+li.divider {
		display: block;
	}

	li.smaller {
		font-size: 10px;

		i {
			font-size: 10px !important;
			margin-right: 4px !important;
			margin-left: -2px !important;
		}
	}

	span.icon {
		position: relative;
		top: 1px;

		&.pull-left {
			margin-right: .5em;
		}

		&.pull-right {
			margin-left: .5em;
		}
	}

	.dropdown-custom-trigger {
		.btn {
			.icon {
				font-size: 14px;

				&:not(:last-child) {
					padding-right: 10px;
				}
			}
		}
	}

	&[use-plus-icon] {
		div[type="button"].btn {
			-webkit-box-shadow: none;
			box-shadow: none;
			margin: 0 !important;
			padding: 0;
			width: initial;

			i {
				font-size: 30px;
				color: #2685EE;

				&:hover {
					cursor: pointer;
					color: #4999F1;
				}
			}

			&:hover,
			&:active,
			&:focus {
				background-color: transparent;
			}

			&:active:focus {
				outline: none !important;
			}

		}
	}

	.dropdown {

		//Temporary solution to disable dropdown-backdrop. It shows only on LP's PC (maybe because of #shadow-root (open))
		.dropdown-backdrop {
			left: initial;
			right: initial;
			bottom: initial;
			top: initial;
		}



		.dropdown-submenu {
			ul {
				// width: 165px !important;
			}

			.dropdown-menu li {
				cursor: pointer;

				a {
					background-color: white;
				}

				i {
					color: #8e8e8e;
				}

				&:hover {
					i {
						color: @wf_blue;
					}

					a {
						background-color: @wf_gray_lighter;
					}

					span {
						color: #6f7f80
					}
				}
			}
		}

		//end of: .dropdown-submenu
	}
}

//end of: wf-dropdown



wf-dropdown .dropdown .dropdown-menu,
wf-item-tools .dropdown .dropdown-menu,
.wf-dropdown .dropdown .dropdown-menu,
.dropdown-menu.as-sortable-dragging {
	width: 190px !important;


	&.as-sortable-dragging {
		display: inline-block;

		.group-list-item {
			display: flex;
		}

		.title-text {
			display: inline-block;
			word-wrap: break-word;
			white-space: normal;
			width: 115px;
		}

		i {
			padding-right: 10px;
		}

		#sortable-group {
			padding-right: 0px;
			padding-left: 10px;
		}

	}


	li {
		border-left: solid 4px white;

		&.as-sortable-placeholder {
			box-shadow: 0 0 0 10px white inset;
			background-color: fade(@wf_gray_lighter, 40%);
		}

		>a {
			color: @wf_gray_dark;
			padding: 10px;

			&:after {
				margin-right: 0px;
				position: absolute;
				right: 12px;
				top: 50%;
				margin-top: -5px;
			}

			>i {

				&.fa,
				&.fas,
				&.glyphicon,
				&.ionicons {
					display: block;
					float: left;
					min-width: 14px;
					margin-right: 10px;
					text-align: center;
					font-size: 14px;
					color: #8e8e8e;
				}
			}

			>span {
				display: block;
				float: none;
				clear: none;
				overflow: hidden;
				padding-right: 20px;
				white-space: normal;

				&.subText {
					padding-left: 24px;
					font-size: 11px;
					color: @wf_gray;
				}
			}
		}


		&:hover {
			border-left: solid 4px @wf_blue;

			i {
				color: @wf_blue;
			}

			>a {
				background-color: @wf_gray_lighter;

				>span {
					color: #6f7f80;

					&.subText {
						color: @wf_gray_dark;
					}
				}
			}
		}

		&.removal {
			&:hover {
				border-left: solid 4px @wf_red;

				i {
					color: @wf_red
				}
			}
		}

		&.divider {
			padding: 0 !important;
			border-left: none;
		}
	}
}

// end of: .dropdown-menu