.invoiceController{
	min-height: 500px;
	transition: background-image 3s ease;
	background: no-repeat center center;
	background-color: white;
	border: white solid 1px;
	border-radius: 1px;
	padding: 30px;
	
	>p{
		padding-top: 15px;
		font-size: 12px;
		color: #D2D2D2;
		margin: 0;
	}

	&.loading {
		transition: background-image 0s ease;
		background-image: url(../../assets/img/loaders/wf_green.gif)
	}
	
	.invoiceTableHeader{
		border-bottom: #ECF1F4 solid 2px;
		padding: 15px 0px 15px 0px;
		margin: 0px 0px 10px 0px;
		
		div{
			display: inline-block;
			width: 159px;
		}

		p{
			font-size: 14px;
			text-transform: uppercase;
			color: @wf_gray;
			margin: 0;
		}

		.status{
			text-align: center;
		}

		.amount{
			padding: none;
			text-align: right;
			p { display: inline; }

			span{
				text-transform: none;
				color: #D8D8D8;
				font-size: 11px;
			}
		}
	}// end of: .invoiceTableHeader
	
	.invoiceTableRow{
		border-bottom: #F8F8F8 solid 1px;

		>div{
			padding: 5px;
		}
		

		div{
			display: inline-block;
			width: 159px;
			height: 30px;
		}

		p{ margin: 0; }

		>div >p{
			display: inline-block;
			vertical-align: middle;
			line-height: normal;
			font-weight: 500;
			
			>span {
				display: inline-block;
				vertical-align: middle;
				line-height: normal;
			}
		} 

		&:hover{
			background-color: @wf_gray_hover;
		}

		.invoice-number{
			font-size: 14px;
			color: #616E7B;
			font-weight: 700;

			&:hover{
				color: @wf_black;
				cursor: pointer;
				text-decoration: underline;
			}
		}

		.created{
			color: @wf_gray;
		}

		.download{
			text-align: left;
			span{
				font-size: 11px;
    			color: #bdc3c7;
			}
			i{
				font-size: 12px;
				margin-left: 5px;
				color: #bdc3c7;
			}

			&:hover{
				div {
					cursor: pointer;
					display: inline;
					span, i{
						color: @wf_black;
					}
				}
			}
		}

		.dueDate{
			color: @wf_gray;
		}

		.deliveryMethod{
			color: @wf_gray;
		}

		.status{
			text-align: center;
			font-size: 14px;
		}

		.amount{
			text-align: right;
			font-size: 14px;
			color: @wf_black;
		}

		.overdue{
			color: @wf_red;
		}

		.unpaid{
			color: @wf_yellow_dark;
		}

		.paid{
			color: @wf_green;
		}
	}// end of: .invoiceTableRow
}//end of: .invoiceController

