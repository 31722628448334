@keyframes pulse {
	0% {
	transform: scale(1);
	}

	50% {
	transform: scale(1.1);
	}

	100% {
	transform: scale(1);
	}
}

@keyframes pulse-down {
	0% {
	transform: scale(1);
	}

	50% {
	transform: scale(0.9);
	}

	100% {
	transform: scale(1);
	}
}

@keyframes gray-color-pulse {
	0% {
		background-color: #FFFFFF;
	}

	25% {
		background-color: #F9F9F9;
	}

	50% {
		background-color: #F4F4F4;
	}

	75% {
		background-color: #F2F2F2;
	}

	100% {
		background-color: #EFEFEF;
	}
}

@keyframes pulse-gray {
	0% {
		background-color: white;
	}

	50% {
		background-color: #f3f3f3;
	}

	100% {
		background-color: white;
	}
}

@keyframes metadata-pulse {
	from {
		box-shadow: 0 0 0 -1px rgba(149, 165, 166, 0.5);
	}
	to {
		box-shadow: 0 0 0 6px rgba(149, 165, 166, 0);
	}
}


@keyframes spin {
	0% {
	transform: rotate(0deg);
	}
	100% {
	transform: rotate(360deg);
	}
}

@keyframes touch-effect {
	0% { 
	-webkit-transform: scale(0);
	transform: scale(0);
	} 100% {
	-webkit-transform: scale(1.0);
	transform: scale(1.0);
	opacity: 0;
	}
}

@keyframes bounce-it {
	0%, 20%, 50%, 80%, 100% {
	transform: translateY(0);
	}
	40% {
	transform: translateY(-10px);
	}
	60% {
	transform: translateY(-5px);
	}
}

@keyframes shake-it {
	10%, 90% {
	transform: translate3d(-0px, 0, 0);
	}
	
	20%, 80% {
	transform: translate3d(1px, 0, 0);
	}

	30%, 50%, 70% {
	transform: translate3d(-2px, 0, 0);
	}

	40%, 60% {
	transform: translate3d(2px, 0, 0);
	}
}

@keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

@keyframes opacity {
	0%, 100% {
	opacity: 0;
	}
	50% {
	opacity: 1;
	}
}

@keyframes slide-up {
	0% {
	transform: translateY(-50%);
	}
	100% {
	transform: translateY(0);
	}
}

@keyframes slide-down {
	0% {
	transform: translateY(0);
	}
	100% {
	transform: translateY(-50%);
	}
}
	
@keyframes pulseBoxShadow {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.7);
		box-shadow: 0 0 0 0 rgba(204,169,44, 0.7);
	}
	70% {
		-moz-box-shadow: 0 0 0 15px rgba(204,169,44, 0);
		box-shadow: 0 0 0 15px rgba(204,169,44, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
		box-shadow: 0 0 0 0 rgba(204,169,44, 0);
	}
}

@keyframes pulseBoxShadowBlue {

	0% {
		box-shadow: 0 0 0 0 rgba(36, 127, 202, 0.7);
		transform: translate(0,0);
	}
	5% {
		transform: translate(0,-5px);
	}
	20% {
		transform: translate(0,0);
	}

	20% {
		transform: translate(0,0);
	}
	25% {
		transform: translate(0,-5px);
	}
	40% {
		transform: translate(0,0);
	}
	70% {
		box-shadow: 0 0 0 20px rgba(36, 127, 202, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(36, 127, 202, 0);
	}
}

@keyframes slideUpDown {
	0%, 100% {
		transform: translate(0, 0);
	}
	
	50% {
		transform: translate(0, 20px);
	}
}

@keyframes slideRightLeft {
	0%, 100% {
		transform: translate(-1px, 0);
	}
	
	50% {
		transform: translate(2px, 0);
	}
}