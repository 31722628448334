// Dashboard Tiles
// --------------------------------------------------

@import "wf-variables.less";

.tiles-heading, .tiles-body, .tiles-footer {
	&:extend(.clearfix all);
	.transition(~"0.05s ease-in");
}

.info-tiles, .shortcut-tiles {
	margin: 0px 0px 20px;
	display: block;
	border-radius: @border-radius-large;
}
a.info-tiles, a.shortcut-tiles {background: none;}

// Info Tiles

.info-tiles {
	.tiles-heading {
		letter-spacing: normal;
		padding: 6px 10px;
		font-weight: 700;
		box-shadow: inset 0 -50px 0 0 rgba(255, 255, 255, 0.1);
		.border-top-radius(@border-radius-large);
		text-transform: uppercase;
		text-align: center;
		font-size: @font-size-mini;
		

		.pull-right {
			.transition(~"0.06s ease-in");	
			color: rgba(255,255,255,0.4);
		}
	}
	.tiles-body {
		font-size: 32px;
		border-bottom-right-radius: @border-radius-large;
		border-bottom-left-radius: @border-radius-large;

		.text-center {
			position: relative;
			text-align: center;
			i {
				position: absolute;
				margin: auto;
				top: -5px;
				left: -5px;
				color: rgba(255, 255, 255, 0.15);
				&:hover {color: rgba(255, 255, 255, 0.15)}
			}
			.info-text {
				font-size: 36px;
				height: 87px;
				line-height: 87px;
				color: #fff;
				.superscript {
					position: relative;
					font-size: 18px;
					top: -18px;
					color: rgba(255, 255, 255, 0.6);
				}
				.subscript {
					position: relative;
					font-size: 18px;
					top: 0px;
					color: rgba(255, 255, 255, 0.6);
				}

			}
			.info-text-bottom {
				font-size: @font-size-base;
				color: rgba(255, 255, 255, 0.6);

			}
		}
	}
	.tiles-body-alt {
		font-size: 32px;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
	.tiles-body-alt, .tiles-body {
		padding: 15px;
		font-weight: 300;

		.sparkline {width: 100px; margin: 0 auto; padding: 0px;}
		.text-top {vertical-align: super; font-size: 24px}
		.text-smallcaps {font-variant: small-caps;}
		.text-center {margin-top: -5px;}

		i {font-size: 32px; color: rgba(255,255,255,0.4); .transition(~"0.06s ease-in");}
		> i {position: absolute;}
		div + small {font-size: @font-size-base; text-align: center; display: block; font-weight: 400; color: rgba(255,255,255,0.7); margin-top: -7px; margin-bottom: 4px;}
	}
	.tiles-footer {
		color: #fff;
		color: rgba(255,255,255,0.4);
		padding: 5px 10px;
		border-bottom-right-radius: @border-radius-large;
		border-bottom-left-radius: @border-radius-large;
		
		i {float: right; position: relative;top: 2px; display: none;}

		&:after {
			content: "";
			position: absolute;
			bottom: 0%;
			right: -1px;
			width: 0px;
			height: 0px;
			margin-right: 11px;
			margin-bottom: 20px;
			border-top: 5px solid rgba(0, 0, 0, 0.35);
			border-right: 5px solid @wf_gray_light;
			border-bottom: 5px solid @wf_gray_light;
			border-left: 5px solid rgba(0, 0, 0, 0.35);
			border-top-left-radius: @border-radius-large;
			
		}
		&:hover {color: #fff;}
	}
	&:hover {
		i {color: rgba(255,255,255,1);}
		text-decoration: none;

		.tiles-footer {
			color: #fff;
			i {display: inline;}
		}
		.tiles-heading .pull-right {color: rgba(255,255,255,1);}
	}
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	.info-tiles .tiles-footer:after {right: 0; border-top: 6px solid rgba(0, 0, 0, 0.35);}
}

@media (max-width: @screen-xs-max) {
	.col-xs-12 .info-tiles .tiles-footer:after {bottom:-20px;}
}

// Shortcut Tiles
.shortcut-tiles {
	.tiles-body {
		padding: 10px 10px 0px 10px;
		font-size: 32px;
		line-height: 32px;
		border-top-right-radius: @border-radius-large;
		border-top-left-radius: @border-radius-large;
		i {
			font-size: 32px; color: rgba(255,255,255,0.6); .transition(~"0.06s ease-in");
		}
		.pull-right {
			line-height: 0;
			.badge {
				.rgba(black,0.3);
				//background-color: rgba(0,0,0,0.3);
			}
		}
		
	}
	.tiles-footer {
		text-transform: none;
		text-align: right;
		padding: 7px 10px;
		letter-spacing: normal;
		font-size: @font-size-mini;
		text-transform: uppercase;
		border-bottom-right-radius: @border-radius-large;
		border-bottom-left-radius: @border-radius-large;
	}
	&:hover {
		text-decoration: none;
		i {color: rgba(255,255,255,1);}
	}
}


// Tiles Mixin / Generator
.info-tiles-variant(@color, @text-color:#fff) {
	.tiles-heading 	{background: @color;}
	.tiles-body 	{background: @color;}
	.tiles-body-alt {background: @color;}
	.tiles-footer 	{background: @color;}
	color: @text-color !important;
	&:hover {
		.tiles-heading 	{background: darken(@color, 5%);}
		.tiles-body 	{background: darken(@color, 5%);}
		.tiles-body-alt {background: darken(@color, 5%);}
		.tiles-footer 	{background: darken(@color, 5%);}
		color: @text-color;
	}
}

.shortcut-tiles-variant(@color, @text-color:#fff) {
	.tiles-heading,.tiles-body,.tiles-footer {background: @color;}
	color: @text-color !important;
	&:hover {
		.tiles-heading,.tiles-body,.tiles-footer {background: darken(@color,5%);}
		color: @text-color;
	}
}

// Tiles colors
.info-tiles {
	&.tiles-info 			{.info-tiles-variant(@brand-info);}
	&.tiles-default 		{.shortcut-tiles-variant(@tone-brighter, @gray-dark);}
	&.tiles-success 		{.info-tiles-variant(@brand-success);}
	&.tiles-danger 			{.info-tiles-variant(@brand-danger);}
	&.tiles-warning 		{.info-tiles-variant(@brand-warning);}
	&.tiles-primary 		{.info-tiles-variant(@brand-primary);}
	&.tiles-inverse 		{.info-tiles-variant(@brand-inverse);}
	&.tiles-midnightblue 	{.info-tiles-variant(@wf_black_dark)}
	&.tiles-sky 			{.info-tiles-variant(@brand-sky);}
	&.tiles-orange 			{.info-tiles-variant(@brand-orange)}; 
	&.tiles-indigo 			{.info-tiles-variant(@brand-indigo)}; 
	&.tiles-green 			{.info-tiles-variant(@brand-green);}
	&.tiles-magenta  		{.info-tiles-variant(@brand-magenta);}
	&.tiles-purple  		{.info-tiles-variant(@brand-purple);}
	&.tiles-brown  			{.info-tiles-variant(@brand-brown);}
	&.tiles-grape			{.info-tiles-variant(@brand-grape);}
	&.tiles-toyo			{.info-tiles-variant(@brand-toyo);}
	&.tiles-alizarin		{.info-tiles-variant(@brand-alizarin);}

	&.tiles-facebook		{.info-tiles-variant(rgb(59,89,152));}
	&.tiles-twitter			{.info-tiles-variant(rgb(0,172,237));}
}

.shortcut-tiles {
	&.tiles-info 			{.shortcut-tiles-variant(@brand-info);}
	&.tiles-default 		{.shortcut-tiles-variant(@tone-brighter, @gray-dark);}
	&.tiles-success 		{.shortcut-tiles-variant(@brand-success);}
	&.tiles-danger 			{.shortcut-tiles-variant(@brand-danger);}
	&.tiles-warning 		{.shortcut-tiles-variant(@brand-warning);}
	&.tiles-primary 		{.shortcut-tiles-variant(@brand-primary);}
	&.tiles-inverse 		{.shortcut-tiles-variant(@brand-inverse);}
	&.tiles-midnightblue 	{.shortcut-tiles-variant(@wf_black_dark)}
	&.tiles-sky 			{.shortcut-tiles-variant(@brand-sky);}
	&.tiles-orange 			{.shortcut-tiles-variant(@brand-orange)}; 
	&.tiles-indigo 			{.shortcut-tiles-variant(@brand-indigo)}; 
	&.tiles-green 			{.shortcut-tiles-variant(@brand-green);}
	&.tiles-magenta  		{.shortcut-tiles-variant(@brand-magenta);}
	&.tiles-purple  		{.shortcut-tiles-variant(@brand-purple);}
	&.tiles-brown  			{.shortcut-tiles-variant(@brand-brown);}
	&.tiles-grape  			{.shortcut-tiles-variant(@brand-grape);}
	&.tiles-toyo  			{.shortcut-tiles-variant(@brand-toyo);}
	&.tiles-alizarin  		{.shortcut-tiles-variant(@brand-alizarin);}

	&.tiles-facebook		{.info-tiles-variant(rgb(59,89,152));}
	&.tiles-twitter			{.info-tiles-variant(rgb(0,172,237));}
}






//EasyPieChart in Tiles

.info-tiles .tiles-body .easypiechart {
	margin: 0 auto;
	height: 100px;
	line-height: 100px;
	width: 100px;


	.percent {
		width: 100px;
		line-height: 100px;
		font-size: 20px;
		font-weight: 300;
		letter-spacing: normal;
		text-transform: uppercase;
		color: @gray-dark;
	}


}

.info-tiles.tiles-default .tiles-body {
	background: #fff;
	
}
.info-tiles.tiles-default:hover {
	.tiles-heading {
		background: @tone-brighter;
	}
	.tiles-body {
		background: #fff;
	}
}