.PackageSolutionPageController {
	.page-heading {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin: 45px 0 35px 0px;
		padding: 8px 0 20px;

		.backButton { display: none; }

		.options {
			float: none;
			top: 0;
			order: 2;
		}

		.page-title {
			order: 1;
			padding: 0;
			span {
				color: @wf_black;
				margin: 0;
				font-size: 38px;
				font-weight: 300;
				span { font-weight: 600 !important; }
			}
		}


	}
}