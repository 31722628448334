.ObjectViewerController{
	.object-viewer-header{
		padding-top: 40px;
		h1{
			display: inline-block;
			margin: 0 0 16px 0 !important;
			font-weight: 700;
			font-size: 32px;
			line-height: 36px;
			width: 761px;
		}

		.itemTitle{
			margin-bottom: 5px;
			font-size: 11px;
			display: inline-block;
			vertical-align: top;
			span{
				margin-left: 2px;
				display: block;
			}
		}

		.image{
			display: inline-block;
			margin-right: 10px;

			max-width: 55px;
			height: auto;
			position: relative;
			overflow: hidden;
			.img{
				background-size:cover !important;
				display: block;
				margin: 0 auto;
				height: 55px;
				width: 55px;
				-webkit-border-radius: 50%;
				-moz-border-radius: 50%;
				-ms-border-radius: 50%;
				-o-border-radius: 50%;
				border-radius: 50%;
			}	
		}

		.part1{
			color: black;
			font-size: 14px;
			font-weight: 400px;
			margin-bottom: 20px;
			width: 761px;
			p{
				white-space: pre-line;
				&.limitText{
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					max-height: 64px;      /* fallback */
					-webkit-line-clamp: 4; /* number of lines to show */
					-webkit-box-orient: vertical;
				}
			}

			.readMore{
				font-size: 12px;
				font-weight: 500;
				color: #2685EE;
				cursor:pointer;
			}
		} //end of: .part1

		&.userInbox{
			.image{
				float: left;
			}
			.itemTitle{
				margin-bottom: 0;
				h1{
					margin-bottom: 0 !important;
				}
			}
			.part1{
				p{
					float: left;
				}
			}
		} // end of: .userInbox
	} //end of: .object-viewer-header

	.object-viewer-body{
		padding-right: 9px;
		margin-right: 0;
		padding-left: 0;

		.guidance{
			padding-top: 5px;
			.part2{
				font-size: 12px;
				font-weight: 400px;
				p{
					max-width: 900px;
					font-size: 13px;
					white-space: pre-line;	
				}

				.accordion{
					.panel-title{
						cursor:pointer;
						display: inline-block;
						margin: 0 0;
						span{
							display: inline;
							margin-left: 5px;
							font-weight: 600;
							color: #2685EE;
						}
						&.expanded{
							i{
								-webkit-transition: 0.3s;
								transition: 0.3s;
								transform: rotate(90deg);	
							}
						}
						i{
							cursor: pointer;
							color: #2685EE;
							font-size: 12px;
							-webkit-transition: 0.3s;
							transition: 0.3s;
						}
					}
				}
				#collapseOne{
					margin-top: 5px;
					border: #E9EAEB solid 1px;
					border-radius: 3px;
					background-color: #ffffff;

					div{ padding: 20px; }

					p{ color: #8B8B8B; }
				}
			}
		} //end of: .guidance

		.wf-object-viewer-box-component {
			.item-header-text {
				a {
					color:#444;

					> span:first-child {
						padding-right: 5px;
					}

					&:hover {
						> span:first-child {
							text-decoration: underline;
						}
					}

					span.filetype {
						color: #999;
						font-size: 10px;
						display: inline-block;
						font-weight: 400;
						
						i.fa {
							position: relative;
							top: 1px;
							font-size: 13px;
							margin-left: 1px;
						}
					}
				}
			}
		}

		.left-boxes{
			.left-box{
				margin-top: 15px;
			}
			.panel-body{
				padding: 0;

				.wf-object-viewer-box-component{
					.box-header{
						width: auto;
						min-width: 300px;
						padding: 10px 20px;
						border-bottom: #F7F7F7 solid 2px;
						border-top-left-radius: 3px;
						border-top-right-radius: 3px;
						
						.box-title{
							>.row{ margin-left: 10px; }

							h3{
								display: inline-block;
								font-size: 20px;
								font-weight: 600;
							}

							.item-count{
								display: inline-block;
								
								span:first-child{ margin: 0 8px 0 8px; }

								span{
									color: #CCCCCC;
									font-weight: bold;
									font-size: 15px;
								}
								span:last-child{
									color: #CCCCCC;
									font-weight: 300;
									font-size: 12px;
								}
							}

							.action-icons{
								margin: 12px 0 8px 10px;
								padding: 0 10px;
								float: right;
								.plus-icon{
									text-align: center;

									i{
										font-size: 30px;
										color: #2685EE;
										
										&:hover{
											cursor: pointer;
											color: #4999F1;
										}
									}
								}
							}

							.inboxIcon{
								margin-right: 10px;
								font-size: 22px;
								color: #2c3e50;
							}
						}// end of: .box-title

						.expand-toggle{
							.expand-toggle-switch{
								margin: 20px 0 8px 0;
								float: right;
								width: auto;

								.expand-toggle-switch {
									display: inline-block;
									width: 34px;
									height: 21px;
									border: none !important;
									box-shadow: none !important;
								}

								.expand-toggle-switch input {
									display:none;
									margin-top: 5px;
								}

								.slider {
									height: 20px;
									width: 40px;
									position: absolute;
									cursor: pointer;
									top: 0;
									left: 0;
									right: 0;
									bottom: 0;
									background-color: #B5BBC1;
									transition: .2s; 
									box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.07) inset;
									overflow: hidden;
								}

								.slider:before {
									position: absolute;
									content: "";
									height: 18px;
									width: 18px;
									left: 1px;
									top: 1px;
									bottom: 4px;
									background-color: white;
									transition: .2s;
									box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
								}

								input:checked + .slider { background-color: #81C937; }

								// input:focus + .slider { box-shadow: 0 0 1px #81C937; }

								input:checked + .slider:before {
									-webkit-transform: translateX(20px);
									-ms-transform: translateX(20px);
									transform: translateX(20px);
								}
								/* Rounded sliders */
								.slider.round { border-radius: 34px; }

								.slider.round:before { border-radius: 50%; }
							}

							.circle-loader {
								float: right;
								margin: 20px 5px 8px 0;
								height: 20px;
								width: 20px;
								left: 2px;
								top: 2px;
								bottom: 4px;
							}

							.circle-loader.loading{
								.spinner(22px);
							}

							.expand-text{
								float: right;
								margin: 0px 10px 8px 0;
								span{
									float: right;
									margin-top: 22px;
									white-space: nowrap;
									color: #C5C5C5;
									font-size: 11px;
								}
							}
						}
						.search-bar{
							margin-top: 16px;
							width: 15%;
							max-width: 225px;
							transition: width .2s ease;

							&.expand-search{ width: 100%; }

							&.hide-search{ display: none !important; }
						}
					} //end of: .box-header

					.box-body{
						width: auto;
						min-width: 300px;
						padding: 20px 20px 15px 20px;

						.box-items{
							margin: 0 0 25px 0;
							max-height: 550px;
							overflow: hidden;
							
							.chartLine{
								padding: 20px 10px 10px 0px;
								margin-bottom: 20px;
							}

							&.fade-bottom-scroll {
								overflow: hidden;
								position: relative;
								&:after {
									content: '';
									display: block;
									height: 16px;
									width: auto;
									position: absolute;
									right: 0;
									bottom: 0;
									left: 0;
									
									background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
									background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
									background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
									filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
								}
							}

							&:hover {
								.mCSB_scrollTools { opacity: 1; }
							}

							.mCSB_scrollTools{ opacity: 0.1; }
							
							.wrapped-item-component{
								display: block;
								width: 100%;

								.object-item{
									a { color: #444; }
								}

								&:not(:first-child){
									.object-viewer-item{ border-top: #E4E4E4 solid 1px; }
								}

								&:not(:first-child){
									.userInboxWrap{ border-top: 1px solid #E4E4E4; }
								}

								.object-viewer-item-component{

									.object-viewer-item{
										justify-content: center;
										height: auto;	
										padding: 20px;
										margin: 0;
										
										a{ display: inline-block; width: 100%; }
										>.row{ margin-left: 11px; }


										// p{
										// 	font-size: 12px;
										// 	&:first-child{
										// 		font-weight: bold;
										// 		font-size: 13px;
										// 	}
										// }

										&:hover {
											.item-tools{
												visibility: visible;
											}
											background-color: #FBFBFB;
											-webkit-transition: border-left-color 0.1s ease-out;
											-moz-transition: border-left-color 0.1s ease-out;
											-o-transition:border-left-color 0.1s ease-out;
											transition: border-left-color 0.1s ease-out;
										}

										.item-header-text{
											color:#444;
											display: inline-block;
											font-size: 13px;
											i{ display: inline; }
											
										}

										.influenceStatus {
											display: inline-block;
											margin-left: 3px;
										}

										.item-body-text{
											text-overflow: ellipsis;
											word-wrap: break-word;
											overflow: hidden;
											max-height: 2.9em;
											line-height: 1.4em;
											i{ display: inline; }
										}
										
										.item-tools{
											align-self: center;
											// padding-left: 15px;
											// min-height: 35px;

											visibility: hidden;
										}

										.expand-one-item{
											text-align: center;
											width: 40px;
											margin-left: 5px;

											label{
												border-radius: 50%;
												/*behavior: url(PIE.htc);*/ /* remove if you don't care about IE8 */
												width: 36px;
												height: 36px;
												padding: 8px 0 8px 0;
												margin: 0;

												&.loading{
													.spinner(22px);

													i{
														display: none;
													}
												}
											}

											input {display:none;}

											i{
												cursor: pointer;
												font-size: 15px;
												-webkit-transition: 0.3s;
												transition: 0.3s;
											}

											input:checked + i{
												-webkit-transition: 0.3s;
												transition: 0.3s;
												transform: rotate(90deg);
											}

											label:active{
												-webkit-transition: 0.4s;
												transition: 0.4s;
												background-color: #CCCCCC;
											}
										}

										:not(.popover-items){
											>.item-icon{
												width:35px;
												height:35px;
												margin-right: 10px;
												background-size:cover;
												background-position: center center;
												background-repeat: no-repeat;
											}
										}

										.expandable-div{
											min-height: 36px;	
										}

										.content-wrap{ min-height: 54px; }
										
										.object-viewer-answering{
											padding: 0;
											align-self: center;
											height: 27px;
										}

										.answer-style{
											.item-header-text{
												display: inline;
												font-size: 14px;
												font-weight: bold;
											}

											.creatorUser-name{
												font-size: 10px;
											}
										}

										.question-style{
											.item-header-text{
												font-size: 12px !important;
												font-weight: normal !important;
											}
										}

										&.children{
											padding: 15px 30px 15px 10px;
											border-top: none;

											.item-icon{
												width:25px;
												height:25px;
												margin-right: 10px;
												background-size:cover;
												background-position: center center;
												background-repeat: no-repeat;
											}
										}

										.object-viewer-metadata{
											margin-top: 10px;
											margin-left: 0;
										}
									} //end of: .object-viewer-item

									.object-viewer-children{
										&.userInbox{
											float: right;
										}
									}

									.userInboxWrap{
										.userInbox{
											width: 100px;
											text-align: center;
											height: auto;
											position: relative;
											overflow: hidden;
											padding: 15px 0;
											.img{
												background-size:cover !important;
												display: block;
												margin: 0 auto;
												margin-bottom: 5px;
												height: 30px;
												width: 30px;
												-webkit-border-radius: 50%;
												-moz-border-radius: 50%;
												-ms-border-radius: 50%;
												-o-border-radius: 50%;
												border-radius: 50%;
											}	

											// .userNameTooltip{
											// 	.tooltip-arrow{
											// 		border-top-color: @wf_black !important;
											// 	}
											
											// 	.tooltip-inner{
											// 		background-color: @wf_black;
											// 		color: white;
											// 		text-align: center;
											// 		border-radius: 6px;
											// 	}
											// }

											.userName{
												display: inline-block;
												font-weight: bold;
												width: 100%;
												overflow: hidden;
												white-space: nowrap;
												text-overflow: ellipsis;
											}

											.timeSent{
												display: block;
												font-size: 10px;
											}
										}
									} //end of: .userInboxWrap

									.userInboxMetadata{
										padding-left: 0;
										margin-top: 15px;
										float: left; 
									}
								} //end of: .object-viewer-item-component
							} //end of: .wrapped-item-component
							
							.expand{
								text-align: center;
								p{
									display: inline;
									cursor: pointer;
									color: #2685EE;
								}
								i{
									cursor: pointer;
									color: #2685EE;
								}
							} 

						}//end of: .box-items
					} //end of: .box-body
				} //end of: .wf-object-viewer-box-component
			} // end of: .panel-body
		} //end of: .left-boxes

		.right-boxes{
			margin-top: 33px;
			padding: 0;
			.right-box{
				margin: 15px 0;
				padding: 0 0 25px 20px;
			}
			.wf-object-viewer-box-component{
				.box-header{
					width: auto;
					min-width: 300px;
					padding: 0px;
					border-top-left-radius: 3px;
					border-top-right-radius: 3px;
					
					.box-title{
						border-bottom: #E5E5E5 solid 2px;
						padding-bottom: 10px !important;
						display: inline-block;
						width: 100%;

						>.row{
							margin-left: 5px;
						}

						h3{
							display: inline !important;
							font-size: 20px;
							font-weight: bolder;
						}

						.item-count{
							display: inline-block;
							span:first-child{ margin: 0 8px 0 8px; }
							span{
								color: #CCCCCC;
								font-weight: bold;
								font-size: 15px;
							}
							span:last-child{
								color: #CCCCCC;
								font-weight: 300;
								font-size: 12px;
							}
						}
						.action-icons{
							margin-left: 12px;
							margin-right: 30px;
							padding: 0px;
							float: right;
							.plus-icon{
								text-align: center;

								i{
									font-size: 30px;
									color: #2685EE;
									
									&:hover{
										cursor: pointer;
										color: #4999F1;
									}
								}

								.smaller-icon{
									font-size: 18px;
									color: #677481;
								}
							} //end of: .plus-icon
						} //end of: .action-icons
					} // end of: .box-title
				} //end of: .box-header

				.box-body{
					.box-items{
						margin: 10px 0 25px 0;
						max-height: 400px;

						.mCustomScrollBox{
							.mCSB_container{
								left: initial !important;
								margin-right: 15px !important;
							}
						}

						&.fade-bottom-scroll {
							overflow: hidden;
							position: relative;
							
							&:after {
								content: '';
								display: block;
								height: 16px;
								width: auto;
								position: absolute;
								right: 0;
								bottom: 0;
								left: 0;
								
								background: -moz-linear-gradient(top,  rgba(236,240,241,0) 0%, rgba(236,240,241,1) 100%);
								background: -webkit-linear-gradient(top,  rgba(236,240,241,0) 0%,rgba(236,240,241,1) 100%);
								background: linear-gradient(to bottom,  rgba(236,240,241,0) 0%,rgba(236,240,241,1) 100%);
								filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ECF0F1', endColorstr='#ECF0F1',GradientType=0 );

							}
						}

						.object-viewer-item-component{
							.object-viewer-item{
								display:block;
								padding: 7.5px;
								height: auto;	
								border-bottom: #F2F2EE solid 1px;

								>.row{ margin-left: 11px; }


								.item-header-text{
									font-weight: 600;
									font-size: 12px;
									color: #444;

									i{ display: inline; }
								}
								.item-body-text{
									text-overflow: ellipsis;
									word-wrap: break-word;
									overflow: hidden;
									max-height: 2.9em;
									line-height: 1.4em;

									i{ display: inline; }
								}

								.item-tools{
									align-self: center;
									margin: 0 10px 0 10px;
									// min-height: 35px;
								}

								.item-icon{
									float:left;
									width:30px;
									height:30px;
									background-size:cover;
									background-position: center center;
									background-repeat: no-repeat;
								}

								&:hover {
									background-color: #FBFBFB;
									-webkit-transition: border-left-color 0.1s ease-out;
									-moz-transition: border-left-color 0.1s ease-out;
									-o-transition:border-left-color 0.1s ease-out;
									transition: border-left-color 0.1s ease-out;
								}
							} //end of: .object-viewer-item
						} // end of: .object-viewer-item-component
					} //end of: .box-items
				} //end of: .box-body
			}//end of: .wf-object-viewer-box-component
		} //end of: .right-boxes

		&.simplifiedLayout{
			.left-boxes{
				padding-right: 0px;
				padding-left: 9px;
			}
			// padding: 15px 25px;
			panel{
				&.left-box{
					margin-top: 0px;
				}
				.panel{
						background-color: initial;
						box-shadow: initial;
						margin-bottom: 0px;
					.panel-body{
						background-color: initial;
						.wf-object-viewer-box-component{
							.box-header{
								border-bottom: none;
								padding: 0px 0px 10px 10px;
								.box-title{
									h3{
										font-size: 14px;
									}

									.item-count{
										span{
											font-size: 12px;
											color: @wf_gray_dark;
											font-weight: normal;
										}
									}
								}
							}// end of: .box-header
							.box-body{
								padding: 0px;
								.mCSB_container{ left: 0 !important; }
								.box-items{
									margin: 0;
									padding-bottom: 0px !important;
									.wrapped-item-component{
										:last-child{
											.object-viewer-item-component{
												padding-bottom: 10px;
											}
										}
										.object-viewer-item-component{
											margin: 0 1px;
											.object-viewer-item{
												padding: 6px 20px 6px 20px;
												border-top: none;
												margin-left: none;
												background-color: transparent !important;

												.item-icon{
													width: 25px;
													height: 25px;
												}
												.object-viewer-metadata{
													margin-top: 5px;
													margin-left: 32px;
												}

												.item-header-text{
													font-size: 12px;
												}
												.item-body-text{
													font-size: 11px;
												}

												.answer-style{
													p{
														&:first-child{
															font-size: 13px;
														}
														font-size: 11px !important;
													}

													.creatorUser-name{ font-size: 11px; }
													
													span{
														font-size: 10px;
													}
												}

												.question-style{
													margin-bottom: 5px;
													.item-header-text{
														width: 80%;
														font-size: 11px !important;
													}
												}
											}// end of: .object-viewer-item

											wf-dropdown.object-viewer-tools {
												visibility: hidden;
											}
											&:hover {
												background-color: #f8f8f8;
												border-left: solid 1px fade(@wf_gray, 50%);
												border-right: solid 1px fade(@wf_gray, 50%);
												margin: 0;

												wf-dropdown.object-viewer-tools{
													visibility: visible;
												}
												.object-viewer-item{
													wf-object-metadata .objectViewer{
														.detailedView{
															visibility: visible;
														}
													}
												}
											}
										}// end of: .object-viewer-item-component
									}// end of: .wrapped-item-component

									.chartLine{
										padding: 20px;
										margin-bottom: 20px;
										canvas{
											// width: 425px !important;
											margin-left: auto;
											margin-right: auto;
										}
									}//end of: .chartLine
								}// end of: .box-items
							}// end of: .box-body
						}// end of: wf-object-viewer-box-component
					}// end of: .panel-body
				}// end of: .panel
			}// end of: panel
		}//end of: .simplifiedLayout

		.objectiViewer-empty-state{
			.emptyList-message{
				padding: 15px 0 10px 0 !important;
				p {
					display: inline;
					font-size: 13px;
					color: #B1B1B1;
					font-weight: bold;
				}
				i{
					display: inline;
					color: #2685EE;
					margin: 0 5px;
				}

				i.smaller-icon{
					color: #677481;
				}
			}
			img{
				width: 100px;
			}

			p:hover{
				cursor: default;
			}

			.relatedContent{
				padding: 20px 0 25px 0;

				img{
					width: 30px;
				}
			}
		} //end of: .objectViewer-empty-state
	} // end of: .object-viewer-body
} //end of: .ObjectViewerController
