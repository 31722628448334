.SustainabilityRoomController-adminBar {
	background: #ECF0F1;
	box-shadow: -1000px 0 #ECF0F1, 1000px 0 #ECF0F1;
}

.SustainabilityRoomController {
	.layout-public {
		&, * { color: #3B3B3B; }
	}

	.topHeader {
		padding: 20px 0 30px;
		max-width: 60%;
		margin: auto;
		text-align: center;

		.layout-public & {
			padding-top: 0;
			padding-bottom: 45px;
		}

		.details {
			.fa {
				font-size: 14px;

				&.urlRouteName-icon {
					font-size: 17px;
					position: relative;
					top: 1px;
				}
			}
		}

		.img {
			width: 120px;
			height: 120px;
			position: relative;
			margin: auto;
			margin-bottom: 10px;
		}

		h1 {
			margin-top: 0;
			padding-top: 0;
			margin-left: -1px;
			margin-bottom: 2px;
			font-weight: bold;
			font-size: 31px;

			&, * { color: #444; }

			i {
				zoom: 0.7;
				position: relative;
				top: -3px;
			}
		}

		p {
			font-size: 15px;
			color: #444;
		}

		.details { color: #444; }
	} //end of: .topHeader

	.extendedBarBg {
		background-color: #ECF0F1;
		min-height: 500px;
		box-shadow: -1000px 0 #ECF0F1, 1000px 0 #ECF0F1, 1000px 500px #ECF0F1, -1000px 500px #ECF0F1, 0 500px #ECF0F1;
		padding: 30px 0;
	}

	.layout-public & .extendedBarBg {
		background-color: #F5F5F5;
		box-shadow: -1000px 0 #f5f5f5, 1000px 0 #f5f5f5, 1000px 500px #f5f5f5, -1000px 500px #f5f5f5, 0 500px #f5f5f5,
			-100px 0 #f5f5f5, 100px 0 #f5f5f5, 100px 50px #f5f5f5, -100px 50px #f5f5f5, 0 50px #f5f5f5;
	}

	.room-children {
		font-size: 13px;

		.border-container {
			padding-right: 10px;
			margin-right: 15px;
			border-right: 1px solid #D6D6D6;

			strong {
				font-size: 15px;
				color: #444;
			}

			a {
				color: #477ECB;
				display: inline-block;
				padding: 6px 0;
				line-height: 17px;

				&.active { color: #555; }

				&:hover { text-decoration: underline; }
			}
		} //end of: .border-container
	} //end of: .room-children

	.room-child-content {
		[wf-permission-write] { display: none; }

		.backButton { display: none; }

		.page-heading {
			margin-top: 0;
			padding-top: 0;
			padding-bottom: 10px;

			h1 {
				font-size: 22px;
				padding: 0;
			}

			p { display: none; }

			.options { display: none; }
		}
	} // end of: .room-child-content
} //end of: .SustainabilityRoomController

.ScorecardController{
	.heading{
		display: inline-block;
		width: 100%;
		padding: 12px 0;
	} //end of: .heading
	
	.item-measure{
		min-height: 110px;
		position: relative;
		// height: 60px;
	}
	.text{
		width: 50%;
		p{
			position: absolute;
			width: 50%;
			// top: 50%;
			top: 32%; //quickfix
			transform: translate(0%, -50%);
			font-size: 13px;
			font-weight: 400;
			color: @wf_black;
		}
	} //end of .text
	
	.latest-data{
		text-align: center;
		width: 28%;

		.latest-answer{
			position: absolute;
			width: 25%;
			// top: 44%;
			top: 26%;
			transform: translate(0%, -50%);
			margin-left: 30px; //quickfix

			&.no-data-message{
				width: 30%;
			}
			
			.year{
				font-weight: 200;
				color: @wf_black;
				font-size: 16px;
			}

			.value{
				font-weight: bold;
				color: @wf_black;
				font-size: 16px;
			}

		}
		
		.no-data-message{
			font-weight: bold;
			color: @wf_black;
			font-size: 12px;
		}

	} //end of .latestData

	.interactive-graph{
		width: 25%;

		.measure-chart{
			padding-top: 0px !important;
		}
	} //end of: .interactive-graph
} //end of: .ScorecardController