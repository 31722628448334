.organization-panel {
	margin: 20px 0px;
	padding: 20px 0px;

	.side-bar {
		border-right: 1px solid @wf_gray;
		height: 100%;
		margin-right: 45px;

		.grouped-settings {
			display: flex;
			flex-direction: column;
			width: 100%;

			&:not(:first-child) { 
				.grouped-settings-header {
					margin-top: 30px;
				}
			 }

			.grouped-settings-header {
				width: 100%;
				padding: 0px 16px;
				margin: 10px 0;
				
				span {
					font-size: 14px;
					font-weight: 500;
					text-transform: uppercase;
					color: @wf_gray_dark;
				}
			}

			.grouped-settings-body {
				display: flex;
				flex-direction: column;
				width: 100%;

				a.settings-item {
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					align-items: center;
					
					width: 100%;
					padding: 12px 16px;
					border-left: 3px solid transparent;
					background-color: transparent;
					text-decoration: none;
					
					.settings-icon {
						width: 20px;
						text-align: center;
						
						i {
							font-size: 14px;
							color: @wf_black_light;
						}
						margin-right: 15px;
					}
	
					.settings-title {
						span {
							max-width: 210px;
							text-overflow: ellipsis;
							overflow: hidden;

							font-size: 14px;
							color: @wf_black_light;
							font-weight: 500;
						}
					}

					&:hover {
						cursor: pointer;
						background-color: fade(@wf_gray, 20%);

						.settings-icon i {
							color: @wf_black;
						}
						.settings-title span {
							color: @wf_black;
						}
					}
	
					&.selected {
						border-color: @wf_black;
						background-color: fade(@wf_gray, 20%);

						.settings-icon i {
							color: @wf_black;
						}
						.settings-title span {
							color: @wf_black;
						}
					}
				}
			}
		}
	}

	.settings-page {
		.backButton {
			display: none;
		}
	
		panel {
			width: 100% !important;
		}

		.page-heading {
			margin-top: 0;
			padding-top: 0;
		}

		// Specific rules 

		.panel-wrapper { width: 100% !important; }
		
		.OrganizationSettingsController {  }
		.EventLogController {  }

	}
}