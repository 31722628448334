// Forms design docs: https://designers.hubspot.com/docs/cos/hubspot-form-markup#styling-forms

/* All HubSpot Forms
   ========================================================================== */
.hs-form 
{
	/* Form Field (selector for form field wrapper) */
	.hs-form-field {
		margin-top: 17px;
	}

	/* Descriptions (targets class applied to Help Text divs) */
	.hs-field-desc {}

	/* Labels (selects field labels and error messages) */
	label {
		color: #707980;
	}
	.hs-form-field > label {
		color: #707980;
	}

	.hs-form-required {
		color: #e74c3c;
	}

	/* Inputs (selectors for all inputs)  */
	input[type="text"],
	input[type="password"], 
	input[type="datetime"], 
	input[type="datetime-local"], 
	input[type="date"], 
	input[type="month"], 
	input[type="time"], 
	input[type="week"], 
	input[type="number"], 
	input[type="email"], 
	input[type="url"], 
	input[type="search"], 
	input[type="tel"], 
	input[type="color"],
	input[type="file"],
	textarea,
	select {
		display: block;
		width: 100%;
		height: 34px;
		padding: 8px 16px;
		font-size: 12px;
		line-height: 1.4;
		background-color: #fff;
		background-image: none;
		border: 1px solid #e0e0e0;
		border-radius: 3px;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
		transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
		
		color: #707980;
		height: 34px;
		padding: 9px 10px;
		box-shadow: none !important;

		white-space: pre-wrap;
		word-wrap: break-word;
	}

	textarea {
		height: 110px;
		resize: none;
	}

	/* Inputs in focus (selectors for all inputs when clicked)  */
	input[type="text"]:focus,
	input[type="password"]:focus, 
	input[type="datetime"]:focus, 
	input[type="datetime-local"]:focus, 
	input[type="date"]:focus, 
	input[type="month"]:focus, 
	input[type="time"]:focus, 
	input[type="week"]:focus, 
	input[type="number"]:focus, 
	input[type="email"]:focus, 
	input[type="url"]:focus, 
	input[type="search"]:focus, 
	input[type="tel"]:focus, 
	input[type="color"]:focus,
	input[type="file"]:focus,
	textarea:focus,
	select:focus {
		border-color: #707980;
	}

	/* Multi-line inputs (selectors to target multi-line fields */
	textarea {}
	textarea:focus {
		border-color: #707980;
	}

	/* Dropdowns (selectors for dropdowns) */
	select {}
	select:focus {}

	ul.inputs-list {
		list-style: none;
		margin: 0;
		padding: 0;

		.hs-form-booleancheckbox-display {
			padding: 3px 3px 3px 0;
			cursor: pointer;

			input {
				cursor: pointer;
			}

			input + span {
				display: inline-block;
				position: relative;
				left: 5px;
				top: -2px;
			}
		}
	}

	/* Multi-select (selectors for multi-select fields) */
	form.hs-form-field ul.inputs-list {

	}

	form.hs-form-field ul.inputs-list li input {}
	input[type="radio"] {}
	input[type="checkbox"] {}

	.hs-error-msgs {
		overflow: hidden;
		height: 0;
	}

	.showValidationErrors & {
		/* Required (selectors for fields, when they do not pass validation) */
		input:required.invalid, 
		textarea:required.invalid, 
		select:required.invalid {
			border-color: rgba(231,76,60,.6);
		}

		input:focus:required.invalid, 
		textarea:focus:required.invalid, 
		select:focus:required.invalid {
			border-color: rgba(231,76,60,.6);
		}

		/* Error message (selector for validation messages) */
		.hs-error-msgs {
			overflow: visible;
			height: auto;

			list-style: none;
			margin: 0;
			padding: 0;

			label{
				color: #e74c3c;
				margin: 0;
				margin-top: 8px;
			}
		}
	}

	/* Placeholder Text (styles the placeholder attribute text) */
	::-webkit-input-placeholder { /* Webkit Browsers */}
	:-moz-placeholder { /* Firefox 18- */}
	::-moz-placeholder { /* Firefox 19+ */}
	:-ms-input-placeholder { /* IE10 */}

	/* Multi Column Form (selectors for fieldsets and field wrappers) 
	========================================================================== */

	fieldset.form-columns-1 {}
	fieldset.form-columns-1 .hs-form-field {}

	fieldset.form-columns-2 {}
	fieldset.form-columns-2 .hs-form-field {}

	fieldset.form-columns-3 {}
	fieldset.form-columns-3 .hs-form-field {}

	/* Submit buttons (selectors for all non-CTA buttons) 
   ========================================================================== */
	.hs-button.primary,
	input[type="submit"],
	input[type="button"] {
		display: inline-block;
		margin-bottom: 0;
		font-weight: 500;
		text-align: center;
		vertical-align: middle;
		touch-action: manipulation;
		cursor: pointer;
		background-image: none;
		border: 1px solid transparent;
		white-space: nowrap;
		padding: 8px 16px;
		font-size: 12px;
		line-height: 1.4;
		border-radius: 3px;
		user-select: none;

		font-family: Roboto,'Segoe UI','Droid Sans',Tahoma,Arial,sans-serif;
		font-weight: 500;
		border-width: 0;
		padding: 9px 16px;
		box-shadow: inset 0 0 0 1px rgba(0,0,0,.08);
		color: #fff;
		background-color: #3498DB;
		border-color: #1e5d87;
		min-width: 85px;
	}

	.hs-submit {
		height: 34px;

		.hs-button.primary {
			position: absolute;
			right: 95px;
		}
	}

	.hs-button.primary:hover,
	input[type="submit"]:hover,
	input[type="button"]:hover {
		background-color: #217dbb;
	}

	.hs-button.primary:focus,
	input[type="submit"]:focus,
	input[type="button"]:focus {
		background-color: #217dbb;
	}
}
