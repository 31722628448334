wf-measure-targets-editor {
	.modal-header {
		.modal-title {
			padding-bottom: 5px;
		}
	}

	.modal-body {
		.column-headers {
			padding: 0 15px 5px 15px;
			margin: 0 5px 10px 5px;
			overflow: visible;

			.column-header {
				span {
					margin-right: 5px;
				}
			}
		}

		.measure-targets-steps {
			.as-sortable-placeholder {
				box-shadow: 0 5px white inset, 0 -10px white inset;
				background-color: @wf_gray_lighter;
				border-bottom: 1px solid @wf_gray_lighter;
			}
		}

		.verification-message {
			display: flex;
			justify-content: center;
			padding-top: 40px;

			span {
				color: @wf_red;
				font-size: 14px;
			}
		}
	}

}

.measure-targets-steps {

	.measure-targets-step {
		padding: 10px 15px;
		margin: 5px 0;
		border-radius: 3px;

		&:not(:last-child) { border-bottom: 1px solid @wf_gray_lighter; }

		.constantDataSourceMetadata {
			margin-left: 5px;
			position: relative;
			top: 6px;
		}

		.input-group {
			.ui-select-container {
				min-width: 140px;
			}
			
			&.disabled {
				pointer-events: none;
				opacity: 0.5;

				.ui-select-match {
					.ui-select-match-text {
						visibility: hidden;
					}
				}
			}
		}

		.side-actions {
			.remove {
				background-color: white;
				color: @wf_red;
				margin-left: 30px;
			}
		}
	}
}

