@import "wf-variables.less";
@import "wf-mixins.less";

.valueChain-modal-orgAlreadyExists .modal-dialog {
	width: 500px;
}

.valueChain-modal-removeOrganization {

	.modal-dialog {
		width: 600px;
	}

	.modal-body {
		text-align: center;
		font-size: 14px;
		white-space: pre-wrap;
		padding: 40px 70px !important;

		i.fa {
			color: #D1563E;
			font-size: 75px;

		}

		.headerText {
			font-size: 15px;
			color: #555;
			padding-top: 20px;
			padding-bottom: 5px;
			font-weight: bold;
		}

		strong {
			color: #555;
		}
	}
}

.ValueChainOrganizationsController {
	.closeOpenedWithParent {
		display: inline-block;
		margin-left: 7px;
		color: #bbb;
		padding: 0 4px;
		cursor: pointer;

		&:hover {
			color: #999;
		}
	}

	.side-action {
		display: inline-block;
		padding: 3px 0 3px 20px;
		position: relative;
		top: -3px;

		i {
			margin-left: 5px;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	.listHeader {
		color: #444;
	}

	wf-chart {
		margin-bottom: -25px;
		pointer-events: none;
		height: 175px;

		>div {
			width: 115px;
			height: 115px;
			top: -5px;

			span.value-percentage {
				margin-top: -22px;
				margin-left: 2px;
			}

			span.value-count {
				margin-top: -3px;

				color: #bbb;
				font-size: 11px;
				font-weight: normal;

				.slash {
					display: inline-block;
					padding: 0 1px;
				}
			}
		}

		p {
			font-weight: 500;
			font-size: 11px;
			margin: 0 -10px;
			padding-top: 4px;
		}
	}

	.filter {
		padding-top: 12px;
		padding-bottom: 12px;

		.filter-label {
			// width: 70px;
			width: 10%;
			float: left;
			padding-top: 4px;
			// font-size: 20px;
			color: #aaa;
			padding-bottom: 5px;
			// text-align: center;
			margin-right: 18px;

			i {
				font-size: 15px;
			}

			span {
				display: block;
			}
		}

		.filter-option-label {
			font-size: 11px;
			padding-bottom: 2px;
		}

		&.isActive {
			.filter-clear {
				visibility: visible;
			}

			.filter-label {
				color: #777;
			}
		}

		.filter-clear {
			width: 3.3%;
			float: left;
			overflow: hidden;
			visibility: hidden;
			font-size: 14px;
			color: #a5a5a5;
			text-align: center;
			margin-right: 4px;
			padding: 2px 7px;
			height: 26px;
			border: 0;
			box-shadow: none;

			&:hover {
				color: #666;
			}
		}

		.filter-options {
			width: 83.7%;
			float: left;
		}

		.btn-group {
			>div {
				position: relative;
				display: inline-block;

				&:hover {
					.filter-option-more {
						display: block;
					}
				}

				&.inverted {
					.filter-option-more {
						display: none !important;
					}

					.filter-button {
						background-color: @wf_red_dark;

						.count {

							&,
							&.count-intersected:before {
								color: #fff0f0;
							}

							&:hover {
								background: rgba(44, 62, 80, 0.9) !important;
								box-shadow: 0 1px #813837, 0 -1px #813837, 1px 0 #813837;

								&:after {
									content: "\f06e" !important;
								}
							}
						}
					}

					// .wf-image, .icon {
					// 	position: relative !important;
					// 	left: -2px;
					// 	top: -1px;

					// 	&:before {
					// 		content: "\2260";
					// 		display: block;
					// 		position: absolute;
					// 		border-radius: 100%;
					// 		background-color: #fff;
					// 		width: 14px;
					// 		height: 14px;
					// 		margin-left: 6px;
					// 		margin-top: 4px;
					// 		box-shadow: 0 0 0 2px rgba(44, 62, 80, 0.7);
					// 		font-size: 19px;
					// 		color: #2c3e50;
					// 		text-align: center;
					// 		line-height: 11px;
					// 	}
					// }
				}

				.filter-option-more {
					display: none;
					position: absolute;
					width: auto;
					height: 23px;
					line-height: 21px;
					font-size: 16px;
					background: @btn-default-bg;
					right: 12px;
					left: 8px;
					z-index: 1;
					margin-top: -7px;
					font-weight: 700;
					border-radius: 0px 0 3px 3px;
					box-shadow: 0 3px rgba(0, 0, 0, 0.03) inset, 0 2px 1px rgba(0, 0, 0, 0.01);
					text-align: center;
					border: 1px solid rgba(0, 0, 0, 0.07);
					cursor: pointer;

					&:hover {
						background: #eee;
						box-shadow: 0 2px 1px rgba(0, 0, 0, 0.01);
					}
				}
			}

			div.filter-button {
				padding: 0 !important;
				max-width: none;
				z-index: 2;
				position: relative;

				&.disabled {
					pointer-events: auto;
					cursor: pointer;
					opacity: 0.25;

					&:not(.btn-inverse) {
						.text {
							color: #000;
						}
					}
				}

				&.disabled-no-pointer-events {
					pointer-events: none;
					cursor: pointer;
					opacity: 0.25;
				}

				&.hasCount {
					.count {
						display: block;
					}
				}

				&.option {
					&.btn-inverse {
						.count {
							&:hover {
								background: rgba(255, 255, 255, 0.17) !important;
								color: #dde3ea !important;

								&:before {
									color: #dde3ea !important;
								}
							}
						}
					}

					.count {
						&.count-intersected {
							&:hover {
								background: rgba(255, 255, 255, 0.17) !important;
								color: transparent !important;

								&:before {
									color: #888;
								}
							}
						}

						&:hover {
							background: rgba(0, 0, 0, 0.03) !important;
							color: #888 !important;
						}

						//Eye
						&:after {
							content: none !important;
						}
					}
				}

				>div {
					padding: 5px 10px;
					// display: inline-block;
					line-height: 16px;
					max-width: 200px;

					&.country-flag {
						width: 17px;
						height: 13px;
						opacity: 0.8;
						box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
						margin: 6px -2px 0 7px;
						padding: 0;
					}

					&.icon {
						padding-right: 0;
						margin-right: -2px;

						i {
							&.failedMails {
								color: #DF4A37;
							}

							&.fulfilled {
								color: #48C72B;
							}

							&.notFulfilled {
								color: #AFAFAF;
								font-size: 12px;
							}

							&.overdue {
								color: #F97732;
							}

							&.hasProgress {
								color: #DBAE0A !important;
							}

							&.hasDueDate {
								color: #aaa !important;
							}

							&.specialRequirements {
								color: #ADA41A !important;
							}

							&.allSigned {
								color: #3498db !important;
							}
						}
					}

					&.text {
						// padding-right: 5px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					&.text-additional {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						font-weight: normal;

						font-weight: 300;
						opacity: 0.7;
						padding-right: 15px;
						padding-left: 0;
					}

					&.count {
						&.asClose {
							display: block;
							font-size: 12px;

							&:hover {
								background-color: rgb(114, 129, 146) !important;
								color: #fff !important;
							}

							&:after {
								content: none !important;
							}
						}

						display: none;
						position: relative;
						padding: 4px 7px;
						// margin-left: 2px;
						background-color: rgba(0, 0, 0, 0.03);
						color: #888;
						margin: 1px;
						margin-left: -3px;
						font-weight: 400;
						// border-radius: 30px;
						// padding: 0px 6px;
						font-size: 10px;
						border-radius: 2px;
						min-width: 25px;
						// line-height: 16px;

						&:hover {
							background: @wf_red !important;
							color: transparent !important;

							&:after {
								content: "\f070";
								font-family: FontAwesome;
								font-style: normal;
								font-weight: normal;
								display: block;
								position: absolute;
								padding: 3px 0;
								z-index: 1;
								color: #fff;
								top: 0;
								left: 0;
								right: 0;
								border: 0;
								text-align: center;
								font-size: 14px;
							}

							&:before {
								display: none;
							}
						}

						&.count-intersected {
							color: transparent;

							&:before {
								content: attr(data-count-intersected);
								display: block;
								position: absolute;
								padding: 4px 7px;
								z-index: 1;
								color: #888;
								top: 0;
								left: 0;
								right: 0;
								border: 0;
								text-align: center;
							}
						}
					}

					&.wf-image {
						border-radius: 50%;
						margin-left: -1px;
						margin-right: 4px;
						padding: 0 !important;
						margin: 5px -5px 0px 7px;
					}
				}

				&.btn-inverse {
					&.disabled {
						opacity: 1;

						.text {
							opacity: 0.5;
						}

						.wf-image {
							opacity: 0.6;
						}
					}

					.count {
						color: #dde3ea;
						background-color: rgba(255, 255, 255, 0.17);

						&.count-intersected {
							&:before {
								color: #dde3ea;
							}
						}
					}

					.wf-image {
						box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.14) inset;
					}
				}
			}
		}
	}

	.selected-filters {
		border-top: 1px solid #eee;
		padding: 4px 0;
		background-color: white;
		z-index: 1000 !important;
		height: auto !important;
		border-bottom: 1px solid #eee;

		&.filters-collapsed {
			border-bottom: none;
		}

		&.onSticky {
			border-bottom: 1px solid #eee;

			button.toggle-button {
				display: none;
			}

			button.go-to-filters-button {
				display: inline-block;
			}

			.filter-options {
				opacity: 1;
				// padding-left: 27px;

				.btn-group {
					display: block;
				}
			}
		}

		&.onUnSticky {}

		&.show-all-active-filters {
			.filter-options {
				padding-bottom: 20px;

				.btn-group {
					max-height: none;

					&:after {
						position: relative;
						background-color: transparent;
					}
				}

				.show-all-filters-button {
					top: auto;
					bottom: 8px;
				}
			}
		}

		button.toggle-button {
			i {
				margin-left: 5px;
				color: @wf_blue;
			}
		}

		button.go-to-filters-button {
			display: none;

			i {
				margin-left: 5px;
				color: @wf_blue;
			}
		}

		.filter-options {
			width: auto;
			float: none;
			overflow: hidden;
			padding-left: 43px;
			opacity: 0;
			transition: opacity .2s;

			.btn-group {
				max-height: 80px;
				margin-bottom: 0px;

				&:after {
					content: '';
					background-color: white;
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					top: 60px;
					z-index: 10;
				}
			}

			>* {
				display: none;
			}

			&.filters-visible {
				opacity: 1;

				>* {
					display: block;
				}
			}

			.show-all-filters-button {
				display: block;
				cursor: pointer;
				color: @wf_blue;


				overflow: hidden;
				position: absolute;
				top: 81px;
				bottom: 0;
				z-index: 15;
			}
		}

		button.clear-filters {
			margin: 0 15px;

			i.fa {
				color: @wf_red;
				margin-right: 5px;
			}
		}

		wf-export-csv {
			.export-button {
				height: 16px;

				i {
					color: #2685ee;
					margin-right: 7px;
				}

				span {
					color: #707980;
				}
			}
		}
	}

	//end of: .selected-filters


	// .paginate-pages {
	// 	position: fixed;
	// 	bottom: 0;
	// 	background-color: white;
	// 	width: 790px;
	// 	box-shadow: 0 -2px 0 0 rgba(255, 255, 255, 0.4), 0 -4px 0 0 rgba(255, 255, 255, 0.3), 0 -6px 0 0 rgba(255, 255, 255, 0.3);
	// 	border-top-color: #f0efe7;
	// }

	.org-item {
		/*opacity: 1;*/
		/*transition: opacity 1s;*/

		/*&.ng-leave {
			-webkit-transition: .7s;
			transition: .7s;
			opacity: 1;

			&.ng-leave-active {
				opacity: 0;
			}
		}*/

		&.removing {
			opacity: 0.4;
			pointer-events: none;
		}

		i.typeIcon {
			// margin-right: .35em;
			width: 15px;
		}

		.org-details {
			padding: 3px 0 0;

			div {
				display: inline-block;
				padding-right: 2px;

				&:before {
					content: '\00B7';
					padding-right: 5px;
					font-weight: 800;
				}

				&:first-child:before {
					display: none;
				}
			}
		}

		.org-industries,
		.org-actorTypes {
			padding-top: 5px;

			.btn {
				padding: 3px 6px 2px;
				box-shadow: inset 0 0px 0px 1px rgba(0, 0, 0, 0.1) !important;
				border-left: 2px solid rgba(0, 0, 0, 0.1);
				border-radius: 2px;
				background: transparent !important;
				cursor: default;
				overflow: hidden;
				max-width: 100%;
				margin: 0 4px 4px 0;
				text-overflow: ellipsis;
				white-space: nowrap;
				text-align: left;
			}
		}

		.org-country {
			padding: 2px 0;

			.country-flag {
				width: 17px;
				height: 13px;
				display: inline-block;
				vertical-align: text-top;
				opacity: 0.8;
				box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
				margin-right: 4px;
			}
		}

		.org-customId {
			padding-top: 15px;
		}

		ul {
			li {
				padding: 1px 0 1px;
			}
		}

		.users {
			padding-top: 20px;

			&.loading {
				.spinner(18px);
				width: 100px;
				min-height: 69px;

				>* {
					visibility: hidden;
				}
			}

			li {
				position: relative;
				padding-left: 15px;

				&:not(:last-child) {
					padding-bottom: 4px;
				}

				i.userIcon {
					font-size: 13px;
					position: absolute;
					left: 0;
					top: 2px;
				}

				i.activatedIcon {
					color: #7DC36D;
					font-size: 7px;
					margin-left: 3px;
					top: -1px;
					position: relative;
				}

				.user-info {
					display: inline-block;
					vertical-align: top;
					cursor: default;
				}

				&.public-contact {
					.activatedIcon {
						margin-right: 3px;
					}

					.public-contact-icon {
						display: inline;
					}

					.user-info {
						position: relative;

						&:before,
						&:after {
							visibility: hidden;
							opacity: 0;
							pointer-events: none;
							transition: .1s;
						}

						&:before {
							content: attr(wf-tooltip-text) !important;
							visibility: visible !important;
							position: absolute;
							padding: 5px 8px;
							border-radius: 2px;
							background-color: fade(@wf_black_darker, 90%);
							max-width: 200px;
							text-align: center;
							font-size: 11px;
							color: #fff;
						}

						&[wf-tooltip-placement-top-left] {

							&:before,
							&:after {
								top: -25%;
								transform: translateY(-100%);
							}
						}

						&:after {
							position: absolute;
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 5px 5px 5px 0;
							border-color: transparent fade(@wf_black_darker, 90%) transparent transparent;
						}

						&:hover:before,
						&:hover:after {
							visibility: visible;
							opacity: 1;
							transition: .15s;
						}
					}
				}

				.public-contact-icon {
					display: none;
					margin-right: 3px;
				}

				.details {
					color: #aaa;
					font-size: 11px;

					div {
						float: left;
						padding-right: 5px;

						&:before {
							content: '\00B7';
							padding-right: 5px;
							font-weight: 800;
						}

						&:first-child:before {
							display: none;
						}

						&.mail {
							cursor: pointer;

							&:hover {
								text-decoration: underline;
							}

							&.failed {
								color: rgb(232, 64, 64);
								font-weight: bold;

								i {
									position: relative;
									padding-left: 3px;
									top: 1px;
								}
							}
						}
					}

				}
			}
		}

		ul.parents {
			padding-top: 25px;

			li {
				float: left;
				padding-right: 15px;

				a {
					color: #707070;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		a.addItem {
			display: inline-block;
			margin-top: 5px;

			i {
				position: relative;
				font-size: 13px;
				top: 1px;
			}

			&:hover {
				text-decoration: underline;
			}

			&.disabled {
				opacity: 0.7;
				text-decoration: none;

				span,
				i {
					pointer-events: none;
				}
			}

			// .fa-icon-before()

			// &.user {
			// 	i {
			// 		font-size: 18px;
			// 		top: 2px;
			// 	}
			// }
		}

		wf-item-tools {
			button {
				padding-left: 0;
				padding-right: 0;
				text-align: center;
				width: 34px;
			}
		}

		.org-name {
			padding: 0;
			margin: 0;

			a {
				color: #000;
			}
		}

		.sub-supplier-to {
			.sub-supplier-to-header {
				padding-bottom: 5px;
				font-weight: 600;
			}

			.organization {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-items: center;

				&:hover {
					cursor: pointer;
				}

				.img {
					height: 15px;
					width: 15px;
					display: inline-block;
					margin-right: 1px;

					i {
						font-size: 13px;
					}
				}

				.vat-number-tooltip {
					margin-left: 10px;

					&::before {
						content: '•';
						position: relative;
						right: 5px;
					}
				}
			}
		}
	}

	.panel-clickable {
		cursor: pointer;

		.panel {
			transition: box-shadow .2s;
		}

		&:hover {
			.panel {
				transition: box-shadow .1s;
				box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35), 0 -1px 0px 0 rgba(0, 0, 0, 0.07);
			}
		}
	}

	.distinct-influence-structures, .side-internal-influences {
		.panel {
			&:before {
				content: "\f08e";
				font-family: FontAwesome;
				font-style: normal;
				font-weight: normal;
				color: @wf_gray;
				text-align: right;
				padding: 10px 12px;
				transition: background-color ease .2s, color ease .2s;

				position: absolute;
				border-radius: 3px;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
			}
		}

		.grid {
			display: grid;
		}

		.hover-options {
			margin-bottom: 20px;
			border-radius: 3px;
			color: #fff;
			transition: box-shadow .1s;

			&:hover {
				box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35), 0 -1px 0px 0 rgba(0, 0, 0, 0.07);

				.option {
					opacity: 1;
					background-color: rgba(52, 152, 219, 0.75);
				}
			}

			.open {
				border-radius: 3px 3px 0 0;
			}

			.export {
				border-radius: 0 0 3px 3px;
			}

			.option {
				cursor: pointer;
				position: relative;
				opacity: 0;
				height: 50%;
				z-index: 2;
				padding: 15px;
				display: flex;

				background-color: rgba(52, 152, 219, 0.8);
				text-shadow: 0 0 10px #67B2E4, 0 0 10px #67B2E4, 0 0 10px #67B2E4;
				transition: background-color ease .2s, text-shadow ease .2s;

				i {
					display: flex;
					align-items: center;
					font-size: 23px;
				}

				span {
					display: flex;
					align-items: center;
					text-align: left;
					font-size: 14px;
					font-weight: 500;
					margin-left: 15px;
					padding-right: 15px;
				}

				&:hover {
					opacity: 1;
					text-shadow: 0 0 10px #48A2DF, 0 0 10px #48A2DF, 0 0 10px #48A2DF, 0 0 10px #48A2DF, 0 0 10px #48A2DF, 0 0 10px #48A2DF;
					background-color: rgba(52, 152, 219, 0.9);
				}
			}
		}
	}

	.side-internal-influences {
		.hover-options {
			align-items: center;
    		display: flex;

			.open {
				border-radius: 3px 0 0 3px;
			}

			.export {
				border-radius: 0 3px 3px 0;
			}

			.option {
				height: 100%;
				width: 50%;
				padding: 0px 10px 0px 15px;
			}
		}
	}

	panel.map {
		.panel-body {
			padding: 0;
		}
	}
}

.status {
	margin-left: 5px;
	margin-right: 5px;
	display: inline-block;
	border-radius: 1px;
	padding: 0px 4px 0px 3px;

	&.active {
		color: #00a500;
		box-shadow: 0 0 0 1px rgba(0, 165, 0, 0.88);
	}

	&.inactive {
		color: #c18900;
		box-shadow: 0 0 0 1px rgba(193, 137, 0, 0.82);
	}
}

.ValueChainMailSettingsController {

	.purpose,
	.purpose {
		padding-left: 0;
	}


}

.mail-subject {
	padding: 0;
	padding-top: 5px;
}

.ValueChainPackageEditorController {
	.packageSettings {
		padding-top: 20px;

		&.admin {
			overflow: hidden;
			background: rgba(0, 0, 0, 0.03);
			padding: 18px;
			border-radius: 5px;
			margin-bottom: 35px;
		}

		button {
			text-align: left;
			max-width: 215px;
			display: flex;

			> span:first-child {
				white-space: normal;
			}
		}

		>div {
			float: left;
			width: 20%;
			padding: 2px 20px 2px 0;
			margin-bottom: 20px;

			&:not(:first-child) {
				border-left: 1px solid @wf_gray_semilight;
				padding-left: 20px;
			}

			wf-object-settings {
				.button-wrapper {
					padding-top: 0;
				}
			}
		}

		.editIcon {
			padding-left: 10px;
			font-size: 13px;
			position: relative;
			top: 1px;
		}
	}

	.requirement-display {
		.pl10 {
			padding-left: 0 !important;
		}

		.requirement-label {
			font-size: 12px;
		}
	}

	.settings-signing {
		strong {
			font-size: 12px;
		}
	}

	.influence-relatedContentByCreator.mainStructure {
		margin-top: -15px;
	}
}

wf-influence-status {
	display: inline-block;
	// background: rgba(0,0,0,0.02);
	// border: 1px solid rgba(0,0,0,0.08);
	// border-radius: 11px;
	// min-width: 26px;
	// text-align: center;
	height: 21px;
	font-size: 0px;
	// padding: 0 6px;

	.progressNumber {
		font-size: 12px;
	}

	span {
		// float: left;
		// display: block;
	}

	i.fa,
	i.ion,
	i.svg {
		display: inline-block;
		font-size: 13px;
		line-height: 18px;
		// line-height: 19px;
		// display: block;
		// height: 16px;
		// width: 15px;
		// float: left;

		& {
			&:not(.typeIcon) {
				font-size: 12px;
				padding: 2px 4px;
				border-radius: 3px;

				&:hover {
					cursor: pointer;
				}
			}
		}

		&.fulfilled {
			color: #48C72B;

			&:hover {
				background-color: fade(#48C72B, 20%);
			}
		}

		&.notFulfilled {
			color: #AFAFAF;
			font-size: 12px;

			&:hover {
				background-color: fade(#AFAFAF, 20%);
			}
		}

		&.deadlineSoon {
			color: #5984b0;
		}

		&.overdue {
			color: #F97732;

			&:hover {
				background-color: fade(#F97732, 20%);
			}
		}

		&.hasProgress {
			color: #DBAE0A !important;

			&:hover {
				background-color: fade(#DBAE0A, 20%);
			}
		}

		&.hasDueDate {
			color: #aaa !important;

			&:hover {
				background-color: fade(#aaa, 20%);
			}
		}

		&.specialRequirements {
			color: #ADA41A !important;

			&:hover {
				background-color: fade(#ADA41A, 20%);
			}
		}

		&.assessmentNeeded {
			color: #707980 !important;

			&:hover {
				background-color: fade(#707980, 20%);
			}
		}

		&.allSigned {
			color: #3498db !important;

			&:hover {
				background-color: fade(#3498db, 20%);
			}
		}

		&.hasTargetUser {
			padding: 2px 1px;
			height: 22px;
			vertical-align: bottom;

			&:hover {
				background-color: fade(#c1a489, 20%);
			}

			svg {
				font-size: 17px;
				fill: #c1a489;
				width: 17px;
				height: 17px;
				display: inline-block;
				user-select: none;
			}
		}
	}


	.fulfillment-statistics {
		&:not(:first-child) {
			margin-left: 5px;
		}

		display: inline-block;
		border: solid 1px #e5e5e5;
		border-radius: 3px;

		.state {
			display: inline-block;
			padding: 0 5px;
			border-radius: 3px;

			&:hover {
				cursor: pointer;
			}

			&.fulfilled {
				color: @wf_green;

				&:hover {
					background-color: fade(@wf_green, 20%);
				}
			}

			&.unfulfilled {
				color: @wf_red;

				&:hover {
					background-color: fade(@wf_red, 20%);
				}
			}

			&.assessment-needed {
				color: @wf_yellow_dark;

				&:hover {
					background-color: fade(@wf_yellow_dark, 20%);
				}
			}

			&.reporting-needed {
				color: @wf_black;

				&:hover {
					background-color: fade(@wf_black, 20%);
				}
			}

			i {
				font-size: 10px !important;
				padding-right: 2px;
			}

			span {
				font-size: 11px !important;
				font-weight: 600;
			}
		}
	}

	.fulfillment-status {
		display: inline-block;
	}
}

.influence-relatedContentByCreator {
	>strong {
		display: block;
		padding-bottom: 1px;
	}

	>div {
		padding: 1px 0 0;
		min-height: 23px;
	}

	a {
		display: inline-block;
		padding: 1px 0;
		margin: 2px 0;

		i {
			// display: block;
			padding-right: 3px;
			font-size: 12px;
			position: relative;
			top: -1px;
			// float: left;
			// clear: none;
		}

		span {
			// float: none;
			// clear: none;
			// overflow: hidden;
		}

		&:hover {
			span {
				text-decoration: underline;
			}
		}

		&:after {
			display: inline-block;
			content: "\f08e";
			color: rgb(149, 193, 239);
			padding-left: 1px;
			font: normal normal normal 14px/1 FontAwesome;
			font-size: 14px;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			position: relative;
			top: 1px;
		}
	}
}

wf-object-settings,
wf-requirement {
	display: block;

	strong {
		display: block;
		font-size: 11px;
	}
}

.wf-requirement-popover {
	max-width: none;
	width: 350px;

	a {
		word-break: break-all;

		&:hover {
			text-decoration: underline;
		}
	}

	.wf-bodyText {
		padding-top: 10px;
	}

	wf-object-metadata {
		padding-top: 12px;
	}
}

wf-requirement {
	.requirement-value {
		display: inline-block;
		cursor: pointer;

		&:hover {
			.text {
				text-decoration: underline;
			}
		}
	}

	.selectedRequirementMismatch {
		color: red;
	}

	.icon-comment {
		display: inline-block;
		position: relative;
		top: -2px;
		padding-left: 2px;
		font-size: 14px;
		color: #3498db;
	}

	.details {
		min-height: 20px;
		// padding-left: 10px;
	}

	.localFulfillment {
		display: inline-block;
		width: 20px;
		height: 20px;
		position: relative;
		top: 1px;
		margin-right: 8px;


		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 3px;
			left: 3px;
			width: 12px;
			height: 12px;
			border-radius: 100%;
			transition-duration: .1s;
			box-shadow: 0 0 0px 1px transparent;
		}

		&:hover {
			&.unfulfills:before {
				box-shadow: 0 0 5px 1px #e63c3c;
			}

			&.fulfills:before {
				box-shadow: 0 0 5px 1px #7dc36d;
			}

			&.fulfillsException:before {
				box-shadow: 0 0 5px 1px #f3bc00;
			}

			&.partial:before {
				box-shadow: 0 0 5px 1px #f3bc00;
			}

			&.emptyRequirement:before {
				box-shadow: 0 0 5px 1px @wf_gray;
			}
		}

		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 5px;
			left: 5px;
			width: 8px;
			height: 8px;
			border-radius: 100%;
			transition-duration: .3s;
		}

		&.unfulfills:after {
			background-color: #e63c3c;
		}

		&.fulfills:after {
			background-color: #7dc36d;
		}

		&.fulfillsException:after {
			background-color: #f3bc00;
		}

		&.partial:after {
			background-color: #f3bc00;
		}

		&.emptyRequirement:after {
			background-color: @wf_gray;
		}
	}
}

ul.valueChain-influences {
	>li {
		// padding-bottom: 3px;
		// padding-left: 23px;
		padding: 1px 0 1px;
		// margin-left: -23px;
		display: block;
		position: relative;

		&.highlight {
			// transform: scale(1.2);
			// transform-origin: left center;
			// margin-left: -1px;

			// > a {
			// 	font-weight: 500;
			// }

			// background: white;

			.influence {
				a {
					// box-shadow: -4px 0 0px 0px rgba(0, 0, 0, 0.48);

					>span {
						font-weight: 600;
					}
				}
			}
		}

		&.dim {
			.influence {
				a {
					opacity: 0.5;

					&:hover {
						opacity: 0.8;
					}
				}
			}
		}

		&.notFulfilled .influence {
			// color: #AFAFAF;
		}

		&.fulfilled .influence {
			a {
				// color: #48C72B;
				color: #389621;
			}
		}

		&.hasProgres .influence {
			a {
				// color: #48C72B;
				color: #B9950A;
			}
		}

		&.overdue .influence {
			a {
				// color: #F97732;
				color: #B55E31;
			}
		}

		.influence {
			padding-right: 10px;

			a {
				overflow: hidden;
				clear: none;
				float: none;
				width: auto;

				color: #707980;
				padding: 2px 3px;

				&:hover {
					text-decoration: underline;
				}
			}

		}

		wf-influence-status {
			padding-top: 2px;
			padding-left: 5px;
		}

		&:hover {
			.influence-dropdown {
				.dropdown-mini {
					opacity: 1;
					transition: opacity .0s;
				}
			}
		}

		.influence-dropdown {
			display: inline-block;
			position: absolute;
			left: -23px;
			top: 1px;
			padding-right: 7px;

			.dropdown-mini {
				opacity: 0;
				transition: opacity .05s;

				&.open {
					opacity: 1 !important;

					>div {
						background-color: rgba(0, 0, 0, 0.05);
					}
				}

				>div {
					box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
					border-radius: 2px;
					text-align: center;
					width: 16px;
					height: auto;
					line-height: 17px;
					cursor: pointer;
					padding: 2px 0 0;
					color: #333;

					&:hover,
					&:focus,
					&:active {
						background-color: rgba(0, 0, 0, 0.05);
					}
				}
			}
		}

		// span.status {
		// 	display: inline-block;

		// 	span {
		// 		// float: left;
		// 		// display: block;
		// 	}

		// 	i {
		// 		font-size: 14px;
		// 		// display: block;
		// 		// height: 16px;
		// 		// width: 15px;
		// 		// float: left;

		// 		&.fulfilled {
		// 			color: #48C72B;
		// 		}
		// 		&.notFulfilled {
		// 			color: #AFAFAF;
		// 			font-size: 12px;
		// 		}
		// 		&.overdue {
		// 			color: #F97732;
		// 		}
		// 		&.hasProgress {
		// 			color: #DBAE0A !important;
		// 		}
		// 		&.hasDueDate {
		// 			color: #aaa !important;
		// 		}
		// 		&.specialRequirements {
		// 			color: #ADA41A !important;
		// 		}
		// 	}
		// }
	}
}

.influenceGrouperController {
	.valueChain-influences {
		margin-bottom: 0;

		.link-wrapper {
			position: relative;

			.influence-dropdown .dropdown-mini {
				opacity: 0;
				transition: opacity .05s;
			}

			&:hover {
				.influence-dropdown .dropdown-mini {
					opacity: 1;
					transition: opacity .0s;
				}
			}
		}

		.influence-dropdown {
			// display: none !important;
		}
	}

	wf-item-component {
		wf-dropdown {
			button {
				// Instead of btn-sm
				font-size: 11px;
				padding: 5px 10px;
				width: 27px !important;
			}
		}
	}

	.report-influences {
		padding-top: 10px;
		padding-left: 122px;

		wf-item-component {
			padding-top: 6px;
			padding-bottom: 6px;
			padding-right: 0;
			padding-left: 10px;
		}

		.influence-fulfillment-status .progress {
			margin-bottom: 0;
		}

		.item-report-influence {
			position: relative;

			.icon-arrow {
				@color: #bbb; // Item icon color is #AFAFAF and arrow is a little lighter
				@angleSize: 10px;
				@arrowSize: 8px;
				position: absolute;
				width: 0;
				height: 0;
				top: 11px;
				left: -26px;

				&:before {
					content: "";
					position: absolute;
					width: @angleSize + 2px;
					height: @angleSize;
					border-left: 2px solid @color;
					border-bottom: 2px solid @color;
				}

				&:after {
					content: "";
					position: absolute;
					top: @angleSize - (@arrowSize / 2) - 1px;
					left: @angleSize - (@arrowSize / 2) - 2px;
					width: @arrowSize;
					height: @arrowSize;
					border-top: 2px solid @color;
					border-right: 2px solid @color;
					transform: rotate(45deg);
				}
			}
		}

		.wf-headerText {
			font-size: 13px !important;
			padding: 0 !important;
			font-weight: 500 !important;
			line-height: 17px !important;
			color: #555 !important;
		}

		.influence-fulfillment-status {
			width: 220px;
			padding-top: 2px;
			padding-left: 20px;
			padding-right: 15px;
		}
	}

	.sub-items {
		margin-top: 15px;
		padding: 16px;
		background: #fafafa;
		margin-left: 66px;
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.015) inset;

		.btn {
			background-color: #f3f3f3;
		}

		.sub-item {
			&:not(:first-child) {
				padding-top: 20px;
			}

			.wf-headerText {
				font-weight: 600 !important;
			}
		}

		.wf-headerText {
			font-size: 14px !important;
			padding: 0 !important;
		}

		.image {
			margin-right: 10px;

			.wf-image {
				font-size: 25px !important;
			}
		}

		.report-influences {
			padding-top: 0;
			padding-left: 40px;

			.wf-headerText {
				font-size: 13px !important;
				font-weight: 500 !important;
			}

			.image {
				margin-right: 15px;
			}
		}
	}

	li {
		&:not(:first-child) {
			.influences-org-group {
				padding-top: 10px;
			}
		}

		.influences-org-group {
			font-weight: 600;
			padding: 5px 0 0;
			font-size: 13px;
		}

		div.influences-org-createInfluence {
			clear: both;
			padding: 3px 0 5px;
		}
	}


	a.addItem {
		display: inline-block;
		margin-top: 5px;

		i {
			position: relative;
			font-size: 13px;
			top: 1px;
			padding-right: 4px;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	i.typeIcon {
		width: 15px;
	}

	ul.parents {
		padding-top: 10px;
		margin-bottom: 2px;

		li {
			float: left;
			padding-right: 15px;

			a {
				color: #707070;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	wf-chart {
		margin-bottom: -25px;
		pointer-events: none;
		height: 175px;

		>div {
			width: 115px;
			height: 115px;
			top: -5px;

			span.value-percentage {
				margin-top: -22px;
				margin-left: 2px;
			}

			span.value-count {
				margin-top: -3px;

				color: #bbb;
				font-size: 11px;
				font-weight: normal;

				.slash {
					display: inline-block;
					padding: 0 1px;
				}
			}
		}

		p {
			font-weight: 500;
			font-size: 11px;
			margin: 0 -10px;
			padding-top: 4px;
		}
	}
}

.influence-fulfillment-status {
	width: 260px;
	padding-top: 2px;
	padding-left: 30px;
	padding-right: 50px;

	.sender {
		padding-bottom: 11px;
	}

	.slash {
		display: inline-block;
		padding: 0 1px;
	}

	.progress {
		height: 6px;
	}
}



.panel-stats-count {
	& {
		/*cursor: pointer;*/
		min-height: 40px;
		/*
		&:hover {
			background: #fafafa;
			box-shadow: 0 0 0 8px #fafafa;
		}*/
	}


	.email-info {
		display: flex;
		flex-direction: column;

		h4{
			align-self: flex-start;
		}

		.email-settings {
			display: flex;
			flex-direction: column;
			row-gap: 15px;

			.email-setting {
				display: flex;
				justify-content: space-between;

				.email-text{
					display: flex;
				}
				.btn-link {
					margin-left: 5px;
					padding: 0px;
					display: flex;
					//float: right;
					align-self: flex-end;
				}
			}

		}
	}


	.stats {
		padding: 0px 20px;

		.number {
			font-size: 34px;
		}
	}

	.number {
		font-size: 46px;
		padding-right: 20px;
		font-weight: bold;
		line-height: .9em;
	}

	p {
		float: none !important;
		clear: none;
		width: auto;
		overflow: hidden;
		margin-bottom: -10px;
		line-height: 1.2em;
		color: #707980; // Needed because .influenceGrouperInfo.productionSites contains a link
	}

	.panel-body,
	.panel-body>& {
		>div {
			border-left: 1px solid #eee;

			&:first-child {
				border: 0;
			}
		}
	}

	&.panel-align-center {
		text-align: center;

		.number {
			float: none !important;
			clear: both !important;
			padding-right: 0;
		}

		p {
			clear: both !important;
			margin-bottom: 0;
		}

		.icon {
			font-size: 12px;
			margin: 0;
			padding: 0;
			padding-left: 5px;
		}
	}

	.icon {
		font-size: 18px;
		padding: 0 8px;
	}

	p,
	.icon {
		padding-top: 12px;
	}

	.requiresActionInfo {

		.number.anyIsOverdue,
		.icon {
			color: #F97732;
		}
	}

	.influenceGrouperInfo {
		// Needed because production sites panel is a link that covers the entire panel
		display: block;
		padding: 20px;
		height: 81px;

		&.productionSites {

			.number,
			.icon {
				color: #48CE76;
			}
		}

		&.subOrganizations {

			.number,
			.icon {
				color: #4fe1e2;
			}

			.icon {
				font-size: 21px;
			}
		}
	}

}

.valueChain-analyzeJobs {
	.analyzeJob {
		&:hover {
			text-decoration: none !important;

			.title {
				text-decoration: underline;
			}

			// .score, .scoreLabel {
			// 	text-decoration: none !important;
			// }
		}

		.title {
			padding-right: 10px;
		}

		.score {
			padding: 0 5px 0 7px;
			font-weight: 700;
			font-size: 13px;
			border-left: 1px solid @wf_gray_light;
		}

		.scoreLabel {
			background: #616377;
			color: white;
			border-radius: 3px;
			padding: 2px 6px;
			display: inline-block;
			font-size: 11px;
			font-weight: normal !important;
		}
	}
}

.ValueChainCategoriesController {

	.wf-filter-container {
		input {
			z-index: auto;
		}
	}

	.btn-create {
		position: relative;
		z-index: 1;
	}

	.standardCategoriesAdder {
		background-color: @wf_green_dark !important;
	}

	.dropdown-menu.groups {
		width: 220px !important;
		max-height: 350px;
		overflow-y: auto;
		margin-top: 20px;
		transform: translateY(-50%);

		.removal {
			a:hover {
				i {
					color: @wf_red;
				}
			}
		}
	}

	.edit-groups-button {
		z-index: 1;
		position: relative;
		margin-left: 15px;


		.group-list-item {
			display: flex;
		}

		.title-text {
			display: inline-block;
			word-wrap: break-word;
			white-space: normal;
			width: 115px;
		}

		i {
			padding-right: 10px;
		}

		#sortable-group {
			padding-right: 0px;
			padding-left: 10px;
		}
	}
}

wf-value-chain-importer {
	.fileDetails {
		padding-bottom: 20px;

		.mimeType {
			color: @wf_gray_dark;
			font-size: 11px;
		}
	}

	.org-item {
		&.orgImported {
			button.import.organization {
				background: white;
				pointer-events: none;

				.caption-imported,
				i {
					display: inline-block;
				}

				.caption-import {
					display: none;
				}
			}

			button.import.user {
				pointer-events: auto;
			}
		}

		&.usersImported {
			button.import.user {
				background: white;
				pointer-events: none;

				.caption-imported,
				i {
					display: inline-block;
				}

				.caption-import {
					display: none;
				}
			}
		}
	}

	button.import {
		i.fa-check {
			display: none;
			padding-right: 3px;
			color: @wf_green;
		}

		span {
			display: inline-block;
		}

		.caption-imported {
			display: none;
		}

		.caption-import {
			display: inline-block;
		}

		&.organization {
			min-width: 177px;
		}

		&.user {
			min-width: 140px;
			pointer-events: none;
		}
	}
}

.wf-value-chain-organization-adder {
	display: block;
	min-width: 320px;

	.header {
		padding-bottom: 5px;
		margin-bottom: 5px;
		border-bottom: 1px solid @wf_gray_lighter;

		wf-button {
			margin-left: 15px;
		}

		span.header-text {
			// color: @wf_black;
			margin-bottom: 7px;
			margin-right: 25px;
			font-weight: 600;
			font-size: 13px;
			margin: 5px 0px;
			display: inline-block;
		}
	}

	.guidance-icon {
		font-size: 14px;
		margin-left: 5px;
		opacity: 0.5;
		cursor: pointer;

		&:hover {
			opacity: 0.9;
		}
	}

	.org-not-parent-message {
		color: @wf_gray_dark;
		padding: 10px;
		border: 1px solid @wf_gray_semilight;
		border-radius: 4px;
		margin-bottom: 10px;

		div.btn {
			margin-top: 7px;
		}
	}

	.network-membership-status {
		width: 320px;
		min-height: 40px;

		.status-wrapper {
			margin-bottom: 5px;

			&:not(.member) {
				color: @wf_gray;

				.network-status,
				.status-icon {
					// color: @wf_gray;
				}
			}

			.status-icon {
				margin: 5px 5px 5px 0;
				display: inline-block;
				width: 15px;
				vertical-align: top;
			}

			.status {
				border-bottom: 1px solid @wf_gray_lighter;

				.min-height-20 {
					min-height: 20px;
				}

				.width-50 {
					width: 50px;
				}

				.network {
					margin: 5px 0;

					.network-title {
						padding-right: 10px;

						span {
							font-weight: 500;
						}
					}

					.manage-contacts-button {
						button {
							outline: none;
							background-color: transparent;
							border: none;
							color: @wf_blue;
							font-size: 11px;

							i {
								margin-right: 3px;
								font-size: 12px;
							}

							span {
								vertical-align: middle;
							}

							&:hover {
								span {
									text-decoration: underline;
								}
							}
						}
					}
				}

				.people-wrapper {

					.people {
						margin-bottom: 5px;

						.person {
							padding-bottom: 5px;

							.icon {
								i {
									margin-right: 5px;
								}
							}

							.person-name {
								.user-details {
									font-size: 11px;
									color: @wf_gray_dark;
								}
							}
						}
					}
				}
			}
		}
	}
}

.ValueChainPackagesController {
	.addSustainabilityTopic {
		background-color: @wf_green_dark !important;
	}

	.numberDetails {
		display: inline-block;
		margin-left: 5px;
		color: @wf_gray_dark;

		.slash {
			display: inline-block;
			margin: 0 2px;
		}
	}

	.latestRecalc {}

	.activeRecalc {
		.spinner {
			.spinner(16px);
			display: inline-block;
			width: 16px;
			height: 16px;
			margin-right: 5px;

			&.queued::before {
				animation-duration: 2.5s;
			}
		}

		.text {
			display: inline-block;
			position: relative;
			top: -3px;
		}

		.progress {
			margin-top: 2px;
			height: 4px;
			background-color: rgba(52, 73, 94, 0.07);
			margin-bottom: 0;

			.progress-bar {
				transition-duration: .3s;
				background-color: rgba(44, 62, 80, 0.5);
				height: 4px;
			}
		}

		.queuedInfo {
			margin-left: 2px;
			padding: 4px;

			&[wf-tooltip]:before {
				width: 350px;
				max-width: none;
				font-size: 12px;
			}

			i {
				color: @wf_blue;
				transform: scale(1.2);
				opacity: 0.7;
				cursor: default;

				&:hover {
					opacity: 1;
				}
			}
		}
	}
}

.popover.wf-value-chain-category-filtering {
	.popoverBoxShadow();

	width: 700px;
	max-width: 700px;
}

.fulfillment-tooltip {
	&.fulfilled {
		.tooltip-inner {
			background-color: @wf_green;
		}

		.tooltip-arrow {
			border-top-color: @wf_green !important;
		}
	}

	&.unfulfilled {
		.tooltip-inner {
			background-color: @wf_red;
		}

		.tooltip-arrow {
			border-top-color: @wf_red !important;
		}
	}

	&.assessment-needed {
		.tooltip-inner {
			background-color: @wf_yellow_dark;
		}

		.tooltip-arrow {
			border-top-color: @wf_yellow_dark !important;
		}
	}

	&.reporting-needed {
		.tooltip-inner {
			background-color: @wf_black;
		}

		.tooltip-arrow {
			border-top-color: @wf_black !important;
		}
	}
}

.value-chain-export-orgs-popover {
	max-width: 700px;
	width: 700px;
}

.value-chain-export-aggregatedItem-popover {
	max-width: 700px;
	width: 700px;
	z-index: 10000;
}


.modal-alert.exportAll_error {
	code {
		display: block;
		margin-top: 5px;
		padding: 8px 10px;
	}
}

.value-chain-editor-items {
	&.as-sortable-dragging  {
		padding: 0 10px;
		background: white;
		box-shadow: 0 1px 5px rgba(0, 0, 0, 0.23);
		border-radius: 3px;

		> .as-sortable-item > .dragReorder .icon-wrapper {
			opacity: 1;
		}
	}

	wf-valuechain-package-subitem {
		display: block;

		.btn {
			font-weight: 500;
		}

		.numberPath {
			display: inline-block;
		}

		.item-content {
			padding: 10px 0;
			border-bottom: 1px solid rgba(0, 0, 0, 0.07);
			padding-left: 30px;

			&:hover {
				background-color: #fafafa;
			}

			.fa-bullseye {
				color: @wf_blue;
			}
		}

		.structure {
			.top {

				.wf-headerText,
				.numberPath span {
					font-weight: 500;
					color: #444;
				}
			}

			.children {
				/*border-top: 1px solid #ccc;*/
				/*margin-top: 10px;*/
				padding-top: 10px;
			}
		}

		.btn.reorder {
			padding-left: 9px;
			padding-right: 9px;
			height: 34px;
		}

		wf-item-tools {
			.glyphicon-file {
				position: relative;
				left: 1px;
			}
		}

		.influence-relatedContentByCreator {
			padding-top: 5px;
			padding-left: 1px;
		}
	}

	.as-sortable-placeholder {
		box-shadow: 0 5px white inset, 0 -10px white inset;
		background-color: @wf_gray_lighter;
	}

	.as-sortable-item {
		position: relative;

		.dragReorder {
			position: absolute;
			top: 6px;
			width: 30px;
			height: 30px;
			display: flex;
			align-items: center;
			left: -8px;

			&:hover {
				background-color: @wf_gray_lighter;
				border-radius: 3px;

				.icon-wrapper {
					opacity: 1;
				}
			}

			&.as-sortable-item-handle {

			}

			.icon-wrapper {
				width: 100%;
				text-align: center;
				cursor: grab;
				opacity: 0.6;

				i {
					cursor: grab;
				}
			}
		}
	}
}

.panel-network-setting-reportingLock {
	.panel {
		transition: box-shadow 0.05s;

		&:hover {
			box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25), 0 -1px 0px 0 rgba(0, 0, 0, 0.03)
		}
	}
	.panel-body {
		display: flex;
		cursor: pointer;
		padding-right: 0;

		&:hover {
			background: #f6f6f6;
			color: #666 !important;
		}

		i {
			padding-right: 7px;
			align-self: center;
			font-size: 15px;
			margin-top: -1px;
		}

		.centerdot {
			padding: 0 2px;
		}
	}
}

.value-chain-network-settings-popover {
	max-width: 300px;

	.setting-reportingLock {
		button {
			display: flex;
			text-align: left;

			.lockedBeforeDate {
				border-top: 1px solid rgba(0, 0, 0, 0.08);
				padding-top: 4px;
				margin-top: 3px;
			}

			.editIcon {
				padding-top: 1px;
				padding-left: 5px
			}
		}
	}
}
