@color_1: #4679bd;
@color_3: #ffffff;
@color_5: inherit;
@font-family_1: 'Open Sans';


//@import "//fonts.googleapis.com/css?family=Open+Sans";
/* -----------------------------------------------
 * Timeline
 * --------------------------------------------- */
.wf-timeline {
	@timelineBadgeDiameter: 16px;
	@arrowWidth: 14px;
	@arrowTop: 20px;
	@arrowTipToBadgeDistance: 4px;
	@borderColor: rgba(0, 0, 0, 0.07);//(#dcdcdc * 1.03);

	list-style: none;
	padding: 10px 0;
	position: relative;
	font-weight: 300;

	&:before {
		top: 0;
		bottom: 0;
		position: absolute;
		content: " ";
		width: 4px;
		background: rgba(0,0,0,0.08);
		left: 50%;
		margin-left: -2px;
	}

	> li {
		margin-bottom: 20px;
		position: relative;
		width: 50%;
		float: left;
		clear: left;
		padding-right: @arrowTipToBadgeDistance + @arrowWidth + (@timelineBadgeDiameter / 2);

		&:before {
			content: " ";
			display: table;
			content: " ";
			display: table;
		}

		&:after {
			content: " ";
			display: table;
			clear: both;
			content: " ";
			display: table;
			clear: both;
		}

		> .timeline-panel {
			// width: calc(100% - 25px);
			// width: -moz-calc(100% - 25px);
			// width: -webkit-calc(100% - 25px);
			width: 100%;
			padding: 16px;
			min-height: (@arrowTipToBadgeDistance * 2) + (@arrowWidth * 2) + (16px * 2);
			float: left;
			// border: 1px solid @borderColor;
			box-shadow: 0 1px 2px 0 rgba(0,0,0,.12),0 -1px 0 0 rgba(0,0,0,.03);
			background: #ffffff;
			position: relative;
			border-radius: 3px;

			wf-item-tools {
				display: none;
			}

			&:before {
				position: absolute;
				top: @arrowTop;
				right: -@arrowWidth;
				display: inline-block;
				height: 1px;
				border-top: @arrowWidth solid transparent;
				border-left: @arrowWidth solid (@borderColor);
				border-right: 0 solid (@borderColor);
				border-bottom: (@arrowWidth) solid transparent;
				box-sizing: content-box;
				content: " ";
			}

			&:after {
				position: absolute;
				top: @arrowTop + 1;
				right: -(@arrowWidth - 1);
				display: inline-block;
				border-top: (@arrowWidth - 1) solid transparent;
				border-left: (@arrowWidth - 1) solid #ffffff;
				border-right: 0 solid #ffffff;
				border-bottom: (@arrowWidth - 1) solid transparent;
				content: " ";
			}

		}

		> .timeline-badge {
			color: @color_3;
			width: @timelineBadgeDiameter;
			height: @timelineBadgeDiameter;
			line-height: 50px;
			text-align: center;
			position: absolute;
			top: @arrowTop + @arrowWidth + 1 - (@timelineBadgeDiameter / 2);
			right: @timelineBadgeDiameter * -0.5;
			z-index: 100;
			border-radius: 50%;
			box-shadow: 0 0 0 3px #9c9c9c inset;
			background-color: #fff;
		}

		&:nth-child(2) {
			margin-top: 60px;
		}
	}

	> li.timeline-inverted {
		padding-right: 0;
		padding-left: @arrowTipToBadgeDistance + @arrowWidth + (@timelineBadgeDiameter / 2);
		
		> .timeline-panel {
			float: right;
			&:before {
				border-left-width: 0;
				border-right-width: @arrowWidth;
				left: -@arrowWidth;
				right: auto;
			}

			&:after {
				border-left-width: 0;
				border-right-width: (@arrowWidth - 1);
				left: -(@arrowWidth - 1);
				right: auto;
			}
		}

		float: right;
		clear: right;

		> .timeline-badge {
			left: @timelineBadgeDiameter * -0.5;
		}
	}
}

// .timeline-badge {
// 	> a {
// 		color: @color_3 !important;
// 	}

// 	a {
// 		&:hover {
// 			color: @color_4 !important;
// 		}
// 	}
// }

.timeline-title {
	margin-top: 0;
	color: @color_5;
}

.timeline-heading {
	h4 {
		font-weight: 400;
		padding: 0 15px;
		color: @color_1;
	}
}

.timeline-body {
	> p {
		padding: 10px 15px;
		margin-bottom: 0;
	}

	> ul {
		padding: 10px 15px;
		margin-bottom: 0;
	}
}

.timeline-footer {
	padding: 5px 15px;
	background-color: #f4f4f4;
	p {
		margin-bottom: 0;
	}

	> a {
		cursor: pointer;
		text-decoration: none;
	}
}

.no-float {
	float: none !important;
}

@media (max-width: 767px) {
	ul.wf-timeline {
		&:before {
			left: 40px;
		}

		> li {
			margin-bottom: 0px;
			position: relative;
			width: 100%;
			float: left;
			clear: left;
			> .timeline-panel {
				width: calc(100% - 65px);
				width: -moz-calc(100% - 65px);
				width: -webkit-calc(100% - 65px);
				float: right;
				&:before {
					border-left-width: 0;
					border-right-width: 15px;
					left: -15px;
					right: auto;
				}

				&:after {
					border-left-width: 0;
					border-right-width: 14px;
					left: -14px;
					right: auto;
				}
			}

			> .timeline-badge {
				left: 28px;
				margin-left: 0;
				top: 16px;
			}
		}
	}

	.wf-timeline {
		> li.timeline-inverted {
			float: left;
			clear: left;
			margin-top: 30px;
			margin-bottom: 30px;
			> .timeline-badge {
				left: 28px;
			}
		}
	}
}
