
.ui-grid {
	min-height: 500px;

	.ui-grid-top-panel {
		background: #fff;
		
		.ui-grid-header {
		}
	}

	.ui-grid-header-cell {
		border-color: #ededed;

		&:hover {
			background: rgba(44, 62, 80, 0.06) !important;
		}
	}
	
	.ui-grid-row {

		&:hover {
			&:not(:first-child) {
				.ui-grid-cell {
					// box-shadow: 0px -1px #dbdee0;
				}
			}
			.ui-grid-cell {
				background-color: rgba(44, 62, 80, 0.06) !important;
				// border-color: #dbdee0;
			}
		}

		.ui-grid-cell {
			background-color: #fff !important;
			border-bottom: 1px solid #eee;
			border-color: #eee;
		}
	}
}