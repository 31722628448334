@import "wf-variables.less";

wf-relative-measure-steps-editor {
	.modal-header {
		.modal-title {
			padding-bottom: 5px;

		}

		.item-preview {
			font-size: 15px;
		}
	}

	.modal-body {
		.column-headers {
			padding: 0 15px 5px 15px;
			margin: 0 5px 10px 5px;
			overflow: visible;

			.column-header {
				span {
					margin-right: 5px;
				}
			}
		}

		.relative-measure-calculation-steps {
			.as-sortable-placeholder {
				box-shadow: 0 5px white inset, 0 -10px white inset;
				background-color: @wf_gray_lighter;
				border-bottom: 1px solid @wf_gray_lighter;
			}
		}

		.relative-measure-calculation-preview {
			display: flex;
			justify-content: center;
			padding-top: 50px;
		}

		.verification-message {
			display: flex;
			justify-content: center;
			padding-top: 40px;

			span {
				color: @wf_red;
				font-size: 14px;
			}
		}
	}

	.invalid-form {
		.relative-measure-calculation-steps {
			//.ui-select-container {
			//	&.ng-empty {
			//		.ui-select-match {
			//			.ui-select-toggle {
			//				border-color: @wf_red;
			//			}
			//		}
			//	}
			//}

			.noSourceMeasureOrCustomValueSet {
				.textbox-value {
					input {
						border-color: @wf_red;
					}
				}

				.measure {
					button {
						box-shadow: inset 0 0px 0px 1px @wf_red;
					}
				}

			}

			.requiredCustomValueNotSpecified {
				.textbox-value {
					input {
						border-color: @wf_red;
					}
				}
			}

			.useNextStepPolicyIsInvalid {
				.policy {
					.ui-select-container {
						.ui-select-match {
							.ui-select-toggle {
								border-color: @wf_red;
							}
						}
					}
				}
			}

			.noMathOperationSet {
				.operation {
					.ui-select-container {
						.ui-select-match {
							.ui-select-toggle {
								border-color: @wf_red;
							}
						}
					}
				}
			}
		}

		.modal-body {
			.relative-measure-calculation-steps {
					.input-group {
						&.disabled {
							.ui-select-container {
								&.ng-empty {
									.ui-select-match {
										.ui-select-toggle {
											border-color: #e0e0e0;
										}
									}
								}
							}
						}
					}
			}

			.relative-measure-calculation-preview {
				.unselected-measure, .unselected-operation {
					border-bottom-color: @wf_red;
				}
			}
		}
	}
}

wf-relative-measure-calculation-preview {
	.steps-preview-wrapper {

		.operation {
			.rotate-45 { transform: rotate(-45deg); }

			.unselected-operation {
				width: 30px;
				height: 30px;
				border-bottom: 1px solid @wf_gray;
				cursor: default;

				&:before {
					margin-bottom: -4px;
				}
			}
		}

		.measure-item {
			.unselected-measure {
				width: 80px;
				height: 30px;
				border-bottom: 1px solid @wf_gray;
				cursor: default;

				&:before {
					margin-bottom: -4px;
				}
			}
		}

		.relative-measure {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;

			.icon {
				order: 1;
				margin-right: 10px;
				position: relative;
				i {
					color: #AFAFAF;
					text-align: center;
					margin-top: 0;
					font-size: 25px;
				}

			}

			.item-text {
				order: 2;
				font-size: 91%;
				display: flex;
				align-items: center;
			}
		}

		.calculation-steps {
			.step:first-child { .operation { display: none } }
		}

		&.default-bg-layout {
			.steps-preview-default-layout(25, 16, 250);
		}
		&.default-md-layout {
			.steps-preview-default-layout(20, 14, 200);
		}
		&.default-sm-layout {
			.steps-preview-default-layout(15, 12, 150);
		}
	}

	// ------------------------ Steps Preview Layouts ------------------------------

	.steps-preview-default-layout(@stepPadding, @fontSize, @bodyTextMaxWidth) {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;

		.operation {
			padding-left: @stepPadding * 1px;
			padding-right: @stepPadding * 1px;
			font-size: @fontSize * 1px;
		}

		.relative-measure, .step {
			// padding-top: (@stepPadding / 2) * 1px;
			// padding-bottom: (@stepPadding / 2) * 1px;
			padding-right: 0;
			padding-left: 0;
		}

		.calculation-steps {
			order: 1;

			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: center;

			padding: 10px;
			border: 1px solid @wf_gray_lighter;
			border-radius: 3px;
		}

		.step {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: center;

			padding-top: 5px;
			padding-bottom: 5px;

			&.result {
				order: 2;
			}

			.value {
				&:before {
					white-space: pre;
					max-width: 300px;

					// text-cut
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				&:hover {
					color: @wf_black_darker;
				}
			}

			.item-text {
				max-height: 50px;
				overflow: hidden;
			}

			.value, .item-text, .icon {
				font-size: @fontSize * 1px !important;
				max-width: @bodyTextMaxWidth * 1px;
				text-align: center;

				i { font-size: @fontSize * 1px !important }
			}
		}
	}

	// -----------------------------------------------------------------------------
}

//Draggable item
.relative-measure-calculation-steps {

	.relative-measure-calculation-step {
		position: relative;
		padding: 10px 15px;
		margin: 5px 0;
		border-radius: 3px;
		transition: padding-bottom 0.3s, margin-top 0.3s;

		&:not(:last-child) { border-bottom: 1px solid @wf_gray_lighter; }

		&:after {
			content: "";
			transition: border 0.15s, background 0.3s;
			border: 1px dashed transparent;
			pointer-events: none;
			position: absolute;
			display: block;
			top: -35px;
			left: 48px;
			bottom: 10px;
			right: 120px;
			border-radius: 4px;
		}

		&.useNextStepPolicyIsSet + .relative-measure-calculation-step {
			margin-top: 45px;
			padding-bottom: 22px;
			padding-top: 13px;

			.fallbackStepInfo {
				visibility: visible;
			}

			&:after {
				border: 1px dashed #ddd;
				background: rgba(0, 0, 0, 0.02);
				transition-delay: 0.1s;
			}
		}

		.fallbackStepInfo {
			visibility: hidden;
			position: absolute;
			top: -25px;
			left: 64px;
			color: #666;
			display: flex;
			align-items: center;

			i.fa {
				padding-right: 10px;
				font-size: 20px
			}

			strong {
				font-size: 12px;
				padding-right: 4px;
			}
		}

		wf-check.distributeOnYear {
			&[tooltip-title]:before {
				width: 350px;
				white-space: pre-wrap;
				right: -100%
			}
		}

		.reorder {
			width: 30px;
			height: 30px;
			display: flex;
			align-items: center;

			&:hover {
				background-color: @wf_gray_lighter;
				border-radius: 3px;
			}

			&.as-sortable-item-handle {

			}

			.icon-wrapper {
				width: 100%;
				text-align: center;
				cursor: grab;

				i {
					cursor: grab;
				}
			}
		}

		.measure {
			wf-item-component {
				.image {
					width: 21px !important;
					height: 20px !important;
					margin-right: 8px !important;

					.wf-image {
						font-size: 17px !important;
					}
				}

				.wf-bodyText {
					padding-top: 0 !important;
				}
			}

			&.isRelativeMeasure {
				wf-item-component {
					cursor: pointer;

					&:hover {
						.wf-image {
							color: @wf_black_light;
						}

						.wf-bodyText {
							text-decoration: underline;
							color: @wf_black_light;
						}
					}
				}
			}
		}

		.constantDataSourceMetadata {
			margin-left: 5px;
			position: relative;
			top: 6px;
		}

			.input-group {
				&.disabled {
					pointer-events: none;
					opacity: 0.5;

					.ui-select-match {
						.ui-select-match-text {
							visibility: hidden;
						}
					}
				}
			}

		.policy {
			.input-group {
				.ui-select-container {
					max-width: 159px;
				}

				.ui-select-match {
					.ui-select-match-text {
						padding-right: 20px;
					}
				}

				.ui-select-choices {
					max-width: 220px;
					width: 220px;
				}
			}
		}

		.side-actions {
			.remove {
				background-color: white;
				color: @wf_red;
			}
		}
	}

	&.as-sortable-dragging {
		.relative-measure-calculation-step {
			background-color: white;
			border: 1px solid @wf_gray_lighter;

			&:hover {
				cursor: grab;
			}

			.reorder {
				background-color: @wf_gray_lighter;
				border-radius: 3px;
			}
		}
	}
}

.fiscalYearMonthOffset-info {
	float: left;
	cursor: default;
	color: @wf_gray_dark;
	font-size: 11px;
	font-weight: normal;
	padding-top: 2px;

	&:hover {
		color: darken(@wf_gray_dark, 20%);
	}

	i {
		padding-right: 4px;
	}
}

.relative-measure-calculation-info {
	color: #71787e;
	float: right;

	.text {
		color: @gray-dark;
		font-size: 11px;
		font-weight: normal;
	}

	.centerdot {
		padding: 0 3px;
	}

	.recalculate {
		color: #71787e;
		padding: 2px 0;
		font-weight: bold;

		i {
			padding-right: 4px;
		}
	}
}
