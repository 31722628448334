.wf-circle-action-button(@width, @height, @fontSize) {
	width: @width * 1px;
	height: @height * 1px;
	font-size: @fontSize * 1px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	div[type="button"] {
		width: @width * 1px;
		height: @height * 1px;
		box-shadow: none;
		outline: none;
		font-size: @fontSize * 1px;

		display: flex;
		justify-content: center;
		align-items: center;


		i:before {
			top: 0;
		}

		&:hover {
			background-color: transparent;
		}

		.fa {
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
			font-size: @fontSize * 1px;
		}
	}

	&:hover {
		background-color: @wf_gray_hover;
		cursor: pointer;
	}
}

.wf-creator-time(@userImgSize, @fontSize, @fontColor) {
	.wf-creator-time {
		display: flex;
		flex-direction: row;
		width: 100%;
	
		&.show-creator-only {
			.creator-name {
				margin: 3px 0;
				span { font-weight: 500; }
			}
		}
	
		&.hide-creator-name {
			.time-wrapper {
				margin: 4px 0;
			}
		}

		div.creator-wrapper:not(:last-child) {
			margin-right: 10px;
			padding-right: 10px;
			border-right: 1px solid fade(@fontColor, 60%);
		}

		.creator-wrapper {
			display: flex;
			flex-direction: row;

			.user-icon {
				.wf-user-icon(@userImgSize, @userImgSize);
			}
		
			.creator-name {
				display: flex;
				align-items: center;
				font-size: @fontSize * 1px;
				color: @fontColor;
			}
		}
	
		.time-wrapper {
			display: flex;
			flex-direction: row;
			align-items: center;

			font-size: @fontSize * 1px;
			color: @fontColor;
	
			>div:nth-child(2) {
				margin-left: 10px;
			}
			
			.date, .time {
				i { 
					font-size: (@fontSize - 1) * 1px;
					margin-right: 5px;
				}
				display: flex;
				align-items: center;
			}
		}
	}
}

.wf-user-icon(@width, @height) {
	display: flex;
	min-width: @width * 1px;

	.user-img {
		padding: 0;
		width: @width * 1px;
		height: @height * 1px;
		border-radius: 50%;
		background-repeat: no-repeat;
		background-size: cover;

		border: 1px solid white;

		&:before {

		}

		&:hover {
			// transform: scale(1.1);
			// transform-origin: top;
			// transition: transform .1s;
			cursor: pointer;
		}

		.user-img-tooltip {
			text-align: left;
			.user-name {
				font-size: 14px;
				font-weight: 500;
			}

			.user-email {
				display: block;
				font-weight: 300;
			}
		}

		.wf-image {
			border-radius: 100%;
			margin-right: 10px;
		}
	}
}

.wf-user-icon(@width, @height, @borderColor) {
	display: flex;
	min-width: @width * 1px;
	
	.user-img {
		padding: 0;
		width: @width * 1px;
		height: @height * 1px;
		border-radius: 50%;
		background-repeat: no-repeat;
		background-size: cover;

		border: 1px solid @borderColor;

		&:before {

		}

		&:hover {
			// transform: scale(1.1);
			// transform-origin: top;
			// transition: transform .1s;
			cursor: pointer;
		}

		.user-img-tooltip {
			text-align: left;
			.user-name {
				font-size: 14px;
				font-weight: 500;
			}

			.user-email {
				display: block;
				font-weight: 300;
			}
		}

		.wf-image {
			border-radius: 100%;
			margin-right: 10px;
		}
	}
}

.wf-switch-toggle(@width, @height, @color, @borderColor) {
	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;
	
	input#wf-toggle {
		max-height: 0;
		max-width: 0;
		opacity: 0;
	}

	label {
		margin-bottom: 0px;
		cursor: pointer;
	}
	
	input#wf-toggle + label {
		display: inline-block;
		position: relative;
		box-shadow: inset 0 0 0px 1px @borderColor;
		text-indent: -5000px;
		width: @width * 1px;
		height: @height * 1px;
		border-radius: 15px;
	}
	
	input#wf-toggle + label:before {
		content: "";
		position: absolute;
		display: block;
		height: @height * 1px;
		width: @height * 1px;
		top: 0;
		left: 0;
		border-radius: 15px;
		background: rgba(19, 191, 17, 0);
		transition: .25s ease-in-out;
	}
	
	input#wf-toggle + label:after {
		content: "";
		position: absolute;
		display: block;
		height: @height * 1px;
		width: @height * 1px;
		top: 0;
		left: 0px;
		border-radius: 15px;
		background: white;
		box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 2px 4px rgba(0, 0, 0, 0.2);
		transition: .25s ease-in-out;
	}
	
	input#wf-toggle:checked + label:before {
		width: @width * 1px;
		background: @color;
	}
	
	input#wf-toggle:checked + label:after {
		left: (@width / 2) * 1px;
		box-shadow: inset 0 0 0 1px white, 0 2px 4px rgba(0, 0, 0, 0.2);
	}
}

.wf-item-numbering(@fontSize, @fontWeight) {
	position: relative;
	margin-right: 10px;
	padding: 1px 4px;

	background-color: darken(@wf_gray_light, 8%);
	border-radius: 3px;
	color: @wf_black;
	font-weight: @fontWeight;
	font-size: @fontSize * 1px;
	line-height: @fontSize * 1px;
}

.wf-work-item-primary-button(@bgColor) {
	background-color: transparent;
	box-shadow: inset 0 0px 0px 1px fade(@bgColor, 50%);
	text-transform: uppercase;
	outline-color: @bgColor;
	min-height: 35px;

	i {
		font-size: 12px;
		color: @bgColor;
		margin-right: 5px;
	}

	font-size: 12px;
	font-weight: 500;
	color: @bgColor;
	span {
		font-size: 12px;
		color: @bgColor;
		font-weight: 500;
	}

	&:hover {
		cursor: pointer;
		box-shadow: inset 0 0px 0px 1px @bgColor;
		background-color: fade(@bgColor, 10%);
	}
}

.wf-work-item-secondary-button(@fontSize, @fontWeight, @fontColor, @hoverBgColor, @selectedBgColor, @paddingTopBottom, @paddingLeftRight) {
	box-shadow: none;
	padding-top: @paddingTopBottom * 1px;
	padding-bottom: @paddingTopBottom * 1px;
	padding-left: @paddingLeftRight * 1px;
	padding-right: @paddingLeftRight * 1px;
	border-radius: 3px;
	background-color: transparent;

	// display: flex;
	// flex-direction: row;
	// align-items: center;

	&:hover { 
		cursor: pointer;
		background-color: @hoverBgColor;
	}

	&.selected {
		background-color: @selectedBgColor;
	}

	i {
		font-size: @fontSize * 1px;
		color: @fontColor;
		margin-right: 5px;
		display: inline-block;
	}

	span {
		font-size: @fontSize * 1px;
		color: @fontColor;
		font-weight: @fontWeight;
		display: inline-block;
	}

	span.subCaption {
		display: block;
		clear: both;
		overflow: hidden;
		float: none;
		font-weight: 500;
		font-size: 11px;
		text-align: right;
		color: #8dbad8;
		padding-top: 2px;
	}
}

.wf-work-item-attachments(@color) {
	margin: 10px -10px 0 -10px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	.attached-file {
		color: @color;
		font-size: 12px;
		padding: 6px 10px;
		margin: 2px 3px;
		border-radius: 3px;
		text-decoration: none;
		display: flex;
		align-items: center;

		i.file-icon { 
			margin-right: 10px;
		}

		i.external-link {
			margin-left: 10px;
			visibility: hidden;
			font-size: 11px;
		}

		span.file-name {
			max-width: 300px;
			display: inline-block;
		}
		
		&:hover {
			cursor: pointer;
			background-color: @wf_gray_hover;
			span { text-decoration: underline; }
			i.external-link {
				visibility: visible;
			}
		}
	}
}

