//scaffolding.less

:focus {outline:none;}
::-moz-focus-inner {border:none;}

html {
   -ms-overflow-style: scrollbar; //IE10 stop scroll bar from appearing over content
	-webkit-font-smoothing: antialiased;
	// text-rendering: optimizeLegibility;
}

body {font-weight: @font-weight-base;}
a {outline: none; cursor: pointer;}
.img-rounded {border-radius: 5px;}

hr.outsider {
	margin-left: -15px;
	margin-right: -15px;
	border-top: 1px solid @border-lighter;
}


//type.less
.text-primary, .text-warning, .text-danger, .text-success, .text-info {
	// &:hover {color: inherit;}
}

//* {letter-spacing: 0.25px;}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: @headings-font-family;
	font-weight: @headings-font-weight;
}

h1, h2, .h1, .h2 {
	margin-top: 0;
	font-weight: 300;
}

h1 small, .h1 small,
h2 small, .h2 small, 
h3 small, .h3 small {font-weight: 300;}


.list-inline > li {
	&:first-child {padding-left: 0;}
}

.panel dl    {margin: 0 auto;}
.panel dl dd {margin-bottom:10px;}

blockquote {
	font-style: italic;
	border-left-width: 2px;
	&.pull-right {
		border-right-width: 2px;
	}
}

address {display: block;}

//code.less
kdb, samp {
	font-family: @font-family-monospace;
}

pre {
	margin-bottom: 20px;
	&.prettyprint {
    	margin-bottom: 10px; //@line-height-computed;
    }
}



// tables.less


// Responsive Tables


.table {
	> thead,
	> tbody,
	> tfoot {
		> tr {
			> th,
			> td {
				vertical-align: middle;
			}
		}
	}
}

.table-responsive {
	overflow-x: auto;
}
@media (max-width: @screen-xs-max) {

	/* Wrap your tables in `.table-flipscroll` and we'll fix the header and make the tbody scrollable */
	.table-flipscroll {
		table, thead {&:extend(.clearfix all);}
		table, caption, td, thead, thead tr, th, tbody {display: block;}

		table { 
			width: 100%; border-collapse: collapse; border-spacing: 0; position: relative; 

			th, td {margin: 0; vertical-align: top; 
				&:last-child {border-bottom: 1px solid @table-border-color;}
			}
			td {min-height: 1.25em; text-align: left; border-left: 0; border-right: 0; border-bottom: 0;}

			thead {float: left;}
			th {text-align: left; border-bottom-width: 1px !important; border-left: 0;
				&:first-child {border-top: 1px solid transparent !important;}
			}

			tbody {width: auto; position: relative; overflow-x: auto; white-space: nowrap; 
				tr  {display: inline-block; vertical-align: top; border-left: 1px solid @table-border-color;}
			}
		}
	}

	/* Force table to not be like tables anymore */
	.table-vertical { 
		table, caption, thead, tbody, th, td, tr { 
			display: block; 
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr { 
			position: absolute;
			top: -9999px;
			left: -9999px;
		}
		tr { border: 1px solid #ccc; }

		td { 
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid @table-border-color; 
			position: relative;
			padding-left: 50% !important; 
			white-space: normal;
			text-align:left;
			&:before { 
    		    position: absolute; // Now like a table header
    		    top: 6px; // Top/left values mimic padding //
    		    left: 6px;
    		    width: 45%; 
    		    padding-right: 10px; 
    		    white-space: nowrap;
    		    text-align:left;
    		    font-weight: bold;
    		    content: attr(data-title); // Label the data
    		}
		}
	}
}

// .table-hidecolumn {
// 	@media (max-width: @screen-xs-max) {
// 		.table-hide-xs {display:none; visibility:hidden;}
// 		.table-hide-sm {display:none; visibility:hidden;}
// 		.table-hide-md {display:none; visibility:hidden;}
// 	}
// 	@media (max-width: @screen-sm-max) {
// 		.table-hide-xs {display:none; visibility:hidden;}
// 		.table-hide-sm {display:none; visibility:hidden;}
// 	}
// 	@media (max-width: @screen-md-max) {
// 		.table-hide-md {display:none; visibility:hidden;}
		
// 	}
// }

// forms.less
legend {
	padding-bottom: 5px;
	font-weight: 300;
	font-size: @font-size-h3;
}
	
label {margin-bottom: 10px; font-weight: normal;}

select[multiple] {
  padding: 7px !important;
}

.form-control {
	border: 1px solid @border-darker;
	color: @gray-dark;
	height: @input-height-base;
	padding: 9px 10px;
	box-shadow: none !important;
	//.box-shadow(inset 0 1px 0px rgba(0,0,0,0.015)) !important;
	select& {
		padding: 6px 9px 5px 4px;
	}
	&:focus {border-color: @brand-primary;}
}

.input-sm {
  .input-size((@input-height-small - 2); (@padding-small-vertical - 1); @padding-small-horizontal; @font-size-small; @line-height-small; @border-radius-small) !important;
}

.input-lg {
  .input-size((@input-height-large - 2); (@padding-large-vertical - 1); @padding-large-horizontal; @font-size-large; @line-height-large; @border-radius-large) !important;
}

.form-horizontal .control-label, .form-control-static {
	padding-top: 8px;
	padding-bottom: 0;
}
	
.form-group {
	margin-bottom: 15px;
	// Use row-border with a form to have both top and bottom 1px separation
	.row-border & {
		border-top: 1px solid lighten(@border-lighter, 5%);;
		padding-top: 15px;
		padding-bottom: 15px;
		margin-bottom: 0;
		margin-left: -15px;
		margin-right: -15px;
		padding-left: 5px;
		padding-right: 5px;
		&:first-child {
			border-top: 0;
			padding-top: 0;
		}
	}
}

.input-group-addon input[type="radio"], .input-group-addon input[type="checkbox"] {
	position: absolute;
	top: 10px;
	margin: 0 -5px;
}

.help-block {
	margin-bottom: 0;
	margin-top: 8px;
}


form .ng-dirty.ng-invalid {
	border-color: @state-danger-text;
	color: @state-danger-text;
}
form .ng-dirty.ng-valid {
	border-color: @state-success-text;
	color: @state-success-text;
}
form .no-validate {
	border: 1px solid @border-darker !important;
	color: @gray-dark !important;
}

.select2-container.ng-dirty {
	border: none !important;
}



.input-icon {
	position: relative;
	> i, .icon {
		position: absolute; 
		display: block;
		margin: 11px 10px; 
		line-height: 14px;
		.text-muted(); 
		//.fa-fw();
		&:hover {text-decoration: none;}
	}
	> .form-control {padding-left: 35px;}
	
	&.right {
		> i, .icon {right: 0px; float: right;}
		> .form-control {padding: 6px 35px 6px 10px;}
		&.input-group > i,  &.input-group > .icon {right: 0;}
	}

	&.input-group.left {> .form-control {padding-left: 30px; .border-left-radius(@border-radius-base)}}
}
.input-icon-sm {
	> i {margin: 7px;}
}

// buttons.less
.btn {
	font-family: @btn-font-family;
	font-weight: 500;
	border-width: 0px;
	padding: 9px 16px;
	.box-shadow(~"inset 0 0px 0px 1px rgba(0,0,0,0.08)");
	&:focus {
		outline: none;
		.box-shadow(~"inset 0 0px 0px 1px rgba(0,0,0,0.08)");
	}
	&:active, &.active, &.fc-state-active {
	.box-shadow(~"inset 0 0px 0px 1px rgba(0,0,0,0.08)");
	}
}
.btn-lg {
	padding: 11px 20px;
}

.btn-sm {
	font-size: 11px;
	padding: 5px 10px;
}

.btn-xs {
	font-size: 10px;
	padding: 2px 5px;
}

.btn-default:hover {color: @gray-darker;}
.btn-group.open .dropdown-toggle {.box-shadow(~"inset 0 0px 0px 1px rgba(0,0,0,0.08)") !important;}

.btn-link, .btn-link:active {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.btn-inverse {
 .button-variant(@btn-inverse-color, @btn-inverse-bg, @btn-inverse-border);
}
.btn-white {
 .button-variant(@btn-white-color, @btn-white-bg, @btn-white-border);
}
.btn-link {
	.box-shadow(~"none");
}

// custom colors
.btn-brown {
  .button-variant(#fff, @brand-brown, @brand-brown);
}
.btn-indigo {
  .button-variant(#fff, @brand-indigo, @brand-indigo);
}
.btn-orange {
  .button-variant(#fff, @brand-orange, @brand-orange);
}
.btn-sky {
  .button-variant(#fff, @brand-sky, @brand-sky);
}
.btn-midnightblue {
  .button-variant(#fff, @wf_black_dark, @wf_black_dark);
}
.btn-magenta {
  .button-variant(#fff, @brand-magenta, @brand-magenta);
}
.btn-green {
  .button-variant(#fff, @brand-green, @brand-green);
}
.btn-purple {
  .button-variant(#fff, @brand-purple, @brand-purple);
}
.btn-alizarin {
  .button-variant(#fff, @brand-alizarin, @brand-alizarin);
}
.btn-block + .btn-block {
	margin-top: 10px;
}

// Alternate buttons
// --------------------------------------------------

.btn-default-alt {
  .btn-alt-states(@btn-default-bg);
  &:active, &.active {color: @gray-dark !important;}
}

.btn-primary-alt      {.btn-alt-states(@btn-primary-bg);}
.btn-warning-alt      {.btn-alt-states(@btn-warning-bg);}
.btn-danger-alt       {.btn-alt-states(@btn-danger-bg);}
.btn-success-alt      {.btn-alt-states(@btn-success-bg);}
.btn-info-alt         {.btn-alt-states(@btn-info-bg);}
.btn-inverse-alt      {.btn-alt-states(@btn-inverse-bg);}

// custom colors
.btn-brown-alt        {.btn-alt-states(@brand-brown);}  
.btn-indigo-alt       {.btn-alt-states(@brand-indigo);}
.btn-orange-alt       {.btn-alt-states(@brand-orange);}
.btn-sky-alt          {.btn-alt-states(@brand-sky);}
.btn-midnightblue-alt {.btn-alt-states(@wf_black_dark);}
.btn-magenta-alt      {.btn-alt-states(@brand-magenta);}  
.btn-green-alt        {.btn-alt-states(@brand-green);} 
.btn-purple-alt       {.btn-alt-states(@brand-purple);} 
.btn-alizarin-alt       {.btn-alt-states(@brand-alizarin);} 


.btn[class*="alt"] {
  //padding: (@padding-base-vertical)-1 (@padding-base-horizontal)-1;  // border makes up for the paddings we are decreasing
  padding: 8px 15px;
}
.btn-lg[class*="alt"] {
  .button-size((@padding-large-vertical)-1;( @padding-large-horizontal)-1; @font-size-large; @line-height-large; @border-radius-large);
  padding: 10px 19px;
}

.btn-sm[class*="alt"] {
  .button-size((@padding-small-vertical)-1; (@padding-small-horizontal)-1; @font-size-small; @line-height-small; @border-radius-small);
  padding: 4px 9px;
  font-size: 11px;
}

.btn-xs[class*="alt"] {
  .button-size((@padding-small-vertical)-1; (@padding-small-horizontal)-1; @font-size-small; @line-height-small; @border-radius-small);
  padding: 1px 4px;
  font-size: 10px;
}


//Social Icons

.btn.btn-social {
	color: #fff;
	padding: 9px 16px;

	&.btn-xs {padding: 2px 5px;}
	&.btn-sm {padding: 5px 10px;}
	&.btn-lg {padding: 11px 20px;}

	&.btn-facebook {.button-variant(#fff, rgb(59,89,152),rgb(59,89,152) );}
    &.btn-twitter {.button-variant(#fff, rgb(0,172,237),rgb(0,172,237))}
    &.btn-github {.button-variant(#fff, rgb(65,131,196),rgb(65,131,196));}
    &.btn-behance {.button-variant(#fff, rgb(23,105,255),rgb(23,105,255));}
    &.btn-flickr {.button-variant(#fff, rgb(0,99,219),rgb(0,99,219));}
    &.btn-jsfiddle {.button-variant(#fff, rgb(70,121,164),rgb(70,121,164));}
    &.btn-reddit {.button-variant(#fff, rgb(255,69,0),rgb(255,69,0));}
    &.btn-youtube {.button-variant(#fff, rgb(205,51,45),rgb(205,51,45));}
    &.btn-foursquare {.button-variant(#fff, rgb(35,152,201),rgb(35,152,201));}
    &.btn-google {.button-variant(#fff, rgb(36,93,193),rgb(36,93,193));}
    &.btn-apple {.button-variant(#fff, rgb(185,191,193),rgb(185,191,193));}
    &.btn-soundcloud {.button-variant(#fff, rgb(255,102,0),rgb(255,102,0));}
    &.btn-steam {.button-variant(#fff, rgb(0,0,0), rgb(0,0,0));}
    &.btn-spotify {.button-variant(#fff, rgb(128,183,25),rgb(128,183,25));}
    &.btn-wordpress {.button-variant(#fff, rgb(33,117,155),rgb(33,117,155));}
    &.btn-skype {.button-variant(#fff, rgb(0,175,240),rgb(0,175,240));}
	&.btn-android {.button-variant(#fff, rgb(164,198,57),rgb(164,198,57));}
	&.btn-digg {.button-variant(#fff, rgb(20,88,158),rgb(20,88,158));}
	&.btn-linkedin {.button-variant(#fff, rgb(0,127,177),rgb(0,127,177));}
	&.btn-googleplus {.button-variant(#fff, rgb(209,72,54),rgb(209,72,54));}
	&.btn-instagram {.button-variant(#fff, rgb(63,114,155),rgb(63,114,155));}
	&.btn-windows {.button-variant(#fff, rgb(0,189,246),rgb(0,189,246));}


	&.btn-facebook-alt 	{.btn-alt-states(rgb(59,89,152));}
    &.btn-twitter-alt 	{.btn-alt-states(rgb(0,172,237));}
    &.btn-github-alt 	{.btn-alt-states(rgb(65,131,196));}
    &.btn-behance-alt 	{.btn-alt-states(rgb(23,105,255));}
    &.btn-flickr-alt 	{.btn-alt-states(rgb(0,99,219));}
    &.btn-jsfiddle-alt 	{.btn-alt-states(rgb(70,121,164));}
    &.btn-reddit-alt 	{.btn-alt-states(rgb(255,69,0));}
    &.btn-youtube-alt 	{.btn-alt-states(rgb(205,51,45));}
    &.btn-foursquare-alt{.btn-alt-states(rgb(35,152,201));}
    &.btn-google-alt 	{.btn-alt-states(rgb(36,93,193));}
    &.btn-apple-alt 	{.btn-alt-states(rgb(185,191,193));}
    &.btn-soundcloud-alt{.btn-alt-states(rgb(255,102,0));}
    &.btn-steam-alt 	{.btn-alt-states(rgb(0,0,0))}
    &.btn-spotify-alt 	{.btn-alt-states(rgb(128,183,25));}
    &.btn-wordpress-alt {.btn-alt-states(rgb(33,117,155));}
    &.btn-skype-alt 	{.btn-alt-states(rgb(0,175,240));}
	&.btn-android-alt 	{.btn-alt-states(rgb(164,198,57));}
	&.btn-digg-alt 		{.btn-alt-states(rgb(20,88,158));}
	&.btn-linkedin-alt 	{.btn-alt-states(rgb(0,127,177));}
	&.btn-googleplus-alt{.btn-alt-states(rgb(209,72,54));}
	&.btn-instagram-alt {.btn-alt-states(rgb(63,114,155));}
	&.btn-windows-alt 	{.btn-alt-states(rgb(0,189,246));}

	//Only for the black steam button
	&.btn-steam.btn-label i {
		.rgba(white,0.15);
		//background: rgba(255,255,255,0.15);
	}
}

.btn-label {
	padding: 0 20px 0 14px !important;

	i {
		padding: 9px;
		line-height: 14px;
		left: -14px;
		width: 40px;
		
		position: relative;
		display: inline-block;
		//background: rgba(0,0,0,.1);
		.rgba(black,0.0);
		border-radius: 3px 0 0 3px;
		box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.1);
	}

	&.btn-block {
		padding-top:    8px !important;
		padding-bottom: 8px !important;
		
		i 		 {top: -9px;}
		i + span {left: -10px; position: relative;}
	}
	&.btn-xs {
		padding: 0 5px 0 2px !important;
		i {
			width: 24px;
			left: -2px;
			line-height: 1em;
			padding: 3px 4px;
		}
	}
	&.btn-sm {
		padding: 0 12px 0 8px !important;
		i {
			left: -8px;
			line-height: 8px;
			width: 32px;
		}
	}
	&.btn-lg {
		padding: 0 29px 0 21px !important;
		i {
			left: -21px;
			line-height: 23px;
			width: 48px;
		}
	}
}