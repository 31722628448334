@import "wf-variables.less";

.schema-form-array.row {
	margin-left: 0;
	margin-right: 0;

	/* Fixes css for horizontal form items in columns  */
	.list-group-item > sf-decorator {
		&:after {
			content: " ";
			display: table;
			clear: both;
		}

		.form-group {
			margin-bottom: 0; /* Remove unnecessary space below each item */

			.form-control-feedback {
				right: 10px; /* To compensate for Boostraps margin between columns */
			}
		}
	}
}


/*.has-feedback label~.form-control-feedback {
  top: 36px;
}*/
.has-feedback label~.form-control-feedback {
	top: 25px !important;
}

.has-success .form-control {
	border-color: #e0e0e0;
}

/* Fixes for pickadate jQuery plugin in classic style mode */

.form-control[disabled][pick-a-date], .form-control[readonly] {
	&[pick-a-date] {
		cursor: pointer;
	}
}

.schema-form-checkbox {
	margin-bottom: 15px;
	display: flex;

	label {
		padding: 5px 5px 5px 20px;

		span {
			color: #6c747b !important;
		}
	}

	input[type="radio"], input[type="checkbox"] {
		margin: 1px 0 0 -20px;
		cursor: pointer;
	}

	.help-block {
		margin: 0px;
		align-self: center;
	}
}

.info {
	i {
		color: grey;
	}

	.tooltip {
		position: relative;
		display: inline-block;
		opacity: 1;

		.tooltiptext {
			visibility: hidden;
			width: 270px;
			color: white;
			text-align: center;
			padding: 5px 0;
			border-radius: 5px;
			border-width: 1px;
			border-style: solid;
			position: absolute;
			top: -28px;
			left: -50px;
			background-color: @brand-midnightblue;
			z-index: 1;

			&:after {
				content: " ";
				position: absolute;
				top: 100%;
				left: 20%;
				margin-left: -5px;
				border-width: 5px;
				border-style: solid;
				border-color: @brand-midnightblue transparent transparent transparent;
			}
		}

		&:hover {
			.tooltiptext {
				visibility: visible;
			}
		}
	}
}

wf-form {
	form {
		&.loading {
			min-height: 200px;
			overflow: hidden;

			.spinner(24px);

			> * {
				visibility: hidden;
			}
		}
	}
}

.picker.picker--opened {
	/*height: 400px;*/
	/*overflow: hidden;*/
	/*overflow: visible;*/
	margin-top: 35px;

	.picker__holder {
		border: 0;
		background: 0;
		box-shadow: 0;
		overflow: visible;
		zoom: .7;
		transition: transform 0s ease-out, opacity 0.15s ease-out, max-height 0s, border-width 0s, -webkit-transform 0s ease-out;

		.picker__frame {
			margin-bottom: 0;
			transition: all 0s ease-out;

			.picker__wrap {
				min-width: 500px;

				.picker__box {
					box-shadow: 0 8px 33px -4px rgba(0, 0, 0, 0.24);
					padding-bottom: 1px;
				}
			}
		}
	}

	.picker__select--year {
		width: 24%;
	}

	.picker__select--month {
		width: 39%;
	}

	.picker__select--month, .picker__select--year {
		border: 1px solid #e0e0e0;
		padding: 0.2em;
		padding-left: 10px;
		margin-top: -8px;
		border-radius: 3px;
	}
}


.file-upload {
	// padding-right: 35px;
	display: block;

	.top-container {
		display: inline-block;
		width: 100%;

		.note {
			float: left;
			font-size: 11px;
			color: #707980;
			font-weight: 400;
			padding: 0 0 10px 0;
		}

		button.discard {
			float: right;
			color: #707980;
			font-size: 11px;
			text-align: center;
			font-weight: 400;
			cursor: pointer;
			padding: 0 0 5px 0;

			&:hover { color: @wf_blue; }
		}
	}


	.currentFiles {
		overflow: hidden;

		&.well {
			background-color: white;
			padding: 10px;
		}

		img {
			float: left;
			width: 150px;
			height: auto;
		}

		button {
			float: left;
			margin-left: 10px;
		}

		.change-button-icon {
			margin-top: 1px;
			margin-right: 5px;
		}

		.currentFile {
			.document-icon {
				font-size: 25px;
				color: @wf_gray;
				margin-right: 15px;
				line-height: 0;
			}

			.document-text {
				position: relative;
				top: 2px;
			}


			a.link {
				&:hover {
					.text {
						color: #2685ee;
						text-decoration: underline;
					}
					cursor: pointer;
				}

				.text {
					color: @wf_black;
					font-size: 14px;
					font-weight: 400;
					margin-right: 5px;
				}

				.external-link {
					font-size: 12px;
					color: @wf_gray;
				}
			}
		}

		.img-thumbnail {
			padding: 0;
			width: 150px;
			height: 150px;
			object-fit: cover;
		}
	}

	.newFileUpload {
		position: relative;

		.text-info {
			color: #888;
		}

		.filename {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		.pickFileButtonContainer {
			&.divider {
				padding-top: 17px;
				margin-top: 7px;
				border-top: 1px solid #eee;
			}
		}

		.single-file-upload {

		}//end of: .single-file-upload

		.multi-file-upload {

		}//end of: .multi-file-upload

		.single-file-upload, .multi-file-upload {
			.wf-droppable.reveal-droppable {
				min-height: 220px;

				&.allow-dropping {
					.drop-message {
						.wrap-val-icon {
							i {
								font-size: 45px;
							}
						}
					}
				}

				.drop-message {
					cursor: pointer;

					.wrap-val-icon {
						width: 70px;
						height: 70px;

						i {
							margin-top: 12px;
							font-size: 45px;
						}
					}

					.val-message-header {
						margin: 10px 0 5px 0;
					}

					>div {
						margin-top: -80px;

						button {
							margin: 0 auto;
						}
					}
				}

				&.drop-denied {
					&::before { border-color: @wf_red; }
				}//end of: .drop-denied

				&::before {
					top: 0px;
					right: 0px;
					bottom: 0px;
					left: 0px;
				}
			}
		}//end of: .single-file-upload, .multi-file-upload


		.uploading {
			// border-top: 1px solid #efefef;
			// padding-top: 10px;
			.upload-process {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;

				.upload-details {
					margin-left: 15px;
				}

				.img-thumbnail {
					padding: 0;
					width: 150px;
					height: 150px;
					object-fit: cover;
				}

				.document-icon {
					font-size: 35px;
					color: @wf_gray;
					margin-right: 15px;
					line-height: 0;
				}
			}//end of: .upload-process
		}
	}
}

.mail-settings-form-placeholders {
	background-color: @wf_gray_lighter;
	border-radius: 3px;
	padding: 15px;
	margin-bottom: 15px;

	.usage-description {
		margin-bottom: 15px;
		span {
			font-size: 14px;
		}
	}

	.placeholder-wrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		margin-bottom: 0;
		border-bottom: 1px solid #e2e2e2;
		padding: 4px 0px;

		span.placeholder {
			order: 1;
			font-weight: 500;
			min-width: 168px;

			+span {
				order: 2;
				margin: 0px 5px;
			}
		}

		span.placeholder-description {
			order: 3;
		}
	}
}

.form-group {
	&.readonly-simplified {
		border-top: 0 !important; // Worked better than margin-top

		& + .form-group {
			border-top: 7px solid transparent; // Worked better than margin-top
		}

		.control-label {
			margin-bottom: 5px;
		}

		.form-control-feedback {
			display: none;
		}

		input, textarea {
			cursor: default;
			background-color: transparent;
			border: 0;
			color: #333;
			padding: 0;
		}
	}

	&.custom-email-text-reminder-form {
		&:first-child {
			margin-top: 30px;
		}

		textarea {
			height: 100px;
		}
	}
}

form.new-layout {
	// text input
	.form-group.has-error {
		input, select, textarea {
			border-color: rgba(231, 76, 60, 0.6) !important;
			color: @wf_red;
		}

		.input-group-addon {
			background-color: #fff;
			border-color: rgba(231, 76, 60, 0.6) !important;
		}
	}

	input.form-control, textarea.form-control {
		border-width: 1px;



		&.ng-valid, &.ng-valid-parse {
			border-color: rgba(52, 152, 219, 0.6);
			color: #707980;
		}

		&:focus {
			border-color: #707980;
		}
	}

	// .ng-valid, .ng-valid-parse {
	// 	.input-group-addon {
	// 		border-color: rgba(52, 152, 219, 0.6);
	// 		color: #707980;
	// 	}
	// }

	//Valid/invalid icon removed
	span.form-control-feedback{ display: none; }

	div.form-group.schema-form-select {
		// select input
		select.form-control {
			height: 38px;
			border-width: 1px;
			padding: 9px 10px;
			-webkit-appearance: none;
			-moz-appearance: none;
			-ms-appearance: none;
			-o-appearance: none;

			&.ng-invalid {
				border-color: rgba(231, 76, 60, 0.6) !important;
				color: #707980;
			}

			&.ng-valid, &.ng-valid-parse {
				border-color: rgba(52, 152, 219, 0.6);
				color: @wf_blue;
			}

			&:focus {
				border-color: rgba(52, 152, 219, 0.6);
			}

			&::-ms-expand {
				display: none;
			}

		}

		&.has-error {
			&:after {
				color: rgba(231, 76, 60, 0.6);
			}
		}

		&:after {
			content: "\f0d7";
			font-family: FontAwesome;
			color: #9ea4a9;
			padding: 5px 0px;
			position: absolute;
			right: -5px;
			top: 31px;
			text-align: center;
			width: 10%;
			pointer-events: none;
			box-sizing: border-box;
			font-size: 14px;
		}

		.help-block {
			.info {
				position: relative;
				top: -73px;
				left: 105px;
			}
		}
	}

	div.help-block, span.help-block { color: @wf_red; }

	div.form-group { padding-right: 0 !important; }

	label.control-label {
		color: @gray-dark;
	}
}//end of: .new-layout


.wf-form-measureAnswerValue {
	padding-bottom: 20px;

	.form-group {
		margin-bottom: 5px;

		input.rawValue {
			padding-right: 70px;
		}
	}

	.notAvailableCheckboxWrapper {
		padding-top: 26px;
		position: absolute;
		top: 0;
		right: 8px;


		label {
			cursor: pointer;

			&:hover, &.checked {
				span {
					color: @wf_gray_text_color;
				}
			}
		}

		span {
			padding-right: 5px;
			color: @wf_gray;
			position: relative;
			top: 1px;
		}

		[wf-tooltip]:before {
			width: 200px;
			z-index: 1;
			right: -89px;
		}

		[wf-tooltip]:after {
			right: -28px;
			top: -11px;
		}
	}

	.decimalMark_input, .decimalMark_output {
		color: #afafaf;
	}

	.decimalMark_input {
		width: 50%;
		padding-bottom: 4px;
		white-space: pre-wrap;
	}

	.decimalMark_output {
		padding-top: 9px;
	}

	.unitName {
		white-space: nowrap;
		padding-bottom: 4px;
	}

	.formatted {
		.validationMessage {
			// display: none;
			color: rgb(231, 76, 60) !important;
		}

		.foundValueMessage {
			// display: none;
			color: #666;
			font-weight: bold;
			padding-top: 4px;
		}

		.extractedValue {
			padding-top: 5px;
			font-size: 16px;
			font-weight: 500;
			color: #333 !important
		}

		div.btn.useExtractedAsValue {
			color: #666 !important
		}
	}

	.deviation {
		padding-top: 5px;
		color: #666 !important;

		.value {
			font-weight: bold;
		}
	}
}

.ui-select-container {
	.form-control {
		border-radius: 2px !important;
	}

	.ui-select-toggle {
		height: 36px;
		background: #fff;
		border: 1px solid #e0e0e0;

		.filter-item-dropdown {
			padding: 1px;

			.filter-icon {
				margin-top: 0;
				width: 15px;
				height: 15px;
			}
		}
	}

	.ui-select-match-text {
		text-overflow: ellipsis;
		overflow: hidden;
		padding-right: 40px;
	}

	.ui-select-placeholder {
		font-weight: normal;
		text-overflow: ellipsis;
		overflow: hidden;
		padding-right: 20px;
		width: 100%;
		display: inline-block;
	}

	.ui-select-choices {
		padding-top: 5px;
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
		margin-top: 0px;
		left: 1px;
		right: 1px;
		width: auto;
	}

	.ui-select-choices-row.active {
		.filter-item-dropdown {
			.filter-icon, .description {
				color: white;
			}
		}
	}

	a.btn {
		right: 16px;
		margin-top: -2px;
		color: #677481;
		font-size: 12px;
		border-radius: 100%;
		height: 20px;
		width: 20px;
		text-align: center;
		padding: 0;
		line-height: 22px;

		&:hover {
			background-color: #eee;
			color: #444;
		}
	}

	.caret {
		margin-top: -1px !important;
		right: 21px !important;

		&:before {
			font-size: 17px;
		}
	}
}

.inline-form {
	@formControlLabel: 15;
	@fieldHeight: 35;
	@distanceBetweenFields: 10;

	width: 100%;
	form { min-height: 54px; }

	.fields-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		flex-wrap: nowrap;

		bootstrap-decorator {
			.wf-form-measureAnswerValue {
				margin-right: 10px;
				padding: 0;
				.decimalMark_input {
					display: none !important;
				}

				.formatted {
					padding-top: 7px;
				}
			}

			&:last-child {
				.form-group {
					margin-right: 0 !important;
				}
			}
		}

		>.form-group {
			&:last-child {
				margin-right: 0 !important;
			}
		}

		.form-group {
			margin-bottom: 0;
			margin-right: @distanceBetweenFields * 1px;
			padding-right: 0 !important;

			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			width: 100%;

			select.form-control::after {
				right: 10px;
				top: 23px;
			}

			&.schema-form-select:after {
				top: 23px;
				right: 1px;
			}

			&.has-error .help-block {
				display: block;
			}
		}

		.wf-form-embedly {
			.form-group {
				padding-top: 20px;
				display: block;

				input.form-control {
					border-bottom-right-radius: 0;
					border-top-right-radius: 0;
				}
			}

			.btn {
				height: 35px;
				margin-left: 2px !important;
				border-bottom-left-radius: 0;
				border-top-left-radius: 0;
			}
		}

		.help-block {
			margin-top: 4px;
			font-size: 11px;
			display: none;
		}

		.schema-form-section {
			display: flex;
			flex-direction: row;

			>:last-child {
				margin-right: 0 !important;
				>:last-child {
					margin-right: 0 !important;
				}
			}

			.form-group {
				margin-bottom: @distanceBetweenFields * 1px;
				&:last-child {
					margin-bottom: 0 !important;
				}
			}

			&.inline-upload-field {
				display: block;
				bootstrap-decorator {
					ng-form {
					margin-right: @distanceBetweenFields * 1px;

						margin-bottom: 0 !important;
						.simple-form {
							display: flex;
							flex-direction: column;
							justify-content: flex-start;

							.well {
								width: (@formControlLabel + @fieldHeight) * 2px;
								height: (@formControlLabel + @fieldHeight) * 2px;
								margin-bottom: 0 !important;
								background-color: white;
								padding: 0px;
								position: relative;

								&.input-as-button {
									&.has-error {
										border-color: rgba(231, 76, 60, 0.6);

										& + .text-danger {
											color: rgb(231, 76, 60);
										}
									}

									&:hover {
										cursor: pointer;
										border-color: @wf_blue_dark;
										.newFileUpload {
											.pickFileButtonContainer {
												.simple-file-upload {
													.wf-droppable {
														.drop-message {
															i.val-message-icon {
																color: @wf_blue_dark;
															}
														}
													}
												}
											}
										}
									}
								}

								.discard-upload-button {
									z-index: 100;
									position: absolute;
									right: 7px;
									top: 7px;

									.wf-circle-action-button(20, 20, 12);
									background-color: white;
									color: @wf_red_dark;
									margin-right: 0 !important;
								}

								.newFileUpload {
									height: 100%;
									.pickFileButtonContainer {
										height: 100%;
										.simple-file-upload {
											height: 100%;
											.wf-droppable {
												height: 100%;
												min-height: 50px;

												&:before { border: none; }

												.wrap-val-icon {
													width: initial;
													height: initial;
													background: transparent;
													border: none;

													margin-top: 0;

													i.val-message-icon {
														font-size: 30px;
														color: @wf_gray_text_color;
														margin-top: 0;
													}
												}
												.drop-message {
													display: flex;
													flex-direction: column;
													justify-content: center;
													align-items: center;
													height: 100%;

													>div {
														top: initial;
														margin-top: 0;
														margin: initial;
													}
													// .val-message-header, .val-message-description, .browse-button { display: none; }
												}
											}
										}

									}

									.simple-upload-process {
										height: 100%;
										.upload-process {
											display: flex;
											flex-direction: column;
											justify-content: center;
											align-items: center;
											height: 100%;
											position: relative;

											.image-details {
												display: flex;
												flex-direction: column;
												justify-content: center;
												align-items: center;

												border:  none;

												width: (@formControlLabel + @fieldHeight) * 2px;
												height: (@formControlLabel + @fieldHeight) * 2px;

												img {
													padding: 0;
													border-radius: 3px;
													border: none;
													object-fit: cover;
													width: 100%;
													height: 100%;
												}
											}

											.file-details {
												display: flex;
												flex-direction: column;
												justify-content: center;
												align-items: center;

												.document-icon {
													margin-right: 0;
													i {
														font-size: 30px;
														color: @wf_gray_text_color;
													}
												}

												.filename {
													margin-top: 5px;
													text-align: center;
													max-width: ((@formControlLabel + @fieldHeight) * 2) - 20px;
													max-height: 30px;
													color: @wf_gray_text_color;
													font-size: 10px;
												}
											}

											.progress-bar-wrapper {
												width: 100%;
												position: absolute;
												bottom: 3px;

												.progress {
													border: 1px solid white;
												}

												.progress-bar {
													border: 1px solid white;
													border-radius: 3px;

													background-color: @wf_blue_dark;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}

			&.flex-column {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				width: 100%;

				>.form-group {
					margin-right: 0 !important;
				}
			}
		}
	}

	label.control-label {
		font-size: 11px;
		font-weight: 400;
		color: @wf_black_light;
		margin-bottom: 5px;
	}

	bootstrap-decorator {
		width: 100%;
	}

	input.form-control, select.form-control {
		height: @fieldHeight * 1px !important;
		width: 100%;
	}

	.input-group-addon {
		height: @fieldHeight * 1px;
		padding-top: 7px;
		padding-bottom: 7px;
	}

	textarea {
		height: @fieldHeight * 1px;
		min-height: @fieldHeight * 1px;
		width: 100%;
		max-height: 300px;
		overflow: hidden;
		resize: none; // Inline form is using wfTextareaAutoHeight to automatically set height and overflow values

		&:focus {
			min-height: (@fieldHeight + 31) * 1px;
		}
	}
}

.input-group {
	display: flex;
}

.input-group-addon {
	width: auto;
}
