.wfPublicRequestController {
	.request-info-box {
		.panel {
			box-shadow: none !important;
			.panel-body {
				padding: 20px !important;
				background-color: #0099DE;

				.title {
					margin: 0px;
					font-size: 18px;
					color: white;
					font-weight: 500;
				}

				.text {
					color: white;
					font-size: 12px;
					padding: 15px 0;
					display: inline-block;
				}

				.request-button {
					color: white;
					font-size: 14px;
					font-weight: 500;
					background-color: #0081BC !important;
					border-color: #0081BC !important;
					border-radius: 3px;
					box-shadow: none !important;

					&:active {
						animation: pulse .2s ease;
					}
				}
			}
		}
	}//end of: .request-info-box

	.request-info-navbar {
		text-align: center;
		padding: 15px 20px 30px 20px;
		.text {
			display: block;
			text-align: center;
			color: #586777;
			font-size: 12px;
			padding-bottom: 10px;
		}

		.request-button {
			border: 0.5px solid white;
			display: inline-block;
			font-size: 14px;
			width: 100%;
			border-radius: 4px;
			height: 40px;
			color: white;

			&:hover {
				cursor: pointer;
			}

			&:active {
				animation: pulse .2s ease;
			}
		}
	}//end of: .request-info-navbar

}//end of: .wfPublicRequestController

.request-info-modal {
	.modal-dialog {
		width: 100% !important;
		.modal-content {
			.modal-header {
				margin-bottom: 20px !important;
				.title {
					margin-top: 0px;
					font-weight: 500;
				}

				button.close {
					margin-top: -5px !important;
					font-size: 35px;
				}
			}//end of: .header

			.modal-body {
				padding: 30px !important;
				padding-bottom: 10px !important;

				.main-content {
					.item{
						&:last-child {
							wf-item-component {
								border-bottom: none;
							}
						}
						.wf-item-component {
							.transclude-side {
								button {
									i {
										margin-right: 5px;
									}

									&.animate {
										animation: pulse .2s ease;
									}

									&[disabled] {
										opacity: 1;
									}
								}
							}
						}//end of: .wf-item-component
					}//end of: .item
				}//end of: .main-content
			}//end of: .modal-body

			.modal-footer {
				button {
					text-align: right;
					padding-right: 0px;
				}
			}//end of: .modal-footer
		}//end of: .modal-content
	}//end of: .modal-dialog
}//end of: .request-info-modal


@media screen and (max-width: @screen-xs-max) {
	.request-info-modal {
		.modal-dialog {
			margin: 0;
			padding: 10px !important;
			max-width: none;
			.modal-content {
				padding: 20px !important;
				.modal-body {
					padding: 20px !important;
					.bootbox-close-button {
						margin-right: 5px;
						margin-top: -3px !important;
						font-size: 35px;
					}

					.bootbox-body {
						.main-content {
							wf-item-component {
								&:hover {
									background-color: transparent;
								}	
							}
						}
					}//end of: .bootbox-body
				}
			}
		}
	}
}//end of: @media screen and (max-width: @screen-xs-max)

