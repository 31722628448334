// ----------- POPOVER ----------------
// ------------------------------------
@tooltip-width: 300px;
wf-export-pdf {
	position: relative; 
	z-index: 50;
	float: right;
	i { margin-right: 7px; color: white !important; }

}//end of: wf-export-pdf


.export-pdf-popover {
	padding: 20px;
	max-width: 500px;
	width: 450px;
	z-index: 1059;

	.popoverBoxShadow();
	
	.popover-header {
		.header {
			margin: 8px 0;
			h4 { 
				display: inline; 
				font-weight: 500;
				color: @wf_black;
			}

			i {
				font-size: 18px;
				margin-right: 12px;
				color: @wf_black;
			}
		}
	}//end of: .popover-headers

	.popover-body {
		padding: 10px 0;

		.title {
			font-size: 14px;
			color: @wf_gray_dark;
			font-weight: 500;
			display: block;
			margin: 10px 0;
		}//end of: .title

		hr {
			margin-top: 10px;
			border-top-color: @wf_gray_lighter;
		}

		.export-options {
			margin-bottom: 15px;
			.options {
				display: inline-block;

				&:hover {
					cursor: pointer;
				}
				
				.option {
					margin-bottom: 10px;
					wf-check {
						label.icheck-label {
							margin-bottom: 0;
							.icheckbox_blue_wf {
								margin-right: 10px;
								input.check-box { display: inline-block }
							}
							span.label-text { font-size: 13px }
						}
					}

					i.info-icon {
						
						&:hover {
							color: @wf_green;
						}
						margin: 4px;
						font-size: 13px;
						color: @wf_gray;
					}

					.export-option-tooltip {
						width: @tooltip-width;
						&.in { opacity: 1 };
						.tooltip-arrow { border-top-color: white; }
						.tooltip-inner {
							padding: 0;
							background-color: white;
							.popoverBoxShadow();

							.pdf-option-tooltip {
								padding: 15px 20px;

								.header {
									width: @tooltip-width;
									.option {
										color: @wf_gray_dark;
										font-weight: 500;
										font-size: 13px;
										float: left;
										width: 103px;
										margin-right: 37px;
										margin-bottom: 5px;
										text-align: left;

										&:last-child {
											margin-right: 0;
										}
									}
								}
							}

						}
					}//end of: .export-option-tooltip
				}//end of: .options
			}
		}//end of: .export-options

		.export-styles {
			margin: 20px 0;
			.styles {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: center;

				.style-selection {
					flex-grow: 1;
					margin-right: 15px;
					text-align: center;

					label {
						width: 100%;
						height: 75px;
						border-radius: 3px;
						margin-bottom: 5px;
						background-color: @wf_gray_lighter;
						border: 2px solid @wf_gray_lighter;

						&:hover {
							cursor: pointer;
							border-color: lighten(@wf_gray, 12%);
						}

						&:last-child { margin-right: 0 }

						&.active {
							border: 2px solid #3497dc;
						}
					}

					span {
						color: @wf_gray;

					}
					
					&.default-style {
						// background: url() no-repeat center;
						background-size: contain;
					}

					&.elegant-style {}

					&.minimal-style {}
				}
			}//end of: .styles
		}//end of: .export-styles

		.note {
			span {
				display: block;
				font-size: 12px;
				color: @wf_gray;

			}
			.iso-standard-info {
				margin-top: 15px;

				i {
					font-size: 16px;
					margin-top: 4px;
				}

				span, i {
					color: @wf_yellow_dark;
				}
			}
		}//end of: .note
	}//end of: .popover-body

	.popover-footer {
		margin-top: 15px;
		.download-button { margin: 10px 0; }
		
		.bottom-buttons {
			button.btn {
				&.preview-button {
					margin-right: 15px;
				}
			}
		}
	}//end of: .popover-footer
}//end of: .export-pdf-popover

// ------------ MODAL ----------------
// -----------------------------------

@a4PaperWidth: 793.7;
@marginTop: 56.69;

.wf-export-pdf-modal {
	.header {
		h2 {
			display: inline-block;
			font-weight: 400;
			font-size: 28px;
		}

		.actions {
			float: right;
			text-align: right;
			margin-top: 10px;

			button { margin: 5px; }

			.download-pdf-button {}
		}
		.close-modal-button {
			padding: 9px 16px;
			position: absolute;
			right: 25px;
			top: 20px;
		}
	}//end of: .header

	.modal-content {
		padding: 80px !important;
	}

	.pdf-preview-html {
		padding: @marginTop + 0px;
		width: 100%;
	}//end of: .pdf-preview-html

	.pdf-preview {
		margin: 50px 45px;
		wf-pdf-viewer {
			canvas {
				width: 100% !important;
				min-height: 1339px;
			}
		}

		&.loading {
			background-repeat: no-repeat;
			background-position: center 45%;
			background-image: url("/assets/img/loaders/wf_green.gif");
			min-height: 300px;
	
			> * {
				display: none;
			}
		}
	}//end of: .pdf-preview
}//end of: .wf-export-pdf-modal