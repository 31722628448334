@import "wf-variables.less";

wf-filtering, [wf-filtering] {
	.filtering-tool {
		min-width: 170px;
		padding-top: 6px;
		padding-bottom: 6px;

		&:first-child {
			// padding-top: 0;
		}

		&.hideDivider {
			border-bottom: 0;
			padding-bottom: 2px;
			
			& + .filtering-tool {
				border-top: 0;
				padding-top: 5px;
			}
		}
	}

	.filtering-tools {
		&.rows {
			> .filtering-tool:first-child {
				padding-top: 0;
			}
		}
	}
	
	.isActive {
		.filter-clear {
			visibility: visible;
		}
		
		.filter-label {
			color: #777;
		}
	}
	
	.noOptions {
		opacity: 0.5;
	}

	.filter-search {
		&.withHeader {
			.filter-header {
				padding-top: 6px;
			}

			.search-wrapper {
				padding: 0 20px 0 30px;
			}
		}
	}

	.filter-clear {
		visibility: hidden;
		font-size: 15px;
		color: #a5a5a5;
		text-align: center;
		margin-right: 4px;
		margin-top: 1px;
		padding: 2px 7px;
		height: 26px;
		border: 0;
		box-shadow: none;

		&:hover {
			color: #666;
		}
	}

	.filter-header {
		width: 65px;
		color: #aaa;
		text-align: left;
		margin-right: 18px;
		// padding-left: 18px;

		> i {
			margin-top: 6px;
			font-size: 15px;
		}

		> span {
			display: block;
			padding-top: 4px;
		}
	}

	.filter-buttons {

		.btn-group {
			/* Paddings and margins so that the focused outline is shown (gets cut off by pull-none) */
			padding-top: 1px;
			padding-left: 1px;
			margin-top: -1px;
			margin-left: -1px;
			display: block;

			.filter-button {
				padding: 0 !important;
				max-width: none;

				div.filter-option-icon, i.filter-option-icon {
					display: block;
					float: left;
					margin-left: 8px;
					margin-top: 5px;
					margin-right: -3px;
				}

				div {
					padding: 5px 10px;
					line-height: 16px;
					max-width: 200px;

					&.wf-image {
						border-radius: 50%;
						margin-left: -1px;
						margin-right: 4px;
						padding: 0 !important;
						margin: 5px -5px 0px 7px;
					}

					&.text {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					&.count {
						padding: 4px 7px;
						background-color: rgba(0,0,0,0.03);
						margin: 1px;
						margin-left: -3px;
						border-radius: 2px;
						min-width: 25px;
						
						.count-text {
							color: #888;
							font-weight: 400;
							font-size: 10px;
						}

						&.asClose {
							display: block;
							font-size: 12px;

							&:hover {
								background-color: rgb(114, 129, 146);
								color: #fff;
							}
						}
					}
				}

				&.enable-inverted-filtering {
					.count {
						position: relative;

						&:hover {
							background: @wf_red !important;
							
							.count-text { visibility: hidden; }

							&:before {
								visibility: visible !important;
								content: "\f06e" !important;
								font-family: FontAwesome;
								display: block;
								position: absolute;
								padding: 4px 7px;
								z-index: 1;
								color: white;
								top: 0;
								left: 0;
								right: 0;
								border: 0;
								text-align: center;
							}
						}
					}
				}

				&.btn-inverse {
					.count {
						.count-text { color: #dde3ea; }

						background-color: rgba(255,255,255,0.17);
					}

					.bgImage {
						box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.14) inset;
					}
				}

				&.inverted {
					background-color: @wf_red_dark;

					.filter-option-more { display: none !important; }
					.text { color: white; }

					.count {
						position: relative;

						.count-text {
							color: white;
						}

						&:hover {
							.count-text {
								visibility: hidden;
							}
							background: rgba(44, 62, 80, 0.9) !important;
							box-shadow: 0 1px #813837, 0 -1px #813837, 1px 0 #813837;

							&:before {
								visibility: visible !important;
								content: "\f06e" !important;
								font-family: FontAwesome;
								display: block;
								position: absolute;
								padding: 4px 7px;
								z-index: 1;
								color: white;
								top: 0;
								left: 0;
								right: 0;
								border: 0;
								text-align: center;
							}
						}
					}
				}
			}
		}
	}

	.filter-dropdown {
		display: flex;

		.input-group {
			display: block;
			flex: auto;

			input.ui-select-search {
				width: 100% !important;
			}

			.ui-select-container {
				.ui-select-match {
					.ui-select-toggle {
						a.btn.btn-link {
							i {
								top: 0px;
								&::before {
									content: "\f12a";
									
									display: inline-block;
									font-family: "Ionicons";
									font-style: normal;
									font-weight: normal;
									font-variant: normal;
									text-transform: none;
									text-rendering: auto;
									line-height: 1;
									-webkit-font-smoothing: antialiased;
								}
							}
						}
					}
				}
			}
		}

		ul.dropdown-menu {
			max-height: 350px;
			margin-top: 2px;
		}

		.ui-select-choices-row-inner {
			padding: 0;
		}

		.filter-item-dropdown {
			padding: 8px 12px;
			.filter-icon {
				width: 16px;
				height: 16px;
				margin-right: 10px;

				//css icon class
				text-align: center;
				font-size: 14px;
				color: #8e8e8e;

				&.user-icon {
					margin-top: 2px;
				}
			}

			.text {
				font-size: 12px;
				font-weight: 400;
			}

			.description {
				color: #AFAFAF;
				font-size: 10px;
				overflow: initial !important;
			}
		}//end of: .filter-item-dropdown

		.filter-label {
			padding-bottom: 5px;
			color: @wf_gray;
			font-size: 11px;
		}
	}//end of: .filter-dropdown
	
	.filter-date-range {
		.filter-label {
			padding-bottom: 5px;
			color: @wf_gray;
			font-size: 11px;
		}

		&.with-slider {
			.date-range-picker-wrapper {
				width: 33.333%;
				padding-right: 13px;
			}
		}

		.date-range-picker-wrapper { width: 100%; }

	}//end of: .filter-date-range

	.slider {
		padding-left: 20px;
		padding-right: 20px;

		.filter-label {
			color: @wf_gray;
			font-size: 11px;
		}
	}

	.ui-select-container {
		.form-control {
			border-radius: 2px !important;
		}

		.ui-select-toggle {
			height: 36px;
			background: #fff;
			border: 1px solid #e0e0e0;

			.filter-item-dropdown {
				padding: 1px;

				.filter-icon {
					margin-top: 0;
					width: 15px;
					height: 15px;
				}
			}
		}

		.ui-select-match-text {
			text-overflow: ellipsis;
			overflow: hidden;
			padding-right: 40px;
		}

		.ui-select-placeholder {
			font-weight: normal;
			text-overflow: ellipsis;
			overflow: hidden;
			padding-right: 20px;
			width: 100%;
			display: inline-block;
		}

		.ui-select-choices-row.active {
			.filter-item-dropdown {
				.filter-icon, .description {
					color: white;
				}
			}
		}

		a.btn {
			right: 16px;
			margin-top: -2px;
			color: #677481;
			font-size: 12px;
			border-radius: 100%;
			height: 20px;
			width: 20px;
			text-align: center;
			padding: 0;
			line-height: 22px;

			&:hover {
				background-color: #eee;
				color: #444;
			}
		}

		.caret {
			margin-top: -1px !important;
			right: 21px !important;

			&:before {
				font-size: 17px;
			}
		}
	}

	.allSelectedFilterOptions {
		border-top: 1px solid #eee;
		padding: 12px 20px 12px 0;
		transition: border-color .3s;

		&.filtersCollapsed {
			border-color: transparent;
		}

		button.toggle-button {
			margin-bottom: 3px;

			i { 
				margin-left: 5px; 
				color: @wf_blue;
			}
		}

		.filter-options {
			opacity: 0;
			transition: opacity .3s;
			padding-left: 30px;

			> * {
				display: none;
			}

			&.filters-visible {
				opacity: 1;

				> * {
					display: block;
				}
			}
		}

		button.clear-filters {
			margin: 0 0 0 15px;

			i.fa {
				color: @wf_red;
				margin-right: 5px;
			}
		}

		wf-export-csv {
			.export-button {
				height: 16px;
				i {
					color: #2685ee;
					margin-right: 7px;
				}

				span { color: #707980; }
			}
		}
	}//end of: .selected-filters
}

@media (max-width: 520px) {
	[wf-filtering] .filtering-tool {
		width: 100% !important;
		padding-bottom: 5px;
	}
}