.well-temp1 {
	background-color: rgba(0, 0, 32, 0.05) !important;
	border: 0 !important;
	font-size: 16px;
	font-weight: 600;
	text-align: center;
	margin: 10px 0;
	padding: 25px;
	border-radius: 5px;
	text-transform: uppercase;
	color: #444;

	&.item {
		padding: 10px;
	}

	&.item-top {
		padding: 15px;

		.img {
			background-color: rgba(0, 0, 32, 0.05) !important;
			margin-right: 15px;
			line-height: 55px;
			width: 55px;
			height: 55px;
			border-radius: 5px;
			font-size: 11px;
		}

		.title {
			text-align: left;
			font-size: 22px;
			margin-top: -1px;
		}

		.description {
			text-align: left;
			font-size: 13px;
			font-weight: 400;
			text-transform: lowercase;
		}
	}
}

.well-temp2 {
	background-color: rgba(0, 0, 32, 0.05) !important;
	border-radius: 5px;
	margin: 10px 0;
	padding: 15px;

	>span {
		display: block;
		text-transform: uppercase;
		color: #444;
		font-size: 16px;
		font-weight: 600;
		text-align: center;
		border-bottom: 1px solid rgba(128, 128, 128, 0.18);
		margin-bottom: 15px;
		padding-bottom: 10px;
	}
}

wf-list-item {
	display: block;
	position: relative;

	// wf-object-metadata > div {
	// 	min-height: 25px;

	// 	.metadata-kinds > .kind-wrapper {
	// 		margin-right: 10px;
	// 	}
	// }

	// wf-object-metadata .metadata-wrap .metadata-kinds .kind-wrapper .kind .metadataIcon {
	// 	font-size: 15px;
	// }

	// wf-object-metadata .metadata-wrap .metadata-kinds .kind-wrapper .kind .change-slider .items-to-slide .metadata-number {
	// 	font-size: 11px;
	// 	position: relative;
	// 	top: -2px;
	// }

	> .add-placeholder {
		div.wf-placeholder-item {
			.animated-background {
				background: linear-gradient(to right, #ffffff 8%, rgba(236, 240, 241, 0.3) 18%, #ffffff 33%);
				background-size: 800px 104px;
			}
			.mask { background: @wf_gray_light; }
		}

		div.panel &, div.modal & {
			div.wf-placeholder-item {
			  .animated-background {
				background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
				background-size: 800px 104px;
			  }
			  .mask { background: #fff; }
			}
		}
	}

	.item-top, .item-body, .item-bottom {
		.clearfix();
	}

	.item-top {
		// padding-bottom: 20px;

		// &:empty {
		// 	display: none;
		// }

		.item-top-image {
			margin-right: 15px;
		}

		.item-top-texts {
			.wf-preHeader {
			}

			.wf-headerText {
				font-weight: 500;
				font-size: 15px;
				color: #444;
				line-height: 1.2em;
				padding: 2px 0 4px;
			}

			.wf-subHeader {
				padding-top: 0;
				padding-bottom: 1px;
				font-size: 12px;
			}
		}
	}

	.item-body {
		// .spacer {
		// 	height: 20px;

		// 	&:last-child {
		// 		display: none;
		// 	}
		// }

		.wf-bodyText {
			line-height: 1.48em;
			font-size: 12px;
			padding-top: 20px;
		}
	}

	.item-creator-time {
		padding-top: 5px;
		font-size: 11px;
		color: @wf_gray;
		transition: color .1s;
		display: inline-block;

		.wf-creatorImage {
			margin-right: 4px;
			opacity: 0.7;
			transition: opacity .1s;
		}

		&:hover {
			color: @wf_gray_dark;

			.wf-creatorImage {
				opacity: 1;
			}
		}

		.wf-createdAt {
			.fa-icon-before('\f017');

			&:before {
				font-size: 12px;
				margin-right: 4px;
                padding-left: 1px;
			}
		}
	}

	.item-details-partDivider {
		&:first-child {
			display: none;
		}

		display: inline-block;
		width: 1px;
		height: 11px;
		margin: 0 5px;
		background-color: fadeOut(@wf_gray_dark, 70%);
		position: relative;
		top: 2px;
	}

	.item-attachedInformation {
		margin-top: 15px;
		margin-bottom: 0;
	}

	.item-bottom {
		> *:not(wf-object-metadata):first-child {
			margin-top: 20px;
		}

		> wf-object-metadata:first-child {
			> div {
				margin-top: 20px;
			}
		}
		// border-top: 1px solid #E6ECF5;
		// padding-top: 15px;

		wf-object-metadata {
			display: block;
			margin-left: -5px;

			.objectViewer {
				display: block;
			}
		}

		.item-requirement-fulfillment {
			font-size: 11px;
			color: @wf_gray_dark;

			.fulfillment-indicator {
				background-color: #87C99C;
				width: 7px;
				height: 7px;
				display: inline-block;
				position: relative;
				border-radius: 100%;
				margin-right: 5px;
			}
		}
	}

	// wf-creator-time { .wf-creator-time(33, 14); }
}


wf-list-item [wf-expand] {
	&:hover {
		// background-color: @wf_gray_hover;
	}

	.icon-close {
		display: none;
		position: absolute;
		top: 8px;
		right: 10px;
		padding: 10px;
		z-index: 10;
		text-shadow: none;
		color: white;
		opacity: 0.5;
		font-size: 18px;
		cursor: pointer;

		&:hover {
			opacity: 0.8;
		}
	}

	&.expanded {
		.icon-close {
			display: block;
		}

		> div {
			margin: 15px 0;

			> [wf-expand-trigger] {
				position: relative;

				> .item-verticalLine-junction {
					opacity: 1;
				}
			}
		}
	}

	> div {
		position: relative;
		transition: margin .3s;
		border-radius: 3px;

		> [wf-expand-trigger] {
			position: relative;

			> .item-verticalLine-junction {
				position: absolute;
				width: 3px;
				top: 56px;
				bottom: 0;
				left: 27px;
				margin-left: -210px;
				background-color: #666;
				opacity: 0;
				transition: opacity .3s;

				.junction {
					display: block;
					position: absolute;
					top: -15px;
					left: -12px;
					width: 15px;
					height: 15px;
					border: 3px solid #666;
					border-width: 3px 3px 0 0;
					border-top-right-radius: 100%;

					&:before {
						content: "";
						display: block;
						position: absolute;
						top: -15px;
						left: -15px;
						width: 15px;
						height: 15px;
						border: 3px solid #666;
						border-width: 0 0 3px 3px;
						border-bottom-left-radius: 100%;
					}
				}
			}
		}
	}
}

.item-verticalLine, .item-verticalLine-junction {
	display: none;
}

wf-list-interface {
	display: block;

	.list-head {
		position: relative;

		wf-list-interface & > .item-verticalLine {
			position: absolute;
			width: 3px;
			top: -20px;
			left: 0;
			bottom: -20px;
			margin-left: -210px;
			background-color: #666;
		}

		.list-section {
			.clearfix();
			margin-bottom: 7px;
			padding-bottom: 7px;

			&.list-section-border {
				border: 1px solid @wf_gray_light;
				border-radius: 3px;
			}

			// & + & {
				&:not(:last-child) {
					border-bottom: 1px solid #eee;
				}
			// }

			&.section-layout {
				padding-top: 10px;

				.btn.list-indented {
					i {
						transform: rotate(-90deg);
						transform-origin: left bottom 0;
						font-size: 16px;
						position: relative;
						left: 18px;
						margin-top: -2px;
						width: 15px;
						height: 17px;
						margin-left: -3px;
					}
				}
			}
		}
	}

	.list-item + .list-item {
		border-top: 1px solid rgba(0,0,0,0.1);
	}

	.items {
		> .list-wrapper {
			margin: 0 -25px;
			// background: rgba(2, 42, 70, 0.01);
			// border-top: 1px solid rgba(0, 0, 0, 0.08);
			// box-shadow: 0 2px #00000005 inset;

			> .list-item {
				// padding: 15px 0;
				padding: 25px 25px;
			}
		}

		&.cards {
			> .list-wrapper {
				// margin: 0 -10px;

				> .list-item {
					border: 0;

					.item-content {
						&.hasImage {
							padding-top: 170px;

							.item-top-image {
								margin-right: 0;

								.wf-image {
									position: absolute !important;
									width: 100% !important;
									height: 160px !important;
								}
							}
						}
					}
				}
			}
		}
	}

	.list-item {
		position: relative;
		padding: 10px 0;

		> .item-verticalLine {
			position: absolute;
			width: 3px;
			top: 0;
			left: 0;
			bottom: 0;
			margin-left: -200px;
			background-color: #666;

			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 44px;
				left: -5px;
				width: 13px;
				height: 13px;
				background-color: #666;
				border-radius: 100%;
				z-index: 1;
			}
		}

		&:first-child {
			> .item-verticalLine {
				top: 48px;

				&:before {
					top: -5px;
				}

				wf-list-interface & {
					top: 0;

					&:before {
						top: 44px;
					}
				}


				.junction {
					display: block;
					position: absolute;
					top: -15px;
					left: -12px;
					width: 15px;
					height: 15px;
					border: 3px solid #666;
					border-width: 3px 3px 0 0;
					border-top-right-radius: 100%;

					&:before {
						content: "";
						display: block;
						position: absolute;
						top: -15px;
						left: -15px;
						width: 15px;
						height: 15px;
						border: 3px solid #666;
						border-width: 0 0 3px 3px;
						border-bottom-left-radius: 100%;
					}
				}
			}
		}

		&:last-child {
			> .item-verticalLine {
				height: 55px;
			}
		}
	}
}

wf-list-interface-sandbox {


	.itemJson {
		.item-json-expanded {
			position: relative;

			pre {
				max-height: 650px;
			}

			.icon-close {
				display: block;
				position: absolute;
				top: 0;
				right: 17px;
				width: 38px;
				height: 38px;
				line-height: 38px;
				text-align: center;
				font-size: 16px;
				cursor: pointer;
				text-shadow: 0 0 20px whitesmoke, 0 0 20px whitesmoke, 0 0 20px whitesmoke, 0 0 20px whitesmoke, 0 0 20px whitesmoke, 0 0 20px whitesmoke, 0 0 20px whitesmoke, 0 0 20px whitesmoke, 0 0 20px whitesmoke, 0 0 20px whitesmoke, 0 0 20px whitesmoke, 0 0 20px whitesmoke, 0 0 20px whitesmoke, 0 0 20px whitesmoke, 0 0 20px whitesmoke, 0 0 20px whitesmoke, 0 0 20px whitesmoke;
			}
		}
	}
}
