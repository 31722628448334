/* Following CSS are only in the demo and are of no use in a Production */

@import "wf-variables.less";

.show-grid [class*="col-"] {background: #e6e7e8; text-align: center; margin-bottom: 10px; line-height: 2em; border:10px solid @wf_gray_light;}
.show-grid [class*="col-"]:hover {background: @border-darker; }

.demo-example .breadcrumb {margin-bottom: 20px;}

#demo-type-table {
	width:100%;
	margin: -20px 0 -20px 0;
	td {vertical-align: middle;}
	h1,h2,h3,h4,h5,h6 {margin: 15px 0;}
	td:last-child {text-align: right;}
}

#threads,#comments,#users {max-height: 420px; overflow-x: hidden;} //in index page. fixed height so slimscroll can use it

#demo-dropdown {
	width: 300px; padding: 10px 15px;
	.tabular {
		border-collapse: collapse;
		.tabular-row {
			border-bottom: 1px solid #e5e5e5;
			&:last-child {border-bottom: 0;}
			.tabular-cell {
				width: 50%;
				padding: 10px 0;
			}
		}
	}

	
	label {display: block; margin-bottom: 10px;}
	label[for='fixedheader'] {position: relative;}
	#fixedheader {display: inline-block;}

	.demo-color-variation {
		margin-left: 0;
		display: inline-block;
		position: relative;

		li {padding-right: 3px; padding-left: 0; float: left; padding-bottom: 3px}
		li a {
			display: block;height: 20px;width: 20px; border-radius: 60%;
			&.color-lite 		{background-color:@wf_black_dark;}
			&.color-steel 		{background-color:#687285;}
			&.color-lavender 	{background-color:#716885;}

			&.color-black 		{background-color:#000000;}
			&.color-dark 		{background-color:#313338;}
			&.color-red	 		{background-color:#b43e2e;}
			&.color-green	 	{background-color:@brand-green;}
			&.color-blue	 	{background-color:#2e5dac;}

			&.color-default		{background-color:@border-darker}
			&.color-inverse 	{background-color:@brand-inverse}
			&.color-primary 	{background-color:@brand-primary}
			&.color-midnightblue 	{background-color:@wf_black_dark}
			&.color-gray 		{background-color:@gray}
			&.color-indigo 		{background-color:@brand-indigo}
			&.color-grape 		{background-color:@brand-grape}
			&.color-orange 		{background-color:@brand-orange}
			&.color-danger 		{background-color:@brand-danger}
			&.color-success 		{background-color:@brand-success}
		}
	}
}

//dashboard sparkline container
.sparkline-block {
	margin-left: auto;
	margin-right: auto;
	width: 52px;
	padding: 5px 0;
}

// UI-Buttons Page Demo Styles
ul.demo-btns {
    margin: 0;
    padding: 0;
    list-style: none;
    > li {
        display: inline-block;
        margin-bottom: 10px;
    }
}


@media (max-width: @screen-xs-max) { //Alignments in small screen
	#demo-dropdown {
		margin-right: -160px;
		&:before {margin-right: 160px;}
	}
}

.demo-blocks {
	width: 50px;height: 50px; border: 2px solid @border-lighter;
	display: inline-block; cursor: pointer;
	margin-right: 20px; margin-bottom: 10px;
	&:hover {
		border-color: @border-darker;
	}

	&.color-dark 		{background:#313338;}
	&.color-red	 		{background:#b43e2e;}
	&.color-green	 	{background:#48d09b;}
	&.color-blue	 	{background:#2e5dac;}
	&.color-lavender 	{background:#716885;}

	&.pattern-brickwall 		{/*background: url(../img/patterns/brickwall.png)*/}
	&.pattern-dark-stripes 		{/*background: url(../img/patterns/dark_stripes.png)*/}
	&.pattern-rockywall 		{/*background: url(../img/patterns/rockywall.png)*/}
	&.pattern-subtle-carbon 	{/*background: url(../img/patterns/subtle_carbon.png)*/}
	&.pattern-tweed 			{/*background: url(../img/patterns/tweed.png)*/}
	&.pattern-vertical-cloth 	{/*background: url(../img/patterns/vertical_cloth.png)*/}
	&.pattern-grey_wash_wall 	{/*background: url(../img/patterns/grey_wash_wall.png)*/}
	&.pattern-pw_maze_black		{/*background: url(../img/patterns/pw_maze_black.png)*/}
	&.pattern-wild_oliva 		{/*background: url(../img/patterns/wild_oliva.png)*/}
	&.pattern-stressed_linen 	{/*background: url(../img/patterns/stressed_linen.png)*/}
	&.pattern-sos 				{/*background: url(../img/patterns/sos.png)*/}
}

// CSS flipper
// http://davidwalsh.name/css-flip

/* entire container, keeps perspective */
.flip-container {
	perspective: 1000;
	-webkit-perspective: 1000;
	-moz-perspective: 1000;
}
/* flip the pane when hovered */
.flip-container:hover .flipper, .flip-container.hover .flipper {
	transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg);
	-moz-transform: rotateY(180deg);
}

/* flip speed goes here */
.flipper {
	transition: 0.6s;
	transform-style: preserve-3d;

	position: relative;
}

/* hide back of pane during swap */
.front, .back {
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
}

.back a {
	margin-top: 50%;
	margin-bottom: 50%;
}

/* front pane, placed above back */
.front {
	z-index: 2;
	/* for firefox 31 */
	transform: rotateY(0deg);
	-webkit-transform: rotateY(0deg);
	-moz-transform: rotateY(0deg);
	position: relative;
}

/* back, initially hidden pane */
.back {
	transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg);
	-moz-transform: rotateY(180deg);
	width: 100%;
	text-align: center;
}

// tasks demo
#tasks-demo {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
