.modal-fit-window {
	.orderingController{
		padding-bottom: 40px;

		.modal-body{
			margin-bottom: 20px;
			position: absolute;
			left: 50px;
			right: 50px;
			bottom: 76px;
			top: 265px;
			width: 90% !important;
		}

		.modal-footer{
			position: absolute;
			width: 90%;
			bottom: 50px;
			display: inline-block;
			padding-top: 15px;
		}//end of: .modal-footer
	}
}//end of: .modal-fit-window

.orderingController{
	height: 100%;
	.navigation{
		width: 100%;
		min-height: 60px;
		border-bottom: solid 2px @wf_gray_light;
		padding: 0px 85px 25px 85px;

		.back-button{
			display: inline-block;
			position: absolute;
			left: 50px; //padding
			button{
				padding: 0 !important;
			}
			i{
				font-size: 18px;
				color: @wf_gray;
				margin-right: 10px;
			}
			span{
				font-size: 14px;
			}
		}//end of: .back-button
		

		.forward-button{
			position: absolute;
			right: 50px; //padding
			i{
				margin-left: 10px;
			}
		}

		.steps{
			display: block;
			overflow: hidden;
			width: 550px;
			margin: auto;

			.wrap{
				display: block;
				float: left;
				width: 135px;

				strong{
					color: @wf_gray;
					display: block;
					text-align: center;
					margin-top: 10px;
					font-weight: 500 !important;
					padding: 0 10px;
				}

				.step{
					width:40px;
					height:40px;
					text-align: center;
					line-height: 2em;
					border-radius:100%;
					background-color: white;
					transition: background-color 0.5s ease;
					border: solid 2px @wf_green;
					margin: 0 1em;
					display: block;
					color: white;
					position: relative;
					margin: auto;

					span, i{
						color: @wf_green;
						font-size: 18px;
						line-height: 2.2;
						font-weight: 500;
					}

					&.visited{
						background-color: @wf_green;
						transition: background-color 0.5s ease;
						border: solid 2px @wf_green;
						span, i { color: white; }
					}
					
					&.inactive{
						background-color: white;
						transition: background-color 0.5s ease;
						border: solid 2px @wf_gray;
						span{
							color: @wf_gray;
						}

						&::before{
							background-image: linear-gradient(to right, white 35%, rgba(189, 195, 199, 1) 0%);
						}
					}

					&::before{
						content: '';
						position: absolute;
						top: 1.4em;
						left: -8em;
						width: 7.5em;
						height: .2em;
						z-index: 1;

						background-image: linear-gradient(to right, white 35%, rgba(46, 204, 113, 1) 0%);
						background-position: top;
						background-size: 13px 2px;
						background-repeat: repeat-x;
					}
				}
				
				&:first-child {
					.step::before{
						display: none;
					}
				}

			}
		}//end of: .steps
	}//end of: .navigation

	.picker-error-message, .terms-error-message{
		background-color: @wf_red;
		text-align: center;
		width: 100%;
		height: 0px;
		margin: auto;
		visibility: hidden;
		transition: height .5s ease-in;

		span{ color: white; }

		&.visible{
			padding: 5px;
			visibility: visible;
			height: 27px;
			transition: height .5s ease-in;
		}
	}
	
	.modal-wrap{
		padding-top: 30px;
		.modal-header{
			margin-bottom: 0 !important;
			padding: 0 0 20px 0 !important;
			border-bottom-style: none;
			*{ display: inline; }

			.item-title{
				display: block;
				min-height: 77px;
				// vertical-align: top;
				span{
					font-size: 18px !important;
					font-weight: 400;
					color: @wf_gray_dark;
					display: inline;
					
					&:not(:first-child){
						text-transform: lowercase;
					}
				}
				h1{
					display: block;
					margin: 0 !important;
					font-weight: 700;
					font-size: 23px;
					line-height: 30px;
				}
				p{
					display: block !important;
					font-size: 16px;
				}

				.contact-sales{
					display: block;
				}
			}

			.image{
				display: inline-block;
				margin-right: 10px;

				max-width: 55px;
				height: auto;
				position: relative;
				overflow: hidden;
				.img{
					display: block;
					margin: 0 auto;
					height: 50px;
					width: 50px;
					font-size: 45px;
					color: @wf_gray;
				}	
			}

			.modal-close{
				@size: 52px;
				width: @size;
				height: @size;;
				cursor: pointer;
				opacity: 0.6;
				z-index: 1;
				text-align: center;
				font-size: 19px;
				transition-duration: .08s;
				float: right;
				line-height: 52px;
				position: absolute;
				top: 20px;
				right: 20px;
				
				&:hover {
					opacity: 0.95;
					font-size: 21px;
				}
			}
		}//end of: .modal-header

		.modal-body{
			display: inline-block;
			width: 100%;
			padding: 0 !important;

			.product-picker{
				position: absolute;
				bottom: 25px;
				top: 0px;
				width: 100%;

				.wf-item-component{
					.toggler{
						margin-top: 5px;
					}
				}
				
				.transclude-side{
					.price-and-quantity{
						width: auto;

						.price{
							padding: 10px 20px;
							text-align: right;
							float: right;
							display: inline;

							&.add-color{
								color: @wf_black_dark;
							}

							.unit{
								font-size: 12px;
								text-transform: lowercase;
							}
							.currency{
								font-size: 12px;
							}

							.monthly{
								.unit-price{
									font-size: 18px;
								}
							}

							.yearly{
								.unit-price{
									font-size: 12px;
								}
							}
						}

						.quantity{
							padding: 15px 0;
							visibility: hidden;
							// input{
							// 	padding: 5px !important;
							// 	line-height: 1.4;
							// 	width: 55px;
							// }

							// input[type=number]{
							// 	color: @wf_gray_dark;
							// 	text-align: center;
							// }

							// .years{
							// 	padding: 5px !important;
							// 	line-height: 1.4;
							// 	color: @wf_gray_dark;
							// 	text-transform: lowercase;
							// }

							.btn-group{
								button{
									margin: 0 !important;
									background-color: white;

									&:first-child{
										border-top-right-radius: 0 !important;
										border-bottom-right-radius: 0 !important;
									}

									&:last-child{
										border-top-left-radius: 0 !important;
										border-bottom-left-radius: 0 !important;
									}

									&:hover{
										background-color: @wf_gray_light;
									}

									&:focus{
										outline:0;
									}
								}

								span{
									margin: 0 !important;
									background-color: white;
									border-radius: 0 !important;
									box-shadow: none;
									padding-bottom: 6.5px;
									border-top: 1.5px rgba(0,0,0,0.08) solid;
									border-bottom: 1.5px rgba(0,0,0,0.08) solid;
									text-transform: lowercase;
									width: 78px;
									&:hover{
										cursor: initial;
									}
								}
							}

							&.show{
								visibility: visible;
							}

						}//end of: .quantity
					}
				}// end of: .transclude-side

				.transclude-bottom{
					span{
						color: @wf_blue;
						cursor:pointer;

						&:hover{
							text-decoration: underline;
						}
					}
				}// end of: .transclude-bottom
			}// end of: .product-picker

			.checkout{
				display: inline-block;
				width: 100%;

				.selected-products{
					background-color: #F7F7F7;
					padding: 25px;
					border-radius: 5px;

					.table-header{
						height: 25px;

						span{
							font-size: 12px;
							font-weight: 400;
							color: @wf_gray_dark;
							width: 90px;
							text-align: left;
							float: right;
							margin: 0px 7px;

							&.number-of-years{
								width: 65px;
								margin-left: 25px;
							}

							&.yearly-price{
								width: 71px;
							}

							&.monthly-price{
								width: 83px;
							}

							&.record{
								margin: 0;
								float: left;
								text-align: left;
							}

							&.cost{
								width: 115px;
								text-align: right;
							}

							&.billing-period{ 
								width: 115px; 
								text-align: left;
							}
						}
					}// end of: .table-header

					.product{
						.transclude-side{
							width: 550px;
							margin-left: 10px !important;
							strong{
								display: block;
							}
							.column{
								width: 90px;
								text-align: left;
								float: right;
								margin: 0px 7px;

								span{
									font-size: 12px;
									font-weight: 500;
									color: @wf_black_dark;
									vertical-align: middle;
								}

								&.number-of-years{
									width: 65px;
									margin-left: 25px;
								}

								&.yearly-price{
									width: 71px;
								}

								&.monthly-price{
									width: 83px;
								}

								&.cost{
									width: 115px;
									text-align: right;
									padding: 0;
									span{
										font-size: 18px;
										&.currency{
											font-size: 12px;
										}
									}

									&.add-color{
										color: @wf_black_dark;
									}
								}
								
								&.billing-period{
									width: 115px;
									text-align: left;
									padding: 0;

									.from-to{
										strong{ 
											text-align: left;
											color: @wf_black_dark;
										}
									}

									.period{
										span{ 
											display: block; 
											font-weight: 500;
										}
									}
								}
							}//end of: .column
						}//end of: .transclude-side
					}//end of: .product
				}//end of: .selected-products
				
				.table-footer{
					display: inline-block;
					width: 100%;
					padding-top: 10px;

					.cost{
						min-width: 90px;
						text-align: right;
						padding: 0;
						margin-right: 7px;
						
						strong{
							padding: 5px;
							font-size: 18px;
							color: @wf_black_dark;
						}
						span{
							font-size: 18px;
						}
					}

					.vat{
						clear: both;
						margin-right: 7px;
						span{
							color: #707980;
							font-size: 11px;
						}
					}

					.info{
						display: inline;
						float: left;
						span{ color: @wf_gray_dark };
					}
				}//end of: .table-footer

				.form{
					min-height: 504px;
					padding: 40px 0 15px 0;
					.form-title{
							margin: 0 0 30px 0 !important;
							font-weight: 500;
							font-size: 23px;
							line-height: 30px;
					}

					wf-form{
						display: block;

						.form-group{
							padding-right: 0 !important;
						}
					}// end of: wf-form

					&.loading{
						.spinner(22px);
						min-height: 300px;

						> * {
							display: none;
						}
					}
				}//end of: .form
			}//end of: .checkout

			.invoice-preview{
			}//end of: .invoice-preview

			.final-step{
				text-align: center;
				img{
					width: 150px;
					height: 150px;
				}

				.final-message{
					margin-top: 35px;
					p{
						font-size: 22px;
						color: @wf_black;
					}

					span{
						font-size: 18px;
						color: @wf_gray;
					}
				}

			}

		}//end of: .modal-body

		.modal-footer{
			padding: 15px 0px 0px 0px;

			.icheckbox_wf{
				margin-right: 10px;
			}

			.terms-and-conditions{
				display: inline;
				float: left;

				span{
					font-size: 14px;
					color: @wf_gray;
					&.link-to-terms{
						color: @wf_blue;
						cursor: pointer;
						&:hover{
							text-decoration: underline;
						}
					}
				}

				.terms-text{
					display: inline;
					line-height: 1.3;

					&.terms-text-not-checked{
						span{
							color: @wf_red;
						}

						.link-to-terms{
							font-weight: bold;
						}
					}

					:hover{
						cursor: pointer;
					}
				}
			}

			.buttons{
				i{
					margin-left: 10px;
				}
			}
		}// end of: .modal-footer

		&.loading{
			.spinner(22px);
			min-height: 300px;

			> * {
				display: none;
			}
		}
	}// end of: .content

}//end of: .orderingController

