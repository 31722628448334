ng-pdf {
	.wf-pdf-preview {
		margin-bottom: 15px;
		.tools {
			min-height: 44px;
	
			.navigation {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
	
				.left-button {
					order: 1;
					flex-grow: 1;
				}
	
				.page-info {
					order: 2;
					flex-grow: 2;
					text-align: center;
					padding-top: 7px;

					span {
						font-size: 14px;
						font-weight: 500;
						margin-right: 5px;
						color: @wf_blue;
						
					}
				}
	
				.right-button {
					order: 3;
					flex-grow: 1;
					text-align: right;
				}
	
				button {
					color: @wf_blue;
					font-weight: 400;
					.fa-arrow-left {
						margin-right: 10px;
						&:before { top: 0 }
					}
	
					.fa-arrow-right {
						margin-left: 10px;
						&:before { top: 0 }
						
					}
					&[disabled] {
						color: @wf_gray;
					}
				}
			}

			.text-info {
				float: right;
				color: @wf_gray;
			}
	
			// .info {
			// 	display: inline-table;
			// 	padding: 7px 0;
			// 	span {
			// 		display: inline-block;
			// 		width: 55px;
			// 		padding: 5px !important;
			// 		line-height: 1.3;
			// 		color: @wf_gray_dark;
			// 	}
	
			// 	input {
			// 		padding: 5px !important;
			// 		line-height: 1.3;
			// 		width: 55px;
			// 		float: none !important;
			// 	}
	
			// 	input[type=number] {
			// 		color: @wf_gray_dark;
			// 		text-align: center;
			// 	}
			// }
		}//end of: .tools
	
		// #pdf{}
	}//end of: .wf-pdf-preview

	canvas {
		box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.18), 1px 1px 3px 0px rgba(0, 0, 0, 0.1);
		border: solid 1px @wf_gray_lighter;
		image-rendering: auto;
		image-rendering: crisp-edges;
		image-rendering: pixelated;
	}

	&.loading {
		background-repeat: no-repeat;
		background-position: center 45%;
		background-image: url("/assets/img/loaders/wf_green.gif");
		min-height: 300px;

		> * {
			display: none;
		}
	}
}

