body.browser-ie {
	div#wrapper {
		.wfPublicInfluenceViewer {
			.map-background {
				display: none !important;
			}
		}
	}
}

