.PromotionController {
	.promo-header {
		margin-top: 52px;
		font-size: 32px;
		font-weight: 700;
		line-height: 34px;
		color: #2c3e50;
	}

	.promo-text {
		font-size: 14px;
		font-weight: 200;
		margin-bottom: 32px;
		padding-right: 50px;

		line-height: 24px;
		font-size: 14.5px;
	}

	.promo-text p {
		white-space: pre-wrap;
		margin-bottom: 32px;
		// line-height: 21px;
	}

	.promo-text strong {
		font-size: 17px;
		// color: #000;
		color: #2c3e50;
	}

	.promo-list {
		margin-top: 16px;
	}

	.promo-list li {
		margin-bottom: 12px;
		// padding-right: 30px;
	}

	.promo-list li span {
		display: block;
		float: none;
		clear: none;
		overflow: hidden;
		padding-top: 1px;
		vertical-align: top;
	}

	.promo-list i {
		display: block;
		float: left;
		clear: none;
		overflow: hidden;
		margin: 0 6px 0 0;
		font-size: 23px;
		padding: 0px 0 0 0;
		vertical-align: top;
	}

	.promo-action {
		margin-top: 32px;
	}

	.promo-video {
		opacity: 1;
		color: #000;
		transition: opacity 0.3s ease-out, color 0.5s ease-out;
		cursor: pointer;
	}

	.promo-video:after {
		content: "\f144";
		display: block;
		font: normal normal normal 14px/1 FontAwesome;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		font-size: 105px;
		position: absolute;
		top: 150px;
		left: 258px;
		opacity: 0.5;
		transition: opacity 0.3s ease-out, color 0.5s ease-out;
		pointer-events: none;
	}

	.promo-video:hover {
		opacity: 0.7;
		color: #000;
	}

	.promo-video:hover:after {
		opacity: 1;
	}

	.promo-bottom {
		color: #fff;
		margin-top: 32px;
		padding: 62px 0;
	}

	.promo-bottom .promo-text {
		margin-bottom: 0px;
	}

	.promo-bottom h2 {
		color: #fff;
		margin-top: 0px;
	}

	.promo-bottom p {
		margin-top: 16px;
	}

	.promo-divider {
		border-top: 1px solid #fff;
		display: inline-block;
		margin: 52px 0;
		opacity: 0.2;
		position: relative;
		width: 100%;
	}

	.promo-icon {
		display: block;
		margin: auto;
		max-width: 175px;
		vertical-align: middle;
	}

	.promo-bottom .promo-list {
		margin-top: 0px;
	}
}

.promotion-grid-card{
	padding-bottom: 50px;
	min-height: 418px;

	&:hover {
		.bgImage {
			// box-sizing: content-box;
			// margin: 0 10px;
		}
	}

	.img {
		height: 200px;
		width: auto;
		margin: -@panelPadding;
		margin-bottom: @panelPadding;
		overflow: hidden;
		position: relative;

		.bgImage {
			background-repeat: no-repeat;
			background-size: 100px 100px;
		}

		&.noImage {
			&:before {
				content: "";
				display: block;
				clear: none;
				overflow: hidden;
				width: 100%;
				height: 100%;
				background: url("http://lorempixel.com/400/200/abstract/") no-repeat center center;
			}
		}

		img {
			width: 100%;
		}
	}
	
	.fade-bottom {
		position: absolute;
		width: auto;
		padding: 25px;
		left: 0;
		right: 0;
		bottom: 70px;
		top: 0;

		.text{
			h3{
				font-weight: 500;
			}
		}
	}

	.bottom {
		height: auto;
		padding-bottom: @panelPadding;
		position: absolute;
		right: @panelPadding;
		left: @panelPadding;
		bottom: 0;
		padding-right: 5px;
		padding-left: 5px;

		button{
			margin-bottom: 20px;
		}
		
		.bottom-body {
			float: none;
			clear: none;
			overflow: hidden;
		}

		.sis-standard{
			display: inline-block;
			font-size: 11px;
			span{
				font-style: italic;
				color: @wf_gray;
			}
		}
	}

	.guidance-btn {
		display: none;
	}
}

wf-promotions {
	width: 100%;

	.ie-styles {
		.promotional-category {
			&.vertically-aligned-items {
				.promotional-content {
					.promotional-item {
						flex-basis: auto;
					}
				}
			}
			&.zig-zag {
				.content-wrapper {	
					.promotional-content {
						.promotional-item {
							flex-basis: auto !important;
						}
					}
				}
			}
		}
	}

	.promotional-category {
		margin-top: 30px;

		&.large-image {
			.promotional-content {
				.promotional-item {
					.item-image img {
						max-width: 130px !important;
						max-height: 130px !important;
					}
				}
			}
		}

		&.vertically-aligned-items {
			&.content-left {
				.promotional-content {
					margin-right: 50px;

					.promotional-item {
						margin-bottom: 60px;
					}
				}
			}

			&.content-right {
				flex-direction: row-reverse;
				.promotional-content {
					margin-left: 50px;

					.promotional-item {
						margin-bottom: 60px;
					}
				}
			}

			.promotional-content {
				flex-direction: column !important;
				flex-wrap: nowrap !important;

				.promotional-item {
					flex-basis: initial;
				}
			}
		}//end of: &.vertically-aligned-items

		&.zig-zag {
			.content-wrapper {
				padding-top: 0;
				max-width: 850px;
				margin: 0 auto;

				.promotional-content {
					flex-direction: column;
					.promotional-item {
						flex-basis: initial !important;
						margin: 32px 0;
	
						.item-image {
							margin-top: 0;
							img {
								max-width: 140px;
								max-height: 140px;
							}
						}
	
						&:nth-child(even) {
							.item-image {
								margin-left: 40px;
							}
							.promo-item {
								flex-direction: row;
	
							}
						}

						&:nth-child(odd) {
							.item-image {
								margin-right: 40px;
							}
							.promo-item {
								flex-direction: row-reverse;
							}
						}
	
						.promo-item {
							display: flex;
							flex-wrap: nowrap;
	
							>div {
								width: initial;
							}
						}
					}
				}
			}//end of: .content-wrapper
		}//end of: .zig-zag

		&.vertical-layout {
			margin-top: 0;
			margin-bottom: 20px;

			.content-wrapper {
				order: 2;
				padding-top: 0;

				.promotional-content {
					padding-right: 0;
					padding-left: 0;
					
					.promotional-item {
						background-color: #fff;
						box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 -1px 0px 0 rgba(0, 0, 0, 0.03);
						flex-basis: 100%;

						padding: 25px;
						border-radius: 3px;
						
						&:not(:last-child) {
							margin-right: 30px;
						}

						.promo-item {
							display: flex;
							flex-direction: column-reverse;
							justify-content: space-evenly;
							

							h4 {
								margin: 5px 0 10px 0 !important;
							}

							> div {
								width: 100% !important;
								text-align: center;
							}

							.promotion-html-link {
								margin-top: 15px;
								color: @wf_blue;
							}

							.item-image {
								display: inline-block;
								position: relative;
								width: 80px;
								height: 80px;
								overflow: hidden;
								margin-top: 0;
								img {
									width: auto;
									height: 100%;
								}
							}

						}
					}
				}//end of: .promotional-content
			}//end of: .content-wrapper
		}//end of: .vertical-layout

		&.url-path {
			.promotional-content {
				.promotional-item {
					cursor: pointer;
					position: relative;
					border: 1px solid transparent;
					
					&:hover { 
						border-color: fade(@wf_blue, 50%);
						transition: border-color .1s ease-in;
						&:after { color: @wf_blue; } 
					}

					&:after {
						position: absolute;
						content: '\f35d';
						right: 20px;
						top: 20px;
						font-family: 'Font Awesome 5 Free';
						font-weight: 900;
						color: @wf_gray;
					}
				}
			}
		}

		.promotional-content {
			order: 1;

			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			width: 100%;

			.promotional-item {
				order: 1;
				flex-basis: 320px;
				flex-shrink: 5;
				
				h4 {
					display: inline-block;
					margin-bottom: 20px;
					margin-top: 0;
					font-weight: 600;
				}
				
				a.read-more {
					margin-top: 20px;
					display: inline-block;
		
					i {
						margin-right: 5px;
					}
				}
		
				span.item-description {
					display: block;
					font-size: 13px;
					white-space: pre-wrap;
				}
				
				.item-image {
					text-align: center;
					img {
						max-width: 100px;
						max-height: 100px;
					}
				}
			}//end of: .promotional-item

		}//end of: .promotional-content

		.guidance-video {
			order: 2;
			flex-grow: 5;
			width: 100%;
			iframe {
				border-radius: 3px;
			}
			
			.text {
				width: 100%;
				text-align: center;
				margin-top: 15px;
				font-size: 13px;
				font-style: italic;
				font-weight: 400;
			}
		}//end of: .guidance-video

		.content-wrapper {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			
			padding-top: 40px;
		}//end of: .content-wrapper
	}//end of: .promotional-category
}//end of: wf-promotions

.promotional-banner {
	.banner-wrapper {
		display: inline-block;
		position: relative;
		width: 100%;

		background-color: @wf_black_dark;
		border-radius: 3px;
		padding: 20px 30px;
		margin-bottom: 30px;

		.text {
			padding: 15px 0;
			span {
				font-size: 18px;
				color: white;
				font-weight: 300;
			}
		}
		.contact-button {
			padding: 10px 0;
			text-align: right;

			button {
				color: @wf_black_dark;
				background-color: white;
				border-color: white;

				&:hover {
					background-color: rgba(255, 255, 255, 0.9);
				}
			}
		}
	}//end of: .banner-wrapper
}//end of: .promotional-banner

// from 0px to 768px
@media screen and (min-width: 0) and (max-width: @screen-xs-max) {
	wf-promotions {
		.ie-styles {
			.promotional-category {
				.content-wrapper {
					.promotional-content {
						.promotional-item {
							flex-basis: auto !important;
						}
					}
				}
			}
		}

		.item-image {
			margin-top: 40px;
		}

		.promotional-category {
			.promotional-title {
				.text {
					h2 {
						font-size: 28px !important;
						text-align: left !important;
					}

					span {
						padding: 0 !important;
					}


					h2, span {
						border: none !important;
						line-height: normal !important;
					}
				}
			}

			.content-wrapper {
				flex-direction: column !important;
				
				.promotional-content {
					flex-direction: column;
					margin-left: 0 !important;
					margin-right: 0 !important;

					.promotional-item {
						flex-basis: initial !important;
						margin-bottom: 50px;
						
						.item-image {
							// margin-top: 0;
						}
					}
				}
			}//end of: .content-wrapper
			
			&.vertically-aligned-items {
				.content-wrapper {
					padding: 0; 
					.guidance-video {
						order: 1;
						padding: 0 20px 40px 20px;
					}
					
					.promotional-content {
						order: 2;
					}

				}
			}

			&.vertical-layout {
				.promotional-item {
					max-width: 500px;
					margin: 0 auto 15px auto !important;
				}
			}

			&.zig-zag {
				.promotional-content {
					.promotional-item {
						.item-image {
							margin-right: 0px !important;
							margin-left: 0px !important;
							img {
								max-width: 70px !important;
								max-height: 70px !important;
							}
						}
					}
				}
			}
		}//end of: .promotional-category
	}//end of: wf-promotions

	.promotional-banner {
		.banner-wrapper {
			.contact-button {
				text-align: center;
			}
		}//end of: .banner-wrapper
	}//end of: .promotional-banner
}

// from 768px to 992px
@media screen and (min-width: @screen-xs-max) and (max-width: @screen-sm-max) {
	wf-promotions {
		.ie-styles {
			.promotional-category {
				.content-wrapper {
					.promotional-content {
						.promotional-item {
							flex-basis: auto !important;
						}
					}
				}
			}
		}

		.promotional-category {
			.content-wrapper {
				flex-direction: column !important;
				
				.promotional-content {
					flex-direction: column;
					margin-left: 0 !important;
					margin-right: 0 !important;

					.promotional-item {
						flex-basis: initial !important;
						margin-bottom: 50px;

						.item-image {
							margin-top: 0;
						}
					}
				}
			}//end of: .content-wrapper

			&.vertically-aligned-items {
				.content-wrapper {
					padding: 0; 
					.guidance-video {
						order: 1;
						padding: 0 20px 40px 20px;
					}
					
					.promotional-content {
						order: 2;
					}

				}
			}

			&.vertical-layout {
				.promotional-item {
					max-width: 500px;
					margin: 0 auto 15px auto !important;
				}
			}
		}//end of: .promotional-category
	}//end of: wf-promotions
}