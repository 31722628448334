wf-toggle {
	[wf-toggle-element]:hover & {
		.toggler-circular{
			// visibility: visible;
			&.unchecked{
				.state-untoggled.state-hover:not(:hover){
					display: block;
					color: @wf_gray_light;
				}
				
				&.loading{
					.state-toggled, .state-untoggled, .state-hover{
						display: none;
						// visibility: hidden;
					}
				}
			}
		}//end of: .check-toggle
	}//end of: [wf-toggle-element]:hover

	.toggler-circular{
		width:50px;
		height:50px;
		display: inline-block;
		border: solid 1px @wf_gray;
		border-radius: 100%;
		line-height: 40px;
		vertical-align: middle;
		text-align: center;
		cursor: pointer;

		&.loading{
			.spinner(22px);

			.state-toggled, .state-untoggled, .state-hover{
				display: none;
				// visibility: hidden;
			}
		}

		&.checked{
			.state-toggled{
				// visibility: visible;
				display: block;
				
				&.state-hover {
					display: none;
				}
			}

			.state-untoggled{
				display: none;
			}

			&:hover{
				.state-toggled{
					display: none;
				}
				.state-toggled.state-hover{
					display: block;
				}
			}
		}

		&.unchecked{
			.state-untoggled{
				display: block;

				&.state-hover {
					display: none;
				}
			}

			.state-toggled{
				display: none;
			}

			&:hover {
				.state-untoggled {
					display: none;
				}

				.state-untoggled.state-hover {
					display: block;
				}
			}
		}

		&.untoggle-disabled {
			.state-disabled {
				display: block;
				color: @wf_gray;
			}

			.state-toggled, .state-untoggled { display: none; }

			&:hover { .state-toggled, .state-toggled.state-hover, .state-untoggled, .state-untoggled.state-hover { display: none; } }
		}

		.state-disabled {
			display: none;
		}
		
		i{
			font-size: 20px;
			margin-top: 5px;
			line-height: inherit;

			&.state-hover{
				display: none;
			}

			&.state-untoggled{
				color: @wf_red;

				&.state-hover{
					color: @wf_gray_dark;
				}
			}

			&.state-toggled{
				color: @wf_blue;

				&.state-hover{
					color: @wf_red;
				}
				
			}

		}
	}//end of: .check-toggle
}//end of: wf-toggle