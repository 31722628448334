// The Theme Rightbar
// --------------------------------------------------

.infobar {
	background: #fff;
	display: none;
	width: 300px; 
	top: 50px;
	right: 0;
	bottom: 0;
	height: 100%;
	z-index: @zindex-navbar-fixed - 1;
	overflow: hidden;
	position: fixed;
	border-left: 1px solid @border-darker;
}

body.show-infobar {
	.infobar {
		display: block;

		.panel-heading {
			.panel-title {
				padding: 5px 0;
			}
			padding: 0 20px;
			border-radius: 0;
			border: 0;
			background-color: @tone-bright;
			a {
				color: @gray !important;
				font-size:@font-size-mini;
				display: block;
				text-transform: uppercase;
				//letter-spacing: 0.1em;
				small {
					color: #AEAFB1;
					font-size: @font-size-base;
				}
				&:after {
					.opacity(0.5);
					content:"\f105";
					font-family: FontAwesome;
					font-style: normal;
					font-weight: normal;
					float: right;
				}
				&.collapsed:after {
					content:"\f104";.opacity(0.5)
				}
			}
		}
		.panel {
			box-shadow: none;
			margin: 0;
		}
		.open .panel-heading {
			a {
				&:after {
					.opacity(0.5);
					content:"\f107";
					font-family: FontAwesome;
					font-style: normal;
					font-weight: normal;
					float: right;
				}
			}
		}
		.panel-collapse .panel-body {
			background: #fff;
			margin-top: 0px;
			&:extend(.clearfix all);
			.more {
				padding-top: 0px;
				font-size: @font-size-mini;
				float: right;

			}

			.widget-block {
				&:extend(.clearfix all);
				 padding: 10px;
				 margin-bottom: 10px;
				 border-radius: @border-radius-small;
				 small {
				 	display: block;
				 	color: #fff;
				 	color: rgba(255,255,255,0.75)
				 }
				 h5 {
				 	margin: 0;
				 	color: #fff;
				 	font-size: 1.3em;
				 	>small {vertical-align: super;display: inline;font-size: 0.6em;}
				 }
			}
		}

	}

	#widgetarea {
		display: block;
		overflow: visible;
	}




	/* Style of the Chat area */
	#chatarea {
		display: none;
		margin-top: 10px;

		.chatuser {
			font-size:@font-size-large; 
			margin:0 0px;

		}

		.chathistory {
			// overflow:hidden;
			bottom: 122px;
			top: 40px;
			position: absolute;
			height: auto;
			display:block;
			z-index: 999999999;

			// Add stylized scrollbar
			overflow-y: auto;
			overflow-x: hidden;
		}
		.chatinput {
			height:100px; width: 279px;
			bottom: 10px; position: absolute;
			textarea {
				border: 1px solid @border-darker;
				text-decoration: none;
				background-color: #fff;
				width: 100%;
				height: 62px;
				padding: 10px;
				resize:none;
			}
		}


		.chatmsg {
			text-align: right;
			position: relative;
			background: @gray-lighter;
			color: #1a1a1a;
			width: 279px;
			padding: 10px;
			margin: 10px 0 20px;
			border-radius: @border-radius-base;


			&:after {
				content: "";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				top: 100%;
				right: 5%;
				border-top: 10px solid @gray-lighter;
				border-left: 15px solid transparent;
			}
			p { 
				margin: 0;
				font-size:14px;
			}
			span {
				padding-top: 5px;
				display: block;
				text-align: left;
				font-size: 12px;
				.opacity(0.3);
			}

			&.sent {
				text-align: left;
				background: @wf_black_dark;
				color: #fff;
				position: relative;

				span {
					text-align: right;
				}

				&:after {
					left: 5%;
					right: auto;
					border-left:none;
					border-top: 10px solid @wf_black_dark;
					border-right: 15px solid transparent;

				
				}	
			}
		}
	}
}

.chat-users {
	.list-unstyled();
	margin-top: 0; 
	margin-bottom: 20px; 
	//margin-left: -10px; 
	//margin-right: -10px; 
	padding: 0;

	a {display: block; color: @gray-dark; padding: 2px; border-radius: 30px;}
	a:hover {text-decoration: none; background-color: @border-lighter; border-radius: 30px;}
	li {margin: 0;}
	li:first-child {margin-top:0;}

	img {width: 30px;height: 30px; border-radius: 50%;}
	span {padding-left: 10px}

	li.active a {text-decoration: none; background-color: @border-lighter; border-radius: 30px;}

	li a:after {
		content:"•";
		float: right;
		font-size: 1.7em;
		padding-right: 5px;
		line-height: 30px;
	}

	li[data-stats="online"] a:after 	{color: @brand-success;}
	li[data-stats="busy"] a:after 		{color: @brand-danger;}
	li[data-stats="away"] a:after 		{color: @brand-warning;}
	li[data-stats="offline"] a:after 	{color: @gray-lighter;}
}



#chat-box {
	width: 400px;
	position: fixed;
	z-index: @zindex-modal - 1;
}