.picker-controller{
	display: flex;
	flex-direction: column;
	height: 100%;

	.picker-close{
		@size: 50px;
		width: @size;
		height: @size;;
		cursor: pointer;
		opacity: 0.6;
		z-index: 1;
		text-align: center;
		font-size: 19px;
		transition-duration: .08s;
		float: right;
		line-height: 52px;
		position: absolute;
		top: 33px;
		right: 33px;

		&:hover {
			opacity: 0.95;
			font-size: 21px;
		}
	}

	.picker-header{
		flex-shrink: 0;
		order: 1;
		width: 100%;
		margin-bottom: 25px;

		.item-title{
			vertical-align: top;

			.modalTitle {
				font-size: 18px !important;
				font-weight: 400;
				color: @wf_gray_dark;

				&:not(:first-child){
					text-transform: lowercase;
				}
			}
			h1{
				display: block;
				margin: 0 !important;
				font-weight: 700;
				font-size: 23px;
				line-height: 30px;
			}
			div{
				p{
					display: block !important;
					margin: 0;
					width: 85%;
					text-overflow: ellipsis;
					white-space: pre-wrap;
					max-height: 350px;
				}

				p:first-child{
					font-size: 16px;
					font-weight: 600;
					text-overflow: none;
					white-space: pre-wrap;
				}
			}
		}

		.image{
			display: inline-block;
			margin-right: 15px;
			.img{
				font-size: 45px;
    			margin-left: 1px;
				color: #afafaf;
			}
		}
	} //end of: .picker-header

	.picker-body {
		order: 2;
		width: 100%;
		flex: 1 0 auto;
		display: flex;
		flex-direction: column;

		.picker-content {
			height:100%;
			display: flex;
			flex-direction: column;
			flex: 1 0 auto;

			&.form-opened {
				display: block;
				flex-direction: initial;
				flex: initial;
			}

			wf-item {
				display: flex;
				flex-direction: column;
				flex: 1 0 auto;
				>div{
					display: flex;
					flex-direction: column;
					flex: 1 0 auto;
				}
			}

			.search-bar-and-add-button {
				flex-shrink: 0;
				margin-bottom: 30px;
				div.disableSearch {
					.input-group {
						pointer-events: none;
						opacity: 0.3;
					}
				}

				button {
					div {
						&.form-visible { display: inline; }
						&.form-hidden { display: none; }
					}

					&.close-button {
						i { transform: rotate(45deg); }
						.form-visible { display: none; }
						.form-hidden{ display: inline; }
					}
				}
			}

			.existing-items {
				flex: 1 0 auto;
				display: flex;
				flex-direction: column;

				div[wf-filtering] + div.list-of-items { margin-top: 30px; }

				.list-filters {
					flex-shrink: 0;
					display: inline-block;
					margin-bottom: 30px;

					.btn-group {
						button {
							padding: 0 !important;
							max-width: none;
							outline: none;

							&.leftDivider {
								margin-left: 9px !important;
								overflow: visible;

								&:before {
									content: "";
									display: block;
									width: 1px;
									background: rgba(0, 0, 0, 0.1);
									position: absolute;
									left: -7px;
									top: 1px;
									bottom: 1px;
								}
							}

							div {
								padding: 5px 10px;
								display: inline-table;
								line-height: 16px;
								max-width: 200px;

								&.text {
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;
								}

								&.count {
									background-color: rgba(0,0,0,0.03);
									color: #888;
									margin: 1px;
									margin-left: -3px;
									font-weight: 400;
									font-size: 10px;
									border-radius: 2px;
									padding: 4px 7px;
									display: inline-block;

									&.lazyLoad {
										transition-duration: 0.15s;
										min-width: 0;
										width: 0;
										overflow: hidden;
										padding: 4px 0;

										&.initiated {
											min-width: 32px;
											width: auto;
											padding: 4px 7px;
										}

										&.loader-small {
											width: 32px;
											&:before {
												width: 18px;
												height: 18px;
												margin-top: -9px;
												margin-left: -9px;
												border: 2.9px solid rgba(0, 0, 0, 0.06);
												border-top-color: rgba(0, 0, 0, 0.45);
											}
										}
									}
								}
							}

							&.btn-inverse {
								.count {
									color: #dde3ea;
									background-color: rgba(255,255,255,0.17);
								}
							}
						}
					}
				}//end of: .list-filters

				.list-of-items {
					flex: 1 0 auto;
					flex-grow: 1;
					position: relative;

					.scrollable-div {
						width: 100%;
						height: 100%;
						position: absolute;

						&:hover { .mCSB_scrollTools { opacity: 1; } }
						.mCSB_scrollTools{ opacity: 0.1; }
					}//end of: .scrollable-div

					.list-group {
						.list-group-item {
							padding: 10px 0;
							&:hover { cursor: pointer; }

							.template3 {
								display: inline-block;
								&:hover { cursor: pointer; }
							}

							.toggler{
								padding: 5px 30px;
							}//end of: .toggler
						}//end of: .list-group-item
					}//end of: .list-group
				}//end of: .list-of-items
			}//end of: .existing-items
		}//end of: .content
	} //end of: .picker-body

	.picker-footer {
		flex-shrink: 0;
		order: 3;
		width: 100%;
		padding: 0;
		margin-top: 35px;
		border: none;

		button {
			float: right;
		}
	}//end of: .picker-footer

	.pagination-controls {
		position: absolute;
		bottom: -71px;
		z-index: 10;
	}

}// end of: .picker-controller
