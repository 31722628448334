// CSS Loaders
// Source: 	http://codepen.io/TaniaLD/pen/oKxep
//			http://codepen.io/fbrz/pen/ljuJn


// Panel Loading

.panel-loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.75)
}

//Circular Loaders

.panel-loader-circular {
	.animation(animateloader 1.5s linear infinite);
	clip: rect(0, 80px, 80px, 40px); 
	height: 80px;
	width: 80px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-top: -40px;
	margin-left: -40px;
	&:after {
	  .animation(animateloader2 1.5s ease-in-out infinite);
	  clip: rect(0, 80px, 80px, 40px);
	  content:'';
	  border-radius: 50%; 
	  height: 80px;
	  width: 80px;
	  position: absolute; 
	}
}
	@-webkit-keyframes animateloader{
	  0% { 
	    transform: rotate(0deg)
	  }
	  100% { 
	    transform: rotate(220deg)
	  }
	}

	@keyframes animateloader  {
	  0% { 
	    transform: rotate(0deg)
	  }
	  100% { 
	    transform: rotate(220deg)
	  }
	}


	@keyframes animateloader2 {
	  0% {
	    box-shadow: inset @brand-primary 0 0 0 17px;
	    transform: rotate(-140deg);
	  }
	  50% {
	    box-shadow: inset @brand-primary 0 0 0 2px;
	  }
	  100% {
	    box-shadow: inset @brand-primary 0 0 0 17px;
	    transform: rotate(140deg);
	  }
	}

	@-webkit-keyframes animateloader2 {
	  0% {
	    box-shadow: inset @brand-primary 0 0 0 17px;
	    transform: rotate(-140deg);
	  }
	  50% {
	    box-shadow: inset @brand-primary 0 0 0 2px;
	  }
	  100% {
	    box-shadow: inset @brand-primary 0 0 0 17px;
	    transform: rotate(140deg);
	  }
	}

// Dots Loaders


.panel-loader-dots{
	position: absolute;
	width: 12px;
	height: 12px;
	top: 50%;
	left: 50%;
	margin-left: -15px;
	margin-top: -6px;
	border-radius: 12px;
	.animation(loader10m 3s ease-in-out infinite);
	&:before{
		content: "";
		position: absolute;
		top: 0px;
		left: -25px;
		height: 12px;
		width: 12px;
		border-radius: 12px;
		.animation(loader10g 3s ease-in-out infinite);
	}
	&:after{
		content: "";
		position: absolute;
		top: 0px;
		left: 25px;
		height: 10px;
		width: 10px;
		border-radius: 10px;
		.animation(loader10d 3s ease-in-out infinite);
	}
}

@-webkit-keyframes loader10g{
	0%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), .2);}
	25%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), 1);}
	50%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), .2);}
	75%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), .2);}
	100%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), .2);}
}
@keyframes loader10g{
	0%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), .2);}
	25%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), 1);}
	50%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), .2);}
	75%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), .2);}
	100%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), .2);}
}

@-webkit-keyframes loader10m{
	0%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), .2);}
	25%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), .2);}
	50%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), 1);}
	75%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), .2);}
	100%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), .2);}
}
@keyframes loader10m{
	0%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), .2);}
	25%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), .2);}
	50%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), 1);}
	75%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), .2);}
	100%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), .2);}
}

@-webkit-keyframes loader10d{
	0%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), .2);}
	25%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), .2);}
	50%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), .2);}
	75%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), 1);}
	100%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), .2);}
}
@keyframes loader10d{
	0%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), .2);}
	25%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), .2);}
	50%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), .2);}
	75%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), 1);}
	100%{background-color: rgba(red(@brand-primary), green(@brand-primary), blue(@brand-primary), .2);}
}


.panel-loader-clock {
	border-radius: 60px;
	border: 3px solid @brand-primary;
	height: 80px;
	width: 80px;
	position: absolute;

	top: 50%;
	left: 50%;
	margin-top: -43px;
	margin-left: -43px;
	&:after {
		content: "";
		position: absolute;
		background-color: @brand-primary;
		top:2px;
		left: 48%;
		height: 38px;
		width: 4px;
		border-radius: 5px;
		.transform-origin(50% 97%);
		.animation(grdAiguille 2s linear infinite);
	}
	&:before{
		content: "";
		position: absolute;
		background-color: @brand-primary;
		top:6px;
		left: 48%;
		height: 35px;
		width: 4px;
		border-radius: 5px;
		.transform-origin(50% 94%);
		.animation(ptAiguille 12s linear infinite);
	}
}

@-webkit-keyframes grdAiguille{
    0%{.rotate(0deg);}
    100%{.rotate(360deg)}
}

@keyframes grdAiguille{
    0%{.rotate(0deg);}
    100%{.rotate(360deg);}
}

@-webkit-keyframes ptAiguille{
    0%{.rotate(0deg);}
    100%{.rotate(360deg);}
}

@keyframes ptAiguille{
    0%{.rotate(0deg);}
    100%{.rotate(360deg);}
}
