wf-inline-picker {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: nowrap;
	flex-basis: 100%;
	position: relative;

	@formControlLabel: 15;
	@fieldHeight: 35;
	@distanceBetweenFields: 10;

	.inline-picker-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		flex-wrap: nowrap;
		flex-basis: 100%;

		>.input-field:last-child { margin-right: 0 !important; }

		.input-field {
			margin-bottom: 0;
			margin-right: @distanceBetweenFields * 1px;
			padding-right: 0 !important;

			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			flex-basis: 100%;
			position: relative;

			label.control-label {
				font-size: 11px;
				font-weight: 400;
				color: @wf_black_light;
				margin-bottom: 5px;
			}

			.input-as-search-field {
				padding: 0;
				border: none;
				.input-group {
					height: @fieldHeight * 1px !important;
					width: 100%;

					input {
						height: @fieldHeight * 1px !important;
					}

					.input-group-addon {
						&.icon {
							&:not(.search-active) {
								i { color: @wf_gray_text_color; opacity: 0.5; }
							}
						}

						&.search-count  {

						}

						&.cancel {

						}
					}
					&:hover {
						cursor: pointer;
					}
				}
			}
		}
	}
}

.picker-popover {
	@popoverHeight: 300;

	display: block;
	top: 55px !important;
	width: 100%;
	right: 1px !important;
	left: 1px !important;
	max-width: initial !important;
	max-height: (@popoverHeight + 200) * 1px;
	overflow: hidden;

	border-top-left-radius: 0;
	border-top-right-radius: 0;

	&.bottom {
		margin-top: 1px;
	}

	.popover-content {
		padding: 12px 0 0 0;

		.wf-inline-picker-popover {
			display: flex;
			flex-direction: column;
			flex-basis: 100%;
			justify-content: flex-start;
			position: relative;

			&.paginated {
				.scrollable-div {
					padding-bottom: 50px;
				}
			}

			.list-filters {

			}

			.list-of-items {
				position: relative;

				.filters {
					padding-left: 12px;
				}

				.scrollable-div {
					overflow-y: auto;
					max-height: (@popoverHeight - 20) * 1px;
					padding-bottom: 11px;

					&:after {
						content: initial;
					}

					.list-group {
						padding: 2px 12px 0 12px;

						margin-bottom: 0px;

						.list-group-item {
							padding: 0;
							border: none;

							&:hover {
								cursor: pointer;
							}

							wf-item-component {
								padding: 10px 15px 10px 10px;
								border-radius: 3px;

								.wf-item-component {
									border-radius: 3px;

									.container {
										.image {

										}

										.content-wrapper {
											.content {
												.transclude-side {
													display: flex;
													flex-direction: row;
													align-items: center;
												}

												.inner-content {
													.headers {
														margin-bottom: 0;

														.wf-bodyText {
															max-width: 500px;
															white-space: normal;
															line-height: 16.8px;
															max-height: 55px;
															position: relative;
															overflow: hidden;
															-webkit-line-clamp: 3;
															-webkit-box-orient: vertical;
															display: -webkit-box;
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}

			.pagination-controls {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				position: absolute;
				bottom: 0;
				right: 25px;
				background: rgba(255, 255, 255, 0.75);
				left: 7px;
				border-top: 1px solid rgba(0, 0, 0, 0.09);

				dir-pagination-controls {
					// &:not(:empty) {
					// 	margin-top: 10px;
					// }

					.pagination {
						margin: 9px 0 5px;
					}
				}
			}
		}
	}

}


.picker-toggler {
	margin: 0 20px 2px;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	border: 1px solid rgba(0, 0, 0, 0.2);
	text-align: center;
	background: #fff;
	transition: border .1s;

	&:hover {
		border: 1px solid rgba(0, 0, 0, 0.3);
	}

	.state-icon {
		display: none;
		line-height: 38px;
		font-size: 20px;
		display: none;

		&.state-toggled {
			&.state-idle {
				color: @wf_blue;
			}

			&.state-hover {
				color: @wf_red;
				line-height: 40px;
			}
		}

		&.state-untoggled {
			&.state-hover {
				color: rgba(0,0,0,0.15);
			}
		}
	}

	&.toggled {
		border: 1px solid rgba(0, 0, 0, 0.25);
	}

	&.toggled:not(:hover) {
		.state-icon {
			&.state-toggled:not(.state-hover) {
				display: block;
			}
		}
	}

	&.toggled:hover {
		.state-icon {
			&.state-toggled.state-hover {
				display: block;
			}
		}
	}

	&:hover:not(.toggled) {
		.state-icon {
			&.state-untoggled.state-hover {
				display: block;
			}
		}
	}

	&.saving {
		.spinner(20px);
	}
}
