wf-social-share{
	display: inline-block;
	.wfSocialShareController {
		.social-media-header {
			display: inline-block;
			.social-media-header-text {
				// color: white;
			}//end of: .social-media-header-text
		}//end of: .social-media-header

		.social-media {
			.square {
				width: 40px;
				height: 40px;
				display: inline-block;
				border-radius: 3px;
				text-align: center;
				margin: 2px;
				transition: box-shadow .2s ease-in; 

				i {
					color: white;
					font-size: 20px;
					padding: 10px;
				}

				&:hover{ 
					cursor: pointer; 
					box-shadow: 0 0 10px 1px rgba(255, 255, 255, 0.11) inset;
				}

				&.twitter{ background-color: #00B1EB; }
				&.facebook{ background-color: #2F589A; }
				&.google-plus{ background-color: #D51F29; }
				&.linkedin{ background-color: #0077B9; }

				&:active {
					animation: pulse .2s ease;
				}
			}//end of: .square
		}//end of: .social-media

		.share-link-header {
			display: inline-block;
			.share-link-header-text {
				// color: white;
			}//end of: .share-link-header-text
		}//end of: .share-link-header
		
		.share-link {
			.input-field.input-group {
				input.form-control {
					&:focus{
						border-color: #e0e0e0;
					}
				}

				span.ng-clipboard{
					padding: 8px 10px;
					i.icon { 
						font-size: 14px; 
						color: @wf_black;
					}
					
					span.text {
						font-weight: 500;
						display: inline;
					}
				}
				
				&.success {
					input.form-control {
						border-color: @wf_green_dark;
					}

					span.ng-clipboard {
						border-color: @wf_green_dark;

						.copy-button {
							animation: pulse .2s ease;
							span.text, i.icon {
								color: @wf_green_dark;
								font-size: 12px;
							}
						}
					}
				}

				&.error {
					input.form-control {
						border-color: @wf_red;
					}

					span.ng-clipboard {
						border-color: @wf_red;

						.copy-button {
							animation: pulse .2s ease;
							span.text, i.icon {
								color: @wf_red;
								font-size: 12px;
							}
						}
					}
				}
			}//end of: .input-group

			.text-only {
				display: inline-block;
				padding-top: 10px;
				i { margin-right: 5px; }

				&:hover{

				}

				&.success {
					&:hover { }
				}
				
				&.error {
					&:hover {}
				}
			}//end of: .text-only
		}//end of: .share-link
	}
}
