.embedly-previewer .thumb {
  float: left;
  margin: 0 10px 0 0;
  position: relative;
  width: 100px; }
  .embedly-previewer .thumb .items {
    position: relative;
    overflow: hidden;
    padding: 0px;
    height: 100px;
    width: 100px; }
    .embedly-previewer .thumb .items .images {
      position: absolute;
      width: 300em;
      margin: 0px;
      padding: 0px;
      left: 0px; }
      .embedly-previewer .thumb .items .images li {
        list-style: none;
        float: left;
        margin: 0px;
        padding: 0px;
        width: 100px;
        height: 100px; }
      .embedly-previewer .thumb .items .images img {
        max-width: 100px;
        max-height: 100px; }
  .embedly-previewer .thumb .controls {
    top: 0px;
    left: 0px;
    width: 100px;
    position: absolute;
    height: 20px;
    z-index: 1;}
    .embedly-previewer .thumb .controls .left, .embedly-previewer .thumb .controls .right, .embedly-previewer .thumb .controls .nothumb {
      display: block;
      position: relative;
      color: #eee;
      text-align: center;
      width: 20px;
      height: 100%;
      line-height: 20px;
      background-color:rgba(60,60,60,0.6);
      border-radius: 0px;
      box-shadow: 0 0 0 1px rgba(255,255,255,0.4);
      font-size: 17px;
      }
      .embedly-previewer .thumb .controls .left:hover, .embedly-previewer .thumb .controls .right:hover, .embedly-previewer .thumb .controls .nothumb:hover {
        text-decoration: none;
        color: #fff;
        background-color:rgba(50,50,50,0.8);
      }
    .embedly-previewer .thumb .controls .nothumb {
      float: right;
      padding-left: 1px;
       }
    .embedly-previewer .thumb .controls .left {
      float: left;
      margin-right: 1px;
      }
    .embedly-previewer .thumb .controls .right {
      float: left; }
  .embedly-previewer .thumb .nothumb {
    
     }

.embedly-previewer .selector {
  margin: 10px auto;
  position: relative;
  min-height: 85px; }
  .embedly-previewer .selector input {
    width: 420px; }
  .embedly-previewer .selector textarea {
    width: 420px;
    margin: 5px 0; }
  .embedly-previewer .selector .media iframe, .embedly-previewer .selector .media object, .embedly-previewer .selector .media embed, .embedly-previewer .selector .media img {
    margin: 10px auto;
    display: block; }
  .embedly-previewer .selector .media img {
    max-width: 500px; }
  .embedly-previewer .selector .attributes {
    float: none;
    clear: none;
    overflow: hidden;
  }
  .embedly-previewer .selector .attributes span {
    text-decoration: none; }
    .embedly-previewer .selector .attributes span.title:hover, .embedly-previewer .selector .attributes span.description:hover {
      text-decoration: none;
      background-color: #FFF6D9; }
    .embedly-previewer .selector .attributes span.description {
      color: #000; }
    .embedly-previewer .selector .attributes span.title {
      margin: 0 0 5px;
      display: block;
      font-weight: bold;
      color: #333;
      }
  .embedly-previewer .selector .attributes .meta {
    display: block; }
    .embedly-previewer .selector .attributes .meta .favicon {
      width: 16px;
      height: 16px;
      vertical-align: middle;
      margin: 0 3px 0 0; }
  .embedly-previewer .selector .action {
    position: absolute;
    top: 0;
    right: 0;
    display: none !important; }

.embedly-previewer .item {
  margin: 10px 0;
  border-top: 1px solid #dddddd;
  padding: 10px 0; }
  .embedly-previewer .item.video .media iframe, .embedly-previewer .item.video .media object, .embedly-previewer .item.video .media embed, .embedly-previewer .item.video .media img, .embedly-previewer .item.rich .media iframe, .embedly-previewer .item.rich .media object, .embedly-previewer .item.rich .media embed, .embedly-previewer .item.rich .media img, .embedly-previewer .item.photo .media iframe, .embedly-previewer .item.photo .media object, .embedly-previewer .item.photo .media embed, .embedly-previewer .item.photo .media img {
    margin: 10px auto;
    display: block; }
  .embedly-previewer .item.video .media img, .embedly-previewer .item.rich .media img, .embedly-previewer .item.photo .media img {
    max-width: 560px; }
  .embedly-previewer .item.video .title, .embedly-previewer .item.rich .title, .embedly-previewer .item.photo .title {
    margin: 10px 0;
    display: block;
    font-weight: bold;
    font-size: 1.1em;
    color: #333; }
  .embedly-previewer .item .status {
    line-height: 1.2em;
    margin: 10px 0;
    padding: 5px; }
  .embedly-previewer .item .embedly-previewer .thumbnail {
    margin: 10px 10px 10px 5px;
    width: 105px;
    float: left; }
    .embedly-previewer .item .embedly-previewer .thumbnail a {
      display: table-cell;
      width: 100px;
      height: 75px;
      overflow: hidden;
      background-color: #DDD;
      vertical-align: middle;
      position: relative;
      text-align: center; }
    .embedly-previewer .item .embedly-previewer .thumbnail img {
      max-width: 100px;
      vertical-align: middle;
      max-height: 75px; }
    .embedly-previewer .item .embedly-previewer .thumbnail.video .overlay, .embedly-previewer .item .embedly-previewer .thumbnail.rich .overlay {
      /*background-image: url(../images/play.png);*/
      width: 26px;
      height: 25px;
      position: absolute;
      right: 5px;
      bottom: 5px; }
  .embedly-previewer .item .attributes {
    background-color: #EFEFEF;
    padding: 5px; }
  .embedly-previewer .item .meta {
    display: block; }
    .embedly-previewer .item .meta .favicon {
      width: 16px;
      height: 16px;
      vertical-align: middle;
      margin: 0 3px 0 0; }

/*.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.clearfix {
  display: inline-block; }

html[xmlns] .clearfix {
  display: block; }

* html .clearfix {
  height: 1%; }*/
