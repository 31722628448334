@import "wf-variables.less";

.wfLocationController {
	.name-location{
		margin-bottom: 15px;
	}

	.address-location{
		margin-bottom: 15px;
	}
}//.end of: .wfLocationController

.pac-container {
	z-index: 10000; // Puts the Google Maps autocomplete search dropdown above Worldfavor's modals
}

.wfLocation, [wf-schema-location-search] {
	ng-map {
		height: 400px;
		position: relative;

		&::after {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12) inset;
			pointer-events: none;
		}
	}

	.form-group.has-error {
		.input-group.location-search {
			input, .input-group-addon {
				border-color:rgba(231, 76, 60, 0.6) !important;
				background-color: #fff !important;
			}
		}
	}

	.input-group.location-search {
		padding-bottom: 15px;

		.input-group-addon.icon {
			padding: 8px 4px 8px 14px;
			min-width: auto;
			border-right: 0;
			color: rgb(103, 116, 129);
			font-size: 14px;
		}

		input {
			border-left: 0;
			&::-ms-clear {display: none;}

			&.locationSpecified {
				border-right: 0;
			}
		}

		input, .input-group-addon {
			border-color: #e0e0e0 !important;
			background-color: #fff !important;
		}

		.clear {
			cursor: pointer;
			color: rgb(103, 116, 129);
			font-size: 16px;
			text-align: center;
			i {line-height: 18px;}
		}

	}
}

[wf-schema-location-single] {
	.removeLocation {
		font-size: 11px;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}
}

.map-infoWindow {
	padding: 3px 0;

	.header {
		line-height: 18px;
	}

	.subHeaderText {
		padding: 3px 0 3px;
		font-size: 11px;
		color: #888;

		> div + div {
			padding-top: 3px;
		}
	}
}

.popover.location-map-popover {
	width: 100%;
	min-width: 350px;
	top: 55px !important;

	.popover-content {
		padding: 2px !important;
	}
}