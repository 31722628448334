.print-mode {
	// ----------- HELPER CLASSES -------------

	.pull-none { overflow: hidden; clear: none; float: none; width: auto; }
	.pull-left { float: left !important; }
	.pull-right { float: right !important; }
	.m0 { margin: 0 !important; }

	// ----------------------------------------

	.panel {
		border: none !important;
		box-shadow: none !important;
	}

	.cover-page {
		position: relative;
		width: 100%;
		margin: 0 !important;
		padding: 0 !important;
		height: 1230px;
		background-color: hsla(210, 29%, 24%, 0.6);
		background: url("https://worldfavordata.blob.core.windows.net/misc/PDF_Export_Cover_Mountains_Edited.jpg") no-repeat center center;
		background-size: cover;
		border-radius: 3px;
		page-break-after: always;
	
		// &:before {
		// 	content: "";
		// 	top: 0;
		// 	left: 0;
		// 	bottom: 0;
		// 	right: 0;
		// 	position: absolute;
		// 	z-index: -1;   
		// 	background-color: #2b3d4e;
		// 	background: url("https://worldfavordata.blob.core.windows.net/misc/PDF_Export_Cover_Mountains_Edited.jpg") no-repeat center center;
		// 	background-size: cover;
		// 	border-radius: 3px;
		// }
		
		.text {
			position: absolute;
			top: 40%;
			left: 10%;
			right: 10%;

			.organization-name {
				color: white;
				font-size: 20px;
				font-weight: 300;
			}
	
			.title {
				color: white;
				font-size: 38px;
				font-weight: 600;
				margin: 30px 0 15px 0;
			}
			
			.description {
				color: white;
				font-size: 18px;
				font-weight: 300;
			}
		}
	
		.logo {
			position: absolute;
			bottom: 5%;
			right: 8%;
			width: 15%;
			height: 50px;
			background: url("https://worldfavordata.blob.core.windows.net/orgdocuments/o318st034009c2025137p459f903d9q46e7.svg") no-repeat center center;
			background-size: contain;
		}
	}//end of: .cover-page
	
	// ------------------------ default-style -------------------------

	.HierarchicalController {
		width: 100%;

		.hierarchical-item-wrapper {
			page-break-before: auto;
			page-break-after: auto;
		}

		wf-hierarchical-item { border: none !important; }
		.list-group { .list-group-item { border: none !important; } }

		.btn,
		.backButton,
		.guidance-btn, 
		.progressBars,
		.influence-signing,
		.transclude-interface-side,
		.measure-answer-add,
		.options,
		.side-linkages,
		.panel-filter { display: none !important; }

		img#chart-as-png { height: 100%; }

		.mainColumn {
			width: 100%;
			page-break-after: always;
			.list-group {
				.list-group-item {
					.mainItems {
						> div:first-child {
							> wf-hierarchical-item {
								.hier-content.depth1 {
									margin-top: 0 !important;
								}
							}
						}
					}
				}
			}
		}

		.sideColumn { 
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap; 
			align-content: flex-start;
			justify-content: flex-start;
			width: 100%;
			panel { max-width: 450px; }
		}

		wf-hierarchical-item {
			.hier-content {
				@depthTopMargins: 64px, 32px, 22px, 16px, 16px, 16px, 16px, 16px, 16px, 16px, 16px;
				@depthFontSizes: 32px, 24px, 18px, 18px, 16px, 16px, 16px, 16px, 16px, 16px, 16px;
				@depthFontStyle: normal, normal, normal, normal, italic, italic, italic, italic, italic, italic, italic;

				padding-left: 0px !important;
				&:hover { background-color: transparent; }
				.hier-item-content { max-width: 600px; }

				.generate-depth(11);
				.generate-depth(@n, @i: 1) when (@i =< @n) {
					&.depth@{i} {
						page-break-before: auto;
						page-break-after: auto;
						break-after: auto;
						break-before: auto;
						margin-top: extract(@depthTopMargins, @i) !important;
						padding: 0 !important;
						margin-bottom: 0 !important;

						&.user-data {
							margin: 15px 0px !important;
							padding: 15px !important;
							background-color: #f2f5f6;
							width: 700px;
							border-radius: 3px;

							.hier-item-content .wf-image { margin-right: 20px !important; }

							.wf-headerText {
								margin-top: 0 !important;
								font-size: 16px !important;
								font-weight: normal !important;
								font-style: normal !important;
								a { color: #2685ee; }

								.external-link {
									display: inline-block !important;
									color: #2685ee !important;
								}
							}

							.wf-subHeader {
								margin-top: 0;
								color: black;
								font-weight: normal;
							}

							.wf-bodyText {
								font-style: normal !important;
							}
						}

						&.answer-type {
							border-top: 1px solid #eaeaea;
							padding: 16px 16px 16px 0px !important;
							margin-bottom: 0 !important;
							margin-top: 16px !important;

							.wf-headerText, .wf-bodyText { 
								font-size: 14px;
								font-style: normal !important; 
							}
						}


						.wf-image {
							width: 50px;
							height: 50px;
							font-size: 35px;
							text-align: center;
							background-position: center center;
							background-size: cover;
						}

						.wf-headerText {
							padding: 0;
							font-weight: 500;
							font-size: extract(@depthFontSizes, @i);
							font-style: extract(@depthFontStyle, @i);
						}
						
						.wf-bodyText {
							padding: 0;
							margin: 16px 0; 
							font-size: 14px !important;
							white-space: pre-wrap !important;
						}
					}
					.generate-depth(@n, (@i + 1));
				}//end of: .generate-depth()

				wf-chart-line {
					.canvas-wrapper {
						text-align: right;
					}
				}

				wf-question-answering {
					font-size: 14px;
				}

				wf-measure-answering {
					.measure-answer {
						.trend {
							display: none;
						}

						.value {
							white-space: pre-wrap;
							width: 80px;
							font-weight: bold;
							.measure-answer-year {
								font-weight: normal;
								color: #aaa;
								display: block;
								font-size: 12px;
							}
						}
					}
				}

				wf-finding {
					.print-mode {
						height: initial !important;
						display: block !important;
						visibility: visible !important;
					}

					.item-creator-time {
						font-size: 11px;
						color: #95a5a6;
						transition: color .1s;
						display: inline-block;
					}

					.wf-creatorImage {
						width: 12px;
						height: 12px;
						display: inline-block;
						border-radius: 100%;
						background-size: cover;
						margin-bottom: -2px;
						margin-right: 1px;
					}
			
					.finding-details {
						padding: 5px 0;
				
						> div {
							display: inline-block;
							padding-right: 16px;
							font-size: 12px;
							padding-bottom: 6px;
				
							> span:first-child {
								display: inline-block;
								color: #95a5a6;
								padding-right: 2px;
							}
				
							span.severity-indicator {
								display: inline-block;
								width: 12px;
								height: 12px;
								border-radius: 100%;
								margin-right: 3px;
								box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12) inset;
								position: relative;
								top: 1px;
							}
				
							> span:last-child {
								display: inline-block;
								font-weight: 600;
								border-radius: 0px;
								color: rgba(0,0,0,0.6);
				
								&.framed {
									padding: 4px 7px;
									box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08) inset;
								}
							}
						}
				
						
						&.finding-action-and-progress {
							margin-top: 10px;
							.finding-action {
								display: block;
								padding-bottom: 8px;
				
								.action-label {
									margin-right: 3px;
								}
				
								&.denied {
									span.action-text {
										color: #e74c3c;
									}
								}
							}
				
							.finding-progress {
								width: 100%;
				
								.progress {
									height: 10px;
									margin-bottom: 0;
									.progress-bar {
										opacity: 0.8;
										&:hover {
											opacity: 1;
											cursor: pointer;
										}
				
										&.progress-bar-approved { background-color: lighten(#2ecc71, 10%); }
										&.progress-bar-denied { background-color: #e74c3c; }
										&.progress-bar-updated { background-color: #34495e; }
										&.progress-bar-needed { background-color: transparent; }
									}
								}
				
								.legend {
									.legend-item {
										display: inline-block;
										margin-right: 5px;
										&.approved span { color: #2ecc71; }
							
										&.needed span { }
							
										&.denied span { color: #e74c3c; }
							
										&.updated span { color: #34495e; }
									}
								}
							}
						}
					}
				
					.reporting-container {
						visibility: visible;

						margin-top: 10px;
						padding-top: 15px;
						border-top: 1px dashed #bdc3c7;
				
						.owner-target-container {
							height: initial !important;
							padding-bottom: 7px;
							
							.item-label {
								display: inline-block;
								color: #95a5a6;
								padding-right: 2px;
							}
				
							.unset {
								display: inline-block;
								
								span {
									display: inline-block;
									text-transform: uppercase;
									color: rgba(0,0,0,0.6); 
									font-weight: 600;
								}
							}
				
							.owner {
								display: inline-block;
								margin-right: 15px;
								padding-top: 9px;
				
								.wf-creatorName {
									display: inline-block;
									font-weight: 600;
									border-radius: 0px;
									color: rgba(0,0,0,0.6); 
									font-size: 12px;
								}
							}
				
							.target-completion-date {
								display: inline-block;
				
								.date {
									display: inline-block;
									span {
										display: inline-block;
										font-weight: 600;
										border-radius: 0px;
										color: rgba(0,0,0,0.6);
									}
								}
							}
						}
				
						.finding-steps-container {
							padding-top: 0px;
						}
					}
				
					.attached-information {
						padding-top: 10px;
						
						.attach-button {
							margin-left: 15px;
						}
					}
				
					.attached-info-items {
						// border: 1px solid #bdc3c7;
						// border-radius: 3px;
						// padding: 15px;
					
						.attached-info-item {
							display: inline-block;
							padding-right: 24px;
						}
					
						wf-item-component {
							&.small {
								font-size: 100%;
							}
					
							.headers {
								padding-top: 0 !important;
								margin-bottom: 5px !important;
							}
					
							.image {
								width: 15px;
								height: 20px;
								margin-right: 6px;
								margin-top: 3px;
					
								.wf-image {
									font-size: 14px;
								}
							}
					
							.wf-headerText {
								padding-top: 0;
								font-size: 11px;
					
								&, a {
									color: #555;
								}
					
								a .external-link {
									margin-left: 8px;
					
									i {
										font-size: 10px;
										margin-right: 4px;
									}
								}
							}
					
							.wf-bodyText {
								font-size: 11px;
								padding-top: 0 !important;
								color: #707980 !important;
							}
						}
					}

					wf-finding-step {
						display: block;
						padding: 10px 0;
					
						.step-wrapper {
					
							&.locked {
								.answer-items {
									.answer-item {
										pointer-events: none;
										opacity: 0.4;
									}
								}
					
								// * .btn, button {
								// 	cursor: not-allowed;
								// 	pointer-events: none;
								// 	opacity: 0.3;
								// 	box-shadow: none;
								// }
					
								.step-title {
									color: #95a5a6;
								}
					
								.empty-state-wrapper {
									padding: 15px;
									padding-left: 45px;
									border: dashed 1px #d9d9d9;
									border-radius: 3px;
									margin: 10px 0;
									position: relative;
									
									.step-empty-state {
										.icon {
											position: absolute;
											left: 16px;
											top: 8px;
											font-size: 20px;
											padding-top: 1px;
										}
									}
								}
							}
					
							.step-title {
								font-weight: 500;
								font-size: 14px;
								padding-bottom: 3px;
					
								.step-optional-status {
									font-size: 11px;
									font-weight: 400;
									padding-left: 9px;
									border-left: 1px solid #ddd;
									margin-left: 6px;
								}
							}
						
							.answer-items {
								position: relative;
					
								.answer-item {
									margin-left: 15px;
									margin: 10px 0;
							
									&:not(:first-child) {
										// border-top: 1px solid #bdc3c7;
									}
								}
					
								.btn.add-answer {
									margin-top: 7px;
									margin-bottom: 5px;
								}
						
								.step-empty-state {
									color: #95a5a6;
								}
							}
						}
					}
					
					wf-finding-step-answer {
						display: block;
					
						.answer {
							position: relative;
							min-height: 26px;
					
							border: 1px solid #ddd;
							padding: 15px;
							padding-left: 15px + 28px;
							border-radius: 4px;
					
							.answer-text {
								span { white-space: pre-wrap; }
							}
					
							.state-information {
								padding: 6px 0 8px;
								font-weight: 500;
							}
					
							.state-verification-wrapper {
								padding-left: 10px;
							}
					
							&.optional-answer-updated {
								border-color: #2ecc71;
					
								.state-verification-wrapper {
									padding-left: 0;
									margin-bottom: 5px;
								}
								.state-information {
									// background-color: fade(#34495e, 10%);
									// box-shadow: 0 0 0 1px fade(#34495e, 20%);
									color: #2ecc71;
								}
							}
					
							&.answer-updated {
								border-color: #34495e;
					
								.state-verification-wrapper {
									padding-left: 0;
									margin-bottom: 5px;
								}
								.state-information {
									// background-color: fade(#34495e, 10%);
									// box-shadow: 0 0 0 1px fade(#34495e, 20%);
									color: #34495e;
								}
							}
					
							&.answer-denied {
								border-color: #e74c3c;
					
								.state-verification-wrapper {
									border-left: 3px solid #e74c3c;
								}
								.state-information {
									// background-color: fade(#e74c3c, 10%);
									// box-shadow: 0 0 0 1px fade(#e74c3c, 20%);
									color: #e74c3c;
								}
							}
					
							&.answer-approved {
								border-color: #2ecc71;
					
								.state-verification-wrapper {
									border-left: 3px solid #2ecc71;
								}
								.state-information {
									// background-color: fade(#2ecc71, 10%);
									// box-shadow: 0 0 0 1px fade(#2ecc71, 20%);
									color: #2ecc71;
								}
							}
					
							> .icon {
								position: absolute;
								left: 12px;
								// top: -4px;
								font-size: 25px;
								color: #AFAFAF;
								
								i {
									display: block;
					
									&.fa-times {
										color: #e74c3c;
									}
					
									&.fa-check {
										font-size: 22px;
										color: #2ecc71;
										padding-top: 1px;
									}
					
									&.ion-loop {
										color: #34495e;
										font-size: 30px;
									}
								}
							}
						}
					
						.verification {
							margin-bottom: 15px;
					
							.verification-comment {
								padding-bottom: 3px;
					
								.comment-label {
									font-weight: 500;
								}
							}
						}
					
						.attached-info-items {
							paddin-top: 10px;
						}
					
					}
				}
			}
		}//end of: wf-hierarchical-item
	}//end of: .HierarchicalController

	// ----------------------------------------------------------------

	.page-heading {
		h1 { font-weight: 500; font-size: 32px; color: black; span { font-weight: 500; font-size: 32px; color: black; } }
		p { font-size: 14px; width: 600px; color: black; }
	}

	.verification-info {
		width: 400px;
		margin: 0;
		padding-bottom: 30px;
		page-break-inside: avoid;

		.row {
			margin: 0;
			padding: 5px 0;
		}

		.row > div {
			padding: 0px;

			&.fieldValue {
				font-size: 14px;
				padding-bottom: 1px;
				border-bottom: 1px solid rgb(0, 0, 0);
				min-height: 21px;
				position: relative;

				.subValue {
					display: block;
					font-size: 9px;
					position: absolute;
					bottom: 3px;
					right: 0;
				}
			}

			&.fieldLabel {
				padding-top: 2px;
				color: #666;
				font-size: 11px;
			}
		} 
	}

	.supplierDemandDocumentController {
		background-color: white;

		.localFulfillment, .page-heading { display: none; }
		.mainPanel { width: 100%; }
		.questionWrapper { &:first-child { page-break-before: avoid; } }
		.page-heading { padding-bottom: 0; }
		.objType71 { padding-left: 0 !important; }
		.createdAt { display: none; }
		.subQuestion { margin-left: -10px; }

		&.useNewLayout {
			.questionCategory {
				font-weight: 500;
				font-size: 17px;
				color: #444;
			}
	
			.number {
				font-size: 14px;
			}
		}

		.subListInBox-gray {
			background: transparent;
			padding: 0 !important;
			margin: 0 !important;
		}
		
		.panel, .panel-body {
			padding: 0;
			background: transparent !important;
		}

		.questionCategory {
			background-color: #3498db;
			padding: 5px 10px 4px;
			font-weight: bold;
			span { color: #fff; }
		}
		
		.list-group-item {
			border: none;
			border-top: 1px solid #e6e6e6;
			padding-left: 0;

			//WORKS ONLY WITH THESE RULES APPLIED !!! DON'T REMOVE
			border-left: 0 !important;
			border-right: 0 !important;
			border-radius: 0 !important;

			page-break-inside: avoid;

			&:first-child {
				border-top: 0;
			}
		}


		.reportedBy {
			display: none;
		}

		.row {
			page-break-inside: avoid;
			
			.number {
				font-size: 17px;
				width: 5%;
			}

			.answer {
				font-weight: bold;
				width: 20%;
				text-align: center;
			}
		}

		wf-item {
			.img { display: none; }

			.objType18 {
				a {
					i { display: none; }
					color: #3498db;
				}
			}
		}
	}

	//  ------------- ICON REPLACEMENT (BECAUSE OF CHINESE LETTERS SUPPORT) ------------------

	.fa-quote-right { .ionicons-icon('\f347'); }
	.fa-comment { .ionicons-icon('\f3fc'); }
	.fa-map-marker { .ionicons-icon('\f3a3'); }
	.fa-link { .ionicons-icon('\f1fe'); }
	.fa-play-circle { .ionicons-icon('\f215'); }
	.fa-file { .ionicons-icon('\f381'); }
	.fa-comments { .ionicons-icon('\f11f'); }
	.fa-calendar-check-o { .ionicons-icon('\f2d1'); }
	.fa-calendar { .ionicons-icon('\f117'); }
	.fa-user { .ionicons-icon('\f3a0'); }
	.fa-building { .ionicons-icon('\f38f') }
	.fa-industry { .ionicons-icon('\f344') }
	.fa-line-chart { .ionicons-icon('\f2b5') }
	.fa-certificate { .ionicons-icon('\f349') }
	.fa-sync-alt { .ionicons-icon('\f201') }

	.fa-file-pdf-o { .ionicons-icon('\f381') }
	.fa-file-text-o { .ionicons-icon('\f12e') }
	.fa-file-image-o { .ionicons-icon('\f147') }
	
	.fa-lock { .ionicons-icon('\f200') }
	.fa-info-circle { .ionicons-icon('\f149') }
	.fa-check { .ionicons-icon('\f122') }
	.fa-times { .ionicons-icon('\f12a') }
	.fa-external-link { .ionicons-icon('\f39c') }
	.wf-createdAt { .ionicons-icon('\f3b3') }

	.ionicons-icon(@content) {
		&:before {
			content: @content;
			display: inline-block;
			font-family: Ionicons;
			font-style: normal;
			font-weight: 400;
			font-variant: normal;
			text-transform: none;
			text-rendering: auto;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}
	}
}//end of: .print-mode