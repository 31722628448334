.wfPublicInfluenceViewer {
	.map-background {
		pointer-events: none;
		position: fixed;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 298px;
		// background-color: rgb(33,60,80);

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#213c50+1,143147+100&1+0,0+30,0+82,1+100 */
			// background: -moz-linear-gradient(top, rgba(33,60,80,1) 0%, rgba(33,60,80,0.97) 1%, rgba(29,57,77,0) 30%, rgba(22,51,73,0) 82%, rgba(20,49,71,1) 100%); /* FF3.6-15 */
			// background: -webkit-linear-gradient(top, rgba(33,60,80,1) 0%,rgba(33,60,80,0.97) 1%,rgba(29,57,77,0) 30%,rgba(22,51,73,0) 82%,rgba(20,49,71,1) 100%); /* Chrome10-25,Safari5.1-6 */
			// background: linear-gradient(to bottom, rgba(33,60,80,1) 0%,rgba(33,60,80,0.97) 1%,rgba(29,57,77,0) 30%,rgba(22,51,73,0) 82%,rgba(20,49,71,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#213c50', endColorstr='#143147',GradientType=0 ); /* IE6-9 */

			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#143147+0,143147+100&0+0,0+82,1+100 */
			background: -moz-linear-gradient(top, rgba(20,49,71,0) 0%, rgba(20,49,71,0) 86%, rgba(20,49,71,1) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, rgba(20,49,71,0) 0%,rgba(20,49,71,0) 86%,rgba(20,49,71,1) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, rgba(20,49,71,0) 0%,rgba(20,49,71,0) 86%,rgba(20,49,71,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00143147', endColorstr='#143147',GradientType=0 ); /* IE6-9 */
			z-index: 100;
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 48;
			right: 0;
			bottom: 0;
			left: 0;
			opacity: 1;
			background-color: rgb(20,49,71);
			z-index: 98;
		}

		wf-map {
			width: 100%;
			height: 100%;
			position: absolute;
			opacity: 0.1;
			top: 0px;
			left: 0px;
			z-index: 99;
		}
	}//end of: wf-map-image

	.header {
		// position: relative; //map background opacity
		z-index: 1002;
		padding-top: 20px;
		margin: -2px 0px;
		background-color: #213C50;
		box-shadow: -1000px 0 #213C50, 1000px 0 #213C50 !important;

		> .container-fluid {
			padding-left: 0px;
			padding-right: 0px;
		}

		.public-profiles {
			padding-bottom: 20px;
			float: right;
			span{
				font-size: 14px;
				color: #bdc3c7;

				&:hover{
					cursor: pointer;
					color: #ecf0f1;
					text-decoration: underline;
				}
			}
		}//end of: .public-profiles

		.organization {
			padding-bottom: 35px;
			min-height: 167px;
			// border: dashed @wf_green 1px;

			.logo {
				// border: dashed @wf_green 1px;
				position: relative;
				display: block;
				height: 0;
				padding: 0;
				overflow: hidden;
				padding-bottom: 100%;

				.wf-image {
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					height: 100%;
					width: 100%;
					// border: solid 1px white;
					box-shadow: 0 0 0px 1px rgba(255, 255, 255, 0.11) inset;
					border-radius: 2px;
				}
			}//end of: .logo

			.info{
				padding-left: 10px;
				h1, span{
					color: white;
				}
				.name{
					margin: 0px 0px 6px 0px;
					font-weight: 500;
					font-size: 32px;
					display: inline-block;
				}

				.company-details {
					.org-number {
						opacity: 0.5;
						font-size: 13px;
						color: #bdc3c7;

					}
					.number.wf-bodyText {
						font-size: 13px;
						color: #bdc3c7;
					}

					.website {
						margin-top: 10px;

						a.link{
							span {
								color: #bdc3c7;
								font-weight: 500;
								margin-right: 5px;
							}

							.icon {
								font-size: 14px;
								font-weight: 400;
								color: rgba(255, 255, 255, 0.44);
								position: relative;
								top: 1px;
							}

							&:hover {
								span {
									color: #ecf0f1;
									text-decoration: underline;
								}
								.icon {
									color: #ecf0f1;
								}
							}
						}
					}
				}
			}//end of: .organization-details

			.share-button {
				position: initial;
				float: right;
			}//end of: .share-button
		}//end of: .organization

		wf-social-share {
			float: right;
			.share-link {
				.text-only {
					float: right;
					text-align: right;
					i {
						color: #bdc3c7;
					}

					span { color: @wf_gray; }
				}

				&:hover{
					.text-only{
						cursor: pointer;
						i, span { color: @wf_gray_light; }
						span { text-decoration: underline; }
					}
				}

				&.success {
					.text-only {
						i, span { color: @wf_green; }
						&:hover {
							i, span { color: @wf_green !important; }
							span { text-decoration: none; }
						}
					}
				}

				&.error {
					.text-only {
						i, span { color: @wf_red; }
						&:hover {
							i, span { color: @wf_red !important;  }
							span { text-decoration: none; }
						}
					}
				}
			}

		}//end of: wf-social-share

		.share-button {
			z-index: 1000; //because of navbar
			position: absolute;
			top: -42px;
			right: 20px;

			.icon {
				font-size: 16px;
				color: #9399A0;
				padding: 0px 5px;
			}

			.text {
				font-size: 16px;
				color: #9399A0;
				font-weight: 300;
			}
		}//end of: .share-button
	}//end of: .header

	.social-modal {
		overflow: hidden !important;
		.modal-body {
			padding: 15px;
			.modal-header {
				padding: 0 0 20px 0;
				border-bottom: none;

				.icon {
					display: inline-block;
					margin-right: 5px;

					i{
						font-size: 20px;
						color: @wf_black;
						display: block;
						margin: 0 auto;
						height: 20px;
						width: 20px;
					}
				}
				.title {
					vertical-align: top;

					h3 {
						display: inline-block;
						margin: -5px 5px;
						font-size: 20px;
					}
				}

				.close-icon {
					margin-top: -5px !important;
					font-size: 35px;
				}
			}//end of: .modal-header

			wf-social-share {
				.social-media-header {
					.social-media-header-text {
						font-size: 14px;
    					font-weight: 300;
						display: block;
						margin-left: 2px;
						margin-bottom: 5px;
						color: @wf_gray_dark;
					}
				}//end of: .social-media-header

				.social-media {
					padding-bottom: 20px;
					float: none;

					.square {
						width: 50px;
						height: 50px;

						i {
							font-size: 25px;
							padding: 13px 0px;
						}
					}
				}//end of: .social-media

				.share-link-header {
					.share-link-header-text {
						display: block;
						font-size: 14px;
    					font-weight: 300;
						margin-left: 2px;
						margin-bottom: 5px;
						color: @wf_gray_dark;
					}
				}//end of: .share-link-header

				.share-link {
					padding-bottom: 10px;
					.input-field.input-group {
						input.form-control {
							height: 50px;
							background-color: transparent;
						}

						span.ng-clipboard{
							min-width: 50px;
							i.icon {
								font-size: 18px;
								color: @wf_black;
							}
						}
					}
				}//end of: .share-link
			}//end of: wf-social-share
		}//end of: .modal-body

		.modal-footer {
			border: none;
		}
	}//end of: .social-modal

	.hierarchicalPublicLayout {
		.body{
			> .container-fluid {
				padding-left: 10px;
				padding-right: 10px;
			}

			.sticky-navbar {
				height: auto !important;
				width: 100% !important;
				max-width: 1300px;

				.slide-animation {
					overflow: hidden;
					clear: both;
					&.depth-1 {
						background-color: #143147;
						box-shadow: -1000px 0 #143147, 1000px 0 #143147 !important;

						.navbar { //this part is for arrow animation in  each depth
							.navbar-header {
								&:not(.collapsed) {
									button.navbar-toggle {
										&.rotate {
											i {
												transition: .3s;
												transform: rotate(180deg);
											}
										}
									}
								}
							}
						}//end of: .navbar
					}//end of: &.depth-1

					.generate-columns(4);
					.generate-columns(@n, @i: 2) when (@i =< @n) {
						&.depth-@{i} {
							background-color: lighten(#ced3d6, (3% * @i));
							box-shadow: -1000px 0 lighten(#ced3d6, (3% * @i)), 1000px 0 lighten(#ced3d6, (3% * @i)) !important;
							z-index: 999;
							position: relative;

							.filters {
								.filter {
									// background-color: #D4D7DA;
									.text{
										color: @wf_black;
									}

									&:hover {
										background-color: rgba(20, 49, 71, 0.05);
									}

									&.selected {
										background-color: rgba(20, 49, 71, 0.15);
										text-shadow: 0 0 7px rgba(255, 255, 255, 0.5);
									}

									.text-side {
										color: @wf_gray;
									}
								}
							}

							.navbar {
								.navbar-header {
									&:not(.collapsed) {
										button.navbar-toggle {
											&.rotate {
												i {
													transition: .3s;
													transform: rotate(180deg);
												}
											}
										}
									}
								}
							}
						}
						.generate-columns(@n, (@i + 1));
					}

					.navbar{
						background-color: transparent;
						margin: -5px 0;

						.navbar-header{
							text-align: center;
							display: block !important;

							button.navbar-toggle {
								position: absolute;
								right: 5px;
								padding: 10px;
								float: right;
								border: none;
								background-color: transparent !important;
								margin-right: 0px !important;

								i{ transition: .3s; }
								> i:before{
									top: 0px;
								}

							}
						}
					}//end of: .navbar

				}//end of: .slide-animation
				.sticky-navbar-bottom-fade {
					box-shadow: 0px 0px 50px 3px black;
				}
			}//end of: .sticky-navbar

			.levelFunnels{
				box-shadow: none;
				border: none;
				padding: 0;

				.filters {
					padding: 10px 0px;
					margin: 0;
					float: left;

					> li { float: left; }

					.filter {
						padding: 4px 10px;
						margin: 5px 10px 5px 0px;
						border-radius: 3px;
						z-index: 1001;
						transition: background-color .1s;
						max-width: 400px;

						.text {
							color: white;
							font-size: 14px;
							font-weight: 300;
						}

						&:hover {
							background-color: rgba(82, 105, 125, 0.29);
						}

						&.selected {
							background-color: rgba(206, 216, 224, 0.27);
						}

						.icon {
							font-size: 14px;
							margin-right: 5px;
						}

						&:hover {
							cursor: pointer;
						}

						&:first-child{
							i.icon { display: none; }
						}

						&:last-child {
							border-right: none;
						}
					}//end of: .filter
				}//end of: .filters

				.more-info{
					text-align: right;
					span{
						color: @wf_gray_dark;
						font-size: 14px;
					}

					&:hover{
						cursor: pointer;
					}
				}//end of: .more-info
			}//end of: .levelFunnels
		}//end of: .body

		.main-content {
			position: relative;
			padding: 40px 0 50px 0;
			background-color: #F5F5F6;
			box-shadow: -1000px 0 #F5F5F6, 1000px 0 #F5F5F6 !important;

			.panel {
				box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.12), 0 -1px 5px 0 rgba(0, 0, 0, 0.03) !important;
				border-radius: 3px;

				.panel-body {
					padding: 30px;
					.cascade {
						border-bottom: solid .5px #eee;

						&.type-71.depth-2 {
							wf-item-component {
								.headers {
									.wf-headerText {
										font-size: 21px;
										line-height: 21px;
									}
								}
							}
						}

						wf-item-component {
							.borders {
								border-width: 1px;
							}

							.content {
								.headers {
									margin-bottom: 0;
									.wf-headerText {
										padding-top: 0;
									}

									.guidance-btn {
										display: inline-block;
										color: @wf_gray;

										&:hover {
											color: @wf_green;
										}
									}

									.header-icon i {
										width: 21px;
										text-align: center;
									}
								}

							}

							.side-actions {
								.object-viewer-answering {
									.measure-answer {
										.value {
											color: black;
											.measure-answer-year{
												display: inline;
												font-size: 12px;
											}
										}
										.trend {
											display: none;
										}
									}
								}
							}

							.transclude-side {
								.side-icon {
									display: none;
								}

								.side-text {
									color: @wf_gray;
									padding-top: 4px;
								}

								.chartLine {
									width: 200px;
									display: block;
									visibility: visible;
								}
							}

							.transclude-bottom {
								display: none;
								visibility: hidden;
							}
						}

						&.depth-1 { //category
							wf-item-component {
								padding: 30px 0 20px 0px;

								border-bottom: solid 2px black;
								.headers {
									.wf-headerText {
										font-size: 24px;
										line-height: 24px;
										font-weight: 600;
										padding-bottom: 0;
									}
								}
								.transclude-side {
									.side-text {
										padding-top: 6px;
									}
								}
							}
						}

						&.depth-2 { //sub-category
							wf-item-component {
								padding: 20px 0px;

								.headers {
									.wf-headerText {
										font-weight: 500;
										padding-bottom: 0;
									}

									.header-icon i {
										font-size: 18px;
										color: @wf_gray;
									}
								}

								.transclude-side {
									.side-text {
										padding-top: 4px;
									}
								}
							}

							&:hover {
								cursor: pointer;
								background-color: @wf_gray_lighter !important;

								// wf-hierarchical-item {
								// 	>div.toggle-children {
								// 		>i.icon {
								// 			transform: scale(1.3);
								// 		}
								// 	}
								// }
							}
						}

						&.depth-3 {
							border-bottom: solid .5px #eee;

							wf-item-component {
								padding-right: 0px;

								.headers {
									margin-bottom: 0;
									.wf-headerText {
										font-size: 17px;
										line-height: 17px;
										font-weight: 500;
										padding-bottom: 0;
									}
								}
							}
						}

						&.depth-4 { //user-data
							wf-item-component {
								&.type-structure{
									padding-right: 0;

									.headers {
										.wf-headerText {
											font-weight: 400;
										}
									}
								}

								.wf-image {
									font-size: 30px;
								}
							}

						}

						&.depth-5 { //user-data
							wf-item-component {
								.wf-image {
									font-size: 30px;
								}
							}
						}

						wf-hierarchical-item {
							>.toggle-children {
								position: absolute;
								i.icon {
									position: relative;
									top: 25px;
									left: 7px;
									transition: 0.3s;
									color: @wf_blue;
									font-size: 14px;
								}

								&.collapsed {
									i.icon {
										transition: 0.3s;
										transform: rotate(-90deg);
									}
								}

								&:hover {
									cursor: pointer;
									span.text {
										text-decoration: underline;
									}
								}
							}
						}
					}//end of: .cascade

					// .search-bar {
					// 	.input-group-addon{
					// 		&.icon, &.search-count {
					// 			&.search-active {
					// 				border-color: #2685EE;
					// 				i{ color: #2685EE;}
					// 			}

					// 			&.no-results {
					// 				border-color: @wf_red;
					// 				i{ color: @wf_red;}
					// 			}
					// 		}

					// 		&.cancel {
					// 			&.search-active {
					// 				border-color: #2685EE;
					// 			}
					// 			&.no-results {
					// 				border-color: @wf_red;
					// 			}
					// 		}
					// 	}

					// 	.form-control{
					// 		&.search-active{
					// 			border-color: #2685EE;
					// 		}

					// 		&.no-results{
					// 			border-color: @wf_red;
					// 			color: @wf_red;
					// 		}
					// 	}
					// }//end of: .search-bar

					wf-empty-state {
						.emptyList-message {
							ng-transclude {
								display: block;
								.icon {
									font-size: 65px;
									margin-bottom: 25px;
								}

								.text {
									font-size: 14px;
									font-weight: 500;
								}

								.description {
									font-size: 12px;
									color: #868c90;
								}
							}
						}
					}//end of: wf-empty-state
				}//end of: .panel-body
			}//end of: .panel

			.footer {
				background-color: #F5F5F6;
				padding: 0 20px 20px 20px;

				.two-column {
					padding: 20px 0;
					border-bottom: .5px solid @wf_gray;
					&:last-child {
						border: none;
					}

					.learn-more-link {
						display: block;
						padding-top: 3px;
						text-decoration: none;

						span {
							color: @wf_blue;
							&:hover {
								cursor: pointer;
								text-decoration: underline;
							}
						}

					}

					.wf-image{
						float: right;
						img {
							width: 100%;
							height: auto !important;
							min-height: 36.6px;
						}
					}

					.certificate {
						.gri-image {
							float: right;
							img {
								width: 100%;
								height: auto;
							}
						}
					}
				}//end of: .two-column
			}//end of .footer
		}//end of: .main-content
	}//end of: .hierarchicalPublicLayout

	@media screen and (min-width: 0px) and (max-width: @screen-xs-max) { // from 0px to 768px
		.map-background { display: none; }

		.header {
			padding: 0;

			.container-fluid {
				.row {
					margin-left: 0;
					margin-right: 0;
				}
			}

			.share-button:active{
				animation: pulse .2s ease;
				i, span {
					color: white;
				}
			}
		}//end of: .header

		.social-modal {
			.modal-content {
				padding: 20px !important;
				.modal-body {
					wf-social-share {
						width: 100%;
					}
				}
			}
		}//end of: .social-modal

		.responsive-logo {
			width: 20%;
			margin: 0 auto;
			margin-top: 5px;
			float: none;
		}//end of: .responsive-logo

		.info {
			text-align: center;
			padding: 0px !important;

			.name {
				padding-top: 15px;
				margin: 0;
			}

			.company-details {
				span{
					font-size: 12px !important;
					color: #bdc3c7 !important;
				}

				.website {
					display: block;

					a {
						span {
							margin-right: 0 !important;
							font-weight: normal !important;
						}
						i { display: none; }
					}
				}
			}
		}

		.hierarchicalPublicLayout {
			.body {
				.main-content {
					background-color: white !important;
					padding: 0px;

					.row {
						margin: 0px;
					}

					.panel {
						box-shadow: none !important;
						margin-bottom: 50px;
						.panel-body {
							padding: 25px 15px 0px 15px;
							.cascade {

								.toggle-children i.icon {
									display: none;
								}

								&.depth-1, &.depth-2 {
									wf-item-component {
										padding-left: 0px !important;
										padding-right: 0px;
									}
								}

								wf-item-component {
									padding-left: 30px !important;
									.transclude-side {
										margin-left: 20px;
										.side-icon {
											display: inline-block;
											a.fa, i.fa {
												position: relative;
												text-decoration: none;
												margin-top: 2px;
												font-size: 20px;
												color: @wf_black;
												transition: 0.3s;

												&.expanded {
													transition: 0.3s;
													transform: rotate(180deg);
												}
											}

											&:active {
												a.fa, i.fa {
													// animation: pulse .2s ease;
												}
											}
										}

										.chartLine {
											display: none;
											visibility: hidden;
										}
									}

									.transclude-footer {
										display: initial;
										visibility: visible;
										transclude-footer {
											display: block;
											clear: both;

											.chartLine {
												height: 80px;
												.chart-wrapper{
													wf-chart-line {
														.canvas-wrapper {
															position: relative;
															margin: auto;
														}
													}
												}
											}
										}
									}

									.image {
										width: 40px !important;
										height: 40px !important;
									}
								}//end of: wf-item-component

								&.chartLine {
									clear: both;
									width: 100%;
									max-width: 350px;
									margin: 0 auto;
									margin-top: 25px;
								}

								&.touch-pulse {
									animation: pulse-gray .3s ease;
								}
							}//end of: .cascade
						}//end of: .panel-body
					}//end of: .panel

					.footer {
						padding-bottom: 100px;
						.two-column {
							.wf-image{
								img {
									width: 70%;
									float: right;
									height: auto !important;
								}
							}
						}//end of: .two-column
					}//end of .footer
				}//end of: .main-content

				.footer {
					padding-top: 20px;
				}//end of: .footer
			}//end of: .body
		}//end of: .hierarchicalPublicLayout
	}
	@media screen and (min-width: @screen-md-min) { //from 992px
		.hierarchicalPublicLayout {
			.body {
				.selected-filter {
					display: none;
				}
				.levelFunnels {
					height: auto !important;
				}
			}
		}
	}
	@media screen and (min-width: 0px) and (max-width: 450px) {
		.hierarchicalPublicLayout {
			.body {
				.main-content {
					.panel {
						.panel-body {
							wf-item-component {
								.image {
									display: none;
								}

								.side-actions {
									.object-viewer-answering {
										.measure-answer {
											.trend {
												display: none;
											}
										}
									}
								}
							}
						}
					}
					.footer {
						padding-bottom: 100px;
						.two-column {
							.wf-image{
								img {
									width: 100% !important;
								}
							}
						}//end of: .two-column
					}//end of .footer
				}
			}
		}

	}
	@media screen and (min-width: 0px) and (max-width: @screen-sm-max) { //from 0 to 992px
		.hierarchicalPublicLayout {
			.body {
				.depth-1 {
					.filters {
						padding: 0px 30px;
						.filter {
							max-width: 100%;
							// border-bottom: .5px solid #344358;
							.text-side {
								color: #586777;
							}
						}
					}
				}

				.generate-columns(4);
				.generate-columns(@n, @i: 2) when (@i =< @n) {
					.depth-@{i} {
						.selected-filter {
							.text {
								color: @wf_black !important;
							}
						}

						.filters {
							padding: 0px 30px 30px 30px !important;
							.filter {
								background-color: transparent !important;

								&.selected {
									background-color: @wf_gray !important;
								}
							}
						}
					}
					.generate-columns(@n, (@i + 1));
				}

				.levelFunnels {
					max-height: initial;

					.filters {
						float: none;
						> li { float: none; }
						.filter {
							border-right: none;
							padding: 10px;
							margin: 5px 0;
							background-color: transparent;
							max-width: 100%;

							&.selected {
								background-color: #2E485F;
							}

							.text {
								font-size: 14px;
							}

							.text-side {
								float: right;
								font-size: 14px;
							}

							.icon {
								margin-right: 15px;
							}
						}//end of: .filter
					}
				}//end of: .levelFunnels

				.navbar {
					.navbar-header {
						min-height: 50px;
						.selected-filter {
							display: inline-block;
							padding: 10px 10px;
							margin: 8px 0;

							.text {
								font-size: 14px;
								color: white;
							}

							.text-side {
								float: right;
								font-size: 14px;
							}

							.icon {
								font-size: 14px;
								margin-right: 15px;
							}
						}
					}
				}//end of: .navbar

				.main-content {
					.footer {
						padding-bottom: 100px;
						.two-column {
							.wf-image{
								img {
									width: 70%;
									float: right;
									height: auto !important;
									min-height: 36.6px;
								}
							}
						}//end of: .two-column
					}//end of .footer
				}//end of: .main-content
			}//end of: .body
		}//end of: .hierarchicalPublicLayout
	}
}//end of: .wfPublicInfluenceViewer

@media screen and (min-width: @screen-sm-min) and (max-width: @screen-sm-max) { //tablet devices - from 768px to 992px
	body.layout-public {
		.wfPublicInfluenceViewer {
			.header {
				padding-top: 20px;
				>.container-fluid {
					width: @container-sm;
				}
			}

			.map-background {
				display: none;
			}//end of: wf-map-image
		}
		.hierarchicalPublicLayout {
			.body {
				.main-content {
					>.container-fluid {
						width: @container-sm;
					}
				}

				.sticky-navbar {
					.slide-animation {
						&.depth-1 {
							.navbar {
								background-color: #143147;
								box-shadow: -1000px 0 #143147, 1000px 0 #143147 !important;
							}
						}

						.generate-columns(4);
						.generate-columns(@n, @i: 2) when (@i =< @n) {
							&.depth-@{i} {
								.navbar {
									background-color: lighten(#ced3d6, (3% * @i));
									box-shadow: -1000px 0 lighten(#ced3d6, (3% * @i)), 1000px 0 lighten(#ced3d6, (3% * @i)) !important;

								}
							}
							.generate-columns(@n, (@i + 1));
						}
					}
					.navbar {
						.levelFunnels {
							&.container-fluid {
								width: @container-sm;
							}
						}
					}
				}//end of: .sticky-navbar
			}//end of: .body
		}//end of: .hierarchicalPublicLayout
	}
}

@media screen and (min-width: @screen-md-min) and (max-width: @screen-md-max) { //medium devices
	body.layout-public {
		.wfPublicInfluenceViewer {
			.header {
				>.container-fluid {
					width: @container-md;
				}
			}
		}
		.hierarchicalPublicLayout {
			.body {
				.main-content {
					>.container-fluid {
						width: @container-md;
					}
				}

				.sticky-navbar {
					.navbar {
						.levelFunnels {
							&.container-fluid {
								width: @container-md;
							}
						}
					}
				}
			}
		}
	}
}
