@import "wf-variables.less";
@import "wf-mixins.less";

wf-analyze-requirements {
	display: block;
	font-size: 11px;
	border-left: 3px solid #eee;
	padding-left: 10px;

	> div {
		min-height: 56px;
	}
}

.analyze-requirements-small-list {
	max-height: 300px;
	overflow: hidden;
	overflow-y: auto;

	.requirementItem {
		padding: 4px 0 3px;
		margin-right: 10px;

		&:not(:last-child) {
			border-bottom: 1px solid #eee;
		}

		.requirementText {
			word-break: break-word;
		}

		.points {
			float: right;
			font-weight: 600;

			.icon-useObjectDataAsPoints {
				padding-right: 5px;
				color: #999;
			}
			// background: #122b4480;
			// color: white;
			// padding: 1px 4px;
			// position: relative;
			// margin-top: -1px;
			// border-radius: 4px;
		}

		.icon-status {
			display: block;
			float: right;

		}

		&.status-fulfilled {
			.icon-status {
				color: @wf_green;
			}
		}

		&.status-notFulfilled {
			> div {
				opacity: 0.6;
			}

			.icon-status {
				display: none;
				// color: @wf_red;
			}
		}
	}
}

wf-analyze-item-result {
	display: inline-block;
	min-width: 100px;
	cursor: pointer;

	.HierarchicalController & {
		margin-left: 20px;
	}

	.score {
		display: inline-block;
		font-weight: normal;
		font-size: 14px;
		padding: 3px 7px;
		border-radius: 5px;
		border: 0;
		box-shadow: none;

		.newTotal {
			font-weight: 600;
		}

		.delta {
			font-weight: 400;
			margin-left: 2px;
			color: #CEE6F6;

			.parentheses {
				font-size: 12px;
				position: relative;
				top: -1px;
			}
		}
	}

	&:hover {
		.info {
			color: @wf_blue_dark;
		}
	}

	.info {
		text-align: right;
		font-size: 14px;
		color: @wf_blue;
		position: relative;
		top: 2px;
		padding: 0 4px 0 8px;
	}
}

wf-analyze-total-result {
	display: block;

	// .score {
	// 	font-weight: bold;
	// 	font-size: 20px;
	// }

	.panel-stats-count {
		.number {
			color: @wf_blue;
		}
	}

	.top {
		padding-bottom: 10px;
		margin-bottom: 10px;
		border-bottom: 1px solid @wf_gray_light;
	}

	.btn.calculate {
		float: right;

		i {
			color: @wf_blue;
		}
	}

	.results {
		max-height: 400px;
		overflow-y: auto;

		.previousResults-header {
			padding-bottom: 3px;
			margin-bottom: 10px;
			margin-top: 15px;
			border-bottom: 1px solid @wf_gray_light;
			color: @wf_gray_dark;
		}

		.resultItem {
			padding: 5px 0;
			padding-right: 10px;

			&.latest {
				.resultLabel {
					background: @wf_black_dark;
				}

				.score .value {
					font-size: 15px;
					position: relative;
					top: 1px;
					line-height: 15px;
					padding-left: 1px;
					color: @wf_black_dark;
				}
			}

			.calculatedAt {
				color: @wf_gray;
				padding: 3px 2px 3px 0;
			}

			.score {
				padding: 3px 10px;

				.value {
					font-weight: bold;
				}
			}

			.resultLabel {
				display: inline-block;
				background: @wf_gray_dark;
				border-radius: 4px;
				padding: 3px 6px;
				color: #fff;
				font-size: 11px;
				margin-top: 1px;
			}
		}
	}
}

.popover.wf-analyze-item-result-popover {
	.popoverBoxShadow();

	width: 350px;
	max-width: 350px;
}

wf-analyze-requirements-editor {
	.modal-header {
		margin-bottom: 25px;

		.item-preview {
			font-size: 13px;
		}
	}

	.columnHeaders {
		.column-header {
			cursor: default;
			color: #333;

			span {
				display: inline-block;
			}

			&:hover {
				.fa-info-circle {
					color: #a4abb3;
				}
			}

			.fa-info-circle {
				color: #c7ced6;
				margin-left: 5px;
				font-size: 14px;
			}
		}
	}

	.wf-analyze-requirements-editor-calcStep, .as-sortable-placeholder {
		border-bottom: 1px solid transparent;

		&:not(:last-child) {
			border-bottom-color: @wf_gray_light;
		}
	}

	.readonly {
		> * {
			pointer-events: none;
		}

		cursor: not-allowed !important;
	}

	.as-sortable-placeholder {
		box-shadow: 0 10px white inset, 0 -10px white inset;
		background-color: @wf_gray_lighter;
	}

	.ui-select-container .ui-select-match-text {
		padding-right: 12px;
	}


	.matchesRequirement {
		.dropdown-menu {
			width: 370px;
		}
	}

	.resultTask {
		.dropdown-menu {
			width: 230px;
		}

		.labelText {
			transition: width .2s;

			&:focus {
				width: 241%;
			}
		}
	}
}

.wf-analyze-requirements-editor-calcStep { // Standalone because when dragging, the item is outside the wf-analyze-requirements-editor component
	padding: 15px 0;

	.reorder {
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;

		&:hover {
			background-color: @wf_gray_lighter;
			border-radius: 3px;
		}

		&.as-sortable-item-handle {

		}

		.icon-wrapper {
			width: 100%;
			text-align: center;
			cursor: grab;

			i {
				cursor: grab;
			}
		}
	}

	.as-sortable-dragging & {
		padding: 15px;
		background: white;
		box-shadow: 0 1px 5px rgba(0, 0, 0, 0.23);
		border-radius: 3px;
	}

	.control-label {
		margin-bottom: 5px;
	}

	.input-group {
		width: 100%;
		min-height: 36px;

		.ui-select-container {
			position: absolute;
			width: 100%;
		}

		&.checkbox-useObjectDataAsPoints, &.textbox-points {
			width: auto;
		}
	}

	.btn.remove {
		color: @wf_red;
	}



	// .btn.reorder {
	// 	position: relative;

	// 	.icon-reorder {
	// 		display: inline-block;
	// 		position: relative;
	// 		width: 18px;
	// 		height: 4px;
	// 		border: 1px solid #707980;
	// 		border-width: 1px 0;
	// 		top: -2px;

	// 		&:before {
	// 			content: "";
	// 			display: block;
	// 			position: absolute;
	// 			top: -5px;
	// 			left: 5px;
	// 			width: 0;
	// 			height: 0;
	// 			border-style: solid;
	// 			border-width: 0 4px 4px 4px;
	// 			border-color: transparent transparent #707980 transparent;
	// 		}

	// 		&:after {
	// 			content: "";
	// 			display: block;
	// 			position: absolute;
	// 			bottom: -5px;
	// 			left: 5px;
	// 			width: 0;
	// 			height: 0;
	// 			border-style: solid;
	// 			border-width: 4px 4px 0 4px;
	// 			border-color: #707980 transparent transparent transparent;
	// 		}
	// 	}
	// }
}

wf-analyze-job {
	display: block;

	.modal-dialog & {
		wf-hierarchical {
			.backButton {
				display: none;
			}
		}
	}
}

wf-analyze-result-details {
	display: block;

	.calcDateInfo {
		color: @wf_gray_dark;
		padding-bottom: 10px;
	}
}
