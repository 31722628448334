@import "wf-variables.less";

.HierarchicalController {
	wf-item-tools.admin {
		margin-left: -20px !important;
	}

	.packageSettings {
		padding-bottom: 30px;

		&.admin {
			overflow: hidden;
			background: rgba(0, 0, 0, 0.03);
			padding: 18px;
			border-radius: 5px;
			margin-bottom: 35px;
		}

		button {
			text-align: left;
			max-width: 215px;
			display: flex;

			> span:first-child {
				white-space: normal;
			}
		}

		>div {
			float: left;
			width: 20%;
			padding: 2px 20px 2px 0;
			margin-bottom: 20px;

			&:not(:first-child) {
				border-left: 1px solid @wf_gray_semilight;
				padding-left: 20px;
			}
		}

		.editIcon {
			padding-left: 10px;
			font-size: 13px;
			position: relative;
			top: 1px;
		}

		.requirement-display {
			.localFulfillment {
				display: none;
			}

			.pl10 {
				padding-left: 0 !important;
			}
		}
	}

	.mainItems {
		> div {
			&:first-child {
				.hier-content {
					padding-top: 15px;
				}
			}
		}
	}

	&.horizontal {
		.mainColumn {
			z-index: 100;
			left: -214px;
			right: -214px;
			width: auto;
		}

		.hier-children {
			display: block;
			float: left;

			.hier-content {
				float: left;
				display: block;
				width: 257px;
				padding-left: 0 !important;
				border: 0;
				border-top: 2px solid #757575;
				margin-left: 10px;
			}

			.hier-child {
				display: block;
			}

			.tagging {
				display: none;
			}
		}

		.answering {
			display: none;
		}
	}

	wf-hierarchical-item {
		position: relative;
		display: inline-block;
		width: 100%;
		border-bottom: 1px solid #eee;

		wf-dropdown.hier-tools {
			visibility: hidden;
		}

		wf-item .body .img {
			.bgImage {
				margin-top: 0 !important;
			}
		}

		[wf-expand] {
			&:hover {
				background-color: @wf_gray_hover;
			}

			.icon-close {
				display: none;
				position: absolute;
				top: 8px;
				right: 10px;
				padding: 10px;
				z-index: 10;
				text-shadow: none;
				color: white;
				opacity: 0.5;
				font-size: 18px;
				cursor: pointer;

				&:hover {
					opacity: 0.8;
				}
			}

			&.expanded {
				.icon-close {
					display: block;
				}

				.expandButton {
					display: none;
				}

				> div {
					margin: 15px;


					.hier-content {
						background-color: #3B7FB8 !important;

						.answering, .tagging, wf-dropdown {
							visibility: hidden !important;
						}

						.hier-item-content {
							.wf-bodyText, .wf-headerText, .hier-requirement {
								color: #fff !important;
								// padding-right: 50px !important;
							}

							.hier-requirement {
								display: none;
							}
						}

						border-top-left-radius: 3px;
						border-top-right-radius: 3px;

						wf-item-statistics {
							opacity: 0;
						}

						wf-object-metadata {
							.metadata {
								* {
									color: rgba(255,255,255,0.6);
								}

								&:hover {
									background-color: rgba(0, 0, 0, 0.14);
									* {
										color: rgba(255,255,255,0.9);
									}
								}

							}

							.objectViewer {
								* {
									color: rgba(255,255,255,0.4);

								}
							}
						}
					}

					.item-visualization {
						opacity: 1;
					}

					.borders-wrapper {
						border-color: #eee;
					}
				}
			}

			> div {
				position: relative;
				transition: margin .3s;
				border-radius: 3px;

				.hier-content {
					transition: background-color .15s;
					padding-left: 20px !important;
					background: transparent !important;

					wf-item-statistics {
						opacity: 1;
						display: block;
						transition: opacity .15s;
						max-width: 425px;
					}
				}

				.item-visualization {
					background-color: #4897DB;
					transition: opacity .15s;
					opacity: 0;
					// height: 200px;
				}

				.borders-wrapper {
					padding: 10px 25px 20px;
					transition: border-color .15s;
					border: 1px solid transparent;
					border-top: 0;

					border-bottom-left-radius: 3px;
					border-bottom-right-radius: 3px;
					background-color: #fff;
				}
			}

		}

		.hier-content{
			padding: 10px 20px;
			background-color: transparent;
			display: inline-block;
			width: 100%;
			// position: relative; // Popovers append the the element doesn't work with position:relative
			// transition: background-color .02s ease-out;

			.wf-preHeader{
				font-size: 11px;
				font-weight: 500;
				color: #aaa;
			}

			.wf-subHeader{
				font-size: 11px;
				font-weight: 500;
				color: #aaa;
				margin-top: -3px;
				padding-bottom: 5px;
			}

			&.highlight {
				// box-shadow:
				// 	4px 0 #2c3e50 inset;/*,16px 0 2px -10px rgba(44, 62, 80, 0.1) inset;*/

				.hier-toggle-relation {
					box-shadow:
						3px 0 #2c3e50 inset;/*,15px 0 2px -10px rgba(44, 62, 80, 0.1) inset;*/
				}
			}

			&:hover {
				background-color: #fcfcfc;
				// transition: background-color 0s;

				wf-dropdown.hier-tools{
					visibility: visible;
				}

				.objectViewer.main-item{
					.detailedView{
						visibility: visible;
					}

					.object-viewer-toggle{
						visibility: visible;
					}
				}

				.hier-toggle-relation {
					// display: block;
					// opacity: 1;
					background-color: rgba(199, 202, 205, 1);
				}

				&.highlight {
					.hier-toggle-relation {
						box-shadow: 3px 0 #2c3e50 inset;
					}
				}
			}

			&.depth1 {
				.as-header {
					font-size: 21px;
					font-weight: 500;
				}
			}

			&.depth2 {
				.as-header {
					font-size: 17.8px;
					font-weight: 400;
				}
			}

			&.depth3 {
				.as-header {
					font-size: 15px;
					font-weight: 600;
				}
			}

			&.depth4 {
				.as-header {
					font-size: 14px;
					font-weight: 400;
				}
			}
		}

		.hier-toggle-relation {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 22px;
			cursor: pointer;
			color: #fff;
			background-color: rgba(199, 202, 205, 0.4);
			text-align: center;
			padding-top: 3px;
			// display: none;
			opacity: 1;
			transition: background-color .1s, opacity .1s;

			&:hover {
				transition: background-color 0s, opacity 0s;
				// opacity: 1;
				color: #fff;
				background-color: #2c3e50 !important;
			}

			// &.saving:before {
			// 	content: "";
			// 	display: block;
			// 	position: absolute;
			// 	width: 100%;
			// 	top: 50%;
			// 	height: 20px;
			// 	margin-top: -12px;
			// 	background-color: #2c3e50 !important;
			// }

			&.saving {
				.spinner-standalone(14px, "light");

				&:before {
					z-index: 1;
				}

				&:after {
					content: "";
					display: block;
					position: absolute;
					top: 0;
					width: 100%;
					height: 100%;
					background-color: #44515f !important;
					border-top: 1px solid #2c3e50;
					border-bottom: 1px solid #2c3e50;
				}
			}

			i {
				display: block;
				position: absolute;
				width: 100%;
				top: 50%;
				height: 20px;
				line-height: 20px;
				margin-top: -12px;
			}
		}

		.hier-numbering {
			color: #000000;
			text-align: left;
			margin-right: 15px;
		}

		.hier-requirement {
			padding-left: 10px;

			.details {
				width: 130px;
			}
		}

		.hier-children {
			&.depth0 {
				padding-left: 0;
			}
		}

		.wf-headerText {
			margin: 0;
			padding: 0 0 5px;
		}

		wf-measure-answering {

		}
	}

	.panel-filter.new  {
		// margin-bottom: -18px;

		.list-group-item.filter {
			padding: 1px 20px 1px;
			border-top-color: #E8E8E8;

			&.fulfillmentFilterButtons {
				.btn-inverse .fa.fa-repeat {
					color: #afbbc7 !important;
				}
			}

			.list-group-item {
				padding: 12px 0;

				.date-range-picker-wrapper {
					display: flex;
					flex-direction: row;

					.filter-date-range-picker {
						width: 100%;
						&:not(:last-child) {
							margin-right: 10px;
						}

						.filter-option-label {
							font-size: 11px;
							padding-bottom: 2px;
						}
					}
				}
			}

			.levelFunnel {
				border-top: 1px solid #eaeaea;

				&:first-child {
					border-top: 0;
				}

				> div {
					padding: 12px 0;
				}
			}

			[wf-search-quick] {
				padding: 7px 0;

				// input {
				// 	border-radius: 3px !important;
				// }

				.input-group {
					width: 100%;
				}

				// .input-group-addon.icon {
				// 	display: none;
				// }
			}

			.filter-icon {
				// width: 25px;
				width: 60px;
				padding-top: 10px;
				// font-size: 20px;
				color: #aaa;
				padding-bottom: 5px;
				text-align: center;
				margin-right: 18px;

				i {
					margin-top: 6px;
					font-size: 14px;
				}

				span {
					display: block;
					padding-top: 6px;
				}
			}

			.bgImage {
				border-radius: 50%;
				margin-left: -1px;
				margin-right: 4px;
				padding: 0 !important;
				margin: 5px -5px 0px 7px;
			}


			.btn-group {
				div.filter-button {
					padding: 0 !important;
					max-width: none;

					&.disabled {
						pointer-events: auto;
						cursor: pointer;
						opacity: 0.25;

						&:not(.btn-inverse) {
							.text {
								color: #000;
							}
						}
					}

					&.disabled-no-pointer-events {
						pointer-events: none;
						cursor: pointer;
						opacity: 0.25;
					}

					.filter-option-icon {
						display: block;
						float: left;
						margin-left: 8px;
						margin-top: 4px;
					}

					div {
						padding: 5px 10px;
						// display: inline-block;
						line-height: 16px;
						max-width: 200px;

						&.text {
							// padding-right: 5px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}

						&.count {
							padding: 4px 7px;
							position: relative;
							// margin-left: 2px;
							background-color: rgba(0,0,0,0.03);
							color: #888;
							margin: 1px;
							margin-left: -3px;
							font-weight: 400;
							// border-radius: 30px;
							// padding: 0px 6px;
							font-size: 10px;
							border-radius: 2px;
							// line-height: 16px;

							&.count-intersected {
								color: transparent;

								&:before {
									content: attr(data-count-intersected);
									display: block;
									position: absolute;
									padding: 4px 7px;
									z-index: 1;
									color: #888;
									top: 0;
									left: 0;
									right: 0;
									border: 0;
									text-align: center;
								}
							}
						}
					}

					&.btn-inverse {
						&.disabled {
							opacity: 1;

							.text {
								opacity: 0.5;
							}

							.wf-image {
								opacity: 0.6;
							}
						}

						.count {
							color: #dde3ea;
							background-color: rgba(255,255,255,0.17);

							&.count-intersected {
								&:before {
									color: #dde3ea;
								}
							}
						}

						.bgImage {
							box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.14) inset;
						}
					}
				}
			}
		}
	}

	.levelFunnel {
		overflow: hidden;
		padding: 0;

		> div {
			padding: 15px 20px;
		}

		button {
			padding: 0 !important;
			max-width: none;

			div {
				padding: 5px 10px;
				// display: inline-block;
				line-height: 16px;
				max-width: 200px;

				&.text {
					// padding-right: 5px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				&.count {
					padding: 4px 7px;
					// margin-left: 2px;
					background-color: rgba(0,0,0,0.03);
					color: #888;
					margin: 1px;
					margin-left: -3px;
					font-weight: 400;
					// border-radius: 30px;
					// padding: 0px 6px;
					font-size: 10px;
					border-radius: 2px;
					// line-height: 16px;
				}
			}

			&.btn-inverse {
				.count {
					color: #dde3ea;
					background-color: rgba(255,255,255,0.17);
				}
			}
		}
	}

	.customCategories {
		button.create {
			margin-top: -4px;
		}

		.highlight-toggler {
			padding-left: 7px !important;
		}

		wf-dropdown {
			padding-left: 12px;

			button.btn-sq {
				width: 22px;
				padding: 2px 0px 3px;
				line-height: 1.5;
				border-radius: 3px;
				box-shadow: none;

				i {
					font-size: 10px;
				}
			}
		}
	}

	.progressBars {
		.tooltip {
			margin-left: -12px;

			.tooltip-inner {
				width: 180px;
			}
		}

		&.fulfillment {
			.header {
				height: 18px;
				overflow: visible;
				padding-bottom: 0;
			}
		}
		.header {
			font-size: 14px;
			padding-bottom: 15px;

			i {
				margin-right: 10px;
			}

			span {
				font-weight: bold;
				color: #444;
			}

		}

		.barItem {
			padding: 11px 2px 0px 0;
			margin-right: -5px; // Offset to make tooltip trigger (icheck stops propagation of mouseover event)
			min-height: 43px;

			&:last-child {
				.progress {
					margin-bottom: 4px;
				}
			}

			&.thickBar {
				.highlight-toggler {
					padding-top: 4px;
				}

				.progress {
					height: 10px;

					.progress-bar {
						background-color: @wf_black;
						height: 10px;
					}
				}
			}

			&.green {
				.progress {
					.progress-bar {
						background-color: @wf_green;
					}
				}
			}


			.highlight-toggler {
				padding-left: 20px;
			}

			.labels {
				padding-bottom: 3px;
				font-size: 12px;

				a {
					color: @gray-dark;

					i {
						display: none;
						font-size: 9px;
						position: relative;
						top: 0px;
						opacity: 0.9;
					}

					&:hover {
						span {
							text-decoration: underline;
						}

					}
				}


				.count {
					display: inline-block;
					padding-right: 10px;
					color: #bbb;
					font-size: 11px;

					.slash {
						display: inline-block;
						padding: 0 1px;
					}
				}

				.percentage {
					display: inline-block;
					font-weight: 600;
				}
			}

			.progress {
				height: 4px;
				background-color: rgba(52, 73, 94, 0.07);
				margin-bottom: 0;

				.progress-bar {
					transition-duration: .3s;
					background-color: rgba(44, 62, 80, 0.5);
					height: 4px;
				}
			}
		}

	}

	.selected-filters {
		border-top: 1px solid #eee;
		padding: 12px 0;
		background-color: white;
		z-index: 1000 !important;
		height: auto !important;
		border-bottom: 1px solid #eee;

		&.filters-collapsed {
			border-bottom: none;
		}

		&.onSticky {
			border-bottom: 1px solid #eee;
			button.toggle-button {
				display: none;
			}

			button.go-to-filters-button {
				display: inline-block;
			}

			.filter-options {
				opacity: 1;

				.btn-group {
					display: block;
				}
			}
		}

		&.onUnSticky {

		}

		&.show-all-active-filters {
			.filter-options {
				padding-bottom: 20px;
				.btn-group {
					max-height: none;

					&:after {
						position: relative;
						background-color: transparent;
					}
				}

				.show-all-filters-button {
					top: auto;
					bottom: 0px;
				}
			}
		}

		button.toggle-button {
			margin-bottom: 3px;

			i {
				margin-left: 5px;
				color: @wf_blue;
			}
		}

		button.go-to-filters-button {
			display: none;

			i {
				margin-left: 5px;
				color: @wf_blue;
			}
		}

		.filter-options {
			padding-left: 29px;
			opacity: 0;
			transition: opacity .3s;

			> * {
				display: none;
			}

			.btn-group {
				max-height: 80px;
				margin-bottom: 0px;

				&:after {
					content: '';
					background-color: white;
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					top: 60px;
					z-index: 10;
				}
			}

			&.filters-visible {
				opacity: 1;

				> * {
					display: block;
				}
			}

			.show-all-filters-button {
				display: block;
				cursor: pointer;
				color: @wf_blue;


				overflow: hidden;
				position: absolute;
				top: 66px;
				bottom: 0;
				z-index: 15;
			}
		}

		button.clear-filters {
			margin: 0 0 0 15px;

			i.fa {
				color: @wf_red;
				margin-right: 5px;
			}
		}
	}//end of: .selected-filters
}

.HierarchicalEditorController {

}

.panel-body {
	.sidePanelHeader {
		font-size: 14px;
		padding-bottom: 15px;

		i {
			margin-right: 10px;
		}

		span {
			font-weight: bold;
			color: #444;
		}
	}
}

.wf-linkages {
	min-height: 100px;

	.linkageItem {
		padding: 5px 0;
		padding: 7px 3px 3px 5px;
		border-radius: 3px;
		margin: 0 -5px;

		wf-dropdown {
			visibility: hidden;
			margin-top: -4px;
			margin-left: 16px;

			.btn {
				padding-bottom: 4px;

				&:not(:hover) {
					background-color: white;
				}
			}
		}

		&:hover {
			background: #fbfbfb;

			wf-dropdown {
				visibility: visible;
			}
		}
	}

	.header + .linkageItem {
		margin-top: 4px;
	}

	.header {
		a {
			color: @gray-dark;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.wf-image {
		margin-top: -2px;
		margin-right: 8px;
	}
}

.hierarchical-editor-items {
	&.as-sortable-dragging  {
		padding: 0 10px;
		background: white;
		box-shadow: 0 1px 5px rgba(0, 0, 0, 0.23);
		border-radius: 3px;

		> .as-sortable-item > .dragReorder .icon-wrapper {
			opacity: 1;
		}
	}

	wf-hierarchical-editor-item {
		display: block;

		.btn {
			font-weight: 500;
		}

		.numberPath {
			display: inline-block;
		}

		.item-content {
			padding: 10px 0;
			border-bottom: 1px solid rgba(0, 0, 0, 0.07);
			padding-left: 30px;

			&:hover {
				background-color: #fafafa;
			}
		}

		.structure {
			.top {
				.wf-headerText, .numberPath span {
					font-weight: 500;
					color: #444;
				}
			}

			.children {
				/*border-top: 1px solid #ccc;*/
				/*margin-top: 10px;*/
				padding-top: 10px;
			}
		}

		.btn.reorder {
			padding-left: 9px;
			padding-right: 9px;
			height: 34px;
		}

		wf-item-tools {
			.glyphicon-file {
				position: relative;
				left: 1px;
			}
		}

		.influence-relatedContentByCreator {
			padding-top: 5px;
			padding-left: 1px;
		}
	}

	.as-sortable-placeholder {
		box-shadow: 0 5px white inset, 0 -10px white inset;
		background-color: @wf_gray_lighter;
	}

	.as-sortable-item {
		position: relative;

		.dragReorder {
			position: absolute;
			top: 6px;
			width: 30px;
			height: 30px;
			display: flex;
			align-items: center;
			left: -8px;

			&:hover {
				background-color: @wf_gray_lighter;
				border-radius: 3px;

				.icon-wrapper {
					opacity: 1;
				}
			}

			&.as-sortable-item-handle {

			}

			.icon-wrapper {
				width: 100%;
				text-align: center;
				cursor: grab;
				opacity: 0.6;

				i {
					cursor: grab;
				}
			}
		}
	}
}
