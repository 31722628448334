wf-xml-importer {
	.instructions {
		border: 2px solid @wf_blue;
		border-radius: 3px;
		text-align: center;

		padding: 20px;
		margin-bottom: 20px;

		h3 {
			margin-top: 0px;
			color: @wf_blue_dark;
		}
	}//end of: .instruction

	.header {
		margin-bottom: 15px;

		.header-icon {
			font-size: 20px;
			color: #616161;
		}

		.title { display: inline; }

		.description {
			display: block;
			margin-top: 15px;
			span {
				color: @wf_gray;
				display: block;
			}

			.warning {
				color: @wf_yellow_dark;
			}
		}

		.top-buttons {
			button {
				i { margin-right: 5px; }
			}
			.load {
				
			}

			.clear {
				color: @wf_red;
			}
		}
	}//end of: .header

	.body {
		ui-codemirror {
			border: solid 1px #ececec;
			display: inline-block;
			width: 100%;
			
			.CodeMirror {
				height: 500px;
			}
		}

		.file-info {
			display: block;
			margin-bottom: 20px;
			.file-label {
				float: left;
				font-weight: 600;
				font-size: 12px;
				width: 105px;
				text-transform: capitalize;
				margin-right: 15px;
			}

			.info {
				display: inline-block;
				overflow: hidden;
				clear: none;
				float: none;
				width: auto;
				color: #95a5a6;
				max-width: 500px;
			}

			.action {
				float: right;
			}
			
			wf-item-component {
				[wf-item-bodytext] { padding: 0 }
				.wf-headerText { padding: 0 }
				.headers { margin-bottom: 0 }
			}
		}//end of: .file-info
	}//end of: .body

	.footer {
		margin-top: 35px;

		text-align: right;

		wf-button {
			margin-left: 10px;
		}
	}//end of: .footer

	.quick-action-bar {
		button {
			i {
				margin-right: 5px;
				color: @wf_blue;
			}
			span {
				font-size: 14px;
				color: @wf_blue;
			}

			&:hover { span, i { color: @wf_blue_dark } }

			&[disabled] { span, i { color: @wf_gray; } }
		}

		.left-action-buttons {
			.import-root-item {
				float: left;
			}

			wf-button {
				float: left;
				margin-right: 15px;
	
				button {
					&.root-item-imported {
						pointer-events: none;
						span, i { color: @wf_green !important; } 
					}
				}
			}
		}

		.right-action-buttons {
			.unsaved-changes {
				float: right;
				color: @wf_red;
				font-weight: 500;
				font-size: 14px;
				padding: 7px 16px;
				margin: 0 7.5px;
			}

			.save-json-wrapper {
				float: right;
			}

			button.import-entire-standard {
				float: right;
				margin: 0 7.5px 0 7.5px;
			}
			wf-button {
				float: right;
				margin: 0 7.5px 0 7.5px;
	
				button {
					&.save-json {
						span, i { color: @wf_green };
					}

					&.stop-importing-button {
						span, i { color: white };
					}
				}
			}
		}

		.separator {
			float: right;
			border: .5px solid @wf_gray_light;
			height: 20px;
			margin: 7px 10px 0px 10px;
		}
	}//end of: .quick-action-bar

	.progress {
		margin-top: 25px;
	}//end of: .progress

	wf-hierarchical-item {
		.transclude-side {
			margin: 0 10px 0 10px;

			.type {
				display: inline-block;
				font-size: 14px;
				font-weight: 600;
				cursor: default;
				span { cursor: default; }
				margin-right: 15px;
			}

			button {
				margin-left: 15px;
				
				i { 
					margin-right: 5px;
					font-size: 13px;
					color: @wf_blue;
				}
				span { 
					font-size: 13px;
					color: @wf_blue;
				}

				&:hover {
					span, i { color: @wf_blue_dark }
				}
	
				&[disabled] {
					span, i { color: @wf_gray; }
				}

				&.edit-button {
					&.disabled { span, i { color: @wf_gray; } }
					i { margin-right: 5px; }
				}

				&.import-button {
					i { margin-right: 5px; }

					&.item-imported {
						.import { display: none; }

						.imported {
							display: block;
							span, i { color: @wf_green !important; } 
						}
					}

					.imported { display: none; }
				}
			}
		}
	}//end of: wf-hierarchical-item
}//end of: wf-xml-importer

.log-modal {
	.modal-close {
		font-size: 12px;
		top: 10px;
		right: 15px;
	}

	.header {
		.title {
			margin-top: 0px;
			i { font-size: 20px; }
		}
	}

	.body {
		margin: 30px 0 30px 0;

		.log-item {
			display: block;
			width: 100%;
			height: 25px;
	
			font-size: 12px;
			.text {
				color: @wf_gray;
			}
	
			.number {
				color: @wf_black;
			}
	
			.info {
				color: @wf_red;
			}
		}
	}

	.footer {
		text-align: right;
	}
}//end of: .log-modal