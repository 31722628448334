@import "wf-mixins.less";
@minBoxHeight: 350px;

body.signup {
	background: url(/assets/img/materiality-insights_perspective1.jpg);
	background-size: cover;
	overflow: auto;

	#topnav, #wrapper {
		display: none;
	}
}

.wfClaimOrganizationController {
	padding-bottom: 150px !important;
	padding-left: 0 !important;
	padding-right: 0 !important;

	.org-claim-header {
		margin-top: 25px;
		margin-bottom: 45px;

		.pageHeader {
			h1 {
				color: @wf_black;
				margin-top: 0;
				font-size: 32px;

				span {
					font-weight: 600;
				}
			}

			span.description {
				font-size: 14px;
				color: @wf_black;
			}
		}
	}

	.steps-indicator-wrapper {
		.steps-indicator {
			float: right;
			width: 276px;

			img {
				margin: auto;
				display: block;
			}

			.step-text {
				&:not(:last-child) {
					color: @wf_green;
				}

				color: @wf_gray_dark;
				font-weight: bold;
				padding-top: 5px;
				width: 33.333%;
				float: left;
				text-align: center;
			}
		}
	}

	.promotions-panel {
		.panel {
			background: transparent;
		}
		.panel-body {
			background-color: rgba(255, 255, 255, 0.5);
		}
	}

	.panel-body {
		padding: 40px;

		.title {
			width: 100%;
			font-size: 22px;
			font-weight: 600;
			text-align: left;
			color: black;
			padding-bottom: 40px;
		}

		wf-form {
			display: inline-block;
			width: 100%;
		}

		button.submit {
			width: 100%;
			font-size: 16px;
			margin-top: 40px;
		}

		wf-promotions {
			margin-top: 0;
			margin-bottom: 0;

			.promotional-category {
				margin-top: 0;
				margin-bottom: 0;

				.promotional-title {
					margin-top: 0;
					margin-bottom: 0;
					.text {
						h2 {
							width: 100%;
							font-size: 22px;
							font-weight: 600;
							text-align: left;
							border-bottom: none;
							line-height: initial;
							margin: 0;

							span {
								background: none;
								padding: 0;
								line-height: initial;
							}
						}
					}
				}

				.content-wrapper {
					padding-bottom: 0px;
					.promotional-content {
						margin-right: 0 !important;

						.promotional-item {
							margin-bottom: 20px !important;
							padding-bottom: 20px;
							border-bottom: 1px solid @wf_gray_light;
							width: 100%;

							&:last-child {
								border-bottom: none;
								margin-bottom: 0 !important;
								padding-bottom: 0;
							}

							h4 {
								margin-bottom: 10px;
								color: #5b5b5b;
								font-weight: 400;
								font-size: 20px;
							}

							.item-image {
								img { max-width: 100px; }
							}
						}
					}
				}
			}
		}
	}
}//end of: .wfClaimOrganizationController

wf-landing-page {
	display: inline-block;
	padding-bottom: 100px;
	width: 100%;

	.wf-landing-page-wrapper {
		&.ie-styles {
			.lp-body {
				.lp-description {
					.steps-wrapper {
						.step {
							flex-basis: auto;
						}
					}
				}
			}

			.lp-promotion-panel {
				.panel-body {
					.work-in-digital-sustainability {
						flex-basis: auto;
					}
				}
			}
		}//end of: &.ie-styles
	}//end of: .wf-landing-page-wrapper

	.wf-landing-page-wrapper {
		.lp-header {
			margin-top: 45px;
			margin-bottom: 45px;

			.lp-page-header {
				h1 {
					color: @wf_black;
					margin-top: 0;
					font-size: 32px;

					span {
						font-weight: 600;
					}
				}

				span.description {
					font-size: 14px;
					color: @wf_black;
				}
			}

			.lp-invite-contact {
				display: flex;
				flex-direction: row;
				margin-bottom: 0;
				justify-content: flex-end;
				margin-top: 5px;

				.invite-wrapper, .contact-wrapper {
					display: flex;
					flex-direction: row;
					justify-content: flex-end;
				}

				.users {
					.user {
						margin-left: -10px !important;
						box-shadow: 0 0 0 1px #ecf0f1;
					}

				}
			}
		}//end of: .lp-header

		.lp-body {
			margin-top: 20px;
			margin-bottom: 20px;

			.lp-description {
				background-color: rgba(255, 255, 255, 0.7);
				padding: 20px 25px;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: flex-start;
				box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 -1px 0px 0 rgba(0, 0, 0, 0.03);
				border-top-left-radius: 3px;
				border-top-right-radius: 3px;

				.steps-wrapper {
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					padding: 5px 0px;
					height: 100%;
					width: 100%;

					.step {
						display: flex;
						flex-direction: row;
						flex-wrap: nowrap;
						flex-basis: 100%;
						//IE 10 support-----
						flex-shrink: 10;
						//------------------

						.step-content {
							order: 1;
							display: flex;
							flex-direction: row;
							flex-wrap: nowrap;

							//IE 10 support-----
							flex-shrink: 10;
							//------------------

							.step-image {
								img {
									width: 42px;
								}
								margin-right: 15px;
							}

							.step-description-wrapper {
								display: flex;
								flex-direction: column;
								flex-wrap: nowrap;
								width: 100%;

								//IE 10 support-----
								flex-shrink: 10;
								//------------------

								.step-text {
									order: 1;
									flex-grow: 1;
									span {
										font-size: 12px;
										color: #1c1c1c;
									}
								}

								.step-info {
									order: 2;
									margin-top: 5px;

									color: #808080;

									span.anchor {
										color: @wf_blue;
										text-decoration: none;
										cursor: pointer;
										&:hover {
											text-decoration: underline;
										}
									}

									span.additional-info {
										font-weight: 600;
									}

									span.link {
										color: @wf_blue;
										cursor: default;
										i {
											margin-left: 5px;
										}

										.number-of-companies {
											font-weight: 500;
										}

										&:hover { cursor: pointer; }
									}
								}
							}
						}//end of: .step-content

						.step-divider {
							order: 2;
							position: relative;
							margin: auto 20px;
							text-align: center;

							i {
								color: @wf_gray_dark;
								font-size: 16px;
							}
						}//end of: .step-divider

						&:last-child {
							.step-divider { display: none; }
						}
					}//end of: .step
				}//end of: .steps-wrapper
			}

			wf-materiality-analysis {
				panel .panel {
					border-top-left-radius: 0px;
					border-top-right-radius: 0px;
				}
			}//end of: wf-materiality-analysis
		}//end of: .lp-body

		.lp-invite-contact {
			margin-bottom: 30px;

			.contact-wrapper {
				.contact-button {
					margin-right: 15px;
					button {
						padding: 9px 12px;
						background-color: transparent !important;
						color: @wf_blue;
						outline-color: @wf_blue;
						box-shadow: inset 0 0px 0px 1px @wf_blue;
						i { margin-right: 5px; }
					}
				}//end of: .contact-button
			}//end of: .contact-wrapper

			.invite-wrapper {
				.button-wrapper {
					display: flex;
					flex-direction: row;
					justify-content: center;
				}

				.contact-button {
					margin-right: 15px;
					button {
						padding: 9px 12px;
						background-color: transparent !important;
						color: @wf_blue;
						outline-color: @wf_blue;
						box-shadow: inset 0 0px 0px 1px @wf_blue;
						i { margin-right: 5px; }
					}
				}

				.invite-button {
					a {
						background-color: @wf_blue;
						padding: 9px 12px;

						i { margin-right: 5px; }
						margin-right: 0px;
					}
				}//end of: .invite-button

				.users {
					margin-bottom: 20px;
					margin-left: 25px;
					order: 2;
					flex-grow: 1;
					flex-shrink: 5;

					display: flex;
					flex-direction: row-reverse;
					justify-content: center;

					.user {
						width: 35px;
						height: 35px;
						background-color: @wf_gray;
						border-radius: 50%;
						display: inline-block;
						background-position: center;
						background-repeat: no-repeat;
						background-size: contain;

						&:not(:last-child) {
							margin-left: 5px;
						}
					}//end of: .user
				}//end of: .users
			}//end of: .invite-contact-wrapper
		}//end of: .lp-invite-contact

		.lp-promotion {
			.promotional-title {
				margin-top: 30px;
				margin-bottom: 30px;

				&.arrow {
					.text {
						h2 {
							span {
								color: @wf_gray;
								font-size: 16px;
							}
						}
					}
				}

				.text {
					h2 {
						width:100%;
						font-size: 22px;
						font-weight: 500;
						text-align: center;
						border-bottom: 1px dashed #d5d5d5;
						line-height: 0.1em;
						margin: 10px 0;

						span {
							background: @wf_gray_lighter;
							padding: 0 20px;
							line-height: 0.1em;

							&:before {
								background:@wf_gray_lighter;
								padding: 0 20px;
							}
						}
					}
				}
			}//end of: .promotional-title
		}//end of: .lp-promotion

		.lp-promotion-panel {
			margin-top: 25px;
			.panel-body {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;

				wf-promotions {
					order: 1;
				}

				.work-in-digital-sustainability {
					order: 2;
					flex-basis: 30%;
					margin-left: 25px;

					h4 {
						font-weight: 500;
					}

					.description {
						display: block;
					}

					.learn-more-button {
						margin-top: 15px;
					}

					.extra-info {
						color: @wf_gray;
					}


				}//end of: .work-in-digital-sustainability
			}
		}//end of: .lp-promotion-panel
	}//end of: .wf-landing-page-wrapper

	.lp-footer {
		margin-top: 20px;
		margin-bottom: 20px;

		.made-with-love {
			border-top: 1px solid #e1e1e1;
			text-align: center;
			font-size: 12px;
			color: @wf_gray_dark;
			padding: 20px;

			i {
				color: @wf_red;
				margin: 0 5px;
			}
		}
	}//end of: .lp-footer
}//end of: wf-landing-page

wf-materiality-analysis {
	.initial-loading {
		.limited-list .gri-items {
			height: 400px;
		}
	}

	.limited-list {
		.panel {

			.panel-body {
				padding: 30px;
			}
		}
	}

	.loader-fancy {
		background: url(/assets/img/loaders/materiality_analysis_fancy.gif) no-repeat center center;
		height: 300px;

		> * {
			visibility: hidden;
		}
	}

	.column-headers {
		margin-top: 30px;
		padding-bottom: 10px;

		.learn-more-header {
			text-align: left;
			margin: 0 30px 0 -30px;
		}

		.column-header {
			cursor: default;
			color: #333;
			font-weight: 500;

			span {
				display: inline-block;
			}

			&:hover {
				cursor: pointer;
				.fa-info {
					color: #a4abb3;
					box-shadow: 0 0 0 1px #a4abb3 inset;
				}
			}
		}
	}

	.chunks-loading {
		wf-filtering {
			height: 0;
			visibility: hidden;
		}
	}

	wf-filtering {
		display: block;
		visibility: visible;
		overflow: hidden;
		height: 45px;
		transition: height .5s ease-out;

		.gri-foundation { .griColors(#002C5C); }
		.gri-general_disclosures { .griColors(#0B406B); }
		.gri-management_approach { .griColors(#02528A); }
		.gri-economic { .griColors(#732A82); }
		.gri-environmental { .griColors(#06966F); }
		.gri-social { .griColors(#F36B21); }

		.btn-group > {
			.btn {
				height: 30px;
				font-size: 11px;
				margin-right: 6px !important;

				.text {
					height: 30px !important;
					line-height: 21px !important;
					padding: 5px 13px !important;
				}

				.count {
					height: 30px !important;
					line-height: 21px !important;
					font-size: 11px !important;
					font-weight: bold !important;
					padding: 4px 9px !important;

					&:after {
						content: ' areas';
						font-weight: 400;
					}
				}
			}
		}

		.griColors(@color) {

			&, &:focus, &:hover {
				box-shadow: inset 0 0 0 1px fade(@color, 70);
				background-color: #fff !important;
			}

			.count {
				background-color: fade(@color, 10) !important;
				color: @color !important;
			}


			&.btn-inverse {
				background-color: @color !important;

				.count {
					color: #fff !important;
					background-color: rgba(255, 255, 255, 0.17) !important;
				}
			}
		}
	}

	.gri-items {
		position: relative;
		transition: height .5s ease-out;
		overflow: hidden;

		&.isListLimited {
			.fadeBottom(100px, 20px);
		}

		&.chunks-loading {
			&.linear {
				.progress > .progress-bar:after {
					animation: progress-gradient-move .9s linear infinite;
				}
			}
			.progress > .progress-bar {
				&:after {
					content: "";
					display: block;
					position: absolute;
					width: 100%;
					height: 100%;

					/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+87,0+100 */
					background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.35) 82%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
					background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.35) 82%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.35) 82%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */

					animation: progress-gradient-move .9s ease-in-out infinite;
				}
			}
		}

		.gri-item {
			padding: 15px 0;
			border-bottom: 1px solid rgba(0,0,0,0.05);
			height: 73px;
			overflow: visible;
			position: absolute;
			top: 600px;
			left: 0px;
			width: 100%;
			transition: top .7s, opacity .7s;
			background: #fff;
			z-index: 10;

			&.ng-leave {
				opacity: 1;

				&.ng-leave-active {
					opacity: 0;
				}
			}

			&.ng-enter {
				opacity: 0;

				&.ng-enter-active {
					opacity: 1;
				}
			}

			.sustainability-area {
				.gri-standard-cover {
					width: 43px;
					height: 43px;
					overflow: hidden;
					position: relative;
					box-shadow: 0 0 0 1px rgba(0,0,0,0.1) inset;
					border-radius: 2px;
					margin-right: 15px;
					background-color: #fff;

					&:after {
						content: attr(data-gri-title);
						display: block;
						position: absolute;
						height: 100%;
						top: 3px;
						left: 3px;
						right: 0;
						font-size: 9px;
						line-height: 1em;
						color: #999;
					}


					span {
						content: "";
						display: block;
						position: absolute;
						border-top-left-radius: 100%;
						border-top-right-radius: 100%;
						width: 130%;
						height: 50%;
						bottom: 0;
						transform: translate(-11%, 0);
						text-align: center;
						font-size: 10px;
						padding-top: 5px;
						z-index: 1;

						&:before {
							content: attr(data-gri-code);
							display: block;
							color: #fff;
						}
					}
				}

				.item-title.gri {
					font-size: 11px;
					color: @wf_gray;
				}

				.item-description.gri {
					font-weight: 600;
					font-size: 14px;
					color: #444;
				}
			}//end of: .sustainability-area

			.match {
				padding: 10px;
				.match-value {
					font-size: 14px;
					min-width: 65px;
					text-align: right;
				}

				.progress {
					margin-bottom: 0;
					margin-top: 3px;
					height: 12px;

					.progress-bar {
						transition: width 0.5s ease;
						position: relative;
						overflow: hidden;
					}
				}
			}//end of: .match

			.related-goals {
				.sdg-thumbnails {
					padding: 5px 20px 5px 5px;
					white-space: nowrap;

					.sdg-thumbnail {
						&:not(:first-child) {
							box-shadow: 0 0 0 3px #fff, -4px 0 3px rgba(0, 0, 0, 0.2);
						}

						box-shadow: 0 0 0 3px #fff;
						margin-right: 7px;
						margin-bottom: 7px;
						display: inline-block;
						margin-right: -6px;
						border-radius: 3px;
						overflow: hidden;
						cursor: pointer;
						transition: transform .08s, box-shadow .08s;

						&.popover-opened {
							transform: scale(1.2);
							box-shadow: 0 0 0 2px #fff;
						}
					}
				}
			}//end of: .related-goals

			.add-to-scope {
				padding-top: 5px;
				text-align: center;
				button {
					background-color: #3498db;
					padding: 5px 7px;

					span.remove-text { display: none; }

					&.added-to-scope {
						box-shadow: none;
						background-color: transparent;

						&.remove-button-mobile {
							display: none;

							span {
								display: inline-block
							}
							background-color: @wf_red;
							color: white;
						}

						&:hover {
							background-color: @wf_red;
							color: white;

							span.add-and-added-text { display: none; }
							span.remove-text { display: inline; }
						}
					}
				}
			}

			.learn-more {
				padding: 5px;
				.infoIcon {
					color: #ccc;
					cursor: pointer;
					margin: 5px 5px;
					font-size: 16px;

					&:hover {
						color: #bbb;
						transform: scale(1.3);
					}
				}
			}//end of: .learn-more
		}//end of: .gri-item
	}//end of: .gri-items

	.toggle-items {
		background-color: #F5F7F8;
		font-weight: bold;
		text-align: center;
		padding: 20px 0;
		cursor: pointer;
		margin: 0 -30px -30px;
		border-radius: 3px;
		text-transform: uppercase;
		color: #9a9a9a;
	}

	.sdg-goals-aggregated {
		.sdg-image {
			max-height: 105px;
			width: 110px;
			margin-right: 25px;
		}

		.sdg-info {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;

			.sdg-logo {
				order: 1;
			}

			.sdg-text {
				order: 2;
				width: 100%;

				//IE 10 support-----
				flex-shrink: 10;
				//------------------

				.sdg-header {
					font-size: 16px;
					padding: 0;
					margin: 0;
					padding-bottom: 10px;

					strong.sdg {
						color: #0996da;
					}
				}

				.sdg-description {
					color: @wf_gray_dark;
				}
			}
		}

		.panel-body {
			padding: 30px;
		}

		.goal-items {
			margin-left: -10px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.goal-item {
				cursor: pointer;
				padding: 0 10px;
				position: relative;
				display: inline-block;
				width: 20%;

				&:before {
					content: attr(data-count);
					display: block;
					position: absolute;
					bottom: -12px;
					right: -1px;
					background: #213c50;
					font-size: 13px;
					font-weight: bold;
					border-radius: 100%;
					height: 27px;
					width: 27px;
					color: #fff;
					text-align: center;
					line-height: 27px;
					box-shadow: 0 0 0 4px white;
					z-index: 1;
				}

				img {
					width: 100%;
					border-radius: 3px;
					transition: transform .15s;

					&.popover-opened {
						transform: scale(1.15);
						box-shadow: 0 0 0 2px #fff;
						transition: transform .05s;
					}
				}
			}
		}
	}

	.lp-loader-bar {
		position: fixed;
		bottom: 0px;
		left: 0;
		right: 0;
		width: 100%;
		background-color: @wf_blue;
		padding: 15px;
		z-index: 1000;
		transition: background-color 1s;

		.bar-container {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			max-width: 1300px;
			margin: 0 auto;

			.icon-text {
				order: 1;
				flex-grow: 1;
				flex-shrink: 15;

				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-items: center;

				.loader-icon {
					display: inline-block;
					text-align: center;
					font-size: 20px;
					margin-right: 15px;
					color: white;
					max-height: 50px;

					&:before {
						border: 2.5px solid rgba(255, 255, 255, 0.3);
						border-top-color: rgba(255, 255, 255, 1);
					}
				}

				.text {
					display: inline-block;
					font-size: 14px;
					font-weight: 500;
					color: white;
					padding: 0 5px;
					margin-right: 15px;
				}
			}//end of

			.org-profile {
				order: 2;
				flex-grow: 30;
				flex-shrink: 10;
				margin-right: 15px;

				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;

				.industries {
					order: 1;
					flex-grow: 10;
					flex-shrink: 5;

					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
				}

				&.single-industry {
					.industry {
						box-shadow: none;
						margin-right: 10px;
						pointer-events: none;
						background-color: #2980b9;
						margin-bottom: 0 !important;
						white-space: unset;
						max-width: initial !important;

						.wf-image {
							width: 18px;
							height: 18px;
						}

						.industry-text {
							font-weight: 500;
							font-size: 12px;
						}
					}
				}

				&.multiple-industries {
					.industry {
						flex-grow: initial !important;
						box-shadow: none;
						margin-right: 10px;
						background-color: #2980b9;
						padding: 0;
						margin-right: 10px;
						margin-bottom: 0px;

						.wf-image {
							width: 33px;
							height: 33px;
							margin-right: 0 !important;
						}

						.industry-text { display: none; }

						span { font-weight: 500; }

						&:hover {
							cursor: pointer;
						}
					}
				}
			}//end of: .org-profile

			.bar-buttons {
				order: 3;
				flex-grow: 1;
				flex-shrink: 2;

				display: flex;
				flex-direction: row;
				align-items: center;

				.bar-link-button {
					order: 2;
					flex: none;
					min-width: 90px;
					padding: 5px;
					text-align: center;

					margin-left: 0;

					font-weight: 400;
					font-size: 14px;
					color: white;
					cursor: pointer;

					span:hover { text-decoration: underline; }

					i {
						margin-right: 5px;
						font-size: 14px;
						position: relative;
					}
				}
			}
		}

		&.loaded
		{
			background-color: @wf_green !important;

			.industry {
				background-color: #3eaf65 !important;
			}
		}
	}//end of: .lp-loader-bar

	.fa-info {
		color: #c7ced6;
		margin-left: 5px;
		font-size: 11px;
		width: 16px;
		height: 16px;
		line-height: 17px;
		border-radius: 100%;
		box-shadow: 0 0 0 1px #c7ced6 inset;
		text-align: center;
	}
}//end of: wf-materiality-analysis

.wf-materiality-analysis-sdg-popover {
	max-width: none;
	width: 350px;
	min-width: 300px;
	z-index: 20;


	.popoverBoxShadow();

	.item-sdg {
		.item-title {
			font-weight: 600;
			font-size: 14px;
			color: #444;
			padding-bottom: 3px;
		}
	}

	.wf-popover {
		overflow-y: hidden !important;
		.fadeBottom(20px, 0px);
	}
}

.wf-modal.modal.wf-landing-page-contact-modal {
	// These styles work together with the css in wf-hubspot.less
	// when opening the Hubspot contact form in wfLandingPage component.

	.modal-dialog .modal-content {
		min-height: 400px;

		.modal-close { display: none; }

		.hs-richtext {
			margin-bottom: 25px;

			h2 {
				margin-top: 0;
				line-height: 1.3em;
				margin-bottom: 15px;
				font-size: 20px;
				font-weight: 400;
			}
		}

		.hs_submit, .hs-submit {
			margin-top: 20px;
		}

		.submitted-message {
			padding-bottom: 80px;
		}

		.modal-footer {
			margin-top: 0;

			button.btn-cancel {
				position: absolute;
				right: 40px;
				bottom: 40px;
			}
		}
	}
}

.wf-modal.modal.guidance {
	&.gri-guidance-modal {
		.modal-content {
			padding-top: 100px;
			.guidance-explanation {
				position: absolute;
				top: 0;
				left: 0;
				right: 0px;
				border-bottom: 1px solid @wf_gray_light;
				min-height: 75px;
				padding: 30px 40px 0 40px;

				i {
					font-size: 14px;
					color: @wf_green;
					margin-right: 10px;
				}

				span {
					font-size: 14px;
				}
			}
			.modal-header {
				button.bootbox-close-button.close {
					position: absolute;
					top: 26px;
					right: 40px;
				}
			}
		}
	}
}

@keyframes progress-gradient-move {
	0% {
		left: -100%;
	}

	50% {
		left: 0%;
	}

	100% {
		left: 100%;
	}
}

// from 0px to 480px
@media screen and (min-width: 0) and (max-width: @screen-xs-min) {
	wf-materiality-analysis {
		.ie-styles {
			.sdg-goals-aggregated {
				.goal-items {
					.goal-item {
						flex-basis: auto;
					}
				}
			}
		}

		.panel-body {
			.loader-fancy { background-size: contain; }
		}

		.sdg-goals-aggregated {
			.sdg-info {
				flex-direction: column !important;
				margin: 0 20px !important;

				.sdg-logo {
					margin: 0 auto;
					.sdg-image {
						width: 90px;
						margin-right: 0;
					}
				}
				.sdg-text {
					margin: 30px 0;
					text-align: center;
				}
			}

			.goal-items {
				margin: 0px !important;
				flex-wrap: wrap;
				justify-content: space-evenly !important;

				.goal-item {
					flex-basis: 35%;
					margin: 10px 0;
				}
			}
		}

		.lp-loader-bar {
			.bar-container {
				.org-profile {
					.industries {
						justify-content: flex-start !important;
					}
				}

				.bar-buttons {
					margin: 0px 40px !important;
					justify-content: flex-start !important;
					flex-wrap: wrap;

					.bar-link-button {
						text-align: left;
						margin-right: 10px;
					}
				}
			}
		}
	}

	.wf-modal.modal {
		&.guidance, &.wf-landing-page-contact-modal {
			.modal-content {
				padding: 20px !important;
			}
		}
	}

	.popover {
		&.wf-materiality-analysis-sdg-popover {
			left: 5px !important;
			width: 97.5%;
			margin-top: 7px;

			&.sdg-goals-aggregated {
				margin-top: 15px;
			}

			.arrow { display: none; }
		}
	}

	.wf-modal.modal.wf-landing-page-contact-modal {
		.modal-dialog .modal-content {
			.modal-footer {
				button.btn-cancel {
					right: 20px !important;
					bottom: 20px !important;
				}
			}
		}
	}

	.wf-modal.guidance {
		&.gri-guidance-modal {
			.modal-content {
				padding-top: 80px !important;
				.guidance-explanation {
					min-height: 55px !important;
					padding: 20px 20px 0 20px !important;
				}
				.modal-header {
					button.bootbox-close-button.close {
						top: 16px !important;
						right: 20px !important;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1300px) {
	wf-materiality-analysis {
		.lp-loader-bar {
			.bar-container {
				padding-right: 60px;
			}
		}
	}
}

// from 0px to 768px
@media screen and (min-width: 0) and (max-width: @screen-xs-max) {
	body {
		&.lift-intercom-button {
			#intercom-container {
				iframe {
					&.intercom-launcher-frame, &.intercom-launcher-discovery-frame {
						bottom: 20px;
					}
				}
			}
		}
	}

	wf-landing-page {
		.wf-landing-page-wrapper {
			&.ie-styles {
				.lp-promotion-panel {
					.panel-body {
						.work-in-digital-sustainability {
							flex-basis: auto;
						}
					}
				}
			}
		}

		.wf-landing-page-wrapper {
			.lp-promotion-panel {
				.panel-body {
					flex-direction: column;

					.promotional-title {
						text-align: center;

						.text {
							h2 { line-height: normal !important; }
						}
					}

					.work-in-digital-sustainability {
						flex-basis: 100%;
						margin: 15px 0 !important;
						text-align: center;
						max-width: 75%;
						margin: 10px auto !important;
					}
				}
			}
		}
	}

	wf-materiality-analysis {
		panel.limited-list {
			.panel-body {
				padding: 20px !important;
				.toggle-items {
					margin: 0 -20px -20px;
				}
			}
		}

		.lp-loader-bar {
			.bar-container {
				padding-right: 0;
				flex-direction: column;
				.icon-text {
					justify-content: center;
				}

				.org-profile {
					margin: 15px 40px;

					.industries {
						justify-content: center;

						.industry {
							margin-right: 15px !important;
							margin-bottom: 0 !important;
							.wf-image {
								width: 25px;
								height: 25px;
							}

							.industry-text {
								margin-top: 4px;
							}
						}

						.industry:last-child {
							margin-right: 0 !important;
						}

					}
				}

				.bar-buttons {
					justify-content: center;
				}
			}
		}
	}

	footer {
		ul li {
			text-align: center;
			float: none !important;
		}
	}

	.wfClaimOrganizationController {
		.org-claim-header {
			margin-bottom: 30px;
		}

		.steps-indicator-wrapper {
			width: 100%;
			display: inline-block;
			margin-top: 30px;
			.steps-indicator {
				float: none;
				margin: 0 auto;
			}
		}
	}
}

// from 768px to 992px
@media screen and (min-width: @screen-xs-max) and (max-width: @screen-sm-max) {
	wf-landing-page {
		.wf-landing-page-wrapper {
			&.ie-styles {
				.lp-promotion-panel {
					.panel-body {
						.work-in-digital-sustainability {
							flex-basis: auto;
						}
					}
				}
			}
		}

		.wf-landing-page-wrapper {
			.lp-promotion-panel {
				.panel-body {
					flex-direction: column;

					.promotional-title { text-align: center; }

					.work-in-digital-sustainability {
						flex-basis: 100%;
						margin: 15px 0 !important;
						text-align: center;
						max-width: 75%;
						margin: 10px auto !important;
					}
				}
			}
		}
	}
}

// from 0 to 992px
@media screen and (min-width: 0) and (max-width: @screen-sm-max) {
	wf-landing-page {
		.wf-landing-page-wrapper {
			.lp-body {
				.lp-description {
					.steps-wrapper {
						flex-direction: column;
						.step {
							flex-direction: column;
							.step-content {
								.step-image img {
									width: 32px;
								}
								.step-description-wrapper {
									margin-left: 15px;
								}
							}
							.step-divider {
								margin: 10px 0;
								i { transform: rotate(90deg); }
							}
						}
					}
				}
			}
		}
	}

	wf-materiality-analysis {
		panel.limited-list {
			.panel-body {
				wf-filtering {
					height: 100%;
					.filter-buttons {
						.filter-options {
							display: flex;
							flex-direction: row;
							justify-content: flex-start;
							flex-wrap: wrap;
						}
					}
				}

				.gri-items {
					margin-top: 20px;
					height: initial !important;

					.gri-item {
						position: initial;
						height: initial;

						.sustainability-area {
							margin-bottom: 10px;
							.gri-standard-cover {
								width: 45px;
								height: 45px;
								margin-right: 27px;
							}
						}//end of: .sustainability-area

						.match {
							padding: 0 10px;
							.header {
								margin: 15px 13px 15px 0;
							}

							.match-value {
								margin: 15px 0;
							}

							.progress {
								margin: 15px 0;
							}
						}//end of: .match

						.related-goals {
							text-align: left;
							.sdg-thumbnails {
								margin: 5px 0;
								padding: 5px;

								.sdg-thumbnail {
									margin-right: 7px;
									margin-bottom: 0;
									box-shadow: none !important;

									&.popover-opened {
									}
								}
							}
						}//end of: .related-goals

						.learn-more {
							position: absolute;
							right: 0;
							padding-right: 0;
							text-align: right;

							.infoIcon {
								font-size: 16px;
								margin: 11px;
								padding: 0;

								&:hover {
									transform: none !important;
								}
							}
						}//end of: .learn-more

						.header {
							i {
								margin: 0 1px 0 3px;
								color: #ccc;
							}
							margin: 5px 10px 10px 0;
							font-weight: 500;
						}

						.add-to-scope {
							button {
								span.remove-text { display: none; }
								span.add-and-added-text { display: inline; }

								&.added-to-scope {
									&:not(.remove-button-mobile) {
										box-shadow: none;
										background-color: transparent;
										color: #707980;
										pointer-events: none;

										&:before { content: none; }
										span { visibility: visible }
									}
									&.remove-button-mobile {
										span.remove-text { display: inline !important; }
										display: inline-block;
									}
								}
							}
						}//end of: .add-to-scope
					}//end of: .gri-item
				}//end of: .gri-items
			}//end of: .panel-body
		}//end of: panel.limited-list

		.lp-loader-bar {
			// .content-wrapper {
			// 	flex-wrap: wrap;
			// 	.org-profile {
			// 		flex-wrap: wrap;
			// 		justify-content: center;

			// 		.industries {
			// 			width: 100%;

			// 			.industry {
			// 				flex-grow: 1;
			// 			}
			// 		}
			// 	}

			// 	.bar-buttons {
			// 		margin: 0 auto !important;
			// 	}
			// }
		}
	}//end of: wf-materiality-analysis

	.sdg-goals-aggregated {
		.sdg-info {
			margin: 20px;
		}

		.goal-items {
			margin: 20px 0;
		}
	}

	wf-promotions {
		.promotional-category {
			.promotional-title {
				margin-bottom: 30px !important;
			}
		}
	}
}

// from 992px to 1200px
@media screen and (min-width: @screen-sm-max) and (max-width: @screen-md-max) {
}

// from 1200px
@media screen and (min-width: @screen-md-max) {
}
