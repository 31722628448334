@import "wf-mixins.less";
@import "variables.less";
@import "wf-variables.less";
@import "wf-animations.less";

@panelPadding: 20px;

html {
	// overflow: hidden;
}

body {
	// font-size: 13px;
	// font-family: 'Open Sans';
	// min-width: 320px !important;

	&.fixed-layout {
		// overflow-y: scroll;
		margin: 0;
		//padding: 0 3%;
	}
}

.appVersionInfo {
	color: #bbb;
	margin-top: 10px;
	font-size: 11px;
}

.navbar {
	.currentUser {
		.names-wrapper {
			padding-top: 6px;
			transition: padding-top .2s;
		}

		&.withOrgName {
			.names-wrapper {
				padding-top: 0;

				.org-name {
					height: 15px;
					max-width: 250px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
		}

		.user-name {
			font-weight: 600;
		}

		.org-name {
			font-weight: 300;
			height: 0;
			// opacity: 0;
			overflow: hidden;
			transition: height .2s;
		}

		.user-image {
			height: 30px;
			width: 30px;
			border-radius: 50%;
			background-position: center center;
			background-size: cover;
			background-color: rgba(0,0,0,0.1);
		}
	}

	.dropdown-menu.account {
		.spinner {
			height: 100px;
			.spinner(22px;)
		}
	}

	.dropdown-menu.notifications {
		.notificationsStyle();

		.emptyList-message {
			i {
				font-size: 24px;
				margin-bottom: 12px;
				color: @wf_gray;
			}
		}
	}
}

.ui-select-choices {
	opacity: 1 !important; // Temporary fix: After merging log-filtering branch into development branch on 2017-08-17 all the ui-select dropdowns is hidden by opacity.

	max-height: 330px;
}

.EventLogController {
	.ui-select-container {
		.ui-select-choices-row-inner {
			padding: 5px 10px;
		}

		.ui-select-choices-row.active {
			.contact-info {
				.email, .phone {
					i, span {
						color: white;
					}
				}
			}
		}

		.user-image {
			float: left;
			position: relative;
			width: 35px;
			height: 35px;

			.user-icon {
				width: 35px;
				height: 35px;
				position: static;
				border-radius: 100%;
				background-position: center center;
				background-size: cover;
			}
		}//end of: .user-image

		.user {
			display: inline-block;
			margin-left: 10px;

			.name {
				font-size: 14px;
				font-weight: 400;
			}

			.contact-info {
				.email, .phone {
					display: inline-block;
					margin-right: 10px;
					i {
						font-size: 10px;
						margin-right: 3px;
						color: @wf_gray;
					}
					span {
						color: @wf_gray;
						font-size: 11px;
						overflow: initial !important;
					}
				}
			}
		}
	}

	.top-header {
		padding: 0 10px 20px 10px;
		.activity-log, .date-time {
			i { margin-right: 5px; }
			i, span {
				font-size: 12px;
				color: @wf_gray;
			}
		}

		.activity-log {
			padding-left: 15px;
		}

		.date-time {
			text-align: left;
		}
	}

	.items {
		[wf-expand-content] {
			wf-item-component {
				margin-left: 50px;
			}
		}
	}

	.notifications {
		.notificationsStyle();
	}
}

.notificationsStyle() {
	.guidance-btn {
		display: none;
	}

	.date-time {
		padding: 0 10px;
		.date {
			font-size: 13px;
			font-weight: 500;
		}

		.time {
			font-size: 11px;
			color: @wf_gray;
		}
	}

	&.loading {
		background: #fff url(/assets/img/loaders/spinner_btn-white.gif) no-repeat center center;
		min-height: 100px;

		> * {
			visibility: hidden;
		}
	}

	.notificationItem {
		border-bottom: 1px solid @border-lighter;
		color: @gray-dark !important;
		padding: 10px 15px;

		a {
			display: block;
			overflow: hidden;
			white-space: normal; color: @gray-dark;
			// &.active {color: @gray-darker !important; background: lighten(@brand-info, 57%) !important}
			// &.active:hover {color: @gray-darker !important; background: lighten(@brand-info, 57%) !important}
			&:hover {color: @gray-darker !important; background: none !important; text-decoration: underline}

			// Manually fix width for tooltip
			.btn-mark-unread + .tooltip > .tooltip-inner {min-width: 83px;}

			button.btn-mark-read, button.btn-mark-unread, button{
				float: right; border: none !important;
				padding: 0 !important;
				background: none !important;
				 line-height: 0;

				i {
					color: @gray-lighter !important;
					margin: 0;
					font-size: 11px !important;
					padding: 3px !important;
					margin-right: -3px;
					top: -4px;
					// margin-left: 5px;

				}
				&:hover{ i {color: @wf_blue !important;}}
			}
		}
		// .time {color: @gray-lighter; padding-left: 10px; padding-right: 7px; font-size: @font-size-mini; white-space: nowrap; text-align: right; vertical-align: top; line-height: 11px;}

		&.dd-header a, &.dd-footer a {color: @link-color; &:hover {text-decoration: none;}}
		&.dd-header {
			padding: 7px 10px;
				float: left;
			span:first-child { font-weight: 700;}
			span:last-child {
				// .pull-right;
				float: right;
				display: block;
				a {padding: 0 !important;}
			}
			a {background: none !important}
			a:hover {color: @link-hover-color !important}
		}
		&.dd-footer {
			font-size: @font-size-mini;
			a {text-align: right; padding: 7px 10px; &:hover, &:focus{background: none !important; color: @link-hover-color !important; text-decoration: none;}}
		}
		&:last-child {border-bottom: 0;}

		border-bottom: 1px solid rgb(238, 238, 238);

		&:hover {
			background-color: #fafafa;
			transition: background-color 75ms;
		}

		// .time {
		// 	color: #aaa;
		// 	float: right;
		// 	display: block;
		// }

		// .eventSentence {
		// 	color: #aaa;
		// 	padding-bottom: 8px;
		// }

		wf-item.template3 {
			h4 {
				font-size: 14px;
				font-weight: bold;
				color: rgb(68, 68, 68);
				line-height: 17px;
			}

			.wf-bodyText {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}
	}
}

.form-control {
	height: auto;
}

.form-group {
	padding-right: 35px;
	position: relative;

	.form-control-feedback {
		// top: 24px;
	}

	label ~ .form-control-feedback {
		top: 25px !important;
	}
}

input[type=search] {
	webkit-appearance: none !important;
	box-sizing: border-box !important;
}

.list-group-item {
		background-color: transparent;
		border-left-width: 0;
		border-right-width: 0;
		border-color: rgba(0,0,0,0.05);

		&:first-child {
			border-top: 0;
		}

		&:last-child {
			border-bottom: 0;
		}
}

.page-heading {
	margin-top: 15px;
	padding: 8px 0 32px;
	overflow: hidden;
	clear: both;

	&:extend(.clearfix all);

	.backButton {
		padding-top: 7px;
		padding-bottom: 3px;
	}

	.guidance-btn {
		position: relative;
		font-size: 17px;
	}

	.modal & {
		margin-top: 0;
		padding-top: 0;
		padding-bottom: 10px;

		h1 {
			padding-top: 0;
			margin-top: 0;
		}
	}

	.options {
		float: right;
		position: relative;
		top: 10px;

		i {
			color: @wf_green;

			&:hover {
				color: @wf_green_dark;
			}
		}

		.help-url {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: center;
			background-color: transparent;
			padding: 10px 20px;
			border-radius: 3px;
			text-decoration: none;

			i.help-icon {
				font-size: 26px;
				color: @wf_green;
				margin-right: 15px;
			}

			.help-label-wrapper {
				display: flex;
				flex-direction: column;
				align-content: center;
				justify-content: flex-start;

				.help-title {
					font-size: 18px;
					font-weight: 600;
					color: @wf_black;
					text-align: left;
				}

				.help-description {
					text-align: left;
					font-size: 12px;
					color: @wf_gray_text_color;
				}

			}

			.external-icon {
				i {
					font-size: 12px;
					color: @wf_black;
					visibility: hidden;
				}
				margin-left: 15px;
			}

			&:hover {
				cursor: pointer;
				background-color: white;
				.external-icon { i { visibility: visible } }
			}
		}
	}

	p {
		clear: both;
		max-width: 600px;
		font-size: 13px;
	}

	.preTitle {
		font-size: 20px;
		padding-top: 15px;
		color: #444;
		font-weight: 300;

		a {
			color: #444;

			&:hover {
				// text-decoration: underline;
			}
		}
	}

	.preTitle + h1 {
		padding-top: 0px;
	}

	h1 {
		// color: #40B29C;
		margin: 0;
		padding: 5px 0 20px 0;
		float: none;
		clear: none;
		font-size: 32px;
		font-weight: 700;
		line-height: 34px;
		color: @wf_black_dark;
	}
}

#top-nav li > ul > li {
	position: relative;

	> ul {
		top: 0;
	}
}

.text-bold {
	font-weight: bold;
}

.text-italic {
	font-style: italic
}

.text-underline {
	text-decoration: underline;
}

.text-normal {
	// We deliberately do NOT reset font-size or word-wrap.
	font-style: normal;
	font-weight: normal;
	letter-spacing: normal;
	line-break: auto;
	text-align: left; // Fallback for where `start` is not supported
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	white-space: normal;
	word-break: normal;
	word-spacing: normal;
}

.btn-group-together {
	position: relative;
	display: inline-block;
	vertical-align: middle;

	>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	> .btn, .btn {
		max-width: 300px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		&:not(:first-child) {
			margin-left: -1px;
		}

		&:not(:first-child):not(:last-child) {
			border-radius: 0;
		}

		&:first-child {
			&:not(:last-child), &:not(.dropdown-toggle) {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}

		&:last-child {
			&:not(:first-child), &:not(.dropdown-toggle) {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}

		&.btn-inverse {
			background-color: @wf_black_dark;
		}
	}
}

.btn-group {
	margin-bottom: -3px;

	> .btn, .btn {
		max-width: 300px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		margin: 0 4px 4px 0 !important;
		border-radius: 3px !important;


		&.btn-inverse {
			background-color: @wf_black_dark;
		}
	}
}

.svg-container {
	display: inline-block;
	position: relative;
	width: 100%;
	padding-bottom: 100%; /* aspect ratio */
	vertical-align: top;
	overflow: hidden;
}
.svg-content-responsive {
	display: inline-block;
	position: absolute;
	top: 10px;
	left: 0;
}


body.navbar-midnightblue #topnav {
	box-shadow: -1000px 0 @wf_black_dark, 1000px 0 @wf_black_dark !important;
	background: @wf_black_dark;

	li.rootSubMenuSelector {
		margin-right: 22px;

		&:hover {
			> a {
				&:before {
					border: 1px solid rgba(122, 148, 176, 0.8);
					// background: rgba(255, 255, 255, 0.04);
					background: rgba(0, 0, 0, 0.5);
				}

				span, i {
					color: #fff;
				}
			}

		}


		> a {
			position: relative;
			padding-left: 14px;
			padding-right: 14px;

			&:focus { outline: none; }

			&:before {
				content: "";
				display: block;
				position: absolute;
				border: 1px solid rgba(122, 148, 176, 0.65);
				margin: 6px 0;
				background: rgba(0, 0, 0, 0.3);
				transition: background .1s, border .1s;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 1;
				border-radius: 3px;
			}

			span, i {
				color: rgb(224, 230, 236);
				transition: color .1s;
				z-index: 2;
				position: relative;
			}

			i {
				font-size: 14px;
				margin-left: 8px;
			}
		}
	}
}

.navbar-nav {
	li.active > a{
		background-color: @wf_black_dark !important;
		color:white !important;
	}
	li.active > a > i{
		background-color: @wf_black_dark  !important;
		color: white !important;
		font-weight: bold !important;
	}
	li > a:hover > i{
		color: white !important;
	}
	>li.active > ul > li.active> a{
		background-color: @wf_black_dark !important;
		color:white !important;
	}
	>li.active > ul > li.active > ul > li.active a > i{
		background-color: @wf_black_dark !important;
		color: white !important;
		font-weight: bold !important;
	}
	>li.active > ul > li.active > a > i{
		background-color: @wf_black_dark !important;
		color:white !important;
		font-weight: bold !important;
	}


	ul#top-nav {
		li ul {
			&:before {
				content: "";
				display: block;
				background: transparent;
				position: absolute;
				top: -20px;
				right: -20px;
				bottom: -20px;
				left: 0px;
			}
		}

		li li.hasChild:hover {
			a {
				box-shadow: -32px 0 30px -31px rgba(0, 0, 0, 0.15) inset;
			}
		}
	}

	&.loading {
		&:after {
			overflow: hidden;
			display: inline-block;
			vertical-align: bottom;
			-webkit-animation: ellipsis 900ms infinite;
			animation: ellipsis 500ms infinite;
			content: "\2026";
			width: 0px;
			left: 100px;
			position: absolute;
			top: 13px;
			font-size: 16px;
			font-family: bold;
		}
	}

	.dropdown-arrow {
		margin-left: 5px !important;
		margin-right: 0px !important;
	}

	.expandright-arrow {
		position: absolute;
		top: 8px;
		right: 15px;
	}

	.dropdown-menu-icons {
		li a {
			&:hover {
				i {
					color: @wf_blue_dark !important;
				}
			}

			i {
				float: left;
				width: 20px;
				font-size: 13px;
				line-height: 1.3em;

				&.glyphicon {
					line-height: 0.9em;
				}

				&.fa-sign-out {
					font-size: 14px;
					line-height: 1.1em;
				}
			}

			span {
				float: none;
				clear: none;
			}

		}

	}

	//add icon after user image
	.dropdown .currentUser .user-image:after {
		font-family: FontAwesome;
		font-size: 10px;
		content: "\f078";
		position: absolute;
		right: 11px;
		top: 17px;
		display: inline-block;

		-webkit-transition: all 300ms 0s ease-in-out;
    	transition: all 300ms 0s ease-in-out;

		&:hover {
			transform: 			rotate(90deg);
			-webkit-transform: 	rotate(90deg);
   			 -moz-transform: 	rotate(90deg);
    		-o-transform: 		rotate(90deg);
		}
	}

	.dropdown.open .currentUser .user-image:after {
		transform: 			rotate(180deg);
		-webkit-transform: 	rotate(180deg);
		-moz-transform: 	rotate(180deg);
		-o-transform: 		rotate(180deg);
	}

	//move user menu to the right to make room for icon (above)
	.dropdown .currentUser{
		padding-right: 30px !important;
	}

	@keyframes ellipsis {
		to {
			width: 9px;
		}
	}

	@-webkit-keyframes ellipsis {
		to {
			width: 9px;
		}
	}
}

#wrapper {
	min-height: 100%;
	padding-top: 50px;
}

.fixed-layout header, .fixed-layout #wrapper, .fixed-layout #headernav {
	width: 1300px;
}

.static-content-wrapper {
	background-color: transparent;
	overflow: visible !important;

	padding-bottom: 400px;
	/*
		To overcome:
		@media screen and (max-width: 767px)
		.static-content-wrapper {
			overflow: hidden;
		}
		in laylout.less
		because when box-shadow is used (like in .PublicInfluencesController .extendedBarBg)
		the overflow:hidden hides the shadow.
	*/

	footer {
		border-top: 1px solid #e1e1e1;
		padding: 17px 0 15px;

		ul {
			li {
				padding: 0 10px;

				&, a {
					color: #888;
				}
			}
		}
	}
}

@media (min-width: 768px) {
	.modal-dialog {
		margin-top: 55px;

		// left: 8px; //fix to center the modal

		&.modal-sm {
			max-width: 800px;
			width: 800px;
		}

		&.modal-lg {
			max-width: 1196px;
			width: auto;
		}
	}
}

.modal {
	overflow-y: scroll !important;
}

.modal-body {
	padding: 20px !important;
}


.modal {

    // opacity: 0;
    // visibility: hidden;
    // overflow: hidden;
    // -webkit-perspective: 1000px;
    // perspective: 1000px;
    // transition: opacity 0.5s, visibility 0s 0.5s;
    // -webkit-transition: opacity 0.5s, visibility 0s 0.5s;
}

.popover {
	.popoverBoxShadow();
}

.modal-close, .popover-close {
	@size: 52px;
	position: absolute;
	top: 0;
	right: 0;
	width: @size;
	height: @size;
	cursor: pointer;
	opacity: 0.6;
	z-index: 1;
	text-align: center;
	line-height: @size;
	font-size: 17px;
	transition-duration: .08s;

	&:hover {
		opacity: 0.95;
		font-size: 21px;
	}
}

.modal.bootbox {
	z-index: 2010;

	.bootbox-body {
		font-size: 13px;
		line-height: 17px;
	}

	.bootbox-close-button.close {
		font-size: 30px;
		margin-top: -4px;
	}

	.modal-footer:before{
		display: inline-block;
	}
}

.modal.bootbox + .modal-backdrop {
	z-index: 2000;
}

.modal.bootbox {
	&.centerWithIcon {
		.modal-dialog {
			width: 500px;
			margin-top: 125px;
		}

		.bootbox-body {
			overflow: hidden;
			padding: 15px 10px 10px;
		}

		.bootbox-icon {
			display: block;
			margin-right: 15px;
			font-size: 37px;
			color: #ddd;
			float: left;
			clear: none;
		}

		.bootbox-text {
			float: none;
			clear: none;
			overflow: hidden;
			padding-top: 9px;
			// font-size: 13px;
		}
	}
}

//Special styling for modals with the purpose of deleting content
.modal-confirmDelete {

	.modal-dialog {
			width: 600px;
		}

	.modal-body {
		text-align: center;
		font-size: 14px;
		white-space: pre-wrap;
		padding: 40px 70px !important;

		i.fa {
			color: #D1563E;
			font-size: 75px;
		}

		.headerText {
			font-size: 15px;
			color: #555;
			padding-top: 20px;
			padding-bottom: 5px;
			font-weight: bold;
		}

		strong {
			color: #555;
		}
	}
}

// .in.modal {
//     opacity: 1;
//     visibility: visible;
//     -webkit-transition: opacity 0.5s;
//     transition: opacity 0.5s;
// }

// .modal .modal-dialog {

//     -webkit-transform-style: preserve-3d;
//     transform-style: preserve-3d;
//     -webkit-transform: translate3d(0,0,150px);
//     transform: translate3d(0,0,150px);
//     -webkit-transition: -webkit-transform 0.5s;
//     transition: transform 0.5s;

// }


// .in.modal .modal-dialog {
//     -webkit-transform: translate3d(0,0,0);
//     transform: translate3d(0,0,0);
// 	// opacity: 1;
// }

body.navbar-midnightblue.layout-public {
	padding: 51px 0 0 0;

	&.white{
		#topnav{
			box-shadow: none !important;
			background: transparent !important;
			// box-shadow: 1000px 0 rgba(255,255,255,0.9), -1000px 0 rgba(255,255,255,0.9) !important;
			// background: rgba(255,255,255,0.9);
			height: 64px;
			position: static;

			.container-fluid {
				> * {
					display: none;
				}
			}


			&::after {
				// content: "";
				// display: block;
				// height: 1px;
				// width: auto;
				// position: fixed;
				// top: 64px;
				// left: 0;
				// right: 0;
				// background-color: rgba(0, 0, 0, 0.11)
			}

			.navbar-header {
				display: block !important;

				.navbar-brand {
					background-size: contain;
					background-position: 0 center;
					background-repeat: no-repeat;
					width: 105px;
					height: 60px;
				}
			}
			.navbar-header {
				.navbar-brand {
					background-image: url(/assets/img/logos/worldfavor_logo_wordmark_black.svg);
					background-size: contain;
					background-position: 0 center;
					background-repeat: no-repeat;
					width: 105px;
					height: 60px;
					margin: 0;
					border: none !important;
				}
			}
		}

		button.btn {
			border-radius: 0;
			background-color: transparent;
			// background-color: #F8F8F8;
			font-size: 14px;
			font-weight: 300;
			box-shadow: 0 0 0 1px #B6B6B6 inset;

			&.btn-inverse, &.selected {
				background-color: #3B3B3B;
				color: #efefef;
				box-shadow: 0 0 0 1px #3B3B3B inset;
			}

			&.btn-primary {
				color: #333;
			}
		}

		.btn-group {
			button.btn {
				border-radius: 0 !important;
				font-size: 12px;
			}
		}
	}

	&.dark{
		margin: 0;
		padding: 0 !important;

		.static-content-wrapper {
			padding-bottom: 0px;
		}

		#topnav{
			box-shadow: none !important;
			background: #213C50;
			box-shadow: 1000px 0 #213C50, -1000px 0 #213C50 !important;
			height: 64px;
			position: static;

			.container-fluid {
				> * {
					display: none;
				}
			}


			&::after {
				// content: "";
				// display: block;
				// height: 1px;
				// width: auto;
				// position: fixed;
				// top: 64px;
				// left: 0;
				// right: 0;
				// background-color: rgba(0, 0, 0, 0.11)
			}

			.navbar-header {
				display: block !important;

				.navbar-brand {
					background-size: contain;
					background-position: 0 center;
					background-repeat: no-repeat;
					width: 105px;
					height: 60px;
				}
			}
			.navbar-header {
				@media screen and (max-width: @screen-xs-max){
					.navbar-brand {
						width: 80px !important;
						margin-left: 20px !important;
					}
				}
				.navbar-brand {
					border: none !important;
					margin: 0;
					background-image: url(/assets/img/logos/worldfavor_logo_wordmark_black.svg);
					background-size: contain;
					background-position: 0 center;
					background-repeat: no-repeat;
					width: 105px;
					height: 60px;
				}
			}

			.wf-navbar {
				display: none !important;
			}

			.navbar-toggle {
				display: none !important;
			}
		}
	}

	background-color: #fff;

	.a0-lock-container {
		display: none !important;
	}

	.sunburstChartInfo {
		display: none;
	}

	// #topnav {
	// 	box-shadow: none !important;
	// 	background: transparent !important;
	// 	// box-shadow: 1000px 0 rgba(255,255,255,0.9), -1000px 0 rgba(255,255,255,0.9) !important;
	// 	// background: rgba(255,255,255,0.9);
	// 	height: 64px;
	// 	position: static;

	// 	> * {
	// 		display: none;
	// 	}


	// 	&::after {
	// 		// content: "";
	// 		// display: block;
	// 		// height: 1px;
	// 		// width: auto;
	// 		// position: fixed;
	// 		// top: 64px;
	// 		// left: 0;
	// 		// right: 0;
	// 		// background-color: rgba(0, 0, 0, 0.11)
	// 	}

	// 	.navbar-header {
	// 		display: block !important;

	// 		.navbar-brand {
	// 			background-size: contain;
	// 			background-position: 0 center;
	// 			background-repeat: no-repeat;
	// 			width: 105px;
	// 			height: 60px;
	// 		}
	// 	}
	// }

	footer {
		display: none;
	}

	wf-question-answering-history, wf-measure-answering-history {
		display: none;
	}

	wf-measure-answering .measure-answer {
		// padding-left: 80px;

		.value {
			width: auto;
			word-break: break-all;
		}
	}

	.reportedBy {
		display: none;
	}

	.guidance-btn {
		display: none;
	}

	span[translate="Found"] {
		display: none;
	}

	wf-item-relations {
		// &.useTags {
		// 	display: none;
		// }

		// &.useOverlay {
		// 	display: none;
		// }
	}

	wf-item.template32 {
		wf-hierarchy {
			display: block;
			margin-top: -20px;
		}

		.wf-headerText.level1 {
			font-size: 25px;
			font-weight: 600;
			background-color: #e6e6e6;
			padding: 8px 8px;
			line-height: 1.1em;
			margin-bottom: 15px;
		}

		.header.level2 {
			font-size: 21px;
			font-weight: 500;
			margin-top: 12px;
		}

		.subListInBox-gray {
			background: #fff;
		}

		wf-hierarchy {
			.col-xs-7 {
				width: 100%;
			}
		}

		wf-question-answering {
			display: block;
			text-align: left;
		}
	}

	.modal wf-item.template32 {
		background: transparent;

		.page-heading {
			display: none;
		}

		.mainSearch {
			display: none;
		}

		.filterBar1 {
			border: 0;
		}
	}

	wf-item.template33 {
		h4 {
			font-size: 14px;
			font-weight: 500;
		}
	}

	.influenceViewer {
		.influence-details, .influence-content-label {
			display: none;
		}
	}

	.panel {
		background-color: #fff;
		box-shadow: 0 0 0 1px #e4e4e4 !important;
		border-radius: 0;
	}

	wf-item .header {
		font-weight: bold;
		color: #333;
	}

	.modal-body {
		font-size: 13px;
		color: #333;
	}

	.modal-content {
		border-radius: 0;
		border: 0;
		box-shadow: 0 0 50px rgba(0,0,0,0.1);
	}
}

.globalTooltip {
	position: absolute;
	max-width: 300px;
	text-align: left;
	width: auto;
	height: auto;
	font-size: 12px;
	padding: 4px 7px 4px;
	background: #333;
	color: #fff;
	border: 0;
	border-radius: 2px;
	pointer-events: none;
	opacity: 0.9;
	z-index: 100000;
	// transition-duration: .1s;
}

.fade-bottom, .fade-bottom-scroll {
	overflow: hidden;
	position: relative;

	&.fade-bottom-scroll {
		&:after {
			right: 20px;
		}
	}

	&:after {
		content: '';
		display: block;
		height: 16px;
		width: auto;
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;

		background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
		background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
		background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );

	}
}

// .modal-dialog {
// 	&:before {
// 		content: "";
// 		display: block;
// 		width: 20px;
// 		height: 20px;
// 		background-color: rgba(200,200,200, 0.7);
// 		position: absolute;
// 		top: 0;
// 		right: -20px;
// 		cursor: pointer;
// 		border-radius: 3px;
// 	}
// }

.template59 {
	padding-bottom: 100px;

	.list-group-item {
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 15px;
		margin-bottom: 10px;
	}

	wf-item-component {
		border-bottom: 1px solid #f2f2f2;

		&:last-child {
			border-bottom: none;
		}
	}

	@media screen and (min-width: 0) and (max-width: 480px) {
		wf-item-component {
			.sign-in-button {
				font-size: 11px !important;
				padding: 5px 10px !important;
			}
		}
	}
}

.wf-page {
	min-height: 500px;
	transition: background-image 3s ease;
	background: no-repeat center center;
	/*background-image: url(../../assets/img/switch.png);*/

	&.loading {
		transition: background-image 0s ease;
		background-image: url(../../assets/img/loaders/wf_green.gif)
	}
}


.pos-abs-topright {
	position: absolute;
	top: @panelPadding;
	right: @panelPadding;
}

button, div.btn {
	> i.fa {
		font-size: 14px;

		&:before {
			display: block;
			position: relative;
			top: 1px;
		}
	}
}

.loader-spinner-mainBg {
	background: url("/assets/img/loaders/spinner_mainBg.gif") center center no-repeat;
}

// .loader-large,
.loader-medium, .loader-wf, .loader-small, .loader-large {
	background-position: center center;
	background-repeat: no-repeat;

	> * {
		visibility: hidden;
	}

	-webkit-animation: opacityFadeIn 1s; /* Safari 4+ */
	-moz-animation:    opacityFadeIn 1s; /* Fx 5+ */
	-o-animation:      opacityFadeIn 1s; /* Opera 12+ */
	animation:         opacityFadeIn 1s; /* IE 10+, Fx 29+ */


	&.height-100 {
		min-height: 100px;
	}

	&.height-200 {
		min-height: 200px;
	}

	&.height-300 {
		min-height: 300px;
	}

	&.height-400 {
		min-height: 400px;
	}

	&.height-500 {
		min-height: 500px;
	}

	&[wf-loader-message]::after {
		content: attr(wf-loader-message);
		display: block;
		width: 100px;
		height: 30px;
		position: absolute;
		top: 60%;
		left: 50%;
		z-index: 1;
		color: #bfbfbf;
		margin-left: -50px;
		text-align: center;
		font-size: 12px;
	}

	&.loader-slow {
		&::before {
			animation-duration: 2.5s;
		}
	}
}

.loader-medium {
	// background-image: url("/assets/img/loaders/spinner_mainBg.gif");

	.spinner(30px);

	&.white {
		// background-image: url("/assets/img/loaders/spinner_whiteBg.gif");
	}

	// &.bg-gen {
	// 	background-image: url("../images/loaders/genericBox_loop1_medium.gif");
	// }
}


.loader-small {
	.spinner(20px);
}

.loader-large {
	.spinner(60px);
}

.loader-wf {
	background-image: url("/assets/img/loaders/wf_green.gif");
}

.sisBox {
	width: 242px - 6px;
	height: 57px;
	display: block;
	margin: auto;
	margin-top: 15px;
	margin-bottom: 0;
	background: url("/assets/img/sisCredit.png") no-repeat -3px -2px;
	/*border: 2px solid @masterBgColor;*/
	cursor: pointer;
	line-height: 15px;
	font-size: 11px;
	color: #999;
	text-decoration: none !important;

	> span {
		display: block;
		padding: 3px 5px 0 70px;
	}

	.link {
		color: #6aafae;
		font-size: 11px !important;
	}

	&:hover {
		background-position: -3px (-58px - 2px);
		color: #555;

		.link {
			text-decoration: underline;
			color: #1b8684;
		}
	}
}

textarea.code {
	width: 100%;
	height: 50px;
	font-family: Menlo,Monaco,Consolas,"Courier New",monospace;
	font-size: 13px;
	resize: none;
	border: 0;
	background-color: rgba(0, 0, 0, 0.06);
	padding: 15px 20px;
}

.wf-goto {

	& {
		cursor: pointer;
	}
}

wf-item.template1 {

}

wf-item.template2 {

}

wf-item.template3 {

}

.dropdown-header {
	padding: 3px 12px;
	color: #aaa;
	pointer-events: none;
}

.dropdown-submenu {
	position: relative;

	> .dropdown-menu {
		top: 0;
		left: 100%;
		margin-top: -5px;
		margin-left: -1px;
	}

	> a:after {
		display: block;
		content: " ";
		float: right;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: solid;
		border-width: 5px 0 5px 5px;
		border-left-color: #ccc;
		margin-top: 3px;
		margin-right: -5px;
	}

	&:hover {
		> .dropdown-menu {
			display: block;
		}

		> a:after {
			border-left-color: #999;
		}
	}

	&.pull-left {
		float: none;

		> .dropdown-menu {
			left: -100%;
			margin-left: 10px;
		}
	}
}

.btn {
	// font-size: 13px;

	&.btn-sm, .btn-group-sm > & {
		// font-size: 12px;
	}

	&.btn-sq, &.btn-circular {
		padding-left: 0;
		padding-right: 0;
		text-align: center;
		width: 34px;
		height: 34px;

		&.btn-sm {
			width: 27px;
			height: 27px;
		}

		&.btn-lg {
			width: 43px;
			height: 43px;
		}
	}



	&.btn-rounded {
		border-radius: 34px;

		&.btn-sm {
			border-radius: 27px;
		}

		&.btn-lg {
			border-radius: 43px;
		}
	}

	&.btn-circular {
		border-radius: 100px;
		outline: none !important;

		&:focus, &:active:focus {
			box-shadow: 0 0 0 2px #A4C6FD !important;
		}
	}

	&[disabled], &.disabled {
		&.btn-default {
			opacity: 0.3;
		}
	}

	&.loading {
		&, * {
			color: transparent !important;
		}

		& {
			background-repeat: no-repeat;
			background-position: center center;
			.spinner(22px);

			&.btn-sm {
				.spinner(16px, "dark", 6.5);
			}
		}

		&, &.btn-default {
			// background-image: url("../img/loaders/spinner_btn-default.gif");
			background-color: #ddd !important;
		}

		&.btn-white {
			// background-image: url("../img/loaders/spinner_btn-white.gif");
			background-color: #fff !important;
		}

		&.btn-primary {
			// background-image: url("../img/loaders/spinner_btn-primary.gif");
			background-color: @wf_blue !important;
		}

		&.btn-hollow {
			// background-image: url("../img/loaders/spinner_btn-primary.gif");
			background-color: transparent !important;
		}

		&.btn-black {
			background-color: @wf_black !important;
			.spinner(22px, #fff);
		}
	}

	&.btn-hollow {
		color: #707980;
		border-color: #fafafa;

		&, &:focus, &:active {
			background-color: transparent;
		}

		&:hover {
			background-color: rgba(0,0,0,0.05);
		}
	}

	&.btn-semidark {
		color: rgba(0, 0, 0, 0.56);
		background-color: rgba(0, 0, 0, 0.05);
		border-color: rgba(0, 0, 0, 0);

		&:hover, &:focus, &:active {
			background-color: rgba(0,0,0,0.1);
			color: rgba(0, 0, 0, 0.6);
		}
	}

	&.btn-black {
		color: #efefef;
		background: @wf_black;
		border-color: @wf_black;

		&:hover, &:focus, &:active {
			background-color: lighten(@wf_black, 5%);
		}
	}

	&.btn-guidance {
		background-color: @wf_green;
		color: #fff;
		margin-left: 10px;

		i {
			color: #fff !important;
		}

		&:hover {
			background-color: @wf_green_dark;
		}
	}

	&.btn-videoGuidance {
		background-color: transparent;
		box-shadow: inset 0 0px 0px 1px rgba(46, 204, 113,0.28);
		transition: all 200ms ease-in-out;

		&:hover {
			box-shadow: inset 0 0px 0px 1px rgba(46, 204, 113,0.38);
			background-color: rgba(255,255,255,0.3);
		}
	}

	&.btn-create {
		> span::before {
			.fa-icon("\f067"); // fa-plus
			font-size: 14px;
			padding-right: 10px;
			display: block;
			float: left;
			position: relative;
			top: 1px;
		}
	}

	&.btn-create-dropdown {
		> span::after {
			.fa-icon("\f078"); // fa-chevron-down
			font-size: 14px;
			padding-left: 10px;
			display: inline-block;
			position: relative;
			top: -1px;
		}
	}

	&.wf-btn-link{
		color: @wf_gray;
		font-weight: 500;
		border-radius: 0;
		border: none;
		font-size: 15px;
		box-shadow: none;
		padding-top: 6px;
		padding-bottom: 7px;

		&,
		&:active,
		&.active,
		&[disabled],
		fieldset[disabled] & {
			background-color: transparent;
			box-shadow: none;
		}
		&,
		&:hover,
		&:focus,
		&:active {
			outline: none !important;
			border-color: transparent;
		}
		&:hover {
		// &:focus {
			color: #84919D;
			background-color: transparent;
		}
		&[disabled],
		fieldset[disabled] & {
			&:hover,
			&:focus {
			color: @btn-link-disabled-color;
			text-decoration: none;
			}
		}
	}

	&.btn-noBorder {
		border: 0 !important;
		box-shadow: none;
	}

	&.btn-pulsating {
		.box-shadow-pulse-effect(@wf_gray_dark);
	}
}

.modal-dialog {
	box-shadow: 0 5px 50px rgba(0,0,0,0.1);

	.modal-footer {
		.btn {
			min-width: 85px;
		}
	}

	form.loading, .loading {
		.modal-body {
			height: 250px;
			overflow: hidden;
			.spinner(22px);

			> * {
				visibility: hidden
			}
		}

		.modal-footer {
			* {
				display: none;
			}

			button.btn-cancel {
				display: inline-block;
			}
		}
	}
}

.schema-form-textarea {
	textarea {
		height: 150px;
		resize: vertical;
	}
}

.offset-paddedPanel {
	margin: -20px;
}

// .panel-filter {
// 	.list-group-item {
// 		padding: 15px 20px;
// 	}
// }

.counter-badge {

}

.relationListToggler {
	> span {
		text-transform: lowercase;

		&:first-child {
			text-transform: none;
		}
	}
}

panel {
	&.loading {
		.panel-body {
			> * {
				visibility: hidden;
			}
			background: #fff url("../img/loaders/spinner_whiteBg.gif") no-repeat center center;
			min-height: 300px;
		}
	}
}

.worldfavor-screenLoader {
	position: fixed;
	top: 0;
	left: 0;
	background-color: fade(@wf_gray_light, 40%);
	width: 100%;
	height: 100%;
	z-index: 10000;
	text-align: center;
	display: none;

	div {
		font-size: 32px;
		font-weight: bold;
		width: 100%;
		position: absolute;
		top: 50%;
		margin-top: -30px;
		color: #40b29c;
		// text-shadow: 0 0 10px rgba(240, 239, 231, 1), 0 0 10px rgba(240, 239, 231, 1);
	}


	&.loader {
		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 40%;
			left: 50%;
			margin-top: -100px;
			margin-left: -100px;
			width: 200px;
			height: 200px;
			border-radius: 20px;
			background: fade(@wf_gray_light, 20%) url("/assets/img/loaders/wf_green.gif") no-repeat center center;
		}

		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 40%;
			left: 50%;
			margin-top: -55px;
			margin-left: -55px;
			width: 110px;
			height: 110px;
			background-color: @wf_gray_light;
			box-shadow: 0 0 55px 30px @wf_gray_light;
		}
	}
}

.auth0-lock-container {
	.auth0-lock {
		top: 0;
		right: 0;
	}
}

.a0-lock-container.withWorldfavorForm {
	.form-group.auth0-mimic {
		padding-right: 0;

		label {
			display: none;
		}

		.form-control-feedback, .sr-only {
			display: none !important;
		}

		.help-block {
			text-align: left;
			margin-top: 0;
			height: 0;
			display: block !important;
			transition-duration: .07s;
		}

		&.has-error .help-block {
			margin-top: 8px;
			height: auto;
		}

		.input-group {
			border-radius: 3px;
			border: 1px solid rgb(241, 241, 241);
			position: relative;
			background: rgb(241, 241, 241);
			padding-left: 40px;
			-webkit-transition: border-color .8s;
			-moz-transition: border-color .8s;
			overflow: hidden;
			transition: .25s ease-in;
			width: 100%;

			i {
				font-size: 14px;
				z-index: 1000;
				top: 13px;
				left: 13px;
				position: absolute;
				color: rgb(102, 102, 102);
			}

			input[type="password"], input[type="text"] {
				background-color: rgb(255, 255, 255) !important;
				border: 0 !important;
				padding: 0 14px !important;
				right: 0 !important;
				height: 40px !important;
				font-size: 13px !important;
				width: 100% !important;
				border-radius: 0 2px 2px 0 !important;
				-webkit-box-sizing: border-box !important;
				-moz-box-sizing: border-box !important;
				box-sizing: border-box !important;
				position: relative !important;
				color: rgba(0,0,0,.89) !important;
			}
		}
	}

	#a0-lock.a0-theme-default .a0-panel {
		width: 320px;
	}

	.a0-top-header {
		background-color: #ebebeb;
	}

    .a0-avatar-guest {
      background-color: white;
    }

	.terms {
		text-align: left;

		.checkbox-agreeToTerms {
			width: 25px;
			height: 25px;
			margin-top: 1px;
			cursor: pointer;
			border: 2px solid #666;
			border-radius: 1px;
			background-color: #fff;
			margin-right: 10px;
			color: #666;
			transition: border-color .25 ease;

			&.highlight {
				animation: pulse .25s ease;
				border-color: #b4270f;
			}

			.checkmark {
				width: 9px;
				height: 15px;
				border: solid #555;
				border-width: 0 4px 4px 0;
				transform: rotate(45deg);
				margin: auto;
				margin-top: 1px;
			}

			&:hover {
				border-color: #333;

				.checkmark {
					border-color: #222;
				}
			}
		}
	}

}


.alert {
	background-repeat: no-repeat;
	background-position: 48% center;

	&.alert-loading {
		// border-left-color: #ccc;
		border-left: 0;
		background-color: #fafafa;

		box-shadow: 2px 0 rgb(197, 197, 197) inset, 0 0 0 1px rgb(232, 232, 232) inset, 0 0 2px 2px rgba(255, 255, 255, 0.21) inset;

		> * {
			visibility: hidden;
		}

		background-image: url("/assets/img/loaders/spinner_subListInBox-gray.gif")


		// &.alert-warning {
		// 	background-image: url("/assets/img/loaders/spinner_warning.gif")
		// }

		// &.alert-success {
		// 	background-image: url("/assets/img/loaders/spinner_success.gif")
		// }
	}

	&.alert-lightgray {
		border-left-color: #ccc;
		background-color: #efefef;
	}
}

.fulfill-green {
	color: #2ECC71;
}

.guidance-modal {
	.guidance-text {
		white-space: pre-wrap;
	}

	.guidance-text + .guidance-video {
		padding-top: 30px;
	}
}

.ui-pnotify-context {
	position: fixed;
	top: 70px;
	right: 24px;
	width: 300px;
	height: 100%;
	pointer-events: none;
	// border: 1px solid red;
}

.ui-pnotify {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	background-color: #fff;
	cursor: default;

	.ui-pnotify-container {
		// opacity: 0.8;
		// transition: opacity .1s;

		&:hover {
			// opacity: 1 !important;
		}
	}

	.wf-bodyText {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
}


wf-notification {
	.body {
		// &.clickable{
		// 	cursor: pointer;
		// }

		> div {
			float: none;
			clear: none;
			overflow: hidden;
			width: auto;

			&.tools {
				float: right;
				padding-left: 50px;
				overflow: visible;
				text-align: right;
			}

		}

		.img {
			float: left;
			overflow: hidden;
			position: relative;
			width: 30%;
			max-width: 40px;
			min-width: 40px;
			min-height: 40px;
			margin-right: 10px;

			.bgImage {
				background-color: #ddd;
			}

			&.noImage {
				&:before {
					content: "";
					display: block;
					clear: none;
					float: left;
					overflow: hidden;
					width: 100%;
					padding-top: 100%; /* padding-top percentages are relative to the containing block's width. */
					background-color: rgba(0,0,0,0.1);
					box-shadow: 0 0 30px rgba(0, 0, 0, 0.02) inset;
				}
			}

			img {
				width: 80%;
				height: auto;
				float: left;
				margin-top: 2px;
			}

			&:after {
				content: "";
				display: block;
				float: left;
				clear: none;
				overflow: hidden;
				width: 20%;
				height: 1px;
			}
		}

		.expand-trigger {
			cursor: pointer;
			padding: 5px 7px 10px 17px;
			position: relative;
			top: -5px;
			border-radius: 3px;

			&:hover {
				// background-color: #eee;
				color: #333;
			}
		}

		.icon-attempt {
			color: #F0A400;
			padding-right: 5px;
		}

		.eventSentence {
			margin-bottom: 0;
			font-size: 13px;
			color: #333;
			line-height: 15px;
			line-height: 16px;

			&:not(.noTextTransform) {
				text-transform: lowercase;
			}

			.author {
				text-transform: none;
			}

			span.itemText {
				text-transform: none;
				font-weight: bold;
			}
		}

		.event-details {
			.well {

			}
		}

		.time {
			margin: 0 !important;
			padding: 0 !important;
			float: none !important;
			text-align: left !important;
			vertical-align: baseline !important;
			line-height: 16.8px !important;
			font-size: 12px !important;
		}
	}

	.itemPreview {
		padding-top: 15px;
		zoom: 89%;
		opacity: 0.9;

		.img {
			display: none;
		}

		.wf-headerText {
			color: @wf_blue !important;
		}

		.reportedBy {
			display: none;
		}
	}
}

wf-item-relations {
	&[use-tags] {
		.tagsLabel {
			float: left;
			clear: none;
			padding-right: 10px;
			margin-top: 3px;
			font-size: 11px;
		}

		.tagsList {
			float: none;
			clear: none;
			width: auto;
			overflow: hidden;
		}

		a {
			display: inline-block;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			// cursor: pointer;

			// background-color: rgba(0, 0, 0, 0.09);
			background-color: @wf_gray_light;
			max-width: 250px;
			border-radius: 2px;
			margin-right: 4px;
			padding: 3px 6px 3px;
			// box-shadow: 0 -1px 0 0px rgba(0,0,0,0.05) inset;

			font-weight: 300;
			color: #444;
			font-size: 11px;

			&:hover {
				background-color: rgba(0, 0, 0, 0.11);
				/*text-decoration: underline;*/
			}
		}
	}
}

.griDisclaimerInfo {
	h4 {
		margin-top: 0;
		margin-bottom: 15px;
	}

	img {
		width: 80%;
		display: block;
		margin: 30px auto 20px;
	}

	.disclosure {
		padding: 14px;
		border-width: 2px;
		border-style: solid;
		border-radius: 3px;
		font-weight: bold;
		color: #383838;

		&.orange {
			border-color: #d77236;
		}

		&.blue {
			border-color: #2c456f;
		}

		&.green {
			border-color: #589a77;
		}

		&.purple {
			border-color: #642f8a;
		}
	}

	h3 {
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 10px;
		margin-top: 26px;

		&.griFirstHeader {
			margin-top: 0px !important;
		}

		&.orange {
			color: #d77236;
		}

		&.blue {
			color: #2c456f !important;
		}

		&.green {
			color: #589a77;
		}

		&.purple {
			color: #642f8a;
		}
	}

	h2 {
		font-size: 26px;
		font-weight: bold;
		margin-bottom: 26px;
		margin-top: 26px;

		&.griFirstHeader {
			margin-top: 0px !important;
		}

		&.orange {
			color: #d77236;
		}

		&.blue {
			color: #2c456f !important;
		}

		&.green {
			color: #589a77;
		}

		&.purple {
			color: #642f8a;
		}
	}

	ol {
		&.olNumericalList {
			counter-reset: item;

			li {
				// display: block;
			}

			li:before {
				content: counters(item, ".") " ";
				counter-increment: item;
			}
		}

		li {
			margin-top: 10px;
		}
	}

	.indent {
		padding-left: 22px;
	}

	.reportingRecommendations {
		p {
			margin-top: 10px;
		}
	}

	span {
		width: 100%;
		display: block;
		margin: 24px 0 16px 0;
		border-top: 1px solid @wf_gray;
	}

}


.isoDisclaimerInfo {
	h4 {
		margin-top: 0;
		margin-bottom: 15px;
	}

	img {
		width: 80%;
		display: block;
	}

	.col1 {
		width: 62%;
		margin-right: 2%;
		display: inline-block;

		p:first-child {
			margin-bottom: 12px;
		}
	}

	.col2 {
		width: 35%;
		display: inline-block;
		vertical-align: top;

		img {
			margin: 0px auto 20px;
		}
	}
}

.stickyBreadcrumbs-container{
	position: fixed;
	top: 50px;
}


.wf-form-picker.single {
	border-bottom: 1px solid #ddd !important;
	margin-bottom: 20px;
	padding-bottom: 5px;

	.borderBox {
		// border-left: 1px solid #ddd;
		// padding-left: 10px;
	}

	.rootStatusText {
		display: block;
	}

	.rootObjectPreview {
		padding-top: 10px;
		padding-bottom: 5px;
	}
}

.wf-form-picker {
	&.multiple {

		.item-container {
			&.has-error {
				border-color: rgba(231, 76, 60, 0.6);
			}

			&.has-success {
				border-color: rgba(52, 152, 219, 0.6);
			}
		}

		.tags {
			margin: -3px;

			.btn {
				margin: 3px;

				&.openPicker {

				}

				&.tagItem {
					cursor: default;
					border-left: 3px solid #85c1e9;
					padding-left: 13px;
					background: transparent !important;
					max-width: 100%;
					white-space: normal;
					text-align: left;

					.removeItem {
						cursor: pointer;
						margin: -8px -15px -10px 0;
						padding: 6px 13px 0 15px;
						font-size: 15px;
						height: 32px;
						color: @wf_gray;

						&:hover {
							color: @wf_red;
						}
					}
				}
			}

		}
	}
}

.sunburstChartInfo {
	margin-top: 20px;
	border: 1px dashed #ccc;
	border-radius: 3px;
	padding: 10px 12px;
	text-align: center;
}

[hm-read-more] {
	.toggler {
		white-space: nowrap;
	}

	.readMore a:after {
		content: "";
		display: inline-block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 6px 5px 0 5px;
		border-color: #007bff transparent transparent transparent;
		position: relative;
		top: -1px;
		margin-left: 5px;
	}

	.readLess {
		margin-left: 6px;
		a:after {
			content: "";
			display: inline-block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 5px 6px 5px;
			border-color: transparent transparent #007bff transparent;
			position: relative;
			top: -1px;
			margin-left: 5px;
		}
	}
}

.wf-headerText {
	a {
		text-decoration: none !important;
		color: #333;

		.external-link {
			display: inline-block; //because of text-decoration
			margin-left: 10px;
			color: @wf_gray;
			font-size: 12px;
			font-weight: 300;

			i.fa {
				margin-right: 5px;
			}

			&:hover {
				cursor: pointer;
				color: @wf_blue;
			}
		}

		&:hover {
			color: @wf_blue;
			text-decoration: underline;
			.external-link {
				cursor: pointer;
				color: @wf_blue;
			}
		}
	}
}

body div.tooltip{
	@tooltipColor: rgba(24, 42, 60, 0.98); // Slight darker wf_black to compensate for opacity

	&.top .tooltip-arrow{
		border-top-color: @tooltipColor;
	}

	&.right .tooltip-arrow{
		border-right-color: @tooltipColor;
	}

	&.left .tooltip-arrow{
		border-left-color: @tooltipColor;
	}

	&.bottom .tooltip-arrow{
		border-bottom-color: @tooltipColor;
	}

	.tooltip-inner{
		background-color: @tooltipColor;
		color: white;
		text-align: center;
		border-radius: 2px;
		font-size: 11px;
	}
}

.mCustomScrollbar {
	overflow: hidden;
}

.mCustomScrollbar.noScroll_showAll {
	overflow: visible !important;
	max-height: none !important;
	height: auto !important;
	padding-bottom: 30px !important;

	.mCSB_container {
		overflow: visible !important;
		margin-right: 0 !important;
	}

	.mCustomScrollBox {
		overflow: visible !important;
	}

	.mCSB_scrollTools {
		display: none !important;
	}
}

div[wf-search-quick] {
	.form-control {
		transition: border-top-color .0s, border-bottom-color .0s, border-right-width 0s;

		&:focus {
			border-color: #e0e0e0;
		}
	}

	.input-group-addon.icon {
		padding: 8px 4px 8px 14px;
		min-width: auto;
		border-right: 0;
		color: rgb(103, 116, 129);
		font-size: 14px;

		i {
			width: 14px;
			height: 14px;
			display: inline-block;

			&.loading {
				top: 2px;
				left: -1px;
				.spinner(18px);
			}
		}
	}

	.input-group-addon {
		transition: border-color .0s;
	}

	.input-group-addon {
		&.icon {
			min-width: auto;
			padding-left: 13px;
			border-right: 0;
			color: rgb(103, 116, 129);
			font-size: 14px;
		}

		&.search-count {
			color: #9ca4b0;
			border-right: 0;
			display: none;
			min-width: auto;
			line-height: 1.5em;
		}

		&.cancel {
			border-left: 0;
			display: none;
		}

		&.select-icon {
			border-left: 0;
			color: @wf_gray_text_color;
		}
	}

	input {
		border-left: 0;
		&::-ms-clear {display: none;}
	}

	.cancel {
		cursor: pointer;
		color: rgb(103, 116, 129);
		font-size: 16px;
		text-align: center;
		i {line-height: 18px;}
	}

	.input-group {
		&:not(.collapseMode) {

			.input-group-addon.icon {
				transition: padding-right .1s;
			}

			&.search-active {
				.input-group-addon.icon {
					background: #2d3e50;
					color: #fff;
					border-color: #2d3e50;
					padding-right: 14px;
				}

				.form-control {
					border-top-color: rgba(45, 62, 80, 0.6);
					border-bottom-color: rgba(45, 62, 80, 0.6);
					border-right-width: 0;
				}

				.input-group-addon.cancel {
					border-color: rgba(45, 62, 80, 0.6);
					display: table-cell;
				}

				.input-group-addon.search-count {
					border-color: rgba(45, 62, 80, 0.6);
					display: table-cell;
				}
			}
		}

		&.collapseMode {
			.input-group-addon{
				padding: 0 14px;
			}

			.input-group-addon.icon {
				border: 0;
				cursor: pointer;
				i{ font-size: 18px;}

				&.search-active{
					i{ color: #2685EE;}
				}

				&.no-results{
					i{ color: @wf_red;}
				}
			}

			.form-control{
				height: 26px;
				padding: 0;
				border: 0;
				border-radius: 0;
				border-bottom: 1px @wf_gray solid;

				&.search-active{
					border-bottom-color: #2685EE;
				}

				&.no-results{
					border-bottom-color: @wf_red;
					color: @wf_red;
				}
			}

			.input-group-addon.cancel{
				border: 0;
				height: 26px;
				font-size: 11px;
				min-width: 0px;
				border-radius: 0;
				padding-left: 5px;
				padding-right: 5px;
				border-bottom: 1px @wf_gray solid;

				&.search-active{
					border-bottom-color: #2685EE;
				}

				&.no-results{
					border-bottom-color: @wf_red;
				}
			}

			input{
				width: 100%;
				padding-left: 0px;
				float: right;
			}
		}
	}
}

.alert {
	&.mail-preview {
		padding: 20px;
		font-size: 105%;
		color: #666;
	}
}

.progress-bar-wf-green {
	background-color: @wf_green;
}

panel.no-padding {
	.panel-body {
		padding: 0;
	}
}

panel.no-border {
	.panel {
		box-shadow: none;
	}
}

.wf-image {
	border-radius: 2px;
	&.image-placeholder {
		background-image: url("/assets/img/icons/image1.png");
	}

	&.image-placeholder-organization {
		background-image: url("/assets/img/icons/placeholder_org1.png");
	}

	&.user-icon {
		border-radius: 50%;
	}
}

.spin-loader {
	position: relative;
	color: transparent !important;
	&::before {
		content: '';
		border-radius: 50%;
		display: inline-block;
		width: 24px;
		height: 24px;
		border: 0.25rem solid rgba(0, 0, 0, 0.05);
		border-top-color: rgba(0, 0, 0, 0.4);
		animation: spin 1s infinite linear;
		position: absolute;
		left: 50%;
		top: 50%;
		margin-top: -12px;
		margin-left: -12px;
	}
	> * { visibility: hidden; }
}

.spin-loader-white {
	position: relative;
	color: transparent !important;
	&::before {
		content: '';
		border-radius: 50%;
		display: inline-block;
		width: 24px;
		height: 24px;
		border: 0.25rem solid rgba(255, 255, 255, 0.20);
		border-top-color: rgba(255, 255, 255, 0.7);
		animation: spin 1s infinite linear;
		position: absolute;
		left: 50%;
		top: 50%;
		margin-top: -12px;
		margin-left: -12px;
	}
	> * { visibility: hidden; }
}

.touch-effect {
	width: 40px;
	height: 40px;
	background-color: @wf_gray;
	border-radius: 100%;
	animation: touch-effect 1.0s infinite ease-in-out;
}

.MailHistoryController {
	&.in-page {
		.items {
			.panel {
				.panel-body {
					.list-group-item {
						border-top: none !important;
					}
				}
			}
		}
	}

	&.in-modal {
		.modal-header {
			margin-bottom: 10px !important;
		}

		.modal-body {
			bottom: 25px;
			top: 80px;
			.content {
				height: 100%;
				display: flex;
				flex-direction: column;
				flex: 1 0 auto;

				.filters {
					margin-bottom: 20px;
				}

				.list-of-items {
					flex: 1 0 auto;
					flex-grow: 1;
					position: relative;
					.scrollable-div {
						width: 100%;
						height: 100%;
						position: absolute;

						&:hover { .mCSB_scrollTools { opacity: 1; } }
						.mCSB_scrollTools{ opacity: 0.1; }
					}//end of: .scrollable-div
				}
			}
		}
	}
}

[wf-expand-trigger] {
	cursor: pointer !important;

	&.expanded {
		&:before {
			transition: .3s;
			transform: rotate(180deg);
		}
	}

	&.left-side-arrow {
		&:before {
			content: "\f104";
			display: block;
			position: absolute;
			top: 10px;
			bottom: 0;
			left: 0;
			font-family: 'Ionicons';
			height: 15px;
			transition: .3s;
		}
	}

	&.right-side-arrow {
		&:before {
			content: "\f104";
			display: block;
			position: absolute;
			top: 10px;
			right: 0;
			bottom: 0;
			font-family: 'Ionicons';
			height: 15px;
			transition: .3s;
		}
	}
}

wf-button {
	display: inline-block;

	i.icon {
		font-size: 14px;

		&:not(:last-child) {
			padding-right: 10px;
		}
	}

	.btn-sm {
		i.icon:not(:last-child) {
			padding-right: 3px;
		}
	}

	span.caption {
	}
}

[wf-item-creatortime] {
	.creator-image {
		> div {
			border-radius: 100%;
		}
		margin-right: 10px;
	}

	.creator-name {
		font-weight: 500;
	}

	.creator-date, .creator-time {
	}

	.createdAt {
		font-size: 11px;
		color: #aaa;
		padding-top: 2px;

		div {
			display: inline-block;
		}
	}
}

wf-check {
	display: block;
	label {
		cursor: pointer;
		margin-bottom: 7px;

		.label-text {
			display: inline-block;
			position: relative;
			top: 1px;
			padding-top: 1px;
			padding-left: 5px;
		}

		.icheckbox_wf {
			background-color: rgba(255,255,255,0.6);
		}

		// Pure css checkmark
		// .checkmark {
		// 	width: 9px;
		// 	height: 15px;
		// 	border: solid #555;
		// 	border-width: 0 4px 4px 0;
		// 	transform: rotate(45deg);
		// 	margin: auto;
		// 	margin-top: 1px;
		// }
	}
}

#switch-toggle-chart{
	cursor: pointer;

	input {
		max-height: 0;
		max-width: 0;
		opacity: 0;
	}

	label {
		margin-bottom: 0px;
		cursor: pointer;
	}

	input + label {
		display: inline-block;
		position: absolute;
		box-shadow: inset 0 0 0px 1px rgba(255, 255, 255, 0.25);
		text-indent: -3000px;
		height: 12px;
		width: 20px;
		border-radius: 10px;
	}

	input + label:before {
		content: "";
		position: absolute;
		display: block;
		height: 12px;
		width: 12px;
		top: 0;
		left: 0;
		border-radius: 10px;
		background: rgba(19, 191, 17, 0);
		transition: .25s ease-in-out;
	}

	input+ label:after {
		content: "";
		position: absolute;
		display: block;
		height: 12px;
		width: 12px;
		top: 0;
		left: 0px;
		border-radius: 10px;
		background: white;
		box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 2px 4px rgba(0, 0, 0, 0.2);
		transition: .25s ease-in-out;
	}

	input:checked + label:before {
		width: 20px;
		background: @wf_green;
	}

	input:checked + label:after {
		left: 8px;
		box-shadow: inset 0 0 0 1px white, 0 2px 4px rgba(0, 0, 0, 0.2);
	}
}

.switch-toggle {
	cursor: pointer;

	input {
		max-height: 0;
		max-width: 0;
		opacity: 0;
	}

	label {
		margin-bottom: 0px;
		cursor: pointer;
	}

	input + label {
		display: inline-block;
		position: relative;
		box-shadow: inset 0 0 0px 1px rgba(255, 255, 255, 0.25);
		text-indent: -5000px;
		height: 18px;
		width: 30px;
		border-radius: 15px;
	}

	input + label:before {
		content: "";
		position: absolute;
		display: block;
		height: 18px;
		width: 18px;
		top: 0;
		left: 0;
		border-radius: 15px;
		background: rgba(19, 191, 17, 0);
		transition: .25s ease-in-out;
	}

	input+ label:after {
		content: "";
		position: absolute;
		display: block;
		height: 18px;
		width: 18px;
		top: 0;
		left: 0px;
		border-radius: 15px;
		background: white;
		box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 2px 4px rgba(0, 0, 0, 0.2);
		transition: .25s ease-in-out;
	}

	input:checked + label:before {
		width: 30px;
		background: @wf_green;
	}

	input:checked + label:after {
		left: 13px;
		box-shadow: inset 0 0 0 1px white, 0 2px 4px rgba(0, 0, 0, 0.2);
	}
}

[wf-tooltip] {
	position: relative;

	&:before,
	&:after {
		visibility: hidden;
		opacity: 0;
		pointer-events: none;
		transition: .1s;
	}

	&:before {
		content: attr(wf-tooltip);
		position: absolute;
		padding: 5px 8px;
		border-radius: 2px;
		background-color: fade(@wf_black_darker, 90%);
		max-width: 200px;
		text-align: center;
		font-size: 11px;
		color: #fff;
	}

	&:after {
		content: '';
		position: absolute;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 5px 5px 5px 0;
		border-color: transparent fade(@wf_black_darker, 90%) transparent transparent;
	}

	&[wf-tooltip-placement-right] {
		&:before,
		&:after {
			left: 100%;
			top: 50%;
			transform: translateY(-50%);
		}

		&:before {
			margin-left: 9px;
		}

		&:after {
			margin-left: 4px;
		}
	}

	&[wf-tooltip-placement-left] {
		&:before,
		&:after {
			right: 100%;
			top: 50%;
			transform: translateY(-50%);
		}

		&:before {
			margin-right: 9px;
		}

		&:after {
			transform: rotate(-180deg);
			margin-right: 4px;
			margin-top: -5px;
		}
	}

	&[wf-tooltip-placement-top] {
		&:before,
		&:after {
			top: -25%;
			transform: translateY(-100%);
		}

		&:after {
			transform: rotate(-90deg);
			margin: auto;
			right: 0;
			left: 0;
			top: -7px;
		}
	}

	&:hover:before,
	&:hover:after {
		visibility: visible;
		opacity: 1;
		transition: .15s;
	}
}//end of: &[data-original-title] - Tooltip

wf-item-creator-bar {
	display: block;
}

.item-creator-time {
	font-size: 11px;
	color: @wf_gray;
	transition: color .1s;
	display: inline-block;

	.partDivider-left {
		&:not(:first-child):before {
			content: "";
			display: inline-block;
			width: 1px;
			height: 11px;
			margin: 0 6px;
			background-color: fadeOut(@wf_gray_dark, 70%);
			position: relative;
			top: 2px;
		}
	}

	.item-details-partDivider {
		&:first-child {
			display: none;
		}

		& + .item-details-partDivider {
			display: none;
		}

		display: inline-block;
		width: 1px;
		height: 11px;
		margin: 0 6px;
		background-color: fadeOut(@wf_gray_dark, 70%);
		position: relative;
		top: 2px;
	}

	.wf-creatorImage {
		margin-right: 4px;
		opacity: 0.7;
		transition: opacity .1s;
	}

	&:hover {
		color: @wf_gray_dark;

		.wf-creatorImage {
			opacity: 1;
		}
	}

	.wf-createdAt {
		.fa-icon-before('\f017');

		&:before {
			font-size: 12px;
			margin-right: 4px;
		}
	}
}

.wf-tabs {
	&:not(.dragging) {
		.tab-item {
			&:hover:not(.as-sortable-placeholder) {
				color: @wf_black_light;
				border-bottom-color: @wf_black_light;
			}
		}
	}

	&.as-sortable-dragging {
		wf-item-tools, wf-dropdown {
			display: none;
		}
	}

	.tab-item, .as-sortable-placeholder {
		> div {
			padding: 5px 0;
			cursor: pointer;

			&.as-sortable-item-handle {
				box-shadow: 10px 0 fadeOut(@wf_gray_lighter, 25%), -10px 0 fadeOut(@wf_gray_lighter, 25%);
				background-color: fadeOut(@wf_gray_lighter, 25%);
			}
		}
		display: block;
		margin-right: 40px;
		margin-top: 10px;
		cursor: pointer;
		float: left;
		transition: border-color .1s, color .1s, opacity .1s;
		border-bottom: 3px solid transparent;
		color: @wf_gray_dark;
		font-weight: 500;
		font-size: 17px;
		text-decoration: none !important;

		&.as-sortable-placeholder {
			background-color: rgba(0,0,0,0.05);
			border-radius: 3px;
		}

		&.selected {
			color: @wf_black;
			border-bottom-color: @wf_black;
		}

		// &:nth-last-child(2) {
		// 	margin-right: 40px + 25px;
		// }

		&.add {
			opacity: 0.7;
			border-bottom-color: transparent !important;
			margin-right: 25px;

			i {
				padding-right: 2px;
			}

			i, div {
				opacity: 0.8;
			}


			&:hover {
				opacity: 1;

				i, div {
					opacity: 0.9;
				}
			}
		}

		.tab-title {
			display: flex;
			flex-direction: row;
			max-width: 300px;

			span {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;

				&.numbering {
					padding-right: 8px;
					flex-shrink: 0;
				}
			}
		}

		&:hover, &.selected {
			wf-item-tools, wf-dropdown {
				visibility: visible;
			}
		}

		wf-item-tools, wf-dropdown {
			visibility: hidden;

			button {
				border: none;
				box-shadow: none;
				position: relative;
				top: 3px;
				margin: 0 !important;
				width: 27px;
				height: 27px;
				font-size: 11px;
				padding: 0px 0px;
				line-height: 1.5;
			}
		}
	}
}


// Temp fixes for Google Maps (2018-08-14)

.gm-style img {
	// Icons on buttons were gone because of conflict with our box-sizing: border-box
	box-sizing: content-box;
}

// The overall size of buttons are larger which looks bad.
// Fix the size on [Map] and [Satellite] buttons so the text is smaller size.
.gm-style-mtc > div[role=button] {
    font-size: 12px !important;
    padding: 0 10px !important;
    height: 30px !important;
}

.gm-style-mtc > div[role=button] + div {
    top: 35px !important;
}

.gm-style-mtc > div[role=button] + div > div {
    padding: 0 6px 3px !important;
}

.gm-style-mtc label {
    font-size: 12px !important;
    margin-bottom: 0;
}


//-------------------------------------

wf-file-viewer {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;

	.topBar {
		background: #dfdfdf;
		min-height: 64px;
		padding: 10px 16px;
		display: flex;
		justify-content: flex-start;
		flex-direction: row;
		align-items: center;


		.title {
			font-size: 20px;
			font-weight: bold;
			color: #111;
			word-break: break-word;
		}

		.btn {
			text-transform: uppercase;
			font-size: 12px;

			&.download {
				margin-left: 30px;
			}
		}

		.rightButtons {
			margin-left: auto;
			display: flex;

			.btn {
				color: #666;
				margin-left: 10px;
			}
		}
	}

	.iframeWrapper {
		display: flex;
		flex-grow: 1;

		&.loading {
			.spinner(40px, "dark", 8, 45%);
		}

		.notViewableInline {
			width: auto;
			max-width: 330px;
			height: 20%;
			min-height: 50px;
			margin: auto;
			font-size: 16px;

			> div {
				display: flex;
			}

			i.fa {
				padding-right: 16px;
				font-size: 24px;
			}

			.errorInfo {
				padding-top: 3px;
				color: #aaa;
				font-size: 13px;

				div {
					white-space: pre-wrap;
				}

				.details {
					border-left: 3px solid #ccc;
					padding-left: 6px;
					margin: 4px 0;
				}
			}
		}

		#inlineFileFrame {
			display: block;
			width: 100%;
			height: 100%;
			border: 0;

			&.isImage {
				margin: 16px;
			}
		}
	}
}
