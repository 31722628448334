.SustainabilityDiagnosisController {
	.mainBoxes {
		.panel-body {
			height: 430px;

			h3 {
				margin-top: 0;
			}

			button {
				position: absolute;
				bottom: 20px;
				left: 20px;
			}

			.top {
				height: 230px;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center center;
				position: static;

				.donutContainer {
					height: 180px;
					width: 180px;
					margin: auto;
					position: relative;
					top: 50%;
					transform: translateY(-50%);
					overflow: hidden;

					.donutNumbers {
						position: absolute;
						color: #fff;
						margin: auto;
						text-align: center;
						width: 100%;
						top: 50px;

						i {
							display: block;
							margin: auto;
							width: 30px;
							font-size: 21px;
						}
						
						span {
							font-size: 31px;
							font-weight: 300;

							&.part {
								font-weight: 600;
							}
						}

						div {
							font-size: 12px;
							margin-top: -2px;
						}
					}
				}

				.progressBars {
					padding: 0 46px;
					position: relative;
					top: 50%;
					transform: translateY(-50%);
					color: #fff;

					.header {
						font-size: 14px;
						padding-bottom: 15px;

						i {
							margin-right: 10px;
						}

						span {
							font-weight: bold;
							color: #444;
						}

					}

					.barItem {
						&:last-child {
							.progress {
								margin-bottom: 4px;
							}
						}

						&.thickBar {
							.highlight-toggler {
								padding-top: 4px;
							}

							.progress {
								height: 10px;
								margin-bottom: 15px;

								.progress-bar {
									background-color: #fff;
									height: 10px;
								}
							}
						}

						&.green {
							.progress {
								.progress-bar {
									background-color: @wf_green;
								}
							}
						}
						

						.highlight-toggler {
							padding-left: 20px;
						}

						.labels {
							padding-bottom: 3px;
							font-size: 12px;
							text-shadow: 0 0 20px #e4340d;

							a {
								color: @gray-dark;

								i {
									display: none;
									font-size: 9px;
									position: relative;
									top: 0px;
									opacity: 0.9;
								}

								&:hover {
									span {
										text-decoration: underline;
									}

								}
							}


							.count {
								display: inline-block;
								padding-right: 10px;
								color: rgba(255, 255, 255, 0.6);
								font-size: 11px;
								
								.slash {
									display: inline-block;
									padding: 0 1px;
								}
							}

							.percentage {
								display: inline-block;
								font-weight: 600;
							}
						}

						.progress {
							height: 4px;
							background-color: rgba(255, 255, 255, 0.3);
							margin-bottom: 11px;

							.progress-bar {
								transition-duration: .3s;
								background-color: #fff;
								height: 4px;
							}
						}
					}
					
				}
			}
		}
	}

	.btn.toggler {
		width: 90px;

		.circle {
			display: none;
		}


		&.toggled {
			&.loading {
				.circle {
					display: none;
				}
			}

			.circle {
				display: inline-block;
				position: relative;
				width: 22px;
				height: 16px;
				left: -2px;

				&:before {
					content: "";
					display: block;
					position: absolute;
					border: 1px solid rgba(0, 0, 0, 0.12);
					width: 22px;
					height: 22px;
					border-radius: 100%;
					margin-top: -3px;
					background: white;
				}
			}

			i {
				position: relative;
			}

			i.fa-check {
				font-size: 12px;
				margin-left: -1px;
				color: @wf_blue;
			}

			i.fa-minus {
				display: none;
				color: @wf_red;
				
			}

			&:hover {
				i.fa-check {
					display: none;
				}

				i.fa-minus {
					display: inline;
				}
			}
		}
	}

	
}