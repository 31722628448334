// @tick-color: #d8e0f3;
@tick-color: rgba(54, 125, 189, 0.15);
@unselected: #999;
@selected: #357EBD;

.rzslider {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 4px;
	margin: 24px 0 15px 0;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	span {
		position: absolute;
		display: inline-block;
		white-space: nowrap;
	}

	// &.inactive {
	// 	.rz-bubble {
	// 		background: @inactive;

	// 		&:after {
	// 			border-top-color: @inactive;
	// 		}
	// 	}

	// 	.rz-bar {
	// 		&.rz-selection {
	// 			background: @inactive;
	// 		}
	// 	}

	// 	.rz-pointer {
	// 		background-color: @inactive;
	// 	}
	// }

	.rz-base {
		width: 100%;
		height: 100%;
		padding: 0;
	}
	.rz-bar-wrapper {
		left: 0;
		z-index: 1;
		width: 100%;
		height: 32px;
		padding-top: 16px;
		margin-top: -16px;
		box-sizing: border-box;
	}
	.rz-draggable {
		cursor: w-resize;
	}
	.rz-bar {
		left: 0;
		z-index: 1;
		width: 100%;
		height: 12px;
		top: 25px;
		background-position: 0 -60px;
		background: @tick-color;
		border-radius: 3px;
	}
	.rz-selection {
		z-index: 2;
		background: @selected;
		border-radius: 2px;
	}
	.rz-pointer {
		top: 5px;
		z-index: 3;
		width: 5px;
		height: 20px;
		cursor: pointer;
		background-color: @selected;
		border-radius: 2px;
		transition: width .2s;
		&:after {
			position: absolute;
			top: -5px;
			left: -15px;
			width: 30px;
			height: 30px;
			content: '';
		}
		// &:hover {
		// 	&.rz-pointer-min {
		// 		width: 10px;
		// 	}

		// 	&.rz-pointer-max {
		// 		width: 10px;
		// 	}
		// }
	}


	.rz-pointer.rz-active {
		z-index: 4;
	}
	.rz-bubble {
		bottom: 10px;
		padding: 1px 3px;
		cursor: default;

		color: #fff;
		font-size: 10px;
		line-height: 1.333;
		text-shadow: none;
		padding: 1px 5px;
		background: @selected;
		-moz-border-radius: 4px;
		border-radius: 4px;

		&:after {
		position: absolute;
		display: block;
		content: "";
		bottom: -6px;
		left: 50%;
		width: 0;
		height: 0;
		margin-left: -3px;
		overflow: hidden;
		border: 3px solid transparent;
		border-top-color: @selected;
		}
	}
	.rz-bubble.rz-limit {
		color: @unselected;
		font-size: 10px;
		line-height: 1.333;
		text-shadow: none;
		padding: 1px 3px;
		background: #e1e4e9;
		-moz-border-radius: 4px;
		border-radius: 4px;

		position: absolute;
		display: block;
		left: 0;
		cursor: default;

		&:after { content: none; }
	}
	.rz-ticks {
		position: absolute;
		top: -3px;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 0;
		margin: 0;
		list-style: none;
		box-sizing: border-box;
	}
	.rz-ticks-values-under {
		.rz-tick-value {
			top: auto;
			bottom: -32px;
		}
	}
	.rz-tick {
		position: absolute;
		top: 25px;
		left: 0;
		width: 0px;
		// width: 1px;
		height: 5px;
		margin-left: 11px;
		text-align: center;
		cursor: pointer;
		background: @tick-color;
	}
	.rz-tick.rz-selected {
		background: @tick-color;
	}
	.rz-tick-value {
		position: absolute;
		top: -30px;
		-webkit-transform: translate(-50%, 0);
		transform: translate(-50%, 0);
	}
	.rz-tick-legend {
		position: absolute;
		top: 10px;
		max-width: 30px;
		-webkit-transform: translate(-80%, 0);
		transform: translate(-80%, 0);
		white-space: nowrap;
		text-align: center;
		font-size: 9px;
		line-height: 9px;
		color: @unselected;

	}
}
.rzslider.with-legend {
	margin-bottom: 40px;
}
.rzslider[disabled] {
	cursor: not-allowed;
	.rz-pointer {
		cursor: not-allowed;
		background-color: @tick-color;
	}
	.rz-draggable {
		cursor: not-allowed;
	}
	.rz-selection {
		background: #8b91a2;
	}
	.rz-tick {
		cursor: not-allowed;
	}
	.rz-tick.rz-selected {
		background: #8b91a2;
	}
}

// ---------------------------------------------------- 
// ----------------- VERTICAL SLIDER ------------------
// ----------------------------------------------------
 
.rzslider.rz-vertical {
	position: relative;
	width: 4px;
	height: 100%;
	padding: 0;
	margin: 0 20px;
	vertical-align: baseline;
	.rz-base {
		width: 100%;
		height: 100%;
		padding: 0;
	}
	.rz-bar-wrapper {
		top: auto;
		left: 0;
		width: 32px;
		height: 100%;
		padding: 0 0 0 16px;
		margin: 0 0 0 -16px;
	}
	.rz-bar {
		bottom: 0;
		left: auto;
		width: 4px;
		height: 100%;
	}
	.rz-pointer {
		top: auto;
		bottom: 0;
		left: -14px !important;
	}
	.rz-bubble {
		bottom: 0;
		left: 16px !important;
		margin-left: 3px;
	}
	.rz-ticks {
		top: 0;
		left: -3px;
		z-index: 1;
		width: 0;
		height: 100%;
	}
	.rz-tick {
		margin-top: 11px;
		margin-left: auto;
		vertical-align: middle;
	}
	.rz-tick-value {
		top: auto;
		left: 24px;
		-webkit-transform: translate(0, -28%);
		transform: translate(0, -28%);
	}
	.rz-tick-legend {
		top: auto;
		right: 24px;
		max-width: none;
		white-space: nowrap;
		-webkit-transform: translate(0, -28%);
		transform: translate(0, -28%);
	}
	.rz-ticks-values-under {
		.rz-tick-value {
			right: 24px;
			bottom: auto;
			left: auto;
		}
	}
}
