.bootbox {
	&.confirmation {
		.modal-body {
			text-align: center;
			i {
				font-size: 75px;
				color: @wf_blue;
				margin-bottom: 20px;
			}

			p {
				font-size: 16px;
				font-weight: 500;
			}

			span {
				font-size: 14px;
			}
		}
	}
}//end of: .bootbox

.mail-preview-controller {
	.modal-header {
		margin-bottom: 25px !important;
		position: relative;

		.buttons {
			position: absolute;
			right: 0;
			top: 0;

			button {
				i { margin-right: 5px; }

				&.send {
					&.disable {
						opacity: 0.65; 
						cursor: not-allowed;
					}
				}
				
				&.edit {
					margin-right: 10px;
				}
			}
		}
	}//end of: .modal-header

	.modal-body {
		padding: 30px 30px 0 30px !important;
		.form {
			padding: 30px;
			border: solid 1px @wf_gray_lighter;
			background-color: @wf_gray_lighter;
			border-radius: 3px;
			margin-bottom: 15px;

			button {
				display: inline;
				margin-right: 10px;
				i {
					margin-right: 10px;
				}
			}
			span.unsaved-changes {
				color: @wf_red;
				display: inline;
    			font-size: 12px;
			}

			&.form-not-saved {
				border: solid 1px @wf_red;
			}

			&.loading {
				.spinner(22px);
				min-height: 300px;

				> * {
					display: none;
				}
			}
		}//end of: .form
	}//end of: .modal-body

	.modal-footer {
		padding: 30px;
		button {
			i { margin-right: 5px; }
			&.send {
				&.disable {
					opacity: 0.65; 
  					cursor: not-allowed;
				}
			}

			&.cancel {
				margin-right: 10px;
			}
		}
	}//end of: .modal-footer

	.edit-email {
		margin-top: 20px;

		.edit-wrapper {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			
			background-color: @wf_yellow_dark;
			padding: 16px;
			border-radius: 3px;

			&:hover {
				background-color: @wf_yellow_dark;

				.circle-icon-wrapper {
					i { color: @wf_yellow_dark; }
				}
			}

			.circle-icon-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;

				width: 40px;
				height: 40px;
				border-radius: 50%;
				background-color: white;
				margin-right: 16px;

				i {
					color: @wf_yellow_dark;
					font-size: 18px;
				}
			}

			.edit-text {
				display: flex;
				flex-direction: column;
				align-content: center;
				flex-grow: 10;
				flex-shrink: 10;

				span {
					text-align: left;
					color: white;

					&.main-text {
						font-size: 16px;
						font-weight: 500;
					}

					&.description-text {
						font-size: 12px;
						font-weight: 300;
					}
				}
			}

			button.edit-email-button {
				background-color: white;
				text-transform: uppercase;
				font-size: 14px;
				color: @wf_yellow_dark;
				margin-left: 16px;
				font-weight: 500;
				padding: 6px 30px;

				&:hover {
					color: @wf_yellow;
				}
			}
		}
	}

}//end of: .mail-preview-controller


.mail-preview-header {
	min-height: 77px;
	
	h1 {
		font-weight: 700;
		font-size: 23px;
		line-height: 30px;
	}

	.statusIndicator {
		text-align: center;
		display: inline-block;
		width: 40px;
		font-size: 20px;
		position: relative;
		top: -1px;

		&.success {
			color: @wf_green;
		}

		&.fail {
			color: #e66a6a;
		}
	}
	
	.image {
		color: @wf_gray;
		padding: 0 23px 0 1px;
		font-size: 45px;
		height: 63px;
		top: -9px;
		position: relative;

		.historyIcon {
			position: absolute;
			font-size: 19px;
			background-color: white;
			border-radius: 100%;
			width: 24px;
			height: 24px;
			text-align: left;
			line-height: 24px;
			padding-left: 4px;
			top: 40px;
			left: 32px;
		}
	}
	
	.text {
		.mailSentAt {
			color: #444;
			padding: 7px 0 0;

			i {
				padding: 1px 5px 1px 1px;
			}
		}

		ul.email-parameters {
			margin-top: 10px;
			visibility: visible;
			
			&.hide {
				visibility: hidden;
			}


			li {
				padding: 1px 0;

				span, div {
					font-size: 13px;
				}

				div.param-label {
					color: #95a5a6;
					display: inline-block;
					min-width: 55px;

					&:after {
						content: ":";
						display: inline;
					}
				}

				span {
					&.email {
						word-break: break-word;

						&, a {
							color: #333;
							display: inline-block;
						}

						a:hover {
							text-decoration: underline;
						}
						
						&:before, &:after {
							display: inline-block;
						}
						
						&:before {
							content: "<";
						}
						
						&:after {
							content: ">";
							margin-left: -3px;
						}
					}
				}
			}
		}
	}
}

.mail-preview-frame {
	h1 {
		font-weight: 400;
		font-size: 18px;
		line-height: 30px;
		margin-right: 15px;
	}

	.preview {
		border: dashed 1px @wf_gray;
		margin-top: 10px;

		.preview-unavailable {
			margin: 150px 0;
			text-align: center;
		}

		iframe#mail-preview-frame {
			overflow: hidden;
			border: none;
			width: 100%;
		}
	}
}//end of: .mail-preview