@import "wf-variables.less";
@import "wf-mixins.less";

wf-measure-period-settings-editor {
	display: block;
	padding: 0 20px;
	border: 1px solid #e0e0e0;
	border-radius: 3px;
	margin-bottom: 16px;

	button.addInterval {
		position: absolute;
		bottom: 0;
		right: 0;
	}
	.columnHeaders {
		.column-header {
			cursor: default;
			color: #333;

			span {
				display: inline-block;
			}

			&:hover {
				.fa-info-circle {
					color: #a4abb3;
				}
			}

			.fa-info-circle {
				color: #c7ced6;
				margin-left: 5px;
				font-size: 14px;
			}
		}
	}

	.rangeItem {
		border-bottom: 1px solid transparent;
		padding: 15px 0;

		&:not(:last-child) {
			border-bottom-color: @wf_gray_light;
		}
	}

	.ui-select-container .ui-select-match-text {
		padding-right: 12px;
	}

	.input-group {
		width: 100%;
		min-height: 36px;

		.ui-select-container {
			position: absolute;
			width: 100%;
		}

		&.dayInMonth {
			.ui-select-choices {
				width: 210px;
			}

			.ui-select-choices-row {
				float: left;
				width: 14.285%;

				> span {
					padding: 3px 0;
					text-align: center;
				}
			}
		}
	}

	.btn.remove {
		color: @wf_red;
	}

	.periodsPreview {
		.well {
			max-height: 200px;
			overflow: hidden;
			overflow-y: auto;
		}
	}
}
