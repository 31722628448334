@media screen and (min-width: 0px) and (max-width: @screen-sm-max) {
	// #intercom-container{
	// 	display: none !important;
	// }

	body.wf-responsive-ui {
		#wrapper {
			padding-top: 0px;
		}
		padding-top: 51px !important;
		&.fixed-layout header, &.fixed-layout #wrapper, &.fixed-layout #headernav {
			width: 100% !important;
		}

		header#topnav {
			// position: absolute;
			.navbar-header {
				float: none !important;
				.navbar-brand {
					margin-left: 20px;
					margin-top: 8px;
				}

				.navbar-toggle {
					margin-right: 20px;
					width: 36px;
					height: 36px;
					i { color: white; }
				}

			}
			.wf-navbar {
				box-shadow: none;
				padding: 15px;
				max-height: none;
				div.navbar-nav {
					background-color: #213C50;
					float: none !important;
					padding: 10px 30px;
					li {
						float: none !important;
						border-right: none;
						padding: 12px 0px; 
						margin: 0;
						border-bottom: .5px solid #344358; 
						>a {
							padding: 0;
							span {
								font-size: 14px;
								color: white;
							}

							i {
								display: none;
							}
						}
						.disable-menu-children {
							display: none !important;
							visibility: hidden;
						}

						&.active {
							>a {
								background-color: #213C50 !important;
							}
						}
					}
				}

				ul.nav.navbar-nav {
					float: none !important;
					&.toolbar {
						float: none !important;
						margin: 15px 25px;

						>li {
							float: right;
							border-left: none;
							margin-left: 5px;
							margin-top: 5px;

							&:first-child {
								margin: 0px;
								float: none;
							}
							>a {
								top: 0px;
								height: 50px;
								color: white;
								border-radius: 3px;
								border: 1px solid transparent;
								background-color: rgba(0, 0, 0, 0.15) !important;
							}
						}
					}
				}
			}
		}

		.wfPublicInfluenceViewer {
			.header {
				padding-top: 20px !important;
			}
			ng-include header#topnav .wf-navbar.in & .map-background{
				visibility: hidden;
			}//end of: wf-map-image
		}

		#wrapper {
			.wfPublicInfluenceViewer {
				.header {
					.organization {
						margin-top: 15px;
					}

					.share-button {
						z-index: 1040;
						right: 85px;
						top: -30px;
					}
				}
			}
		}
	}
}

body.wf-responsive-ui {
	margin: 0;

	.fixed-layout header, .fixed-layout #wrapper, .fixed-layout #headernav, #wrapper, header {
		width: 100% !important;
	}

	header#topnav {
		box-shadow: -1000px 0 #213C50, 1000px 0 #213C50 !important;
		background: #213C50 !important;

		#horizontal-navbar .navbar-nav {
			>li {
				>a { background:#213C50 }
			}
		}
		.wf-navbar {
			padding: 0;
			
			// Uncollapse the nav
			@media (min-width: @grid-float-breakpoint) { // removed - @grid-float-breakpoint in navbar.less
				ul.navbar-nav {
					float: left;
					// > li { float: left; }
				}
			}
		}

		@media screen and (min-width: @screen-lg-min) {
			.navbar-header {
				.navbar-brand {
					margin-left: 0px;
				}
			}
		}
	}

	.wfPublicInfluenceViewer {
		.header {
			padding-top: 40px;
		}
	}
	#wrapper {
		.static-content-wrapper {
			padding-bottom: 0;
		}
	}
}

@media screen and (min-width: 564px) and (max-width: @screen-sm-max) {
	body {
		padding-left: 60px !important;
		padding-right: 60px !important;
	}
}


@media screen and (min-width: @screen-sm-min) and (max-width: @screen-sm-max) { //tablet devices - from 768px to 992px
	body.layout-public {
		&.wf-responsive-ui{
			header#topnav {
				>.container-fluid {
					.navbar-header { 
						.navbar-brand {
							width: initial !important;
						}
					}
				}
			}
		}

		header#topnav {
			>.container-fluid {
				width: @container-sm;
				.navbar-header { 
					.navbar-brand {
						margin-left: 0px !important;
						width: 80px !important;
					}

					button.navbar-toggle {
						margin-right: 0px !important;
					}
				}
			}
		}
	}
}

@media screen and (min-width: @screen-md-min) and (max-width: @screen-md-max) { //medium devices
	body.layout-public {
		header#topnav {
			>.container-fluid {
				// width: @container-md;
				.navbar-header { 
					.navbar-brand {
						margin-left: 0px !important;
					}
				}
			}

		}
	}
}

@media screen and (min-width: @screen-md-min) { //from 992px 
	body.wf-responsive-ui {
		// padding: 0 !important;
		header#topnav{
			// position: inherit;
			.wf-navbar {
				div.nav.navbar-nav {
					> ul#top-nav {
						> li.hasChild {
							> ul { top: 48px !important; }
						}
					}
				}
			}
		}
	}
}

body.layout-public.dark, body.wf-responsive-ui.layout-public {
	background-color: @wf_gray_lighter;
	div#wrapper {
		div#layout-static {
			div.static-content-wrapper {
				background-color: #F5F5F6 !important;
				box-shadow: -1000px 0 #F5F5F6, 1000px 0 #F5F5F6 !important;
			}
		}
	}
}