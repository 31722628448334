@import "wf-variables.less";

wf-finding {
	display: block;

	.btn:not(.btn-sm) {
		.fa-plus, .fa-pencil {
			// font-size: 14px;
			// position: relative;
			// top: 1px;
			padding-right: 7px;
		}
	}

	.finding-locked-info {
		margin-bottom: 10px;
		background: #fafafa;
		padding: 12px 15px;
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) inset;
		border-radius: 5px;

		i {
			font-size: 23px;
			color: @wf_gray_dark;
			padding-right: 10px;
			padding-left: 1px;
		}

		.title {
			font-weight: 600;
		}

		.description {
			font-size: 11px;
		}
	}

	.finding-locked {
		opacity: 0.5;
	}

	.finding-details {
		padding: 5px 0;

		> div {
			display: inline-block;
			padding-right: 16px;
			font-size: 12px;
			padding-bottom: 6px;

			> span:first-child {
				display: inline-block;
				color: @wf_gray_dark;
				padding-right: 2px;
			}

			span.severity-indicator {
				display: inline-block;
				width: 12px;
				height: 12px;
				border-radius: 100%;
				margin-right: 3px;
				box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12) inset;
				position: relative;
				top: 1px;
			}

			> span:last-child {
				display: inline-block;
				font-weight: 600;
				border-radius: 0px;
				color: rgba(0,0,0,0.6);

				&.framed {
					padding: 4px 7px;
					box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08) inset;
				}
			}
		}

		
		&.finding-action-and-progress {
			margin-top: 10px;
			.finding-action {
				display: block;
				padding-bottom: 8px;

				.action-label {
					margin-right: 3px;
				}

				&.denied {
					span.action-text {
						color: @wf_red;
					}
				}
			}

			.finding-progress {
				width: 100%;

				.progress {
					height: 10px;
					margin-bottom: 0;
					.progress-bar {
						opacity: 0.8;
						&:hover {
							opacity: 1;
							cursor: pointer;
						}

						&.progress-bar-approved { background-color: lighten(@wf_green, 10%); }
						&.progress-bar-denied { background-color: @wf_red; }
						&.progress-bar-updated { background-color: @wf_black; }
						&.progress-bar-needed { background-color: transparent; }
					}
				}

				.legend {
					.legend-item {
						display: inline-block;
						margin-right: 5px;
						&.approved span { color: @wf_green; }
			
						&.needed span { }
			
						&.denied span { color: @wf_red; }
			
						&.updated span { color: @wf_black; }
					}
				}
			}
		}
	}

	.reporting-container {
		margin-top: 10px;
		padding-top: 15px;
		border-top: 1px dashed @wf_gray;

		.owner-target-container {
			padding-bottom: 7px;
			
			.item-label {
				display: inline-block;
				color: @wf_gray_dark;
				padding-right: 2px;
			}

			.unset {
				display: inline-block;
				
				span {
					display: inline-block;
					text-transform: uppercase;
					color: rgba(0,0,0,0.6); 
					font-weight: 600;
				}
			}

			.owner {
				display: inline-block;
				margin-right: 15px;
				padding-top: 9px;

				.wf-creatorName {
					display: inline-block;
					font-weight: 600;
					border-radius: 0px;
					color: rgba(0,0,0,0.6); 
					font-size: 12px;
				}
			}

			.target-completion-date {
				display: inline-block;

				.date {
					display: inline-block;
					span {
						display: inline-block;
						font-weight: 600;
						border-radius: 0px;
						color: rgba(0,0,0,0.6);
					}
				}
			}
		}

		.finding-steps-container {
			padding-top: 0px;
		}
	}

	.attached-information {
		padding-top: 10px;
		
		.attach-button {
			margin-left: 15px;
		}
	}

	.attached-info-items {
		// border: 1px solid @wf_gray;
		// border-radius: 3px;
		// padding: 15px;
	
		.attached-info-item {
			display: inline-block;
			padding-right: 39px;
			margin-right: 24px;
			position: relative;

			wf-dropdown {
				position: absolute;
				right: 0;
				top: 0;
				// display: none;
				opacity: 0;
				transition: opacity .4s;
			}
			
			&:hover {
				wf-dropdown {
					transition: opacity 0s;
					opacity: 1;
				}
			}
		}
	
		wf-item-component {
			margin-top: 3px;

			&.small {
				font-size: 100%;
			}
	
			.headers {
				padding-top: 0 !important;
				margin-bottom: 5px !important;
			}
	
			.image {
				width: 15px;
				height: 20px;
				margin-right: 6px;
				margin-top: 3px;
	
				.wf-image {
					font-size: 14px;
				}
			}
	
			.wf-headerText {
				padding-top: 0;
				font-size: 11px;
	
				&, a {
					color: #555;
				}
	
				a .external-link {
					margin-left: 8px;
	
					i {
						font-size: 10px;
						margin-right: 4px;
					}
				}
			}
	
			.wf-bodyText {
				font-size: 11px;
				padding-top: 0 !important;
				color: #707980 !important;
			}
		}
	}

	.print-mode {
		height: 100% !important;
	}
}

wf-finding-step {
	display: block;
	padding: 10px 0;

	.step-wrapper {

		&.locked {
			.answer-items {
				.answer-item {
					pointer-events: none;
					opacity: 0.4;
				}
			}

			// * .btn, button {
			// 	cursor: not-allowed;
			// 	pointer-events: none;
			// 	opacity: 0.3;
			// 	box-shadow: none;
			// }

			.step-title {
				color: @wf_gray_dark;
			}

			.empty-state-wrapper {
				padding: 15px;
				padding-left: 45px;
				border: dashed 1px #d9d9d9;
				border-radius: 3px;
				margin: 10px 0;
				position: relative;
				
				.step-empty-state {
					.icon {
						position: absolute;
						left: 16px;
						top: 8px;
						font-size: 20px;
						padding-top: 1px;
					}
				}
			}
		}

		.step-title {
			font-weight: 500;
			font-size: 14px;
			padding-bottom: 3px;

			.step-optional-status {
				font-size: 11px;
				font-weight: 400;
				padding-left: 9px;
				border-left: 1px solid #ddd;
				margin-left: 6px;
			}
		}
	
		.answer-items {
			position: relative;

			.answer-item {
				margin-left: 15px;
				margin: 10px 0;
		
				&:not(:first-child) {
					// border-top: 1px solid @wf_gray;
				}
			}

			.btn.add-answer {
				margin-top: 7px;
				margin-bottom: 5px;
			}
	
			.step-empty-state {
				color: @wf_gray_dark;
			}
		}
	}
}

wf-finding-step-answer {
	display: block;

	.answer {
		position: relative;
		min-height: 26px;

		border: 1px solid #ddd;
		padding: 15px;
		padding-left: 15px + 28px;
		border-radius: 4px;

		.answer-text {
			span { white-space: pre-wrap; }
		}

		.state-information {
			padding: 6px 0 8px;
			font-weight: 500;
		}

		.state-verification-wrapper {
			padding-left: 10px;
		}

		&.optional-answer-updated {
			border-color: @wf_green;

			.state-verification-wrapper {
				padding-left: 0;
				margin-bottom: 5px;
			}
			.state-information {
				// background-color: fade(@wf_black, 10%);
				// box-shadow: 0 0 0 1px fade(@wf_black, 20%);
				color: @wf_green;
			}
		}

		&.answer-updated {
			border-color: @wf_black;

			.state-verification-wrapper {
				padding-left: 0;
				margin-bottom: 5px;
			}
			.state-information {
				// background-color: fade(@wf_black, 10%);
				// box-shadow: 0 0 0 1px fade(@wf_black, 20%);
				color: @wf_black;
			}
		}

		&.answer-denied {
			border-color: @wf_red;

			.state-verification-wrapper {
				border-left: 3px solid @wf_red;
			}
			.state-information {
				// background-color: fade(@wf_red, 10%);
				// box-shadow: 0 0 0 1px fade(@wf_red, 20%);
				color: @wf_red;
			}
		}

		&.answer-approved {
			border-color: @wf_green;

			.state-verification-wrapper {
				border-left: 3px solid @wf_green;
			}
			.state-information {
				// background-color: fade(@wf_green, 10%);
				// box-shadow: 0 0 0 1px fade(@wf_green, 20%);
				color: @wf_green;
			}
		}

		> .icon {
			position: absolute;
			left: 12px;
			// top: -4px;
			font-size: 25px;
			color: #AFAFAF;
			
			i {
				display: block;

				&.fa-times {
					color: @wf_red;
				}

				&.fa-check {
					font-size: 22px;
					color: @wf_green;
					padding-top: 1px;
				}

				&.ion-loop {
					color: @wf_black;
					font-size: 30px;
				}
			}
		}
	}

	.verification {
		margin-bottom: 15px;

		.verification-comment {
			padding-bottom: 3px;

			.comment-label {
				font-weight: 500;
			}
		}
	}

	.attached-info-items {
	}

}