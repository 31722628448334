.wf-droppable {
	[wf-droppable-notifier], &[wf-droppable-notifier] {
		position: relative;
		transition: all .2s linear;
		
		.drop-message {
			text-align: center;
			position: absolute;
			height: 100%;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;

			opacity: 0;
			
			* { 
				display: block; 
				transition: all .2s ease;
			}

			.wrap-val-icon {
				background-color: #e2e2e2;
				border: 1px solid #e2e2e2;
				border-radius: 50%;
				width: 100px;
				height: 100px;
				display: inline-block;
			}

			>div {
				margin: auto;
				position: relative;
				top: 50%;
				margin-top: -75px;
			}
		}//end of: .drop-message
		
		&.hide-content {
			*:not(.drop-message):not(:first-child):not(.wrap-val-icon):not(.val-message-icon):not(.val-message-header):not(.val-message-description) {
				visibility: hidden;
				position: relative;
			}
		}//end of: &.hide-content
		
		&.reveal-droppable {
			min-height: 250px;
			
			.drop-message {
				opacity: 1;
				transition: opacity .2s linear;
				.wrap-val-icon {
					i.val-message-icon {
						margin-top: 15px;
						font-size: 70px;
						color: #656565;
					}
				}
				
				.val-message-header {
					font-size: 18px;
					margin: 10px 0;
					color: gray;
				}

				.val-message-description {
					font-size: 14px;
					color: @wf_gray;
				}
			}//end of: .drop-message

			&::before {
				content: "";
				position: absolute;
				top: 20px;
				right: 20px;
				bottom: 20px;
				left: 20px;
				border: 2px dashed #d4d7da;
				border-radius: 3px;
				transition: border-color .2s linear; 
			}
		}//end of: &.reveal-droppable

		&.drag-enter {
			&.allow-dropping {
				.drop-message {
					.wrap-val-icon {
						background-color: @wf_blue;
						border-color: @wf_blue;

						transform: translateZ(0);
						-webkit-font-smoothing: subpixel-antialiased;
						transition: all .2s ease-in;
						transform: scale(1.1);

						i.val-message-icon {
							margin-top: 15px;
							font-size: 70px;
							color: white;

							animation: bounce-it 1s infinite;
						}
					}
	
					.val-message-header {
						font-size: 18px;
						margin: 10px 0;
						color: gray;
					}
	
					.val-message-description {
						font-size: 14px;
					}
				}

				&::before {
					transition: border-color .2s linear; 
					border-color: @wf_blue;
				}
			}//end of: &.allow-dropping

			&.deny-dropping {
				.drop-message {
					.wrap-val-icon {
						background-color: @wf_red;
						border-color: @wf_red;

						i.val-message-icon {
							margin-top: 15px;
							font-size: 70px;
							color: white;

							animation: none !important;
						}
					}
	
					.val-message-header {
						font-size: 18px;
						margin: 10px 0;
						color: gray;
					}
	
					.val-message-description {
						font-size: 14px;
						color: @wf_gray;
					}
				}

				&::before {
					transition: border-color .2s linear; 
					border-color: @wf_red;
				}
			}//end of: &.deny-dropping
		}// end of: .drag-enter

		&.drop {
			.drop-message {
				.wrap-val-icon {
					border-color: @wf_green;
					background-color: @wf_green;

					i.val-message-icon {
						margin-top: 15px;
						font-size: 70px;
						color: white;
					}
				}

				.val-message-header {
					font-size: 18px;
					margin: 10px 0;
					color: gray;
				}

				.val-message-description {
					font-size: 14px;
					color: @wf_gray;
				}
			}

			&::before { border-color: transparent; }
		}//end of: .drop

		&.drop-denied {
			.drop-message {
				.wrap-val-icon {
					border-color: @wf_red;
					background-color: @wf_red;

					i.val-message-icon {
						margin-top: 15px;
						font-size: 70px;
						color: white;
					}
				}

				.val-message-header {
					font-size: 18px;
					margin: 10px 0;
					color: gray;
				}

				.val-message-description {
					font-size: 14px;
					color: @wf_gray;
				}
			}

			&::before { border-color: transparent; }
		}//end of: .drop-denied
	}
}//end of: .wf-droppable

.wf-draggable {

}//end of: .wf-draggable