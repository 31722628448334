.bootbox{
	&.modal-alert {
		.modal-dialog{
			width: 600px;

			.modal-body{
				min-height: 50px;
				.icon {
					i{ font-size: 45px; }
				}//end of: .icon

				.content{
					p{
						white-space: pre-wrap;
						&.title{
							font-size: 16px;
							font-weight: 500;
							line-height: 23px;
						}

						&.text{
							font-size: 14px;
							font-weight: 300;
						}
					}
				}//end of: .content
			}// end of: .modal-body

			.modal-footer {
				border-top: none;
				clear: both;
			}
		}//end of: .modal-dialog
	}//end of: /modal-alert

	&.icon-horizontal {
		.modal-dialog {
			width: 600px;
		}

		.icon {

		}//end of: .icon
		.content{
			.text{
				text-align: left;
				padding-left: 30px;
			}
		}
	}//end of: .icon-horizontal

	&.icon-vertical {
		.modal-dialog {
			width: 600px;
		}

		.icon {
			text-align: center;
		}//end of: .icon
		.content{
			margin: 25px;

			.text{
				text-align: center;
				padding-top: 25px;
			}
		}
	}//end of: .vertical

	&.modal-info {
		.modal-dialog {
			width: 600px;
		}

		.icon i{ color: @wf_blue; }

		.modal-footer{
			button.action{
				background-color: @wf_blue !important;
				border-color: @wf_blue !important;
				color: white;
			}
		}//end of: .modal-footer
	}//end of: .modal-info

	&.modal-success {
		.modal-dialog {
			width: 600px;
		}
		.icon i{ color: @wf_green; }

		.modal-footer{
			button.action{
				background-color: @wf_green !important;
				border-color: @wf_green !important;
				color: white;
			}
		}//end of: .modal-footer
	}//end of: modal-success

	&.modal-warning {
		.modal-dialog {
			width: 600px;
		}
		.icon i{ color: @wf_yellow; }

		.modal-footer{
			button.action{
				background-color: @wf_yellow !important;
				border-color: @wf_yellow !important;
				color: white;
			}
		}//end of: .modal-footer
	}//end of: .modal-warning

	&.modal-danger {
		.modal-dialog {
			width: 600px;
		}

		.icon i{ color: @wf_red; }

		.modal-footer{
			button.action{
				background-color: @wf_red !important;
				border-color: @wf_red !important;
				color: white;
			}
		}//end of: .modal-footer
	}//end of: .modal-danger
}// end of: .bootbox

.wf-modal {
	&.modal {
		width: 100%;
		height: 100%;
		visibility: hidden;
		backface-visibility: hidden;
		overflow-y: scroll !important;

		&.in {
			visibility: visible;
		}

		.modal-dialog {
			.modal-content {
				border-radius: 3px !important;
				padding: 40px;

				.modal-header {
					margin-bottom: 40px;
					padding: 0px;
					border: none;

					.modal-title {
						margin-top: 0px;
						margin-bottom: 0px;
						font-weight: 500;
						font-size: 20px;
					}
				}

				.modal-body {
					padding: 0px !important;

					img { width: 100%; }
				}

				.modal-footer {
					margin-top: 40px;
					border: none;
					padding: 0 !important;
				}
			}
		}
	}//end of: .modal

	&.organization-intro {
		.modal-dialog {
			.modal-content {
				box-shadow: 0px 0px 5px 7px rgba(0, 0, 0, 0.12);

				.header {
					padding: 0 15px;
					text-align: center;
					.title {
						margin-top: 0;
						text-align: center;
						color: @wf_blue;
						font-size: 28px;
						font-weight: 700;
					}//end of: .title

					.intro-description {
						font-size: 14px;
					}//end of: .description

					.intro-required {
						font-size: 12px;
						color: @wf_red;
						display: block;
						text-align: left;
						margin-top: 10px;
					}//end of: .description
				}//end of: .header

				.organization-form {
					padding: 15px;
					margin-top: 10px;

					label.control-label {
						color: #9ea4a9;
						font-weight: 300;
					}

					wf-form {
						form {
							// text input
							input.form-control {
								border-width: 2px;
								&.ng-invalid {
									border-color: rgba(231, 76, 60, 0.6) !important;
									color: @wf_red;
								}

								&.ng-valid, &.ng-valid-parse {
									border-color: rgba(52, 152, 219, 0.6);
									color: @wf_blue;
								}

								&#vatNumber {
									&.ng-not-empty {
										border-color: rgba(52, 152, 219, 0.6) !important;
										color: @wf_blue;
									}
								}

								&:focus {
									border-color: #707980;
								}
							}

							div.form-group.schema-form-select {
								// select input
								select.form-control {
									height: 38px;
									border-width: 2px;
									padding: 9px 10px;
									-webkit-appearance: none;
									-moz-appearance: none;
									-ms-appearance: none;
									-o-appearance: none;

									&.ng-invalid {
										border-color: rgba(231, 76, 60, 0.6) !important;
										color: #707980;
									}

									&.ng-valid, &.ng-valid-parse {
										border-color: rgba(52, 152, 219, 0.6);
										color: @wf_blue;
									}

									&:focus {
										border-color: rgba(52, 152, 219, 0.6);
									}

									&::-ms-expand {
										display: none;
									}
								}


							}//end of: div.form-group.schema-form-select

							.wf-form-picker {
								&.multiple {
									.form-group {
										.item-container {
											border-width: 2px;
											padding: 9px 10px;
											-webkit-appearance: none;
											-moz-appearance: none;
											-ms-appearance: none;
											-o-appearance: none;

											&.has-error {
												border-color: rgba(231, 76, 60, 0.6) !important;
												&:after {
													color: rgba(231, 76, 60, 0.6);
												}
											}

											&.has-success {
												border-color: rgba(52, 152, 219, 0.6);
												&:after {
													color: @wf_blue;
												}
											}
										}
									}
								}//end of: &.multiple
							}//end of: .wf-form-picker

							.organization-location {
								bootstrap-decorator {
									div.wf-form-location {
										div.well {
											padding: 0;
											border: none;
											button { height: 38px; }
										}
									}
								}
							}

							.organization-logo {
								bootstrap-decorator {
									ng-form.file-upload {
										padding-right: 0px;
									}

									div.newFileUpload {
										padding: 0 !important;
										border: none;
										button { height: 38px; }

										img.img-thumbnail {
											padding: 0;
											border: none;
										}
									}

									div.currentImage {
										img {
											padding: 0;
											border: none;
										}
										button {float: right;}
									}

									div.cancel {
										display: none;
									}
								}
							}

							.registration-vat {
								div.form-group {
									margin-top: 10px;
									margin-bottom: 7px;
								}
							}

							.logo-address {
								margin-bottom: -15px;
							}

							div.help-block { color: @wf_red; }

							div.form-group { padding-right: 0 !important; }

							//Valid/invalid icon removed
							span.form-control-feedback{ display: none; }
						}
					}//end of:. wf-form

					.industry-wrapper {
						.industries {
							width: 100%;
							border: 2px solid #e0e0e0;
							padding: 5px;
							border-radius: 3px;
							min-height: 38px;

							.add-industry {
								margin: 3px;
							}

							div.industry {
								padding: 0;
								border-left: 3px solid #85c1e9;
								color: #3498db;
								margin: 3px;
								// text-align: left;

								div.industry-title {
									padding: 9px 5px 9px 16px;
									// display: inline;
									white-space: pre-wrap;
								}

								div.remove-industry-wrapper {
									padding: 9px 10px;
									border-top-right-radius: 3px;
									border-bottom-right-radius: 3px;
									i.remove-industry-icon {
										font-size: 12px;
										color: #e0e0e0;
									}

									&:hover {
										cursor: pointer !important;
										// background-color: #f1f1f1;
										i.remove-industry-icon {
											color: @wf_red;
										}
									}
								}

								&:hover {
									cursor: default;
									background-color: transparent;
								}
							}
						}

						&.empty {
							.industries {
								border-color: rgba(231, 76, 60, 0.6) !important;
							}

							.help-block {
								display: block;
							}
						}

						.help-block {
							display: none;
							color: #e74c3c;
							margin-top: 8px;
						}
					}//end of: .industry-wrapper
				}//end of: .organization-form

				.footer {
					display: inline-block;
					width: 100%;
					padding: 0 15px;
					margin-top: 10px;

					.text {
						text-align: left;
						display: inline-block;
						color: #9ea4a9;
						font-weight: 300;
						white-space: pre-wrap;
					}
					.button-wrap {
						text-align: center;
						margin-top: 20px;
						button {
							margin-top: 15px;
							width: 150px;
						}
					}
				}//end of: .footer
			}
		}
	}//end of: .organization-intro

	&.guidance {
		.modal-header {
			button.bootbox-close-button {
				font-size: 30px;
			}
		}//end of: .title

		.modal-body {
			white-space: pre-wrap;
			font-size: 13px;
		}//end of: .content

		.modal-footer {
			button {
				text-align: right;
				padding-right: 1px;
			}
		}//end of: .footer
	}//end of: .guidance

	&.picker-modal {
		.modal-dialog {
			position: absolute;
			bottom: 40px;
			top: 45px;
			left: 50% !important;
			margin-left: -400px !important;
			width: 100%;
			min-height: 750px;
			margin: 10px;
		}

		.modal-content {
			height: 100%;
			position: static;
		}

		&.countries-picker {
			.list-filters {
				.btn-group {
					button:last-child {
						display: none;
					}
				}
			}
		}
	}//end of: .picker-modal

	&.request-completed {
		.modal-content {
			padding: 60px !important;
			text-align: center;
			.modal-header {
				img {
					width: 150px;
					height: 150px;
				}
			}

			.modal-body {
				.modal-title {
					font-weight: 600;
					font-size: 32px;
					margin-bottom: 10px;
				}

				.message-text {
					font-size: 14px;
				}

				.unanswered-requests-wrapper {
					margin-top: 20px;
					span { font-size: 14px; }
					.unanswered-requests {
						font-weight: 500;
						color: black;
					}
				}
			}

			.modal-footer {
				text-align: center !important;
				button {
					font-size: 18px;
					padding: 10px 40px;
				}
			}
		}
	}//end of: .request-completed

	&.solution-switcher {
		z-index: 2500 !important;
		background-color: fade(@wf_black_darker, 80%);

		.modal-dialog {
			box-shadow: none;

			position: absolute;
			bottom: 0;
			top: 0;
			left: 0;
			right: 0;
			margin: auto !important;
			width: 100%;
			min-height: 750px;

			display: flex;
			justify-content: center;
			align-items: center;
		}

		.modal-content {
			background-color: transparent;
			border: none;
			box-shadow: none;
		}

		.close-button {
			position: absolute;
			right: 50px;
			top: 35px;

			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;

			width: 50px;
			height: 50px;

			&:hover {
				cursor: pointer;
				border-radius: 50%;
				background-color: fade(white, 5%);
			}

			i {
				color: white;
				font-size: 18px;
			}

		}

		.solution-switcher-header {
			text-align: center;
			h1 {
				color: white;
				font-weight: 800;
			}
		}

		.solution-switcher-body {
			.solutions {
				display: flex;
				flex-direction: row;
				justify-content: center;
				flex-wrap: wrap;
				margin: 50px 0 65px 0;

				.solution {
					display: flex;
					flex-direction: column;
					flex-wrap: nowrap;
					transition: transform .4s;

					margin: 30px;

					.image-wrapper {
						transition: border .4s;
					}

					&.active-solution {
						cursor: pointer;
						transform: scale(1.03);
						transition: transform .1s;

						.image-wrapper {
							border: solid 3px white;

							.solution-img {
								box-shadow: none !important;
							}
						}

						span.solution-title {
							color: white;
							font-weight: 500;
						}
						span.network-name {
							color: fade(white, 70%);
						}
					}

					&:hover {
						cursor: pointer;
						transform: scale(1.03);
						transition: transform .1s;

						.image-wrapper {
							border: solid 3px white;
							background-color: white;
							transition: border .1s;

							.solution-img {
								box-shadow: none;
							}
						}

						span.solution-title {
							color: white;
							font-weight: 500;
						}

						span.network-name {
							color: fade(white, 70%);
						}

					}

					a {
						display: flex;
						flex-direction: column;
						text-decoration: none;
						text-align: center;

						&:focus { outline: none; }

						.image-wrapper {
							order: 1;
							border: solid 3px transparent;
							max-width: 185px;
							max-height: 185px;
							margin: auto;

							.solution-img {
								order: 1;
								width: 180px;
								height: 180px;
								margin: 0 auto;

								box-shadow: inset 0px 180px 0px 0px rgba(0, 0, 0, 0.32);

								background-repeat: no-repeat;
								background-size: cover;
								background-position: center;
							}
						}

						.text-wrapper {
							order: 2;
							margin-top: 20px;
							display: flex;
							flex-direction: column;
							justify-content: center;

							span {
								max-width: 220px;

								&.solution-title {
									order: 2;
									color: white;
									font-size: 16px;
									font-weight: 500;
								}

								&.network-name {
									order: 3;
									color: fade(white, 50%);
									font-size: 14px;
									font-weight: 400;
								}
							}
						}
					}
				}
			}
		}


		.solution-switcher-footer {
			text-align: center;
			a {
				text-decoration: none;
				box-shadow: 0 0px 0px 1px white;
				color: white;
			}
		}
	}

	&.list-group-item-overflow-hidden {
		wf-form {
			.list-group-item {
				overflow: hidden;
				border: 0;

				.form-group {
					margin-bottom: 0;
					&::after {
						right: 8px;
						top: 5px;
					}
				}
			}

			ol.list-group + .ng-valid-schema-form {
				float: left;
				padding: 0px 25px !important;
			}
		}
	}

	&.wf-signed-documents-modal {
		.modal-header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 100%;

			.header {
				display: flex;
				justify-content: flex-start;
				margin-left: 10px;

				i {
					font-size: 28px;
					margin-right: 15px;
				}

				h3.modal-title {
					font-size: 24px;
				}
			}

			.close-button {
				button.close {
					font-size: 28px;
					margin-right: 10px;
				}
			}
		}

		.modal-body {
			.list-header {
				margin-left: 15px;
				padding-bottom: 3px;
			}

			.signed-documents {
				.signed-document {
					margin-left: -5px;

					&:first-child {
						margin-bottom: 30px !important;
					}

					a {
						display: flex;
						align-items: center;
						color: @wf_black_darker;
						position: relative;
						text-decoration: none;
						padding: 12px 15px 13px;
						border-radius: 3px;

						&:hover {
							cursor: pointer;
							background-color: @wf_gray_hover;
							a {
								color: @wf_blue_dark;
							}
						}

						&:after {
							content: "\f35d";
							font-family: 'Font Awesome 5 Free';
							font-weight: 900;
							position: absolute;
							top: 16px;
							right: 18px;
						}

						i {
							font-size: 24px;
							text-align: center;
							margin-right: 15px;
						}

						span {
							font-size: 14px;
							font-weight: 500;
							width: 90%;
						}
					}
				}
			}
		}

		.modal-footer {

		}
	}

	&.wf-publish-confirmation-modal {
		.modal-header {
			display: flex;
			justify-content: space-between;

			.close-button {
				button.close {
					font-size: 28px;
					margin-right: 10px;
				}
			}
		}

		.modal-body {
			margin: 45px 0;
			div, a { font-size: 16px }
		}

		.modal-footer {
			button.publish-button {
				text-transform: uppercase;
				letter-spacing: 0.02857em;

				i { margin-right: 7px }
			}
		}
	}

//-------------------------------------------------------------
//----------------------ANIMATIONS-----------------------------
//-------------------------------------------------------------

	&.super-scaled {
		.modal-dialog, .modal-content {
			-webkit-transform: scale(2);
			-moz-transform: scale(2);
			-ms-transform: scale(2);
			transform: scale(2);
			opacity: 0;
			-webkit-transition: all 0.3s;
			-moz-transition: all 0.3s;
			transition: all 0.3s;
		}

		&.in .modal-dialog, .modal-content {
			-webkit-transform: scale(1);
			-moz-transform: scale(1);
			-ms-transform: scale(1);
			transform: scale(1);
			opacity: 1;
		}
	}//end of: .super-scale

	&.fade-in-and-scale-up {
		.modal-dialog, .modal-content {
			-webkit-transform: scale(0.7);
			-moz-transform: scale(0.7);
			-ms-transform: scale(0.7);
			transform: scale(0.7);
			opacity: 0;
			-webkit-transition: all 0.3s;
			-moz-transition: all 0.3s;
			transition: all 0.3s;
		}

		&.in .modal-dialog, .modal-content {
			-webkit-transform: scale(1);
			-moz-transform: scale(1);
			-ms-transform: scale(1);
			transform: scale(1);
			opacity: 1;
		}
	}//end of: .fade-in-and-scale-up

	&.slide-from-the-right {
		.modal-dialog, .modal-content {
			-webkit-transform: translateX(20%);
			-moz-transform: translateX(20%);
			-ms-transform: translateX(20%);
			transform: translateX(20%);
			opacity: 0;
			-webkit-transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
			-moz-transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
			transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
		}

		&.in .modal-dialog, .modal-content {
			-webkit-transform: translateX(0);
			-moz-transform: translateX(0);
			-ms-transform: translateX(0);
			transform: translateX(0);
			opacity: 1;
		}
	}//end of: .slide-from-the-right

	&.slide-from-the-bottom {
		.modal-dialog, .modal-content {
			-webkit-transform: translateY(20%);
			-moz-transform: translateY(20%);
			-ms-transform: translateY(20%);
			transform: translateY(20%);
			opacity: 0;
			-webkit-transition: all 0.3s;
			-moz-transition: all 0.3s;
			transition: all 0.3s;
		}

		&.in .modal-dialog, .modal-content {
			-webkit-transform: translateY(0);
			-moz-transform: translateY(0);
			-ms-transform: translateY(0);
			transform: translateY(0);
			opacity: 1;
		}
	}//end of: .slide-from-the-bottom

	&.fall {
		-webkit-perspective: 1300px;
		-moz-perspective: 1300px;
		perspective: 1300px;

		.modal-dialog, .modal-content {
			-webkit-transform-style: preserve-3d;
			-moz-transform-style: preserve-3d;
			transform-style: preserve-3d;
			-webkit-transform: translateZ(600px) rotateX(20deg);
			-moz-transform: translateZ(600px) rotateX(20deg);
			-ms-transform: translateZ(600px) rotateX(20deg);
			transform: translateZ(600px) rotateX(20deg);
			opacity: 0;
		}

		&.in .modal-dialog, .modal-content {
			-webkit-transition: all 0.3s ease-in;
			-moz-transition: all 0.3s ease-in;
			transition: all 0.3s ease-in;
			-webkit-transform: translateZ(0px) rotateX(0deg);
			-moz-transform: translateZ(0px) rotateX(0deg);
			-ms-transform: translateZ(0px) rotateX(0deg);
			transform: translateZ(0px) rotateX(0deg);
			opacity: 1;
		}
	}//end of: .fall

	&.side-fall {
		-webkit-perspective: 1300px;
		-moz-perspective: 1300px;
		perspective: 1300px;

		.modal-dialog, .modal-content {
			-webkit-transform-style: preserve-3d;
			-moz-transform-style: preserve-3d;
			transform-style: preserve-3d;
			-webkit-transform: translate(30%) translateZ(600px) rotate(10deg);
			-moz-transform: translate(30%) translateZ(600px) rotate(10deg);
			-ms-transform: translate(30%) translateZ(600px) rotate(10deg);
			transform: translate(30%) translateZ(600px) rotate(10deg);
			opacity: 0;
		}

		&.in .modal-dialog, .modal-content {
			-webkit-transition: all 0.3s ease-in;
			-moz-transition: all 0.3s ease-in;
			transition: all 0.3s ease-in;
			-webkit-transform: translate(0%) translateZ(0) rotate(0deg);
			-moz-transform: translate(0%) translateZ(0) rotate(0deg);
			-ms-transform: translate(0%) translateZ(0) rotate(0deg);
			transform: translate(0%) translateZ(0) rotate(0deg);
			opacity: 1;
		}
	}//end of: .side-fall

	&.side-and-stick-to-top {
		top: 0;
		-webkit-transform: translateX(-50%);
		-moz-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);

		.modal-dialog {
			margin-top: 0 !important;
		}

		.modal-dialog, .modal-content {
			-webkit-transform: translateY(-200%);
			-moz-transform: translateY(-200%);
			-ms-transform: translateY(-200%);
			transform: translateY(-200%);
			-webkit-transition: all .3s;
			-moz-transition: all .3s;
			transition: all .3s;
			opacity: 0;
		}

		&.in .modal-dialog, .modal-content {
			-webkit-transform: translateY(0%);
			-moz-transform: translateY(0%);
			-ms-transform: translateY(0%);
			transform: translateY(0%);
			border-radius: 0 0 3px 3px;
			opacity: 1;
		}
	}//end of: .side-and-stick-to-top

	&.flip-horizontal {
		-webkit-perspective: 1300px;
		-moz-perspective: 1300px;
		perspective: 1300px;

		.modal-dialog, .modal-content {
			-webkit-transform-style: preserve-3d;
			-moz-transform-style: preserve-3d;
			transform-style: preserve-3d;
			-webkit-transform: rotateY(-70deg);
			-moz-transform: rotateY(-70deg);
			-ms-transform: rotateY(-70deg);
			transform: rotateY(-70deg);
			-webkit-transition: all 0.3s;
			-moz-transition: all 0.3s;
			transition: all 0.3s;
			opacity: 0;
		}

		&.in .modal-dialog, .modal-content {
			-webkit-transform: rotateY(0deg);
			-moz-transform: rotateY(0deg);
			-ms-transform: rotateY(0deg);
			transform: rotateY(0deg);
			opacity: 1;
		}
	}//end of: .flip-horizontal

	&.flip-vertical {
		/*
			In Chrome there is an issue with this effect - the rendering of the modal lags/breaks when scrolling and the user can't interact properly with it (2018-02-22).
		*/

		-webkit-perspective: 1300px;
		-moz-perspective: 1300px;
		perspective: 1300px;

		.modal-dialog, .modal-content {
			-webkit-transform-style: preserve-3d;
			-moz-transform-style: preserve-3d;
			transform-style: preserve-3d;
			-webkit-transform: rotateX(-70deg);
			-moz-transform: rotateX(-70deg);
			-ms-transform: rotateX(-70deg);
			transform: rotateX(-70deg);
			-webkit-transition: all 0.3s;
			-moz-transition: all 0.3s;
			transition: all 0.3s;
			opacity: 0;
		}

		&.in .modal-dialog, .modal-content {
			-webkit-transform: rotateX(0deg);
			-moz-transform: rotateX(0deg);
			-ms-transform: rotateX(0deg);
			transform: rotateX(0deg);
			opacity: 1;
		}
	}//end of: .flip-vertical

	&.sign {
		-webkit-perspective: 1300px;
		-moz-perspective: 1300px;
		perspective: 1300px;

		.modal-dialog, .modal-content {
			-webkit-transform-style: preserve-3d;
			-moz-transform-style: preserve-3d;
			transform-style: preserve-3d;
			-webkit-transform: rotateX(-60deg);
			-moz-transform: rotateX(-60deg);
			-ms-transform: rotateX(-60deg);
			transform: rotateX(-60deg);
			-webkit-transform-origin: 50% 0;
			-moz-transform-origin: 50% 0;
			transform-origin: 50% 0;
			opacity: 0;
			-webkit-transition: all 0.3s;
			-moz-transition: all 0.3s;
			transition: all 0.3s;
		}

		&.in .modal-dialog, .modal-content {
			-webkit-transform: rotateX(0deg);
			-moz-transform: rotateX(0deg);
			-ms-transform: rotateX(0deg);
			transform: rotateX(0deg);
			opacity: 1;
		}
	}//end of: .3d-sign

	&.just-me {
		.modal-dialog, .modal-content {
			-webkit-transform: scale(0.8);
			-moz-transform: scale(0.8);
			-ms-transform: scale(0.8);
			transform: scale(0.8);
			opacity: 0;
			-webkit-transition: all 0.3s;
			-moz-transition: all 0.3s;
			transition: all 0.3s;
			box-shadow: initial !important;
			border: none;
		}

		&.in .modal-dialog, + .modal-backdrop {
			background: white;
			opacity: 1;
		}

		&.in .modal-dialog, .modal-content {
			-webkit-transform: scale(1);
			-moz-transform: scale(1);
			-ms-transform: scale(1);
			transform: scale(1);
			opacity: 1;
		}
	}//end of: .just-me

}//end of: .wf-modal

.modal-fit-window {
	.modal-dialog {
		position: absolute;
		bottom: 40px;
		top: 45px;
		left: 50% !important;
		margin-left: -400px !important;
		width: 100%;
		min-height: 750px;
		margin: 10px;
	}

	.modal-content {
		height: 100%;
		position: static;
	}

	.modal-body{
		margin-bottom: 20px;
		position: absolute;
		left: 40px;
		right: 40px;
		bottom: 85px;
		top: 100px;
	}

	.modal-footer{
		position: absolute;
		display: block;
		left: 40px;
		right: 40px;
		bottom: 40px;
	}
}

.modal-widget-preview {
	#wf-modal-templateContainer {
		height: 100%;
	}

	iframe {
		padding: 5px;
		border: 3px solid #eee;
	}
}

.modal-raw-settings-editor {
	.modal-dialog {
		width: 800px;
	}

	.list-group-item {
		.close {
			width: 30px;
			height: 30px;
			font-size: 26px;
			border-radius: 100%;

			&:hover {
				background-color: #eee;
			}
		}

		textarea {
			height: 52px;
			font-family: Consolas, 'Courier New', Courier, monospace;
		}
	}
}

//-------------------------------------------------------------
//----------------------RESPONSIVENESS-------------------------
//-------------------------------------------------------------

@media (min-width: @screen-sm-min) {
	.wf-modal {
		&.picker-modal {
			.modal-dialog {
				min-width: 860px;
			}
		}
	}
}

// from 0px to 768px
@media screen and (min-width: 0) and (max-width: @screen-xs-max) {
	.wf-modal {
		&.picker-modal {
			.modal-dialog {
				top: 5px;
				bottom: 5px;
				min-height: 350px;
				margin-left: -50% !important;
				z-index: 2000;

				.modal-content {
					padding: 0px;

					.picker-close {
						display: none;
					}

					.picker-controller {
						.picker-header {
							width: 65%;
							padding-left: 30px;
							padding-top: 30px;
						}

						.picker-body {
							padding-bottom: 30px;
							.existing-items {
								.search-bar-and-add-button {
									padding: 0 30px;
								}

								.mCSB_scrollTools {
									right: 7px;
								}

								.mCSB_container {
									padding-left: 30px;
									padding-right: 10px;
								}

								.list-group-item {
									wf-item-component {
										&:hover {
											background-color: transparent;
										}
										.toggler {
											padding: 0 10px 0 0;

											.toggler-circular {

												&.checked {
													.state-toggled {
														display: inline-block !important;
													}
												}

												&.unchecked {
													.state-toggled {
														display: none !important;
													}
												}

												.state-untoggled.state-hover {
													display: none !important;
												}

												.state-toggled.state-hover {
													display: none !important;
												}
											}
										}
									}
								}
							}
						}

						.picker-footer {
							position: absolute;
							width: 35%;
							top: 0;
							right: 0;
							margin-top: 0;
							padding: 30px 30px 0 0 !important;
						}
					}
				}
			}
		}

		&.solution-switcher {
			.modal-dialog {
				position: relative;
			}
		}
	}
}
