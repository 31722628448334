.droppable-element {
	background-color: white;
	width: 800px;
	height: 350px;
	border: 10px solid gray;
	border-radius: 3px;
	margin-top: 30px;
}

.draggable-element {
	display: inline-block;
	background-color: @wf_black;
	width: 100px;
	height: 50px;
	border: 1px solid @wf_black;
	border-radius: 3px;
	color: white;
	text-align: center;
	padding: 15px;
}

.expandable-trigger {
	border: 1px solid @wf_gray;
	width: 100%;
	cursor: pointer;

	p {
		margin-bottom: 0;
		padding: 20px;
		font-size: 14px;
		font-weight: 500;

		i { margin-left: 5px; }
	}
}

.expandable-content {
	border: 1px solid @wf_blue;
	width: 100%;
	margin-top: 5px;

	p {
		margin-bottom: 0;
		padding: 20px;
		font-size: 14px;
		font-weight: 500;
	}
}