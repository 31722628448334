div.chartist {
	display: inline-block;
	width: 100%;
	position: relative;
	min-height: 200px;

	svg {
		overflow: visible;
	}

	&.transformLeft {
		&.line {
			.ct-labels {
				.ct-label.ct-horizontal {
					transform: translate(-50%);
				}

				foreignObject {
					overflow: initial;
				}
			}
		}
	}

	&.line {
		.ct-series {
			.ct-line {
				stroke-width: 4px;

				&.dashed-line {
					stroke-dasharray: 5px 2px;
				}
			}

			.ct-custom-point {
				stroke-width: 4px;
			}

			.ct-custom-point {
				fill-opacity: 1 !important;
				transition: all 0.2s linear;
				&:hover{
					r: 6;
					stroke-width: 7px;
				  }
			}
		}//end of: .ct-series
	}//end of: .line

	&.bar {
		.ct-series {
			.ct-bar {
				stroke-width: 12px;
			}
		}//end of: .ct-series
	}//end of: .bar

	&.pie {
		svg {
			// padding-top: 30px;
			min-height: 200px;
		}

		.ct-series {
			.ct-slice-pie {
				stroke: white;
				stroke-width: 2px;
			}
		}

		.ct-label {
			font-size: 11px;
			font-weight: 600;
			fill: fade(black, 50%);
		}
	}//end of: .pie

	.ct-grids {
		.ct-grid {
			stroke: #F2F5FA;
			stroke-dasharray: 0px;
		}
	}//end of: .ct-grids

	.ct-labels {
		span.ct-label {
			cursor: default;

			&.ct-vertical {
				justify-content: flex-end;
				align-items: center;
				font-size: 11px;
				color: #B9BBCB;
				white-space: nowrap;
				width: 100% !important;
			}

			&.ct-horizontal {
				white-space: pre-wrap;
				font-size: 11px;
				color: #B9BBCB;
				justify-content: center;
				text-align: center;
				line-height: 1.4;
			}
		}
	}//end of: .ct-labels

	.ct-custom-point {
		fill: white;
	}//end of: .ct-custom-point

	.ct-legend {
		position: relative;
		z-index: 10;
		list-style: none;
		text-align: center;
		margin-top: 20px !important;

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;

		li {
			position: relative;
			margin-right: 4px;
			margin-bottom: 4px;
			display: flex;
			padding: 3px 5px;
			border-radius: 3px;

			&:hover {
				cursor: pointer;
				background-color: #fcfcfc;
			}

			.legend-square {
				width: 15px;
				height: 15px;
				position: absolute;
				left: 0;
				border: 3px solid transparent;
				border-radius: 2px;
			}

			.legend-circle {
				width: 15px;
				height: 15px;
				margin-right: 5px;
				left: 0;
				border: 3px solid transparent;
				border-radius: 50%;
			}

			&.inactive {
				.legend-square, .legend-circle {
					background-color: transparent !important;
				}
			}

			.legend-text {
				font-size: 12px;
				// color: #B9BBCB;
				color: #9d9fac;
			}
		}


		&.ct-legend-inside {
			position: absolute;
			top: 0;
			right: 0;
		}
	}//end of: .ct-legend

	.ct-area, .ct-line {
		pointer-events: none;
	}

	&.disable-pointer-events {
		pointer-events: none;
	}

	.info-message {
		position: absolute;
		top: -20px;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 80%;
		height: 25%;
		text-align: center;

		.dismiss-button {
			float: right;
			font-size: 20px;
			font-weight: bold;
			line-height: 1;
			padding: 5px;
			margin-right: 5px;
			cursor: pointer;
			background: transparent;
			border: 0;
			color: white;
		}

		.message {
			margin: auto;
			padding: 10px;
			background-color: #bcbcbce6;
			border-radius: 5px;
			text-shadow: 0 0 15px #00000078;

			i {
				margin-right: 10px;
				color: white;
			}

			span {
				color: white;
				font-weight: 500;
			}
		}
	}//end of: .info-message


	.chartist-series-selection {
		position: absolute;
		top: -32px;
		left: 53%;
		text-align: left;
		right: 31%;
		width: 30px;

		.chart-dropdown {

			button {
				order: 1;
				border: none;
				box-shadow: none;
				background-color: transparent;
				outline: none;
				padding: 0;
				color: @wf_gray_dark;
				text-align: left;

				span {
					cursor: pointer;
					color: @wf_gray_dark;
					font-size: 10px;
					text-transform: uppercase;
					font-weight: 500;

					&:hover {
						color: @wf_gray_dark;
						text-decoration: none;
					}

					&.dropdown-label {
						margin-right: 0;
						font-weight: 400;
						font-size: 9px;
						display: block;
						margin-bottom: -2px;
					}

					&.selected-item {
						margin-right: 0;
						font-weight: 600;
					}
				}

				i {
					margin-left: 5px;
					font-size: 12px;
				}

				&:hover {
					background-color: transparent;
					i, span.selected-item { color: darken(@wf_gray_dark, 20%); }
				}
			}

			ul.dropdown-menu {
				position: relative;
				order: 2;
				// left: 0;
				margin-left: auto;
				margin-right: auto;

				box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
				min-width: 50px;
				max-width: 200px;

				max-height: 250px;
				overflow-y: auto;

				li {
					padding: 3px 15px 3px 30px;
					border-left: 2px solid transparent;
					white-space: nowrap;

					cursor: pointer;
					color: @wf_gray_dark;
					font-size: 11px;
					text-transform: uppercase;
					font-weight: 500;

					&:hover {
						background-color: fade(@wf_gray_lighter, 40%);
						cursor: pointer;
					}

					&.selected {
						padding-left: 10px;
						border-left-color: @wf_blue;
						font-weight: 500;


						&:before {
							content: '\f00c';
							font-family: FontAwesome;
							color: @wf_blue;
							margin-right: 9px;
						}
					}
				}
			}
		}
	}


}//end of: chartist

.chartist-tooltip {
	position: absolute;
	display: inline-block;
	opacity: 0;
	min-width: 3em;
	padding: .5em .7em;
	background: rgba(0, 0, 0, 0.8);
	color: white;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	text-align: left;
	pointer-events: none;
	z-index: 20;
	transition: opacity .2s linear;
	border-radius: 3px;
	z-index: 10000;

	&:before {
		content: "";
		position: absolute;
		top: 100%;
		left: 50%;
		width: 0;
		height: 0;
		margin-left: -5px;
		border: 5px solid transparent;
		border-top-color: rgba(0, 0, 0, 0.8);
	}

	&.tooltip-show {
		opacity: 1;
	}

	.custom-tooltip-template {
		.tooltip-header {
			span.value {
				font-weight: 700;
			}

			div.value-detailed {
				font-size: 11px;
				font-weight: 300;
				color: #ccc;
			}
		}

		.org-name {
			max-width: 150px;
		}

		.tooltip-body {
			padding-top: 3px;

			.data-value {
				font-size: 15px !important;
			}
		}
	}//end of: .custom-tooltip-template
}//end of: .chartist-tooltip

.period-selection {
	position: absolute;
	left: 50%;
	right: 31%;
	align-items: center;


		ul.dropdown-menu {
			position: relative;
			margin-top: 8px;
			order: 2;
			margin-left: auto !important;
			margin-right: auto !important;

			box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
			min-width: 50px;
			max-width: 200px;

			max-height: 250px;
			overflow-y: auto;

			li {
				padding: 3px 15px 3px 30px;
				border-left: 2px solid transparent;
				white-space: nowrap;

				cursor: pointer;
				color: @wf_gray_dark;
				font-size: 11px;
				text-transform: uppercase;
				font-weight: 500;

				&:hover {
					background-color: fade(@wf_gray_lighter, 40%);
					cursor: pointer;
				}

				&.selected {
					padding-left: 10px;
					border-left-color: @wf_blue;
					font-weight: 500;


					&:before {
						content: '\f00c';
						font-family: FontAwesome;
						color: @wf_blue;
						margin-right: 9px;
					}
				}
			}
		}
}

.blue {
	color: @wf_blue
}

.ct-pointlabel {
	text-anchor: middle;
	font-size: 11px;
	fill: #8F92A3;
	text-shadow:
		0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff,
		0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff,
		0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff,
		0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff,
		0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff,
		0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff,
		0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff,
		0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff,
		0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff,
		0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff;
}
