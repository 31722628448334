wf-export-csv {
	display: inline-block;

	&[skip-button] {
		display: block;
	}

	.export-btn {
		i {
			// color: #2685ee;
			margin-right: 7px;
		}
		span { color: #707980; }
	}

	.popover.export-csv-popover {
		padding: 15px;
		.popoverBoxShadow();

		&:hover {
			cursor: default;
		}
	}

}//end of: wf-export-csv

.popover.export-csv-popover {
	max-width: 330px;
	width: 330px;
	.popoverBoxShadow();
}

wf-export-csv, .popover.export-csv-popover {
	z-index: 10000; // Arbitrary value that is higher than modals that might be open

	.export-options {
		.popover-header {
			.header {
				margin: 8px 0;
				h4 { display: inline; }
				i {
					font-size: 16px;
					margin-right: 12px;
				}
			}

			.description {
				color: @wf_gray;
				padding-top: 10px;
				span{
					display: block;
					padding: 2px 0;
				}
			}
		}//end of: .popover-headers

		.popover-body {
			padding: 10px 0 15px;

			.title {
				font-size: 14px;
				font-weight: 500;
				display: block;
				margin-top: 10px;
			}

			.csv-headers {
				padding: 3px 0 4px;

				.icheckbox_blue_wf {
					margin-top: 5px;
					margin-right: 10px;
				}

				input.check-box {
					display: inline-block;
				}

				input.form-control {
					padding: 5px 10px;
					margin-top: 2px;
				}

				.icheckbox_wf {
					margin-top: 5px;
					margin-right: 10px;
				}
			}

			.exportOption {
				margin-top: 10px;
				overflow: hidden;

				.title {
					font-size: 12px;
					font-weight: 400;
					display: block;
					margin-top: 4px;
					float: left;

					.info {
						font-size: 11px;
						color: @wf_gray_dark;
						display: block;
					}
				}

				.values {
					float: right;


					button {
						margin-left: 0;

						.btn-value {
							margin-left: 10px;
							font-family: Arial, Helvetica, sans-serif;
							font-size: 12px;
							font-weight: 600;
						}
					}
				}
			}
		}//end of: .popover-body

		.popover-footer {
			.count, .download-button { margin: 10px 0; }

			.count {
				padding: 15px 0;
				margin: 4px 0;
				.columns {
					margin-right: 10px;
				}

				.columns, .rows {
					color: @wf_gray;

				}

				.rows {
					&.no-rows{
						color: @wf_red;
					}
				}
			}//end of: .count

			.download-button {
				button.btn {
					i { margin-right: 5px; }
				}
			}
		}//end of: .popover-footer
	}//end of: .export-options

}
