wf-object-metadata{
	> div {
		display: inline-block;
		min-height: 21px;
		margin-top: 0px;
	}

	.metadata-wrap {
		&:not(.metadata-vertical) {
			position: absolute;
		}

		&.metadata-vertical{
			.metadata-row + .metadata-row {
				padding-top: 10px;
			}
	
			.metadata-row {
				margin-top: 12px;
	
				.metadata-wrap {
					padding: 5px 0;
					margin-top: 5px;
				}

				&.user{
					margin-top: 0px;
					.metadata-items{
						clear: none;
						line-height: 15px;
						.item{
							height: auto;
	
							p{
								background-color: transparent;
								border: none;
								padding: 0;
								margin: 0;
							}
	
							p.created{
								text-transform: lowercase;
								padding: 0;
								margin: 0;
							}
	
							div{
								display: inline;
							}
						}
					}
				}
	
				.metadata-icon{
					width: 15px;
					height: 15px;
	
					padding: 1px;
					text-align: center;
	
					margin-right: 0px;
					line-height: 15px;
				}
	
				.metadata-heading{
					display: inline;
					margin: 0 5px 2px 0px;
					max-width: 150px;
					span{
						font-size: 12px;
						line-height: 15px;
						font-weight: 700;
					}
				}
	
				.metadata-items{
					// width: 70%;
					clear: both;
	
					* + .add {
						margin-left: -17px;
					}
	
					.add {
						display: block;
						float: left;
						margin: 2px 2px;
						height: 22px;
						position: relative;
						font-weight: 400;
						padding: 4px 8px 3px 20px;
						line-height: 15px;
						border-radius: 3px;
						box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.14) inset;
						cursor: pointer;
						background-color: #fff;
	
						&:hover {
							background-color: #f6f6f6;
							box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.14) inset;
	
							&:before {
								border-color: #f6f6f6;
								background-color: @wf_blue_dark;
							}
						}
	
						&:before {
							content: "";
							display: block;
							top: 9px;
							left: 9px;
							position: absolute;
							border: 1px dotted #fff;
							width: 5px;
							height: 3px;
							background-color: @wf_blue;
							box-sizing: border-box;
							transform: scale(3);
						}
	
						// ### Circlular button style
						// display: block;
						// float: left;
						// width: 17px;
						// height: 17px;
						// position: relative;
						// border-radius: 100%;
						// background-color: @wf_blue;
						// color: #fff;
						// text-align: center;
						// line-height: 15px;
						// cursor: pointer;
						// margin-top: 4px;
	
						// &:hover, &:active, &:focus {
						// 	background-color: @wf_blue_dark;
						// 	// opacity: 1;
						// 	// box-shadow: 0 0 0 1px @wf_blue;
						// 	// transition: box-shadow 0s;
						// 	&:before {
						// 		border-color: @wf_blue_dark;
						// 	}
						// }
	
						// &:before {
						// 	content: "";
						// 	display: block;
						// 	top: 7px;
						// 	left: 7px;
						// 	position: absolute;
						// 	border: 1px dotted #3498db;
						// 	width: 3px;
						// 	height: 3px;
						// 	background-color: #fff;
						// 	box-sizing: border-box;
						// 	transform: scale(3);
						// }
					}
	
					.item{
						margin: 2px 2px;
						display: block;
						float: left;
						height: 22px;
						
						> span {
							display: inline-block;
							font-weight: 400;
							max-width: 144px;
							padding: 4px 8px 3px;
							line-height: 15px;
							background-color: @wf_gray_light;
							border-radius: 3px;
							text-overflow: ellipsis;
							white-space: nowrap;
							overflow: hidden;
	
							&.linkable {
								max-width: 237px;
							}
	
							a {
								display: block;
								color: @gray-dark;
	
								&:hover {
									text-decoration: underline;
								}
	
								> span {
									display: block;
								}
	
								span.filetype {
									padding-left: 9px;
									margin-right: -2px;
	
									i.fa {
										position: relative;
										top: 1px;
										font-size: 13px;
										margin-left: 1px;
									}
								}
							}
						}
					}
				}
			}//end of: .metadata-row
		}//end of: .metadata-vertical

		.metadata-kinds {
			display: inline-block;
			
			.kind-wrapper {
				float: left;
				
				&.overflow-hidden {
					overflow: hidden !important;
				}

				.kind {
					position: relative;
					border: transparent solid 1px;
					border-radius: 3px;
					text-align: center;
					padding: 2px 6px;
	
					&.enter-animation,
					&.leave-animation {
						transition: margin-left cubic-bezier(0.25, 0.46, 0.45, 0.94) .4s, opacity cubic-bezier(0.25, 0.46, 0.45, 0.94) .4s;
					}
					
					&.pulse {
						animation: metadata-pulse .7s infinite cubic-bezier(0.25, 0.46, 0.45, 0.94);
					}
			
					&:hover:not(.emphasized){
						cursor: pointer;
						background-color: @wf_blue_dark;
						opacity: 0.9;
						visibility: visible;
						
						i { color: white; }
						.change-slider .items-to-slide {
							span { color: white; }
						}
					}
			
					&.active:not(.emphasized){
						background-color: @wf_blue_dark;
						opacity: 0.9;
	
						i { color: white; }
						.change-slider .items-to-slide {
							span { color: white; }
						}
					}
			
					a {
						display: inline-block;
						color: @wf_blue;
						margin-right: 3px;
					}
	
					.change-slider {
						display: inline-block;
						overflow: hidden;
						height: 16.8px;
						vertical-align: top;
						margin-left: 5px;
	
						.items-to-slide {
							display: inline-block;
							margin-top: 0;
							text-align: left;
							list-style: none;
	
							&.lift-up-element {
								transform: translateY(-50%);
							}
		
							&.slide-up {
								animation-name: slide-up;
								animation-duration: .5s;
								animation-fill-mode: backwards;
							}
	
							&.slide-down {
								animation-name: slide-down;
								animation-duration: .5s;
								animation-fill-mode: forwards;
							}
		
							.metadata-number {
								display: block;
								color: #aaa;
								font-size: 11px;
								font-weight: 500;
							}
						}//end of: items-to-slide
					}//end of: .change-slider
	
					.metadataTitle {
						display: inline-block;
						color: #aaa;
						font-size: 11px;
						font-weight: 500;
					}
			
					.metadataIcon {
						display: inline-block;
						height: 16.8px;
						 i {
							 color: #aaa;
							 font-size: 11px;
						}
					}
			
					&.emphasized {
						border: 0.2px @wf_gray_light solid;
						border-radius: 3px;
						-webkit-transition: 0.2s;
						transition: 0.2s;
			
						&:hover{
							background-color: @wf_blue_dark;
							border: 0.2px @wf_blue_dark solid;
							span { color: white; }
							i { color: white; }
						}
			
						&.active{
							background-color: @wf_blue_dark;
							border: 0.2px @wf_blue_dark solid;
							span { color: white; }
							i { color: white; }
						}
			
						i{
							color: @wf_blue;
							font-size: 12px;
						}
			
						.metadata-number{
							color: @wf_blue;
							font-size: 11px;
							font-weight: 500;
							padding: 0 3px;
							margin-left: 2px;
						}
			
						.metadataTitle{
							color: @wf_black;
							padding: 0 3px;
							font-weight: 300;
						}
					}
				}//end of: .kind
			}//end of: .kind-wrapper
		}//end of:.metadata-kinds
	


		//Tooltips
		.kind-wrapper, .metadata-vertical .metadata-items .item {
			&[tooltip-title] {
				position: relative;
				z-index: 2;
				cursor: pointer;
	
				&:before,
				&:after {
					visibility: hidden;
					opacity: 0;
					pointer-events: none;
				}

				&[tooltip-position-top-left] {
					&:before {
						left: -5px;
						right: initial;
					}
				}
				
				&:before {
					content: attr(tooltip-title);
					position: absolute;
					text-align: center;
					font-size: 11px;
					color: #fff;
					right: 0px;
					bottom: 150%;
					margin-bottom: -2px;
					padding: 5px 8px;
					border-radius: 2px;
					border-radius: 3px;
					background-color: rgba(24, 42, 60, 0.98);
					white-space: nowrap;
				}
				
				/* Triangle hack to make tooltip look like a speech bubble */
				&:after {
					position: absolute;
					bottom: 121%;
					left: 50%;
					margin-left: -5px;
					width: 0;
					border-top: 5px solid rgba(24, 42, 60, 0.98);
					border-right: 5px solid transparent;
					border-left: 5px solid transparent;
					content: " ";
					font-size: 0;
					line-height: 0;
				}
	
				&:hover:before,
				&:hover:after {
					visibility: visible;
					opacity: 1;
					animation: fadein .2s ease-in; 
				}
			}//end of: &[data-original-title] - Tooltip
		}//Tooltips
	
		div + .objectViewer {
			padding: 3px 0px 3px 10px;
		}
	
		.objectViewer{
			display: inline-block;
	
			.detailedView{
				font-weight: 200;
				font-size: 11px;
				color: @wf_blue;
				visibility: hidden;
				margin-right: 15px;
				border: 0;
	
				span{
					&:hover {
						text-decoration: underline;
					}
				}
	
				i {
					margin-left: 3px;
					font-size: 11px;
					position: relative;
					top: 1px;
				}
			}
	
			.object-viewer-toggle{
				display: inline-block;
				visibility: hidden;
				height: 16px;
				
				&.loading{
					visibility: visible;
	
					.loader {
						display: inline-block;
					}
				}
	
				&:hover a {
					cursor: pointer;
					text-decoration: underline;
				}
	
				a {
					font-size: 11px;
					color: @wf_blue;
					font-weight: 300;
					padding-left: 0;
					border: 0;
	
					&.no-data{
						color: @wf_gray;
						cursor: default;
						text-decoration: none !important;
					}
				}
	
				.loader {
					display: none;
					border-radius: 50%;
					width: 17px;
					height: 17px;
					padding: 8px 0 8px 0;
					margin: 0;
					margin-left: 5px;
					.spinner(12px, @wf_blue);
				}
	
				.icon{
					text-align: center;
					width: 21px;
	
					.arrow{
						font-size: 15px;
						color: #3498db;
						font-weight: 300;
						position: relative;
						top: 1px;
						left: -1px;
	
						&.up {
							transform: rotate(180deg);
						}
					}
				}
			}//end of: .object-viewer-toggle
		}//end of: .objectViewer
	}//end of: .metadata-wrap

	.embedded-object-viewer-wrap {
		padding-top: 20px;

		.embedded-object-viewer {
			// width: 88%;
			border: solid 1px fade(@wf_gray, 50%);
			border-radius: 3px;
			background-color: white;
			margin-top: 15px;
			border-left: 3px solid @wf_green;
		}
	}//end of: .embedded-object-viewer
}//end of: wf-object-metadata

body div.popover.metadata-popover {
	min-width: 420px;
	min-height: 100px;
	z-index: 3000;

	margin-left: 192px !important;

	&.consolidatedData { 
		margin-left: 318px !important; 
		width: 700px; 
		min-width: 700px; 
		max-width: 700px; 
	  } 

	.arrow {
		left: 5% !important;
		margin-left: -14px !important;
	}

	.popoverBoxShadow();
	
	.popover-content {
		padding: 0;
		.wf-popover {
			overflow: hidden;
			overflow-y: auto;
			padding: 20px 15px;

			&.add-max-height {
				max-height: 550px;
			}
		
			.popover-wrapper {
				.popover-header{
					font-size: 14px;
					padding: 0px 2px 5px 5px;
					background-color: white;
					h4{
						font-weight: bold;
						display: inline;
					}
			
					.itemCount{
						display: inline-block;
						span:first-child{
							margin: 0 6px 0 6px;
						}
						span{
							color: #CCCCCC;
							font-weight: bold;
							font-size: 11px;
						}
						span:last-child{
							color: #CCCCCC;
							font-weight: 300;
							font-size: 10px;
						}
					}
					.icons{
						float: right;
						display: inline-block;
			
						.plusIcon{
							>i{
								background:none;
								border:none;
								font-size:1em;
								color:#2685EE;
								margin-right: 8px !important;
								font-size: 20px;
								
								&:hover{
									cursor: pointer;
									color: #4999F1;
								}
							}
			
							button{
								>i{
									background:none;
									border:none;
									font-size:1em;
									color:#2685EE;
									margin-right: 8px !important;
									font-size: 20px;
									
									&:hover{
										cursor: pointer;
										color: #4999F1;
									}
								}
							}
						}
					}
				}//end of: .popover-header
			
				.popover-body{
					padding-top: 10px;
			
					.chartLine{
						position: relative;
						margin-bottom: 15px;
						margin-left: -10px;
					}
			
					.listOfPopoverItems{
						wf-chart-line{
							margin-top: 15px;
						}
			
						wf-item-component {
							.content, .headers {
								.wf-headerText {
									padding: 0;
								}
							}
						}
			
						.wf-item:last-child{
							wf-item-component {
								border-bottom: none !important;
							}
						}
			
						&.loading{
							.spinner(22px);
							min-height: 50px;
			
							> * {
								display: none;
							}
						}
					}
			
					wf-empty-state.metadata-empty-state{
						.emptyList-message{
							padding: 10px;
							p {
								display: inline;
								font-size: 11px;
								color: #B1B1B1;
								font-weight: bold;
							}
							i{
								display: inline;
								color: #2685EE;
								margin: 0 5px;
							}
						}
						img{
							width: 75px;
							margin-bottom: 10px !important;
						}
			
						p:hover{
							cursor: default;
						}
			
						.relatedContent{
							padding: 20px 0 25px 0;
			
							img{
								width: 30px;
							}
						}
					}
				}//end of: .popover-body
		
				.popover-footer{
					margin-top: 10px;
					width: 100%;
			
					.seeMore{
						width: 100%;
						padding: 10px 0;
						margin-bottom: 10px;
						text-align: center;
						display: inline-block;
						border-radius: 3px;
						// border: @wf_gray_light solid 1px;
						// background-color: @wf_gray_light;
						i{
							padding-right: 5px;
						}
						span{
							font-weight: 500;
						}
					}
			
					.objectViewerButton{
						a{
							width: 100%;
							color: #fff;
						}
					}
				}//end of: .popover-footer
			}//end of: .popover-wrapper
		}//end of: .wf-popover
	}//end of: .popover-content
}//end of:metadata-popover

.HierarchicalController wf-object-metadata {
	width: 100%;
	white-space: nowrap;
	cursor: default;

	.kind {
		cursor: pointer;
	}
	.objectViewer{

	}
}//end of: .HierarchicalController wf-object-metadata