@import "wf-variables.less";
@import "wf-mixins.less";

.text-gray {
	&, * {
		color: #aaa;
	}

	a& {
		&:hover {
			color: #888;
			text-decoration: underline;
		}
	}
}

.text-prewrap {
	white-space: pre-wrap;
}

.text-semibold {
	font-weight: 500;
}

.text-uppercase * {
	text-transform: uppercase;
}

.push-bottom {
	position: absolute;
	bottom: 0;
}

.bg-white {
	background-color: #fff;
}

.text-monospace {
	font-family: Menlo,Monaco,Consolas,"Courier New",monospace;
	font-size: 12px;
}

.pull-none {
	overflow: hidden;
	clear: none;
	float: none;
	width: auto;
}

.text-cut {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.text-code {
	font-family: Menlo,Monaco,Consolas,"Courier New",monospace;
}

textarea.text-code {
	resize: none;

	&[disabled] {
		background: #fff;
		cursor: text;
		color: @gray-dark;
	}
}

.w100 {width: 100% !important;}
.w90 {width: 90% !important;}
.w80 {width: 80% !important;}
.w70 {width: 70% !important;}
.w60 {width: 60% !important;}
.w50 {width: 50% !important;}
.w40 {width: 40% !important;}
.w30 {width: 30% !important;}
.w20 {width: 20% !important;}
.w10 {width: 10% !important;}

.cursor-pointer {
	cursor: pointer;
}

.display-inline-block {
	display: inline-block;
}

.fa-spin-x2 {
	-webkit-animation: fa-spin-x2 1s infinite linear;
	animation: fa-spin-x2 1s infinite linear;
}

@-webkit-keyframes fa-spin-x2 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

@keyframes fa-spin-x2 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}


.fa-spin-x3 {
	-webkit-animation: fa-spin-x3 .5s infinite linear;
	animation: fa-spin-x3 .5s infinite linear;
}

@-webkit-keyframes fa-spin-x3 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

@keyframes fa-spin-x3 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

.modal-dialog {
	.modal-width-300 &, &.modal-width-300 {
		width: 95%;
		max-width: 300px;
	}

	.modal-width-400 &, &.modal-width-400 {
		width: 95%;
		max-width: 400px;
	}

	.modal-width-500 &, &.modal-width-500 {
		width: 95%;
		max-width: 500px;
	}

	.modal-width-600 &, &.modal-width-600 {
		width: 95%;
		max-width: 600px;
	}

	.modal-width-700 &, &.modal-width-700 {
		width: 95%;
		max-width: 700px;
	}

	.modal-width-800 &, &.modal-width-800 {
		width: 95%;
		max-width: 800px;
	}

	.modal-width-900 &, &.modal-width-900 {
		width: 95%;
		max-width: 900px;
	}

	.modal-width-1000 &, &.modal-width-1000 {
		width: 95%;
		max-width: 1000px;
	}

	.modal-width-1100 &, &.modal-width-1100 {
		width: 95%;
		max-width: 1100px;
	}

	.modal-width-1200 &, &.modal-width-1200 {
		width: 95%;
		max-width: 1200px;
	}

	.modal-width-1300 &, &.modal-width-1300 {
		width: 95%;
		max-width: 1300px;
	}
}

.position-relative {
	position: relative;
}

.position-absolute {
	position: absolute;
}

.display-flex {
	display: flex;
}

.flex-dir-column {
	flex-direction: column;
}

.display-block {
	display: block !important;
}

.flex-grow-1 {
	flex-grow: 1;
}

.as-link {
	.asLink();

	&:hover *:not(i) {
		text-decoration: none;
	}
}

.generate-heights(15);

.generate-heights(@n, @i: 1) when (@i =< @n) {
	.height-@{i}0px {
		height: (@i * 10px) !important;
	}
	.generate-heights(@n, (@i + 1));
}

.col-xs-0_5, .col-sm-0_5, .col-md-0_5, .col-lg-0_5, .col-xs-1_5, .col-sm-1_5, .col-md-1_5, .col-lg-1_5, .col-xs-2_5, .col-sm-2_5, .col-md-2_5, .col-lg-2_5 {
	position: relative;
	min-height: 1px;
	padding-left: 10px;
	padding-right: 10px;
	float: left;
}

.col-xs-0_5 {
	width: 4.16666663%;
}

.col-xs-1_5 {
	width: 12.49999995%;
}

.col-xs-2_5 {
	width: 20.83333333%;
}

// sm
@media (min-width: 768px) {
	.col-sm-0_5 {
		width: 4.16666663%;
	}

	.col-sm-1_5 {
		width: 12.49999995%;
	}

	.col-sm-2_5 {
		width: 20.83333333%;
	}
}

// md
@media (min-width: 992px) {
	.col-md-0_5 {
		width: 4.16666663%;
	}

	.col-md-1_5 {
		width: 12.49999995%;
	}

	.col-md-2_5 {
		width: 20.83333333%;
	}
}

// lg
@media (min-width: 1200px) {
	.col-lg-0_5 {
		width: 4.16666663%;
	}

	.col-lg-1_5 {
		width: 12.49999995%;
	}

	.col-lg-2_5 {
		width: 20.83333333%;
	}
}

.box-shadow-pulse-effect(@color) {
	box-shadow: 0 0 0 fade(@color, 60%);
	animation: pulseBoxShadow 2s infinite;
}