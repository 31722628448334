wf-item-component, .wf-item-component{
	display: block;
	// border: dashed @wf_green 1px;

	.container {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		width: 100%;

		.image {
			order: 1;
			flex: none;
		
			.user-icon{
				position: static;
				border-radius: 100%;
				background-position: center center;
				background-size: cover;
			}
			position: relative;
		
			.wf-image {
				color: #AFAFAF;
				text-align: center;
				margin-top: 0;
		
				&.user-icon {
					border-radius: 50%;
				}
			}
		}//end of: .image
		
		.item-tools {
			order: 3;
			flex-shrink: 1;
			// visibility: hidden;
		}
		
		.content-wrapper {
			order: 2;
			flex-shrink: 2;
			width: 100%;
		}

		.content {
			display: flex;
			flex-direction: row;

			.inner-content {
				order: 1;
				flex: auto;
			}

			.transclude-side {
				// border: dashed 1px @wf_blue;
				// padding: 10px;
				margin-left: 20px;
				order: 2;
				flex: none;

				transclude-side {
					display: block;
				}
			}
		
			.transclude-bottom{
		
			}
			
			.side-actions {
				display: inline-block;
				width: auto;
				order: 2;
			}//end of: .side-actions
			
			.headers{
				&:not(:empty){
					padding-top: 1px;
					margin-bottom: 10px;
				}
		
				&:empty{
					margin-bottom: 0px !important;
				}
		
				.wf-subHeader {
					color: #95a5a6;
				}
		
				.header-icon {
					display: inline-block;
					margin-right: 10px;
					i {
						font-size: 21px;
					}
				}
		
				.wf-headerText {
					display: inline-block;
					margin: 0;
					padding: 2px 0;
					color: #333;
				}
		
				.wf-subHeader {
					color: #95a5a6;
				}

				wf-item-creator-bar {
					padding-top: 3px;
					padding-bottom: 3px;
				}
				
				* + .wf-bodyText{ 
					padding-top: 5px; 
		
					&.text-cut{
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
		
				.wf-bodyText{white-space: pre-wrap; }
			}//end of: .headers
		
			.embed-player{
				width: 100%;
				margin: 10px 0;
				.embed-media{ margin: 5px 0; }
			}//end of: .embed-player
		
			.content-metadata {
				position: relative;
				.metadata-directive {
					display: block !important;
					.metadata-wrap {
						// margin-top: 10px;
					}
				}//end of: .metadata-directive
			}//end of: .content-directive
		}//end of: .content
	}//end of: .container

	&:hover{
		&:not(.only-image){
			background-color: @wf_gray_hover;
		}

		wf-object-metadata{
			.detailedView{
				visibility: visible
			}
			.object-viewer-toggle{
				visibility: visible;
			}
		}

		.item-tools{
			visibility: visible;
		}
	}

	&.no-padding{
		padding: 0 !important;
	}

	&.no-min-width{
		min-width: initial !important;
	}

	&.no-hover, .wf-item-component{
		&:hover{
			background-color: initial !important;
		}
	}

	&.padding-vertical{
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	&.borders{
		border-top: 1px solid #eee;
		border-bottom: 1px solid #eee;
	}

	&:first-child{
		border-top: 0;
	}

	.wf-item-component {
		background-color: transparent !important;
		// &.depth1 {
		// 	.as-header {
		// 		font-size: 21px !important;
		// 		font-weight: 500 !important;
		// 	}
		// }

		// &.depth2 {
		// 	.as-header {
		// 		font-size: 17.8px !important;
		// 		font-weight: 400 !important;
		// 	}
		// }

		// &.depth3 {
		// 	.as-header {
		// 		font-size: 15px !important;
		// 		font-weight: 600 !important;
		// 	}
		// }

		// &.depth4 {
		// 	.as-header {
		// 		font-size: 14px !important;
		// 		font-weight: 400 !important;
		// 	}
		// }

	}//end of: .wf-item-component
	
	&.pulse-background {
		animation: pulse-gray 1s ease;
	}


//------------------------------------------------------------
//--------------------------SIZES-----------------------------
//------------------------------------------------------------

	&.small{
		padding: 10px;
		// min-height: 45px; //image height + 2 x padding
		// min-width: 335px;

		.image{
			width: 30px;
			height: 30px;
			margin-right: 10px;

			.user-icon{
				width: 30px;
				height: 30px;
			}

			.wf-image{
				font-size: 25px;
				// width: 25px;
				// height: 25px;
			}
		}//end of: .image

		.item-tools{ 
			margin-left: 22px; 
		}

		.content{
			// min-height: 40px;
			// .transclude-side{
			// }
			// .transclude-bottom{
			// }

			.side-actions{
				.object-viewer-answering{ margin-left: 22px; }
			}//end of: .side-actions

				.headers{
					margin-top: 0px;
					.wf-preHeader{
						font-size: 10px;
						font-weight: 500;
					}

					.wf-headerText{
						font-size: 12px;
						font-weight: 500;
					}

					.wf-subHeader{
						font-size: 10px;
						font-weight: 500;
					}
				}//end of: .headers

			.body{
				.wf-bodyText{ 
					font-size: 10px;
					&.question-style{
						font-size: 12px !important;
						font-weight: normal !important;
					}
				}
			}
			// .embed-player{
			// }//end of: .embed-player
		}//end of: .content
		
		.metadata-directive{
			// padding-left: 30px; //image width + image margin-right
		}
	}//end of: .small

	&.medium{
		padding: 15px;
		// min-height: 70px; //image height + 2 x padding
		// min-width: 410px;

		.image{
			width: 50px;
			height: 50px;
			margin-right: 15px;

			.user-icon{
				width: 50px;
				height: 50px;
			}

			.wf-image{
				font-size: 35px;
				// width: 40px;
				// height: 40px;
			}
		}//end of: .image

		.item-tools{ 
			margin-left: 22px; 
		}

		.content{
			// min-height: 60px;
			// .transclude-side{
			// }
			// .transclude-bottom{
			// }

			.side-actions{
				.object-viewer-answering{ margin-left: 22px; }
			}//end of: .side-actions

			.headers{
				.wf-preHeader{
					font-size: 10px;
					font-weight: 500;
				}

				.wf-headerText{
					font-size: 16px;
					font-weight: 500;
					line-height: 20px;
					padding: 4px 0;
				}

				.wf-subHeader{
					font-size: 11px;
					font-weight: 500;
				}
			}//end of: .headers

			.body{
				.wf-bodyText{
					font-size: 12px;
				}
			}
			// .embed-player{
			// }//end of: .embed-player
		}//end of: .content
		
		.metadata-directive{
			//padding-left: 50px; //image width + image margin-right
		}
	}//end of: .medium

	&.large{
		padding: 20px;
		// min-height: 120px; //image height + 2 x padding
		// min-width: 525px;

		.image{
			width: 80px;
			height: 80px;
			margin-right: 15px;

			.user-icon{
				width: 80px;
				height: 80px;
			}

			.wf-image{
				font-size: 50px;
				// width: 80px;
				// height: 80px;
			}
		}//end of: .image

		.item-tools{ 
			margin-left: 22px; 
		}

		.content{
			// min-height: 100px;
			// .transclude-side{
			// }
			// .transclude-bottom{
			// }

			.side-actions{
				.object-viewer-answering{ margin-left: 22px; }
			}//end of: .side-actions

				.headers{
					.wf-preHeader{
						font-size: 12px;
						font-weight: 500;
					}

					.wf-headerText{
						font-size: 18px;
						font-weight: 500;
					}

					.wf-subHeader{
						font-size: 12px;
						font-weight: 500;
					}
				}//end of: .headers

			.body{
				.wf-bodyText{ 
					font-size: 14px;
				}
			}
			// .embed-player{
			// }//end of: .embed-player
		}//end of: .content

		.metadata-directives{
			padding-left: 95px; //image width + image margin-right
		}
	}//end of: .large

//------------------------------------------------------------
//--------------------------STYLES----------------------------
//------------------------------------------------------------

	&.only-image{
		*{ transition: all 0.2s ease-out;}

		.wf-item-component{
			border: solid 2px @wf_gray_lighter;
			border-radius: 2px;
			opacity: 0.9;
			&:hover{
				background-color: white;
				border: solid 2px @wf_gray_light;
				opacity: 1;
			}
		}
		.image{
			width: 100%;
			height: 100%;
			margin-right: 0;
			display: initial;
			float: initial !important;
			.wf-image{
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center center;
				width: initial;
				height: 300px;
			}
		}//end of: .image
	}//end of: .only-image
} //end of: wf-item-component

html.msie {
	&.msie10 {
		wf-item-component, .wf-item-component {
			.container {
				.content-wrapper {
					display: flex;
				}
				.inner-content {
					flex-basis: 100% !important;
				}
			}
		}
	}
}

@media screen and (min-width: 0) and (max-width: @screen-xs-min) {
	wf-item-component, .wf-item-component {
		.container {
			.image, .wf-image {
				width: 30px !important;
				height: 30px !important;
			}

			.item-tools {
				margin-left: 0;
			}
		}
	}
}
