@color_1: inherit;
@color_2: #ccc;
@color_3: #999;
@color_4: #000;
@color_5: #fff;
@color_6: #555;
@color_7: #08c;
@background_color_1: #fff;
@background_color_2: #eee;
@background_color_3: #ebf4f8;
@background_color_4: #357ebd;
@background_color_5: #f5f5f5;
@background_color_6: #08c;
@selected: #428BCA;
@border_color_1: transparent;
@border_bottom_color_1: rgba(0, 0, 0, 0.2);

.input-group.date-range-picker {
	width: 100%;
	display: block;

	&.active {
		.input-group-addon.left {
			color: #fff;
			background: #2f3e50;
			width: 21px;
		}

		> input.form-control {
			box-shadow: 10px 0 #2F3E50 inset !important;
			border-color: #2f3e50;

			letter-spacing: -0.4px;
			padding-left: 28px;
			color: #707980;
		}
	}

	.input-group-addon {
		min-width: 0;
	}

	input.date-picker {
		cursor: pointer;

		&:hover + .input-group-addon.right{
			.caret {
				&:before {
					color: #262d33;
				}
			}
		}

		&:focus {
			border-color: #e0e0e0;
		}
	}

	.input-group-addon.left {
		position: absolute;
		left: 1px;
		top: 1px;
		width: 36px;
		height: 34px;
		z-index: 10;
		border: 0;
		color: #677481;
		text-align: center;
		pointer-events: none;
		background: #fff;
		transition: width .1s, background .07s;
	}

	> input.form-control {
		padding-left: 46px;
		border-radius: 3px !important;
		color: #fff;
		transition: padding-left .1s, color .1s;
	}

	.input-group-addon.right {
		position: absolute;
		right: 1px;
		top: 1px;
		z-index: 10;
		border: 0;
		color: rgb(112, 121, 128);
		width: 0;


		.caret {
			position: absolute;
			width: 20px;
			right: 6px;
			pointer-events: none;

			&:before {
				font-size: 17px;
			}
		}

		.clear-button {
			cursor: pointer;
			padding: 0px;
			width: 20px;
			height: 20px;
			line-height: 22px;
			border-radius: 100%;
			position: absolute;
			top: 6px;
			right: 26px;
			color: #677481;
			
			&:hover {
				background-color: #eee;
				color: #444;
			}
		}
	}
}


.daterangepicker {
	position: absolute;
	color: @color_1;
	background-color: @background_color_1;
	border-radius: 4px;
	width: 278px;
	margin-top: 7px;
	top: 100px;
	left: 20px;
	padding: 15px 0;
	.popoverBoxShadow();

	&:before {
		position: absolute;
		display: inline-block;
		border-bottom-color: @border_bottom_color_1;
		content: '';
		top: -7px;
		border-right: 7px solid transparent;
		border-left: 7px solid transparent;
		border-bottom: 7px solid #ccc;
	}
	&:after {
		position: absolute;
		display: inline-block;
		border-bottom-color: @border_bottom_color_1;
		content: '';
		top: -6px;
		border-right: 6px solid transparent;
		border-bottom: 6px solid #fff;
		border-left: 6px solid transparent;
	}
	.calendar {
		display: none;
		max-width: 270px;
		margin: 4px;
		th {
			white-space: nowrap;
			text-align: center;
			min-width: 32px;
		}
		td {
			white-space: nowrap;
			text-align: center;
			min-width: 32px;
		}

		&.left {
			border-left: 1px solid rgba(128, 128, 128, 0.21);
			padding-left: 24px;
			margin-right: 10px !important;
		}
		&.right {
			padding-right: 20px;
		}
	}
	.calendar.single {
		.calendar-table {
			border: none;
		}
	}
	.calendar-table {
		border: 1px solid #fff;
		padding: 4px;
		border-radius: 4px;
		background-color: @background_color_1;
	}
	table {
		width: 100%;
		margin: 0;
	}
	td {
		text-align: center;
		width: 20px;
		height: 20px;
		border-radius: 4px;
		border: 1px solid transparent;
		white-space: nowrap;
		cursor: pointer;
	}
	th {
		text-align: center;
		width: 20px;
		height: 20px;
		border-radius: 4px;
		border: 1px solid transparent;
		white-space: nowrap;
		cursor: pointer;
	}
	td.available {
		&:not(.start-date):not(.end-date):hover {
			// background-color: @background_color_2;
			// border-color: @border_color_1;
			// color: @color_1;

			background-color: transparent;
			border-color: transparent;
			box-shadow: 0 0 0 2px #437dbc inset;
			border-radius: 6px;
		}
	}
	// td.in-range:not(.end-date) + td.available:not(.start-date):not(.in-range):hover, td.start-date + td.available:hover {
	// 	border-top-left-radius: 0px;
	// 	border-bottom-left-radius: 0px;
	// 	border-top-right-radius: 6px;
	// 	border-bottom-right-radius: 6px;
	// }

	th.available {
		&:hover {
			background-color: @background_color_2;
			border-color: @border_color_1;
			color: @color_1;
		}
	}
	td.week {
		font-size: 80%;
		color: @color_2;
	}
	th.week {
		font-size: 80%;
		color: @color_2;
	}
	td.off {
		background-color: @background_color_1;
		border-color: @border_color_1;
		color: @color_3;
	}
	td.off.in-range {
		background-color: @background_color_1;
		border-color: @border_color_1;
		color: @color_3;
	}
	td.off.start-date {
		background-color: @background_color_1;
		border-color: @border_color_1;
		color: @color_3;
	}
	td.off.end-date {
		background-color: @background_color_1;
		border-color: @border_color_1;
		color: @color_3;
	}
	td.in-range {
		background-color: @background_color_3;
		border-color: @border_color_1;
		color: @color_4;
		border-radius: 0;
	}
	td.start-date {
		border-radius: 4px 0 0 4px;
	}
	td.end-date {
		border-radius: 0 4px 4px 0;
	}
	td.start-date.end-date {
		border-radius: 4px;
	}
	td.active {
		background-color: @background_color_4;
		border-color: @border_color_1;
		color: @color_5;
		&:hover {
			background-color: @background_color_4;
			border-color: @border_color_1;
			color: @color_5;
		}
	}
	th.month {
		width: auto;
	}
	td.disabled {
		color: @color_3;
		cursor: not-allowed;
		text-decoration: line-through;
	}
	option.disabled {
		color: @color_3;
		cursor: not-allowed;
		text-decoration: line-through;
	}
	select.monthselect {
		font-size: 12px;
		padding: 1px;
		height: auto;
		margin: 0;
		cursor: default;
		margin-right: 2%;
		width: 56%;
	}
	select.yearselect {
		font-size: 12px;
		padding: 1px;
		height: auto;
		margin: 0;
		cursor: default;
		width: 40%;
	}
	select.hourselect {
		width: 50px;
		margin-bottom: 0;
	}
	select.minuteselect {
		width: 50px;
		margin-bottom: 0;
	}
	select.secondselect {
		width: 50px;
		margin-bottom: 0;
	}
	select.ampmselect {
		width: 50px;
		margin-bottom: 0;
	}
	.input-mini {
		border: 1px solid #ccc;
		border-radius: 2px;
		color: @color_6;
		height: 30px;
		line-height: 30px;
		display: block;
		vertical-align: middle;
		margin: 0 0 5px 0;
		padding: 0 6px 0 28px;
		width: 100%;
	}
	.input-mini.active {
		border: 1px solid #08c;
	}
	.daterangepicker_input {
		position: relative;
		padding-left: 10px;
		i {
			position: absolute;
			left: 20px;
			top: 8px;
		}
	}
	.calendar-time {
		text-align: center;
		margin: 5px auto;
		line-height: 30px;
		position: relative;
		padding-left: 28px;
		select.disabled {
			color: @color_2;
			cursor: not-allowed;
		}
	}
	
	
	&.opensleft {
		&:before {
			right: 9px;
		}
		&:after {
			right: 10px;
		}
	}// end of: .opensleft

	&.openscenter {
		&:before {
			left: 0;
			right: 0;
			width: 0;
			margin-left: auto;
			margin-right: auto;
		}
		&:after {
			left: 0;
			right: 0;
			width: 0;
			margin-left: auto;
			margin-right: auto;
		}
	}// end of: openscenter

	&.opensright {
		&:before {
			left: 9px;
		}
		&:after {
			left: 10px;
		}
	}// end of: .opensright

	&.dropup {
		margin-top: -5px;
		&:before {
			top: initial;
			bottom: -7px;
			border-bottom: initial;
			border-top: 7px solid #ccc;
		}
		&:after {
			top: initial;
			bottom: -6px;
			border-bottom: initial;
			border-top: 6px solid #fff;
		}
	}// end of: .dropup

	&.dropdown-menu {
		max-width: none;
		z-index: 3001;
		min-height: 340px;

		.ranges {
			font-size: 11px;
			float: left;
			text-align: left;
			ul {
				padding: 0;
				li {
					padding: 8px 12px;
					font-size: 12px;
					font-weight: 400;
					display: block;

					&:hover {
						background-color: @wf_gray_lighter;
						color: #6f7f80;
						cursor: pointer;
					}

					&.active {
						background-color: @selected;
						color: white;
					}
				}
			}// end of: ul

			.range_inputs {
				padding-right: 30px;
				position: absolute;
				right: 0;
				bottom: 20px;

				.applyBtn {
					background-color: @selected;
					margin-right: 5px;
				}
			}//end of: .range_inputs
		}// end of: .ranges
	}// end of: .dropdown-menu 
	
	&.single {
		.ranges {
			float: none;
		}
		.calendar {
			float: none;
		}
	}// end of: .single

	&.show-calendar {
		.calendar {
			display: inline-block;
		}
	}// end of: .show-calendar

	&.rtl {
		.input-mini {
			padding-right: 28px;
			padding-left: 6px;
		}
		.daterangepicker_input {
			i {
				left: auto;
				right: 8px;
			}
		}
	}
}

@media (min-width: 564px) {
	.daterangepicker {
		width: auto;
		.ranges {
			ul {
				width: 160px;
			}
		}
	}
	.daterangepicker.single {
		.ranges {
			ul {
				width: 100%;
			}
		}
		.calendar.left {
			clear: none;
		}
	}
	.daterangepicker.single.ltr {
		.ranges {
			float: left;
		}
		.calendar {
			float: left;
		}
	}
	.daterangepicker.single.rtl {
		.ranges {
			float: right;
		}
		.calendar {
			float: right;
		}
	}
	.daterangepicker.ltr {
		direction: ltr;
		text-align: left;
		.calendar.left {
			clear: left;
			margin-right: 0;
			.calendar-table {
				border-right: none;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				padding-right: 12px;
			}
		}
		.calendar.right {
			margin-left: 0;
			.calendar-table {
				border-left: none;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
		.left {
			.daterangepicker_input {
				padding-right: 12px;
			}
		}
		.ranges {
			float: left;
		}
		.calendar {
			float: left;
		}
	}
	.daterangepicker.rtl {
		direction: rtl;
		text-align: right;
		.calendar.left {
			clear: right;
			margin-left: 0;
			.calendar-table {
				border-left: none;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				padding-left: 12px;
			}
		}
		.calendar.right {
			margin-right: 0;
			.calendar-table {
				border-right: none;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		.left {
			.daterangepicker_input {
				padding-left: 12px;
			}
		}
		.ranges {
			text-align: right;
			float: right;
		}
		.calendar {
			text-align: right;
			float: right;
		}
	}
}
@media (min-width: 730px) {
	.daterangepicker {
		.ranges {
			width: auto;
		}
		.calendar.left {
			clear: none !important;
		}
	}
	.daterangepicker.ltr {
		.ranges {
			float: left;
		}
	}
	.daterangepicker.rtl {
		.ranges {
			float: right;
		}
	}
}
