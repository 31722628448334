@import "wf-mixins.less";
@import "wf-variables.less";

wf-manage-scope-dashboard, wf-easy-list {
	display: block;

	.initial-loading {
		.limited-list .gri-items {
			height: 400px;
		}
	}

	.button-selector {
		.btn {
			max-width: 250px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			position: relative;
			padding-right: 28px;
		}

		.fa.fa-caret-down {
			position: absolute;
			right: 16px;
			top: 11px;
		}
	}

	.button-source-filter {
		padding: 9px 12px;
		margin-right: 5px;

		.count {
			margin-left: 3px;
			display: inline-block;
			font-weight: 500;
			background: #809eba;
			border-radius: 12px;
			padding: 0;
			min-width: 16px;
			height: 16px;
			font-size: 11px;
			padding-top: 1px;
			color: #fff;
			text-shadow: 0 0 3px rgba(44, 62, 80, 0.25);
	}
	}

	wf-influence-header {
		display: flex;
		width: 100%;
		margin-top: 40px;
		// border: 1px dashed green;

		.wf-influence-header-wrapper {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			width: 100%;
			justify-content: space-between;
			align-items: center;

			.header-left-side {
				display: flex;
				flex-direction: column;
				flex-basis: 75%;

				.requested-from {
					order: 1;
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					justify-content: flex-start;
					align-items: center;

					margin-bottom: 10px;

					.org-image {
						margin-right: 5px;
						img {
							border-radius: 3px;
						}
					}

					.requested-from-text {
						display: flex;
						align-items: center;

						font-size: 14px;
						color: @wf_gray_dark;

						&.creator-org-name {
							font-weight: 500;
							color: @wf_black;
							margin-right: 5px;
						}
					}

				}

				.influence-info-wrapper {
					order: 2;
					display: flex;
					flex-direction: row;
					justify-content: flex-start;

					.influence-image {
						margin-right: 15px;
						img {
							border-radius: 3px;
						}
					}

					.influence-info {
						padding-top: 10px;
						display: flex;
						flex-direction: column;
						justify-content: center;
						white-space: pre-wrap;

						.influence-name {
							font-weight: 700;
							font-size: 38px;
							color: @wf_black_dark;
							line-height: 1;
							margin-bottom: 10px;
						}

						.influence-description {
							font-size: 14px;
							color: @wf_black_dark;
						}
					}
				}
			}

			.header-right-side {
				.panel {
					margin-bottom: 0;
					.panel-body {
						padding: 10px;

						.follow-up-information {
							display: flex;
							flex-direction: column;
							min-width: 170px;

							.button-wrapper, .infoLabelAndValue {
								display: flex;
								flex-direction: row;
								width: 100%;
								background-color: transparent;
								border-radius: 3px;
								padding: 8px 10px;
								position: relative;

								i {
									margin-right: 2px;
									min-width: 15px;
									text-align: center;
								}

								&:hover {
									cursor: pointer;
									background-color: @wf_gray_hover;
								}

								&:not(:first-child) {
									margin-top: 5px;
								}


								&.infoLabelAndValue {
									cursor: default !important;
									background-color: transparent !important;

									&.deadline:not(:last-child) {
										border-bottom: 1px solid #eee;
										padding-bottom: 14px;
										margin-bottom: 4px;
									}

									&.assignedToUser {
										i {
											//color: #4898DA;
										}
									}

									.infoLabel {

									}

									.infoValue {
										font-weight: 600;
									}
								}


								.guidance-button {
									color: @wf_green_dark;
								}

								.comment-button {

								}

								.attachments-button {

								}

								.count {
									position: absolute;
									top: 3px;
									right: 10px;
									bottom: 3px;

									background-color: @wf_blue;
									border-radius: 5px;
									min-width: 25px;
									min-height: 25px;
									display: flex;
									justify-content: center;
									align-items: center;

									.count-text {
										font-size: 14px;
										font-weight: 500;
										color: white;
									}
								}

								.user-icon {
									position: absolute;
									top: 3px;
									right: 10px;
									bottom: 0;

									.wf-user-icon(25, 25, white);
								}
							}
						}
					}
				}
			}

			.follow-up-info-popover {
				min-width: 300px;
				right: 0 !important;
				left: initial !important;
				.popover-content {
					.comment {
						display: flex;
						flex-direction: row;
						justify-content: flex-start;

						.influence-comment-user {
							margin-right: 10px;
							.user-icon {
								&:before {
									top: -10% !important;
									text-align: left;
								}
								.wf-user-icon(35, 35, white);
							}
						}

						.influence-comment-text {
							display: flex;
							align-items: center;
							white-space: pre-wrap;
						}

						span.comment-text {
							font-size: 12px;
							color: @wf_gray_text_color;
						}
					}

					.attachments {
						display: flex;
						flex-direction: column;
						justify-content: flex-start;

						a {
							display: flex;
							flex-direction: row;
							flex-wrap: nowrap;
							align-items: center;
							justify-content: space-between;
							background-color: transparent;
							text-decoration: none;
							border-radius: 3px;

							padding: 5px 10px;

							&:hover {
								cursor: pointer;
								background-color: @wf_gray_hover;
							}

							&:not(:last-child) {
								margin-bottom: 3px;
							}

							.file {
								display: flex;
								flex-direction: row;
								flex-wrap: nowrap;
								align-items: center;
								font-size: 12px;
								width: 85%;

								.file-icon {
									margin-right: 10px;
									font-size: 16px;
								}
							}

							.external-icon {
								font-size: 12px;
								color: @wf_blue;
							}
						}
					}
				}
			}
		}
	}

	.dasboard-views-data-source-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;
		margin-top: 35px;

		.dashboard-data-source {

		}

		.dashboard-top-left {
			flex-shrink: 0;
			align-self: flex-start;
			padding-top: 8px;
		}

		.dashboard-view-switcher {
		}

		.griBadge {
			float: right;
			margin-top: 14px;
			border-radius: 3px;
			cursor: pointer;
			transition: box-shadow 0.15s;

			&:hover {
				.griImage {
					//box-shadow: 0 0 0 1px rgba(36, 88, 167, 0.5) inset;
				}

				.griText {
					text-decoration: underline;
					color: #888;
				}
			}

			.griImage {
				background: url("/assets/img/GRI_Partner_Badge_2024.png") center center no-repeat;
				background-size: cover;
				width: 100px;
				height: 100px;
				//box-shadow: 0 0 0 1px rgba(36, 88, 167, 0.28) inset;
				border-radius: 3px;
				cursor: pointer;
				transition: 0.1s;
			}

			.griText {
				font-size: 11px;
				color: #aaa;
				text-align: right;
				padding-top: 4px;
			}
		}
	}

	.views-tabs {
		.view-item {
			display: block;
			max-width: 200px;
			margin-right: 40px;
			margin-top: 10px;
			padding: 5px 0;
			cursor: pointer;
			float: left;
			transition: border-color .1s, color .1s, opacity .1s;
			border-bottom: 3px solid transparent;
			color: @wf_gray_dark;
			font-weight: 500;
			font-size: 14px;
			text-decoration: none !important;

			&:hover {
				color: @wf_black_light;
				border-bottom-color: @wf_black_light;
			}

			&.selected {
				color: @wf_black;
				border-bottom-color: @wf_black;
			}

			// &:nth-last-child(2) {
			// 	margin-right: 40px + 25px;
			// }

			&.add {
				opacity: 0.7;
				border-bottom-color: transparent !important;
				margin-right: 25px;

				i {
					padding-right: 3px;
				}

				i, div {
					opacity: 0.8;
				}


				&:hover {
					opacity: 1;

					i, div {
						opacity: 0.9;
					}
				}
			}

			.view-image {
				width: 100%;
				height: 35px;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: contain;
				margin-bottom: 7px;
			}

			.view-title {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				display: block;
			}
		}
	}

	.loadingUserData {
		.top-filtering {
			pointer-events: none;
			cursor: default;
		}

		.column-headers {
			visibility: hidden;
		}

		.primary-items {
			.spinner(47px);

			&:before {
				top: 200px;
			}

			> * {
				visibility: hidden;
			}
		}
	}

	.top-filtering {
		margin: 35px -2px 45px;

		&.horizontal-scroll {
			position: relative;

			&:before {
				content: '';
				display: block;
				height: auto;
				width: 60px;
				position: absolute;
				top: 0;
				right: -3px;
				bottom: 20px;
				z-index: 10;

				background: -moz-linear-gradient(left, rgba(242,245,246,0) 0%, rgba(242,245,246,1) 95%);
				background: -webkit-linear-gradient(left, rgba(242,245,246,0) 0%, rgba(242,245,246,1) 95%);
				background: linear-gradient(left, rgba(242,245,246,0) 0%, rgba(242,245,246,1) 95%);

			}

			.filtering-boxes {
				padding-right: 55px;
				flex-wrap: nowrap;
				overflow: hidden;
				overflow-x: auto;
			}
		}

		.filtering-guidance {
			color: #444;
			font-size: 13px;
			padding-bottom: 6px;

			span + span {
				border-left: 1px solid rgba(0,0,0,0.15);
				padding-left: 10px;
				margin-left: 10px;
			}
		}

		.filtering-boxes {
			margin: 0 -2px;
			display: flex;
			flex-wrap: wrap;
		}

		.box {
			float: left;
			min-width: 16.666%;
			margin-bottom: 7px;

			> div {
				padding: 0 3px;
			}

			.level {
				.item-wrapper {
					cursor: pointer;
					float: left;
					min-width: 33.333%;

					.item {
						position: relative;
						height: 36px;
						border-radius: 2px;
						overflow: hidden;
						margin: 2px;
						// transform: scale(1);
						// transition: transform .1s, border-radius .1s;

						&.selected {
							// transform: scale(1.03);
							// border-radius: 4px;

							.bg {
								opacity: 1;
								box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.1) inset, 0 5px 0 0px rgba(255, 255, 255, 0.36) inset;
							}

							.content {
								// background: rgba(0,0,0,0.03);
								// font-weight: 500;
								text-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
								color: rgba(255, 255, 255, 1);
							}
						}

						&.unselected {
							box-shadow: none !important;
							.bg {
								// box-shadow: 0 0 0 50px rgba(214, 214, 214, 0.60) inset
								opacity: 0.5;
							}
						}

						&:hover { .content { color: #fff; } }

						.bg {
							position: absolute;
							top: 0;
							left: 0;
							bottom: 0;
							right: 0;
							background-color: rgb(103, 118, 134);
							box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.25) inset, 0 0 0 0 rgba(255, 255, 255, 0.05) inset;
							transition: box-shadow .2s, opacity .1s;

							&:hover {
								transition: box-shadow .2s, opacity .03s;
							}
						}

						.content {
							position: absolute;
							top: 0;
							left: 0;
							bottom: 0;
							right: 0;
							padding: 10px 5px 10px 8px;
							color: rgba(255, 255, 255, 0.9);
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
							pointer-events: none;
							transition: box-shadow .2s, background-color .2s, padding-top .2s;
							text-shadow: 0 0 8px rgba(0,0,0,0.1);

							&:hover {
								transition: box-shadow .2s, background-color .03s;
								color: rgba(255, 255, 255, 1);
							}

							.icon-wrapper {
								display: inline-block;
							}

							i.icon {
								padding-right: 0.4em;
							}

							i.icon + .text {
							}
						}
					}
				}

				&.level0 {
					.item {
						height: 63px;

						.content {
							font-size: 14px;
							font-weight: 500;
							padding: 16px 20px;

							.icon-wrapper {
								height: 20px;
								display: inline-block;
							}

							i.icon {
								font-size: 165%;
								position: relative;
								top: 2px;
								height: 20px;
							}

							i.icon + .text {
							}
						}
					}

					.bg {
						opacity: 0.93;
						&:hover {
							opacity: 1;
						}
					}
				}

				&.level1 {
					.bg {
						opacity: 0.7;
						&:hover {
							opacity: 0.93;
						}
					}
				}

				&.level2 {
					.bg {
						opacity: 0.55;
						&:hover {
							opacity: 0.7;
						}
					}
				}

				&.level3 {
					.bg {
						opacity: 0.4;
						&:hover {
							opacity: 0.55;
						}
					}
				}

				&.level4 {
					.bg {
						opacity: 0.25;
						&:hover {
							opacity: 0.4;
						}
					}
				}
			}
		}
	}

	.filtering-and-ui-switcher {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 30px;

		.fulfillment-state-filtering {
			display: flex;
			justify-content: flex-start;
			flex-shrink: 10;

			.list-group-item.filter {
				padding: 0px;
				border-top-color: #E8E8E8;

				&.fulfillmentFilterButtons {
					.btn-inverse .fa.fa-repeat {
						color: #afbbc7 !important;
					}
				}

				.list-group-item {
					padding: 12px 0;

					.date-range-picker-wrapper {
						display: flex;
						flex-direction: row;

						.filter-date-range-picker {
							width: 100%;
							&:not(:last-child) {
								margin-right: 10px;
							}

							.filter-option-label {
								font-size: 11px;
								padding-bottom: 2px;
							}
						}
					}
				}

				.levelFunnel {
					border-top: 1px solid #eaeaea;

					&:first-child {
						border-top: 0;
					}

					> div {
						padding: 12px 0;
					}
				}

				.filter-icon {
					// width: 25px;
					width: 60px;
					padding-top: 10px;
					// font-size: 20px;
					color: #aaa;
					padding-bottom: 5px;
					text-align: center;
					margin-right: 18px;

					i {
						margin-top: 6px;
						font-size: 14px;
					}

					span {
						display: block;
						padding-top: 6px;
					}
				}

				.bgImage {
					border-radius: 50%;
					margin-left: -1px;
					margin-right: 4px;
					padding: 0 !important;
					margin: 5px -5px 0px 7px;
				}


				.btn-group {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;

					.filter-button-wrapper {
						display: flex;
						flex-direction: row;
						div.filter-button {
							padding: 3px !important;
							max-width: none;
							display: flex;
							align-items: center;

							&.disabled {
								pointer-events: auto;
								cursor: pointer;
								opacity: 0.25;

								&:not(.btn-inverse) {
									.text {
										color: #000;
									}
								}
							}

							&.disabled-no-pointer-events {
								pointer-events: none;
								cursor: pointer;
								opacity: 0.25;
							}

							.filter-option-icon {
								display: block;
								float: left;
								margin-left: 8px;
								line-height: 16px;
								font-size: 12px;
								order: 1;
							}

							div {
								padding: 5px 10px;
								line-height: 16px;
								max-width: 200px;

								&.text {
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;
									font-size: 12px;
									order: 2;

								}

								&.count {
									padding: 4px 7px;
									position: relative;
									background-color: rgba(0,0,0,0.03);
									color: #888;
									margin: 1px;
									margin-left: -3px;
									font-weight: 400;
									font-size: 12px;
									border-radius: 2px;
									order: 3;

									&.count-intersected {
										color: transparent;

										&:before {
											content: attr(data-count-intersected);
											display: block;
											position: absolute;
											padding: 4px 7px;
											z-index: 1;
											color: #888;
											top: 0;
											left: 0;
											right: 0;
											border: 0;
											text-align: center;
										}
									}
								}
							}

							&.btn-inverse {
								&.disabled {
									opacity: 1;

									.text {
										opacity: 0.5;
									}

									.wf-image {
										opacity: 0.6;
									}
								}

								.count {
									color: #dde3ea;
									background-color: rgba(255,255,255,0.17);

									&.count-intersected {
										&:before {
											color: #dde3ea;
										}
									}
								}

								.bgImage {
									box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.14) inset;
								}
							}
						}
					}
				}
			}
		}

		.ui-mode-switcher {
			display: flex;
			justify-content: flex-end;
			align-items: center;

			background-color: @wf_gray_light;
			padding: 15px;
			margin-left: 30px;
			border-radius: 5px;
			flex-shrink: 0;
			align-self: flex-start;

			.switch-label {
				margin-right: 15px;
				font-size: 14px;
				font-weight: 500;
			}

			.ui-mode-switch-toggle {
				input { display: none; }
				padding: 0 5px;
				.wf-switch-toggle(45, 25, @wf_green, @wf_gray);
			}

			.on-off-label {
				font-size: 14px;
				font-weight: 500;
				text-transform: uppercase;
				min-width: 26px;
				margin-left: 15px;
			}
		}
	}

	.search-bar {
		margin: 0 0 30px;
	}

	.limited-list {
		.panel {

			.panel-body {
				padding: 30px;
			}
		}
	}

	.loader-fancy {
		background: url(/assets/img/loaders/materiality_analysis_fancy.gif) no-repeat center center;
		height: 300px;

		> * {
			visibility: hidden;
		}
	}

	.column-headers {
		margin-top: 30px;
		padding-bottom: 20px;

		.learn-more-header {
			text-align: left;
			margin: 0 30px 0 -30px;
		}

		.column-header {
			cursor: default;
			color: #333;
			font-weight: 500;

			span {
				display: inline-block;
			}

			&:hover {
				cursor: pointer;
				.fa-info {
					color: #a4abb3;
					box-shadow: 0 0 0 1px #a4abb3 inset;
				}
			}
		}
	}

	.chunks-loading {
		wf-filtering {
			height: 0;
			visibility: hidden;
		}
	}

	wf-filtering {
		display: block;
		visibility: visible;
		overflow: hidden;
		// transition: height .5s ease-out;

		.gri-foundation { .griColors(#002C5C); }
		.gri-general_disclosures { .griColors(#0B406B); }
		.gri-management_approach { .griColors(#02528A); }
		.gri-economic { .griColors(#732A82); }
		.gri-environmental { .griColors(#06966F); }
		.gri-social { .griColors(#F36B21); }

		// .btn-group > {
		// 	.btn {
		// 		height: 30px;
		// 		font-size: 11px;
		// 		margin-right: 6px !important;

		// 		.text {
		// 			height: 30px !important;
		// 			line-height: 21px !important;
		// 			padding: 5px 13px !important;
		// 		}

		// 		.count {
		// 			height: 30px !important;
		// 			line-height: 21px !important;
		// 			font-size: 11px !important;
		// 			font-weight: bold !important;
		// 			padding: 4px 9px !important;

		// 			&:after {
		// 				content: ' areas';
		// 				font-weight: 400;
		// 			}
		// 		}
		// 	}
		// }

		.griColors(@color) {

			&, &:focus, &:hover {
				box-shadow: inset 0 0 0 1px fade(@color, 70);
				background-color: #fff !important;
			}

			.count {
				background-color: fade(@color, 10) !important;
				color: @color !important;
			}


			&.btn-inverse {
				background-color: @color !important;

				.count {
					color: #fff !important;
					background-color: rgba(255, 255, 255, 0.17) !important;
				}
			}
		}
	}

	.primary-items {
		// position: relative;
		// transition: height .5s ease-out;
		// overflow: hidden;

		&.isListLimited {
			.fadeBottom(100px, 20px);
		}

		&.showItemLines {
			.primary-item:not(:first-child) {
				> div {
					border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
					padding-top: 14px;
				}
			}
		}

		&.chunks-loading {
			&.linear {
				.progress > .progress-bar:after {
					animation: progress-gradient-move .9s linear infinite;
				}
			}
			.progress > .progress-bar {
				&:after {
					content: "";
					display: block;
					position: absolute;
					width: 100%;
					height: 100%;

					/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+87,0+100 */
					background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.35) 82%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
					background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.35) 82%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.35) 82%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */

					animation: progress-gradient-move .9s ease-in-out infinite;
				}
			}
		}

		> .row {
			display: flex;
			flex-wrap: wrap;
		}

		.primary-item {
			// border-bottom: 1px solid rgba(0,0,0,0.05);
			// height: 73px;
			overflow: visible;
			// position: absolute;
			// top: 600px;
			// left: 0px;
			// width: 100%;
			// transition: opacity .7s;

			// z-index: 10;

			&.grid-item {
				padding-bottom: 20px;

				.grid-style, .grid-style > div, panel, .panel, .panel-body {
					height: 100%;
					margin: 0;
				}
			}

			&.grid-item + .list-item,

			&.list-item.level0 + .list-item.level0,
			&.list-item.level1 + .list-item.level1,
			&.list-item.level2 + .list-item.level2,
			&.list-item.level3 + .list-item.level3,
			&.list-item.level4 + .list-item.level4,

			&.level1 + .level0,
			&.level2 + .level0,
			&.level3 + .level0,
			&.level4 + .level0,

			&.level2 + .level1,
			&.level3 + .level1,
			&.level4 + .level1,

			&.level3 + .level2,
			&.level4 + .level2,

			&.level4 + .level3,
			{
				clear: both;

				&:not(.isViewMode) {
					padding-top: 30px;
				}
			}

			.item-numbering {
				margin-right: 5px;
			}

			// &.level0, &.level1, &.level3 {
				.list-style {
					border-top: 0 !important;
					.sustainability-area {
						.wf-image, .gri-standard-cover {
							margin-top: 5px;
						}
						.sustainability-area-headers {
							.item-title, .item-description, .item-text {
								color: @wf_black_dark;
							}

							.item-description, .item-text {
								white-space: pre-wrap;
							}

							.item-title + .item-description {
								padding-top: 4px;
								font-size: 15px;
								font-weight: 500;
								color: @wf_gray_text_color;
							}

							.item-description + .item-text {
								font-size: 15px;
								font-weight: 500;
								color: @wf_gray_text_color;
							}

							.item-title + .item-text {
								padding-top: 4px;
								font-size: 15px;
								font-weight: 500;
								color: @wf_gray_text_color;
							}

							.guidance-icon {
								margin-left: 10px;
								position: relative;
								display: inline-block;
								top: -3px;
								i {
									color: @wf_green;
									font-size: 14px;
									cursor: pointer;
								}
							}
						}

						.wf-work-item-attachments {
							.wf-work-item-attachments(@wf_blue_dark);
						}
					}
				}
			// }

			&.level0 {
				.list-style {
					// padding: 50px 0 30px 0 !important;

					.sustainability-area {
						.sustainability-area-headers {
							.item-numbering {
								.wf-item-numbering(17, 500);
								height: 27px;
								top: -3px;
							}

							.item-title {
								font-size: 26px;
								font-weight: 500;
							}
						}
					}
				}
			}

			&.level1 {
				.list-style {
					// padding: 40px 0 30px 0 !important;

					.sustainability-area {
						.sustainability-area-headers {
							.item-numbering {
								.wf-item-numbering(16, 500);
								height: 26px;
								top: -3px;
							}

							.item-title {
								font-size: 24px;
								font-weight: 500;
							}
						}
					}
				}
			}

			&.level2 {
				.list-style {
					// padding: 30px 0 30px 0 !important;

					.sustainability-area {
						.sustainability-area-headers {
							.item-numbering {
								.wf-item-numbering(15, 500);
								height: 25px;
								top: -3px;
							}

							.item-title {
								font-size: 22px;
								font-weight: 400;
							}
						}
					}
				}
			}

			&.level3 {
				.list-style {
					// padding: 30px 0 30px 0 !important;

					.sustainability-area {
						.sustainability-area-headers {
							.item-numbering {
								.wf-item-numbering(14, 500);
								height: 24px;
								top: -3px;
							}

							.item-title {
								font-size: 21px;
								font-weight: 400;
							}
						}
					}
				}
			}

			&.isViewMode {
				.content-workMode {
					display: none;
				}

				.toolbar-absolut-pos {
					position: absolute;
					right: 27px;
					z-index: 1;

					.title {
						position: absolute;
						left: -509px;
						padding-right: 60px;
						white-space: pre-wrap;
						z-index: 2;
					}
				}

				.icon-button {
					float: right;
					text-align: center;
					width: 20px;
					height: 20px;

					&.download-chart-button {
						margin-left: 12px;
						width: 20px;
						height: 20px;
					}

					i.fa {
						font-size: 12px;
						font-weight: 600;
						text-decoration: none !important;
						margin-left: 0;
						font-family: 'Font Awesome 5 Free';
						font-weight: 900;
						-webkit-font-smoothing: antialiased;
						display: inline-block;
						font-style: normal;
						font-variant: normal;
						text-rendering: auto;
						line-height: 1;
					}

					&[tooltip-title] {
						position: relative;
						z-index: 2;
						cursor: pointer;

						&:before,
						&:after {
							visibility: hidden;
							opacity: 0;
							pointer-events: none;
						}

						&:before {
							content: attr(tooltip-title);
							position: absolute;
							text-align: center;
							font-size: 11px;
							color: #fff;
							right: 0px;
							bottom: 150%;
							margin-bottom: -2px;
							padding: 5px 8px;
							border-radius: 2px;
							border-radius: 3px;
							background-color: rgba(24, 42, 60, 0.98);
							white-space: nowrap;
						}

						/* Triangle hack to make tooltip look like a speech bubble */
						&:after {
							position: absolute;
							bottom: 121%;
							left: 50%;
							margin-left: -5px;
							width: 0;
							border-top: 5px solid rgba(24, 42, 60, 0.98);
							border-right: 5px solid transparent;
							border-left: 5px solid transparent;
							content: " ";
							font-size: 0;
							line-height: 0;
						}

						&:hover:before,
						&:hover:after {
							visibility: visible;
							opacity: 1;
							animation: fadein .2s ease-in;
						}
					}//end of: &[data-original-title] - Tooltip
				}
			}

			&.isWorkMode {
				.content-viewMode {
					display: none;
				}

				.work-item-panel-class {
					.panel-body {
						padding: 0 !important;
						background-color: #F6F6F6;
						min-height: 150px;
					}
				}
			}

			&.list-item {
				> .container-fluid {
					// padding-top: 19px;
					padding-bottom: 15px;
					border-top: 1px solid rgba(0,0,0,0.1);
				}
			}

			&.ng-leave {
				opacity: 1;
				transition: opacity 0;


				&.ng-leave-active {
					transition: opacity 0;
					opacity: 0;
				}
			}

			&.ng-enter {
				opacity: 0;
				transition: opacity .5s;

				&.ng-enter-active {
					transition: opacity .5s;
					opacity: 1;
				}
			}

			.sustainability-area {
				.wf-image {
					width: 43px;
					height: 43px;
					overflow: hidden;
					position: relative;
					box-shadow: 0 0 0 1px rgba(0,0,0,0.02) inset;
					border-radius: 2px;
					margin-right: 15px;
				}

				.gri-standard-cover {
					width: 43px;
					height: 43px;
					overflow: hidden;
					position: relative;
					box-shadow: 0 0 0 1px rgba(0,0,0,0.1) inset;
					border-radius: 2px;
					margin-right: 15px;
					background-color: #fff;

					&:after {
						content: attr(data-gri-title);
						display: block;
						position: absolute;
						height: 100%;
						top: 3px;
						left: 3px;
						right: 0;
						font-size: 9px;
						line-height: 1em;
						color: #999;
					}


					span {
						content: "";
						display: block;
						position: absolute;
						border-top-left-radius: 100%;
						border-top-right-radius: 100%;
						width: 130%;
						height: 50%;
						bottom: 0;
						transform: translate(-11%, 0);
						text-align: center;
						font-size: 10px;
						padding-top: 5px;
						z-index: 1;

						&:before {
							content: attr(data-gri-code);
							display: block;
							color: #fff;
						}
					}
				}

				.item-title.gri {
					font-size: 11px;
					color: @wf_gray;
				}

				.item-description.gri {
					font-weight: 600;
					font-size: 14px;
					color: #444;
				}
			}//end of: .sustainability-area

			.match {
				padding: 10px;
				.match-value {
					font-size: 14px;
					min-width: 65px;
					text-align: right;
				}

				.progress {
					margin-bottom: 0;
					margin-top: 3px;
					height: 12px;

					.progress-bar {
						// transition: width 0.5s ease;
						position: relative;
						overflow: hidden;
					}
				}
			}//end of: .match

			.related-goals {
				.sdg-thumbnails {
					padding: 5px 20px 5px 5px;
					white-space: nowrap;

					.sdg-thumbnail {
						&:not(:first-child) {
							box-shadow: 0 0 0 3px @wf_gray_light, -4px 0 3px rgba(0, 0, 0, 0.2);
						}

						box-shadow: 0 0 0 3px @wf_gray_light;
						margin-right: 7px;
						margin-bottom: 7px;
						display: inline-block;
						margin-right: -6px;
						border-radius: 3px;
						overflow: hidden;
						cursor: pointer;
						transition: transform .08s, box-shadow .08s;

						&.popover-opened {
							transform: scale(1.2);
							box-shadow: 0 0 0 2px @wf_gray_light;
						}
					}
				}
			}//end of: .related-goals

			.learn-more {
				padding: 5px;
				.infoIcon {
					color: #ccc;
					cursor: pointer;
					margin: 5px 5px;
					font-size: 16px;

					&:hover {
						color: #bbb;
						transform: scale(1.3);
					}
				}
			}//end of: .learn-more

			.actions {
				.toggle {
					text-align: center;

					.btn {
						transition: background-color .3s;

						&:hover {
							transition: background-color .1s;
						}

						i.icon {
							padding-right: 3px;
							display: none;
						}

						&.toggled {
							background-color: @wf_red;

							&:hover {
								background-color: darken(@wf_red, 10%);
							}

							&.loading {
								background-color: @wf_red !important;
							}


							i.icon {
								display: inline-block;
							}
						}
					}
				}
			}

			.list-style {

			}

			.grid-style {
				.attached-info-panel {

				}

				.question-panel {
					wf-item-statistics.summary-statistics {
						margin-bottom: 20px;
						padding-bottom: 10px;
						border-bottom: 1px solid @wf_gray_lighter;
						justify-content: center;
					}

					&.single {
						height: 270px;

						react-question-box, react-attached-info-percentages {
							display: flex;
							height: 270px;
							flex-direction: column;
						}
					}
				}


			}

			wf-item-statistics {
				&.summary-statistics {
					.totals {
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						flex-wrap: wrap;

						.total-item {
							width: initial !important;
							min-width: initial !important;
							display: flex;
							flex-direction: column;
							padding: 10px 20px;
							flex-basis: 100px;
						}
					}
				}

				.totals {
					display: block;

					.total-item {

					}
				}
			}
		}//end of: .primary-item

		.grid-item {
			&.opened {
				.panel {
					position: fixed;
					z-index: 100000;
					top: 179px;
					width: 830px;
					height: 700px;
					left: 50%;
					margin-left: -415px;
					box-shadow: 0 4px 20px rgba(0, 0, 0, 0.17), 0 4px 0 10000px rgba(0, 0, 0, 0.17);
				}
			}

			wf-chart-new {
				max-height: 226px;
			}

			.panel {
				// position: static;
				// z-index: 100000;
				// top: 179px;
				// width: auto;
				// height: auto;
				// left: 0;
				// margin-left: 0px;
				// transition: top 1s, left 1s, width 1s, height 2s, margin-left 1s, z-index 1s, box-shadow 1s;
			}

			.btn.container-add {
				border-radius: 100%;
				margin-left: 12px;
				position: relative;
				top: -8px;
				right: -6px;
			}

			.btn.container-open {
				border-radius: 100%;
				margin-left: 8px;
				position: relative;
				top: -8px;
				right: -6px;
				display: block;
			}

			.measure-unit {
				padding-left: 30px;
				color: @wf_gray;
			}

			.measure-chart {
				margin-top: 15px;
				margin-bottom: -15px;
			}

			.item-numbering {
				.wf-item-numbering(14, 500);
				height: 23px;
				top: -2px;
				color: @wf_gray_text_color;
				background-color: @wf_gray_light;
			}

			&.isLoaderWorkMode {
				.item-mainTextual-wrapper {
					padding-top: 2px;
					// margin
				}

				.item-mainTextual {
					position: relative;
					top: -1px;
					font-size: 14px;
					font-weight: 400;
					max-width: 740px;
					color: @wf_gray_text_color;
				}
			}
		}

		.panel-body {
			min-height: 326px;
			padding: 27px;
		}

	}//end of: .primary-items

	.toggle-items {
		background-color: #F5F7F8;
		font-weight: bold;
		text-align: center;
		padding: 20px 0;
		cursor: pointer;
		margin: 0 -30px -30px;
		border-radius: 3px;
		text-transform: uppercase;
		color: #9a9a9a;
	}

	.sdg-goals-aggregated {
		.sdg-image {
			max-height: 105px;
			width: 110px;
			margin-right: 25px;
		}

		.sdg-info {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;

			.sdg-logo {
				order: 1;
			}

			.sdg-text {
				order: 2;
				width: 100%;

				//IE 10 support-----
				flex-shrink: 10;
				//------------------

				.sdg-header {
					font-size: 16px;
					padding: 0;
					margin: 0;

					strong.sdg {
						color: #0996da;
					}
				}

				.sdg-description {
					color: @wf_gray_dark;
				}
			}
		}

		.panel-body {
			padding: 30px;
		}

		.goal-items {
			margin-left: -10px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.goal-item {
				cursor: pointer;
				padding: 0 10px;
				position: relative;
				display: inline-block;
				width: 20%;

				&:before {
					content: attr(data-count);
					display: block;
					position: absolute;
					bottom: -12px;
					right: -1px;
					background: #213c50;
					font-size: 13px;
					font-weight: bold;
					border-radius: 100%;
					height: 27px;
					width: 27px;
					color: #fff;
					text-align: center;
					line-height: 27px;
					box-shadow: 0 0 0 4px white;
					z-index: 1;
				}

				img {
					width: 100%;
					border-radius: 3px;
					// transition: transform .15s;

					&.popover-opened {
						transform: scale(1.15);
						box-shadow: 0 0 0 2px #fff;
						// transition: transform .05s;
					}
				}
			}
		}
	}

	.lp-loader-bar {
		position: fixed;
		bottom: 0px;
		left: 0;
		right: 0;
		width: 100%;
		background-color: @wf_blue;
		padding: 15px;
		z-index: 1000;
		// transition: background-color 1s;

		.bar-container {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			max-width: 1300px;
			margin: 0 auto;

			.icon-text {
				order: 1;
				flex-grow: 1;
				flex-shrink: 15;

				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-items: center;

				.loader-icon {
					display: inline-block;
					text-align: center;
					font-size: 20px;
					margin-right: 15px;
					color: white;
					max-height: 50px;

					&:before {
						border: 2.5px solid rgba(255, 255, 255, 0.3);
						border-top-color: rgba(255, 255, 255, 1);
					}
				}

				.text {
					display: inline-block;
					font-size: 14px;
					font-weight: 500;
					color: white;
					padding: 0 5px;
					margin-right: 15px;
				}
			}//end of

			.org-profile {
				order: 2;
				flex-grow: 30;
				flex-shrink: 10;
				margin-right: 15px;

				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;

				.industries {
					order: 1;
					flex-grow: 10;
					flex-shrink: 5;

					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
				}

				&.single-industry {
					.industry {
						box-shadow: none;
						margin-right: 10px;
						pointer-events: none;
						background-color: #2980b9;
						margin-bottom: 0 !important;
						white-space: unset;
						max-width: initial !important;

						.wf-image {
							width: 18px;
							height: 18px;
						}

						.industry-text {
							font-weight: 500;
							font-size: 12px;
						}
					}
				}

				&.multiple-industries {
					.industry {
						flex-grow: initial !important;
						box-shadow: none;
						margin-right: 10px;
						background-color: #2980b9;
						padding: 0;
						margin-right: 10px;
						margin-bottom: 0px;

						.wf-image {
							width: 33px;
							height: 33px;
							margin-right: 0 !important;
						}

						.industry-text { display: none; }

						span { font-weight: 500; }

						&:hover {
							cursor: pointer;
						}
					}
				}
			}//end of: .org-profile

			.bar-buttons {
				order: 3;
				flex-grow: 1;
				flex-shrink: 2;

				display: flex;
				flex-direction: row;
				align-items: center;

				.bar-link-button {
					order: 2;
					flex: none;
					min-width: 90px;
					padding: 5px;
					text-align: center;

					margin-left: 0;

					font-weight: 400;
					font-size: 14px;
					color: white;
					cursor: pointer;

					span:hover { text-decoration: underline; }

					i {
						margin-right: 5px;
						font-size: 14px;
						position: relative;
					}
				}
			}
		}

		&.loaded
		{
			background-color: @wf_green !important;

			.industry {
				background-color: #3eaf65 !important;
			}
		}
	}//end of: .lp-loader-bar

	.fa-info {
		color: #c7ced6;
		margin-left: 5px;
		font-size: 11px;
		width: 16px;
		height: 16px;
		line-height: 17px;
		border-radius: 100%;
		box-shadow: 0 0 0 1px #c7ced6 inset;
		text-align: center;
	}
}//end of: wf-manage-scope-dashboard


.wf-management-scope-dashboard-filtering {
	.popoverBoxShadow();

	width: auto;
	max-width: 700px;
	.popover-content {
		padding: 25px;

		.filter-options {
			position: relative;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}
	}

}


div.panel.capturing-visualization {
	overflow: hidden;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: white;
		width: 100%;
		height: 100%;
		z-index: 100;
		border-radius: 10px;
	}

	&:after {
		content: attr(data-capturing-title);
		position: absolute;
		top: 47%;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 101;
		border-radius: 10px;
		color: #647785;
		text-align: center;
		font-weight: bold;
		font-size: 12px;
	}
}

.wf-relative-measure-clear-cache-popover {
	.popoverBoxShadow();

	.onlyCurrentDashboardWrapper {
		.infoIcon {
			transform: scale(1.3);
			padding-right: 3px;
			color: @wf_green;
		}

		.dashboardTitle {
			font-size: 13px;
			display: inline-block;
			margin-top: 1px;
			margin-left: 17px;
		}
	}
}
