div.TransferController {
	.page-header {
		button.delete-button {
			float: right;
			margin-top: 5px;
		}
	}//end of: .page-header

	.header {
		margin-bottom: 15px;

		.icon {
			font-size: 20px;
			color: #616161;
		}

		.title { display: inline; }

		.description {
			display: inline-block;
			margin-top: 15px;
			span {
				color: @wf_gray;
				display: block;
			}

			.warning {
				color: @wf_yellow_dark;
			}
		}
	}//end of: .header

	.footer {
		padding: 20px 0;

		.notes {
			width: 400px;
			font-size: 11px;
			color: @wf_yellow_dark;

			&.warning {
				width: 100%;
				color: @wf_red;
				font-size: 12px;
				font-weight: 500;
				text-transform: uppercase;
			}
		}

		.import-status {
			padding: 5px;
			margin-top: 10px;
			span {
				font-weight: 500;
				font-size: 13px;
				text-align: right;
			}

			.import-succees {
				span { color: @wf_green; }
			}

			.import-fail {
				span { color: @wf_red; }
			}
		}
	}//end of: .footer

	.import-data {
		.body {
			.wf-file-preview {
				border-bottom: solid 1px @wf_gray_light;
				margin-bottom: 20px;

				.file-info {
					display: block;
					margin-bottom: 20px;
					.file-label {
						float: left;
						font-weight: 600;
						font-size: 12px;
						width: 105px;
						text-transform: capitalize;
						margin-right: 15px;
					}

					.info {
						display: inline-block;
						overflow: hidden;
						clear: none;
						float: none;
						width: auto;
						color: #95a5a6;
						max-width: 280px;
					}

					.action {
						float: right;
					}
					
					wf-item-component {
						[wf-item-bodytext] { padding: 0 }
						.wf-headerText { padding: 0 }
						.headers { margin-bottom: 0 }
					}
				}//end of: .file-info
			}//end of: .wf-file-preview
		}//end of: .body
	}// end of: .import-data

	.information-repository {
		padding: 20px;
		border: 1px solid #ebebeb;
		border-radius: 5px;

		table {
			border-collapse: collapse;
			width: 100%;

			tr {
				td, th { cursor: default; }
				th { color: #bdc3c7; font-weight: 400 }
				.object-type { text-align: left; i { margin-right: 5px;} }
				.item-count { text-align: right; &.loader-small { &::before { left: 90%; } } }
				.relation-count { text-align: right; &.loader-small { &::before { left: 90%; } } }
				.status { text-align: right; width: 125px; }
				.actions { text-align: right; }
				.checkbox { text-align: center; label { margin-right: 0; } }
			}

			tr.table-body {
				&.disabled {
					td {
						&:not(.checkbox) {
							opacity: 0.5;
						}
						pointer-events: none;
						span { color: @wf_gray_dark; cursor: default !important; text-decoration: none !important; }
					}
				}

				span {
					text-transform: capitalize;
					font-size: 13px;
					color: @wf_gray_dark;
				}

				.object-type, .status, .relation-count {
					span { color: #707980; font-size: 13px; }
				}

				.item-count, .relation-count {
					span { margin-right: 5px; }
					span, .checkbox {
						display: inline-block;
					}
				}

				.status { opacity: 1 !important; }

				.actions {
					span {
						color: @wf_blue;
						&:hover {
							text-decoration: underline;
							cursor: pointer;
						}
					}
				}
			}
		}
	}//end of: .information-repository

	.relations {
		padding: 20px;
		border: 1px solid #ebebeb;
		border-radius: 5px;
		margin-top: 20px;

		.title {
			margin-bottom: 0 !important;
		}

		span.include-text-checkbox {
			display: inline;
			font-size: 13px;
			font-weight: 500;
			color: #95a5a6;
			margin-right: 10px;
		}

		.main-checkbox {
			display: inline;
		}

		table {
			border-collapse: collapse;
			width: 100%;

			tr {
				td, th { cursor: default; padding: 0 !important; }
				th { color: #bdc3c7; font-weight: 400 }
				.object-type { text-align: left; i { margin-right: 5px;} }
				.count { text-align: center; }
				.actions { text-align: right; }
				.checkbox { text-align: right; label { margin-right: 0; } }
			}

			tr.table-body {
				&.disabled {
					td {
						&:not(.checkbox) {
							opacity: 0.5;
						}
						pointer-events: none;
						span { color: @wf_gray_dark; cursor: default !important; text-decoration: none !important; }
					}
				}

				span {
					text-transform: capitalize;
					font-size: 13px;
					color: @wf_gray_dark;
				}

				.object-type {
					span { color: #707980; font-size: 13px; }
				}

				.actions {
					span {
						color: @wf_blue;
						&:hover {
							text-decoration: underline;
							cursor: pointer;
						}
					}
				}
			}
		}
	}//enf of: .relations

	.body {
		.title {
			display: inline-block;
			color: @wf_gray_dark;
			font-weight: 500;
			margin-top: 0px;
			margin-bottom: 25px;
		}
	}//end of: .body

	.checkbox {
		margin: 0;
		padding: 10px 0;

		&.main-checkbox {
			padding: 0;
			margin-top: 2px;
			span {
				font-size: 13px;
				font-weight: 500;
				color: #95a5a6;
				margin-right: 10px;
			}
		}
		
		input {
			max-height: 0;
			max-width: 0;
			opacity: 0;
		}
	
		label {
			margin-bottom: -4px;
			cursor: default;
		}
		
		input + label {
			display: inline-block;
			position: relative;
			box-shadow: inset 0 0 0px 1px rgba(190, 190, 190, 0.25);
			text-indent: -5000px;
			height: 18px;
			width: 30px;
			border-radius: 15px;
		}
		
		input + label:before {
			content: "";
			position: absolute;
			display: block;
			height: 18px;
			width: 18px;
			top: 0;
			left: 0;
			border-radius: 15px;
			background: rgba(19, 191, 17, 0);
			transition: .25s ease-in-out;
			cursor: pointer;
		}
		
		input + label:after {
			content: "";
			position: absolute;
			display: block;
			height: 18px;
			width: 18px;
			top: 0;
			left: 0px;
			border-radius: 15px;
			background: white;
			box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 2px 4px rgba(0, 0, 0, 0.2);
			transition: .25s ease-in-out;
			cursor: pointer;
		}
		
		input:checked + label:before {
			width: 30px;
			background: @wf_green;
		}
		
		input:checked + label:after {
			left: 13px;
			box-shadow: inset 0 0 0 1px white, 0 2px 4px rgba(0, 0, 0, 0.2);
		}
	}//end of: .checkbox

	&.delete-content-modal {
		.header {
			.icon {
				color: @wf_red;
			}

			.bootbox-close-button {
				font-size: 25px;
			}
		}

		.information-repository {
			.checkbox.main-checkbox {
				input:checked + label:before {
					background: @wf_red;
				}
			}
			.table-body {
				td.checkbox {
					text-align: right;
					input:checked + label:before {
						background: @wf_red;
					}
				}
			}
			.item-count, .item-relation, .status { text-align: center; &.loader-small { &::before { left: 50%; } } }
			.table-header {
				.checkbox { text-align: right; }
			}
		}

		.verification-and-button {
			padding-top: 30px;
			.organization-verification {
				input {
					border: none;
					border-bottom: 1px solid @wf_gray;
					height: 35px;
					font-size: 20px;
				}	
	
				label {
					display: block;
					color: @wf_gray;
					font-weight: 300;
					margin-top: 5px;
				}
			}

			div.buttons {
				margin-top: 17px;
			}
		}
	}//end of: .delete-content-modal

	button { i { margin-right: 5px; } }
}