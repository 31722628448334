/* line 4, ../sass/_offline-theme-base.sass */
.offline-ui, .offline-ui *, .offline-ui:before, .offline-ui:after, .offline-ui *:before, .offline-ui *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* line 7, ../sass/_offline-theme-base.sass */
.offline-ui {
  display: none;
  position: fixed;
  background: white;
  z-index: 1025;
  margin: auto;
  top: 50px;
  left: 0;
  right: 0;

}

.modal-open .offline-ui{
	z-index: 1500;
}
/* line 17, ../sass/_offline-theme-base.sass */
.offline-ui .offline-ui-content:before {
  display: inline;
}
/* line 20, ../sass/_offline-theme-base.sass */
.offline-ui .offline-ui-retry {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  display: none;
}
/* line 24, ../sass/_offline-theme-base.sass */
.offline-ui .offline-ui-retry:before {
  display: inline;
}
/* line 29, ../sass/_offline-theme-base.sass */
.offline-ui.offline-ui-up.offline-ui-up-5s {
  display: block;
}
/* line 32, ../sass/_offline-theme-base.sass */
.offline-ui.offline-ui-down {
  display: block;
}
/* line 37, ../sass/_offline-theme-base.sass */
.offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-retry {
  display: block;
}
/* line 42, ../sass/_offline-theme-base.sass */
.offline-ui.offline-ui-down.offline-ui-reconnect-failed-2s.offline-ui-waiting .offline-ui-retry {
  display: none;
}

@-webkit-keyframes offline-fadein {
  /* line 6, ../sass/_keyframes.sass */
  0% {
    opacity: 0;
  }

  /* line 8, ../sass/_keyframes.sass */
  100% {
    opacity: 1;
  }
}

@-moz-keyframes offline-fadein {
  /* line 6, ../sass/_keyframes.sass */
  0% {
    opacity: 0;
  }

  /* line 8, ../sass/_keyframes.sass */
  100% {
    opacity: 1;
  }
}

@-ms-keyframes offline-fadein {
  /* line 6, ../sass/_keyframes.sass */
  0% {
    opacity: 0;
  }

  /* line 8, ../sass/_keyframes.sass */
  100% {
    opacity: 1;
  }
}

@-o-keyframes offline-fadein {
  /* line 6, ../sass/_keyframes.sass */
  0% {
    opacity: 0;
  }

  /* line 8, ../sass/_keyframes.sass */
  100% {
    opacity: 1;
  }
}

@keyframes offline-fadein {
  /* line 6, ../sass/_keyframes.sass */
  0% {
    opacity: 0;
  }

  /* line 8, ../sass/_keyframes.sass */
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes offline-fadeout-and-hide {
  /* line 20, ../sass/_keyframes.sass */
  0% {
    opacity: 1;
    display: block;
  }

  /* line 23, ../sass/_keyframes.sass */
  99% {
    opacity: 0;
    display: block;
  }

  /* line 26, ../sass/_keyframes.sass */
  100% {
    opacity: 0;
    display: none;
  }
}

@-moz-keyframes offline-fadeout-and-hide {
  /* line 20, ../sass/_keyframes.sass */
  0% {
    opacity: 1;
    display: block;
  }

  /* line 23, ../sass/_keyframes.sass */
  99% {
    opacity: 0;
    display: block;
  }

  /* line 26, ../sass/_keyframes.sass */
  100% {
    opacity: 0;
    display: none;
  }
}

@-ms-keyframes offline-fadeout-and-hide {
  /* line 20, ../sass/_keyframes.sass */
  0% {
    opacity: 1;
    display: block;
  }

  /* line 23, ../sass/_keyframes.sass */
  99% {
    opacity: 0;
    display: block;
  }

  /* line 26, ../sass/_keyframes.sass */
  100% {
    opacity: 0;
    display: none;
  }
}

@-o-keyframes offline-fadeout-and-hide {
  /* line 20, ../sass/_keyframes.sass */
  0% {
    opacity: 1;
    display: block;
  }

  /* line 23, ../sass/_keyframes.sass */
  99% {
    opacity: 0;
    display: block;
  }

  /* line 26, ../sass/_keyframes.sass */
  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes offline-fadeout-and-hide {
  /* line 20, ../sass/_keyframes.sass */
  0% {
    opacity: 1;
    display: block;
  }

  /* line 23, ../sass/_keyframes.sass */
  99% {
    opacity: 0;
    display: block;
  }

  /* line 26, ../sass/_keyframes.sass */
  100% {
    opacity: 0;
    display: none;
  }
}

@-webkit-keyframes offline-rotation {
  /* line 64, ../sass/_keyframes.sass */
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  /* line 66, ../sass/_keyframes.sass */
  100% {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}

@-moz-keyframes offline-rotation {
  /* line 64, ../sass/_keyframes.sass */
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  /* line 66, ../sass/_keyframes.sass */
  100% {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}

@-ms-keyframes offline-rotation {
  /* line 64, ../sass/_keyframes.sass */
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  /* line 66, ../sass/_keyframes.sass */
  100% {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}

@-o-keyframes offline-rotation {
  /* line 64, ../sass/_keyframes.sass */
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  /* line 66, ../sass/_keyframes.sass */
  100% {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}

@keyframes offline-rotation {
  /* line 64, ../sass/_keyframes.sass */
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  /* line 66, ../sass/_keyframes.sass */
  100% {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}

/* line 21, ../sass/offline-theme-default.sass */
.offline-ui {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  font-family: 'Roboto', 'Segoe UI', 'Droid Sans', Tahoma, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: 1em;
  top: 50px;
  width: 100%;
  max-width: 1300px;
  overflow: hidden;
}
@media (max-width: 38em) {
  /* line 21, ../sass/offline-theme-default.sass */
  .offline-ui {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    top: 0;
  }
}
/* line 34, ../sass/offline-theme-default.sass */
.offline-ui .offline-ui-content:before {
  line-height: 1.25em;
}
/* line 37, ../sass/offline-theme-default.sass */
.offline-ui .offline-ui-retry {
  position: absolute;
  right: 3em;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  text-decoration: none;
  color: inherit;
  line-height: 3.5em;
  height: 3.5em;
  margin: auto;
  padding: 0 1em;
}
/* line 50, ../sass/offline-theme-default.sass */
.offline-ui.offline-ui-up {
  -webkit-animation: offline-fadeout-and-hide forwards 0.5s 2s;
  -moz-animation: offline-fadeout-and-hide forwards 0.5s 2s;
  -ms-animation: offline-fadeout-and-hide forwards 0.5s 2s;
  -o-animation: offline-fadeout-and-hide forwards 0.5s 2s;
  animation: offline-fadeout-and-hide forwards 0.5s 2s;
  -webkit-backface-visibility: hidden;
  background: #2ecc71;
  box-shadow: -1000px 0 #2ecc71, 1000px 0 #2ecc71 !important;
  color: white;
}
/* line 55, ../sass/offline-theme-default.sass */
.offline-ui.offline-ui-down {
  -webkit-animation: offline-fadein 0.5s;
  -moz-animation: offline-fadein 0.5s;
  -ms-animation: offline-fadein 0.5s;
  -o-animation: offline-fadein 0.5s;
  animation: offline-fadein 0.5s;
  -webkit-backface-visibility: hidden;
  background: #e74c3c;
  box-shadow: -1000px 0 #e74c3c, 1000px 0 #e74c3c !important;
  color: white;
}
/* line 60, ../sass/offline-theme-default.sass */
.offline-ui.offline-ui-down.offline-ui-connecting, .offline-ui.offline-ui-down.offline-ui-waiting {
  background: #f1c40f;
  box-shadow: -1000px 0 #f1c40f, 1000px 0 #f1c40f !important;
  color: #7c6d1f;
  padding-right: 3em;
}
/* line 65, ../sass/offline-theme-default.sass */
.offline-ui.offline-ui-down.offline-ui-connecting:after, .offline-ui.offline-ui-down.offline-ui-waiting:after {
  -webkit-animation: offline-rotation 0.7s linear infinite;
  -moz-animation: offline-rotation 0.7s linear infinite;
  -ms-animation: offline-rotation 0.7s linear infinite;
  -o-animation: offline-rotation 0.7s linear infinite;
  animation: offline-rotation 0.7s linear infinite;
  -webkit-backface-visibility: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  content: " ";
  display: block;
  position: absolute;
  right: 1em;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 1em;
  width: 1em;
  border: 2px solid rgba(0, 0, 0, 0);
  border-top-color: #7c6d1f;
  border-left-color: #7c6d1f;
  opacity: 0.7;
}
/* line 82, ../sass/offline-theme-default.sass */
.offline-ui.offline-ui-down.offline-ui-waiting {
  padding-right: 11em;
}
/* line 85, ../sass/offline-theme-default.sass */
.offline-ui.offline-ui-down.offline-ui-waiting.offline-ui-reconnect-failed-2s {
  padding-right: 0;
}
